import React, { useEffect, useState } from 'react'
import { saveAs } from 'file-saver'
import { Box, Hidden, Typography, useMediaQuery } from '@material-ui/core'
import { DownloadButton, IconButton, InfoDialog, RoundedBox, Text } from '../'

import { Info } from 'react-feather'

import useFirstStep from 'hooks/useFirstStep'
import useAuth from 'hooks/useAuth'

import { getBaseURL } from 'service/env'

import constants from 'constants/index'
import helpers from 'helpers/index'

import useStyles from './styles'
import theme from 'theme'

import useSnackbar from 'hooks/useSnackbar'

const StampPage = () => {
  const snackbar = useSnackbar()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { activeStep, activeSubStep, setBackgroundKind } = useFirstStep()
  const { company } = useAuth()

  const baseURL = getBaseURL('dponet')
  const imageSrc = `${baseURL}/${company.impressionStamp}`
  const currentYear = new Date().getFullYear()

  const downloadStamp = () => {
    if (company?.statusId === constants.userCompanies.TRIAL_LIMITED_STATUS) {
      return snackbar.open({
        message:
          'Contrate nossa plataforma e aproveite ao máximo essa funcionalidade.',
        variant: 'error',
      })
    }
    saveAs(imageSrc, `selo-de-impressao-${currentYear}.png`)
  }

  useEffect(
    () => setBackgroundKind('topLeft'),
    //eslint-disable-next-line
    [],
  )

  const handleOpen = () => setOpen(true)

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  })

  return (
    <>
      <Text color="primary" fontSize="5vh" variant="h1" gutterBottom={isMobile}>
        Seja referência
      </Text>
      <Text
        color="secondary"
        fontSize="3vh"
        variant="h4"
        gutterBottom
        className={classes.noWrap}
      >
        Mostre que sua organização se preocupa com a proteção de dados pessoais.
      </Text>
      <RoundedBox>
        <Box
          width="100%"
          display="flex"
          {...(!isMobile && { maxHeight: '40vh' })}
        >
          <Hidden smDown>
            <Box maxWidth="40%" height="40vh">
              <img
                src={imageSrc}
                alt="stamp-client"
                width="auto"
                height="100%"
              />
            </Box>
          </Hidden>
          <Box
            minWidth={isMobile ? '100%' : '40%'}
            className={classes.card}
            pt={isMobile ? 2 : 0}
            px={isMobile ? 1 : 2}
          >
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box mt={isMobile ? 0 : 3}>
                <Typography
                  className={classes.titleCard}
                  variant={isMobile ? 'h3' : 'h1'}
                >
                  Selo
                </Typography>
              </Box>
              <IconButton
                {...(isMobile && { paddingButton: 4 })}
                icon={<Info />}
                onClick={handleOpen}
                mixpanelId={helpers.userSteps.defineMixpanelId(
                  activeStep,
                  activeSubStep,
                  'informação',
                )}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="space-between"
            >
              <Box width="100%" textAlign="left" my={1}>
                <Typography
                  variant={isMobile ? 'subtitle1' : 'h4'}
                  paragraph
                  className={classes.typographyDescription}
                >
                  O selo é o canal oficial de comunicação com o titular de
                  dados.
                </Typography>
                <Typography
                  variant={isMobile ? 'subtitle1' : 'h4'}
                  paragraph
                  className={classes.typographyDescription}
                >
                  Imprima e deixe visível para que todos possam obter mais
                  informações sobre a sua maturidade em adequação à LGPD. Basta
                  apontar a câmera do celular para o QR Code
                </Typography>
              </Box>
              <Hidden mdUp>
                <Box width="100%">
                  <img
                    src={imageSrc}
                    alt="stamp-client"
                    width="auto"
                    height="300px"
                  />
                </Box>
              </Hidden>
              <Box textAlign="left" pb={2}>
                <Typography variant="subtitle1" gutterBottom>
                  Selo de maturidade
                </Typography>
                <DownloadButton
                  label="baixar"
                  onClick={downloadStamp}
                  mixpanelId="download-etapa_selo"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </RoundedBox>
      <InfoDialog
        open={open}
        setOpen={setOpen}
        {...constants.userSteps.DIALOG_INFOS.STAMP}
      />
    </>
  )
}

export default StampPage
