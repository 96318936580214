import React from 'react'
import { Box, Grid, Paper } from '@material-ui/core'
import {
  CreditCard as CreditCardIcon,
  Mail as MailIcon,
  Users as UsersIcon,
} from 'react-feather'

import { InfoItem } from 'views/ThirdPartyManagements/components'

import useStyles from './styles'

const CompanySection = ({ company }) => {
  const classes = useStyles()

  const infos = [
    {
      label: 'Razão social',
      value: company?.name,
      Icon: UsersIcon,
    },
    {
      label: 'Documento',
      value: company?.document,
      Icon: CreditCardIcon,
    },
    {
      label: 'E-mail',
      value: company?.email,
      Icon: MailIcon,
    },
  ]

  return (
    <Paper component={Box} p={3} className={classes.paper}>
      <Grid container spacing={3}>
        {infos.map((info, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <InfoItem {...info} />
          </Grid>
        ))}
      </Grid>
    </Paper>
  )
}

export default CompanySection
