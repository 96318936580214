import constants from 'constants/index'

const getRiskLevelByEnum = (riskEnum) => {
  if (!riskEnum) return

  const risks = constants.thirdPartyManagements.RISKS_LIST

  return risks.find((risk) => risk.enum === riskEnum)?.value
}

export default getRiskLevelByEnum
