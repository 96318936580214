import React, { isValidElement } from 'react'
import { Check as CheckIcon } from 'react-feather'
import PropTypes from 'prop-types'
import { Box, Button, Dialog, Typography } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from './styles'
import theme from 'theme'

const ConfirmationDialog = ({
  actionAcceptButton,
  disableBackdropClick = false,
  hiddenIcon = false,
  open,
  setOpen,
  text,
  textButton = 'Confirmo',
  textButtonCancel = 'Voltar',
  variant = 'primary',
}) => {
  const classes = useStyles()

  const onClose = (e, reason) => {
    if (reason === 'backdropClick' && !disableBackdropClick) return
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        m={4}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gridGap={theme.spacing(2)}
        textAlign="center"
      >
        {isValidElement(text) ? (
          text
        ) : (
          <Typography variant="h3">{text}</Typography>
        )}
      </Box>
      <Box mx={3} mb={2} display="flex" justifyContent="center">
        <Box pr={1}>
          <Button
            disableElevation
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            {textButtonCancel}
          </Button>
        </Box>
        <Button
          disableElevation
          variant="contained"
          color="primary"
          onClick={actionAcceptButton}
          className={clsx({
            [classes.buttonErrorVariant]: variant === 'error',
          })}
          {...(!hiddenIcon && { startIcon: <CheckIcon /> })}
        >
          {textButton}
        </Button>
      </Box>
    </Dialog>
  )
}

ConfirmationDialog.propTypes = {
  actionAcceptButton: PropTypes.func.isRequired,
  disableBackdropClick: PropTypes.bool,
  hiddenIcon: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  textButton: PropTypes.string,
  textButtonCancel: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'error']),
}

export default ConfirmationDialog
