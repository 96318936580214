import theme from 'theme'

const BANNERS = {
  INTERMEDIATOR:
    'Utilize este link exclusivo da sua organização para que seus parceiros possam acessar e responder aos questionários de conformidade. Lembre-se de cadastrá-los antes de compartilhar o link!',
  INVITE:
    "Nesta seção é possível acompanhar todos os convites de avaliação de conformidade, tanto os enviados quanto os recebidos. Quando uma organização aceita seu convite, ela passa a ser listada na seção de 'Parceiros'.",
  PARTNER:
    'Os parceiros são as organizações com as quais você possui vínculo e compartilha dados pessoais. Nesta seção, você pode fazer o acompanhamento e gestão das conformidades, riscos, relatórios e demais informações associadas.',
  SUGGESTION:
    'Sempre que parceiros mencionados em seus mapeamentos de processos forem identificados como sem convites enviados ou status ativo, eles serão listados nesta aba como sugestões de parceiros.',
  PRE_AVALIATION:
    'A pré-avaliação permite que sua organização avalie a conformidade das empresas antes de contratá-las.',
}

const REGISTER_TYPES = {
  REGISTER: 'register',
  IMPORT: 'import',
  PRE_AVALIATION: 'preAvaliation',
}

const RISKS = {
  UNDEFINED: {
    value: 1,
    color: theme.palette.fragility.undefined,
    label: 'Indefinido',
    enum: 'undefined',
  },
  LOW: {
    value: 2,
    color: theme.palette.fragility.low,
    label: 'Baixo',
    enum: 'low',
  },
  MEDIUM: {
    value: 3,
    color: theme.palette.fragility.medium,
    label: 'Médio',
    enum: 'medium',
  },
  HIGH: {
    value: 4,
    color: theme.palette.fragility.high,
    label: 'Alto',
    enum: 'high',
  },
  SEVERE: {
    value: 5,
    color: theme.palette.fragility.severe,
    label: 'Severo',
    enum: 'severe',
  },
}

const RISKS_LIST = Object.values(RISKS)

const SUPPLIER_AUDIT_FREQUENCY_TYPES = [
  { id: 'days', name: 'Dia(s)' },
  { id: 'months', name: 'Mês(es)' },
  { id: 'years', name: 'Ano(s)' },
]

const thirdPartyManagements = {
  BANNERS,
  REGISTER_TYPES,
  RISKS,
  RISKS_LIST,
  SUPPLIER_AUDIT_FREQUENCY_TYPES,
}

export default thirdPartyManagements
