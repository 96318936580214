import api from 'service/api'

const dponetAPI = api.create('dponet')

const exportDocument = async ({ documentVersionId = '', ...params }) => {
  return await dponetAPI.get(`/document_versions/${documentVersionId}/export`, {
    params,
  })
}

export default {
  exportDocument,
}
