import * as yup from 'yup'

const complaintConfigurationSchema = yup.object().shape({
  subConfigurations: yup.array().of(
    yup.object().shape({
      categoryId: yup.string().required('Selecione uma categoria'),
      responsibles: yup.array().required('Selecione um responsável'),
    }),
  ),
  recurringEmail: yup.boolean().required(),
  recurringEmailDays: yup.string().when('recurringEmail', {
    is: true,
    then: yup
      .string()
      .required()
      .matches(/^[1-5]+$/, 'Selecione um número de 1 a 5'),
  }),
})

export default complaintConfigurationSchema
