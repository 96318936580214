import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { Box } from '@material-ui/core'

import useStyles from './styles'

const ConfigurationSkeleton = () => {
  const classes = useStyles()

  return (
    <Box pt={2}>
      <Box pt={2}>
        <Skeleton variant="rect" width={200} height={30} />
      </Box>
      <Box pt={2}>
        <Skeleton variant="rect" width="100%" height={177} />
      </Box>
      <Box pt={2}>
        <Skeleton variant="rect" width="100%" height={54} />
      </Box>
      <Box pt={2}>
        <Skeleton variant="rect" width="100%" height={162} />
      </Box>
      <Box className={classes.boxButtonSkeleton}>
        <Skeleton variant="rect" width={70} height={30} />
        <Skeleton variant="rect" width={70} height={30} />
      </Box>
    </Box>
  )
}

export default ConfigurationSkeleton
