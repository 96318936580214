import * as yup from 'yup'

const schema = yup.object().shape({
  simplified: yup.boolean().required(),
  international: yup.boolean().required(),
  name: yup.string().when('simplified', {
    is: false,
    then: yup.string().required(),
  }),
  document: yup.string().required(),
  email: yup.string().email().when('simplified', {
    is: false,
    then: yup.string().email().required(),
  }),
  date: yup.mixed().when('simplified', {
    is: false,
    then: yup.date().required().typeError('Preencha uma data válida'),
  }),
})

export default schema
