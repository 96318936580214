import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5),
    display: 'flex',
    background: `linear-gradient(180deg, ${theme.palette.primary.main} 49%, ${theme.palette.primary.hover} 100%)`,
    color: theme.palette.white,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 24,
    width: '100%',
  },
  textBox: {
    flex: 1,
  },
}))

export default styles
