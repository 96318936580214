import { makeStyles } from '@material-ui/styles'

const styles = makeStyles((theme) => ({
  boxButtonSkeleton: {
    display: 'flex',
    gap: '20px',
    justifyContent: 'end',
    paddingTop: '20px',
  },
}))

export default styles
