import React from 'react'
import { Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

const SkeletonForm = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Skeleton variant="rect" height={170} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rect" height={515} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rect" height={1000} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rect" height={300} />
      </Grid>
    </Grid>
  )
}

export default SkeletonForm
