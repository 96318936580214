import palette from 'theme/palette'

import { fontSize, PADDING, WIDTH } from '../constants'

const subtitle = (pdf, sub, positionY, textColor = palette.primary.main) => {
  const DEFAULT_WIDTH = WIDTH(pdf)

  pdf.setFont('Raleway-Bold')
  pdf.setFontSize(fontSize.subtitle)
  pdf.setTextColor(textColor)

  const splittedSub = pdf.splitTextToSize(sub, DEFAULT_WIDTH - PADDING * 2)
  const lineHeight = fontSize.subtitle * 1.2
  const totalHeight = splittedSub.length * lineHeight

  pdf.text(splittedSub, PADDING, positionY, null, null, 'left')

  return totalHeight
}

export default subtitle
