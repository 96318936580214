const status = (status, capitalized = false) => {
  const convertStatus = {
    pending: 'PENDENTE',
    in_progress: 'EM ANDAMENTO',
    adopted: 'ADOTADO',
    overdue: 'ATRASADO',
    risk_assumed: 'RISCO ASSUMIDO',
    treated: 'TRATADO',
    conclusion: 'FINALIZADO',
    initial: 'RASCUNHO',
    headway: 'PREENCHIDO',
    waiting_answer: 'AGUARDANDO RESPOSTA',
  }

  if (capitalized) {
    if (!status) return '-'

    const lowerCaseWord = convertStatus[status].toLowerCase()

    return lowerCaseWord[0].toUpperCase() + lowerCaseWord.slice(1)
  }

  return convertStatus[status] || '-'
}

export default status
