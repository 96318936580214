import React, { useState } from 'react'
import {
  Box,
  Button,
  Card,
  FormControlLabel,
  Switch,
  TablePagination,
  Typography,
  useMediaQuery,
} from '@material-ui/core'
import { Settings as SettingsIcon } from 'react-feather'
import { useHistory } from 'react-router-dom'

import {
  Container,
  ContentHeader,
  FilterButton,
  Filters,
  Page,
  Permitted,
} from 'components'
import { ComplaintTable } from './components'

import useFetch from 'hooks/useFetch'
import usePagination from 'hooks/usePagination'
import useFilter from 'hooks/useFilter'
import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'
import theme from 'theme'
import constants from 'constants/index'
import helpers from 'helpers'
import { routes } from 'Routes'

const ComplaintMain = () => {
  const [isLoadingActiveComplaints, setIsLoadingActiveComplaints] =
    useState(false)
  const { company } = useAuth()
  const [activateComplaints, setActivateComplaints] = useState(
    company.complaintActivated,
  )

  const filter = useFilter()
  const { page, perPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  const snackbar = useSnackbar()
  const history = useHistory()

  const { ALL_STATUS_ID, LIST_OF_CATEGORIES } = constants.complaint

  const { response, isLoading } = useFetch(
    service.dponet.complaint.get,
    {
      page,
      perPage,
      ...filter.filters,
    },
    [filter.filters, page, perPage],
  )

  const complaints = response?.data?.complaints

  const handleSelectSwitch = (event) => {
    const isChecked = event?.target?.checked
    setActivateComplaints(isChecked)
    onActivateComplaints(isChecked)
  }

  const goToConfig = () => {
    return history.push(routes.complaint.config)
  }

  const onActivateComplaints = async (checked) => {
    setIsLoadingActiveComplaints(true)
    try {
      await service.dponet.complaint.activateComplaints({
        complaint: {
          activateComplaints: checked,
        },
      })
      snackbar.open({
        message: `Canal de Denúncias ${
          checked ? 'ativado' : 'desativado'
        } com sucesso!`,
        variant: 'success',
      })
      setActivateComplaints(checked)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setIsLoadingActiveComplaints(false)
    }
  }

  return (
    <Page title="Canal de Denúncia">
      <Container maxWidth={false}>
        <Box>
          <ContentHeader title="Canal de Denúncia">
            <Box pl={1}>
              <FilterButton setDrawerOpen={filter.setDrawerOpen} />
            </Box>
            <Permitted
              someTags={[
                constants.permissions.COMPLAINTS.ACTIVATE_COMPLAINTS,
                constants.permissions.COMPLAINTS.LIST_CONFIGURATIONS,
                constants.permissions.COMPLAINTS.MANAGE_CONFIGURATIONS,
              ]}
            >
              <Box pl={1}>
                <Button
                  variant="outlined"
                  startIcon={<SettingsIcon size={20} />}
                  onClick={goToConfig}
                >
                  Configurações
                </Button>
              </Box>
            </Permitted>
          </ContentHeader>
        </Box>
        <Permitted tag={constants.permissions.COMPLAINTS.ACTIVATE_COMPLAINTS}>
          <Box mx={1}>
            <FormControlLabel
              control={
                <Switch
                  disabled={isLoadingActiveComplaints}
                  checked={activateComplaints}
                  onChange={handleSelectSwitch}
                  color="primary"
                />
              }
              label={
                <Typography color="primary" variant="h5">
                  Canal de Denúncias&nbsp;
                  {activateComplaints ? 'ativado' : 'desativado'}
                </Typography>
              }
            />
          </Box>
        </Permitted>
        <Card>
          <ComplaintTable complaints={complaints} isLoading={isLoading} />
          <Box px={2} display="flex" justifyContent="flex-end">
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </Box>
        </Card>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Identificador" name="id" />
          <select textfieldinput="true" label="Categoria" name="category">
            <option value="" />
            {LIST_OF_CATEGORIES.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
          <input
            datepickerinput="true"
            label="Data da denúncia"
            name="createdAt"
          />
          <select textfieldinput="true" label="Status" name="status">
            <option value=""></option>
            <option value={ALL_STATUS_ID.PENDING}>Pendente</option>
            <option value={ALL_STATUS_ID.FINALIZED}>Finalizado</option>
            <option value={ALL_STATUS_ID.CONVERTED_INCIDENT}>
              Convertido Incidente
            </option>
          </select>
        </Filters>
      </Container>
    </Page>
  )
}

export default ComplaintMain
