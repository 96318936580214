const routesWithLabel = (routes, currentRoute, currentPath) => {
  const defaultLabels = {
    // Dashboard
    [routes.myLgpd.dashboard]: '',
    // Data processes
    [routes.dataProcess.all]: 'Listagem',
    [routes.dataProcess.view]: 'Processo',
    [routes.dataProcess.new]: 'Processo',
    [routes.dataProcess.edit]: 'Processo',
    [routes.dataProcess.resume]: 'Processo',
    [routes.dataProcess.suggested]: 'Sugestão',
    [routes.dataProcess.audit]: 'Auditoria',
    [routes.dataProcess.suggestedChanges]: 'Alterações sugeridas ',
    // Departments
    [routes.department.all]: 'Listagem',
    [routes.department.new]: 'Departamento',
    [routes.department.edit]: 'Departamento',
    // Users
    [routes.user.all]: 'Listagem',
    [routes.user.new]: 'Usuário',
    [routes.user.edit]: 'Usuário',
    [routes.user.account]: 'Usuário',
    // Partners
    [routes.marketplace.all]: 'Listagem',
    [routes.marketplace.show]: 'Serviços',
    // Questionnaires
    [routes.questionnaire.all]: 'Listagem',
    // Incidents
    [routes.incidents.all]: 'Listagem',
    [routes.incidents.new]: 'Incidente',
    [routes.incidents.edit]: 'Incidente',
    // Profiles
    [routes.profiles.all]: 'Listagem',
    [routes.profiles.new]: 'Perfil',
    [routes.profiles.edit]: 'Perfil',
    // Fornecedores
    [routes.mySuppliers.all]: 'Listagem',
    [routes.mySuppliers.new]: 'Fornecedores',
    [routes.mySuppliers.show]: 'Visualizar fornecedor',
    [routes.mySuppliers.edit]: 'Fornecedores',
    [routes.mySuppliers.logs]: 'Histórico',
    [routes.supplier.all]: 'Listagem',
    [routes.supplier.show]: 'Visualizar cliente',
    [routes.supplier.logs]: 'Histórico',
  }

  return { url: currentPath, label: defaultLabels[currentRoute] }
}

const formatters = { routesWithLabel }

export default formatters
