import { colors } from '@material-ui/core'

const PENDING_STATUS_ID = 'pending'
const FINALIZED_STATUS_ID = 'finalized'
const CONVERTED_INCIDENT_STATUS_ID = 'converted_incident'

const PENDING_STATUS_LABEL = 'Pendente'
const FINALIZED_STATUS_LABEL = 'Finalizado'
const CONVERTED_INCIDENT_STATUS_LABEL = 'Convertido em incidente'

const COMPANY_RELATION_ANSWER_KIND = 'company_relation'
const REPORT_CLASSIFICATION_ANSWER_KIND = 'report_classification'
const DESCRIPTION_ANSWER_KIND = 'description'

const DPO_MESSAGE_KIND = 'dpo'
const AUTOMATIC_MESSAGE_KIND = 'automatic'

const ALL_STATUS_ID = {
  PENDING_STATUS_ID,
  FINALIZED_STATUS_ID,
  CONVERTED_INCIDENT_STATUS_ID,
}

const ALL_STATUS_LABEL = {
  PENDING_STATUS_LABEL,
  FINALIZED_STATUS_LABEL,
  CONVERTED_INCIDENT_STATUS_LABEL,
}

const ALL_MESSAGE_KINDS = {
  DPO_MESSAGE_KIND,
  AUTOMATIC_MESSAGE_KIND,
}

const STATUS_LABEL = {
  [PENDING_STATUS_ID]: PENDING_STATUS_LABEL,
  [FINALIZED_STATUS_ID]: FINALIZED_STATUS_LABEL,
  [CONVERTED_INCIDENT_STATUS_ID]: CONVERTED_INCIDENT_STATUS_LABEL,
}

const BOX_MESSAGE_COLOR = {
  [DPO_MESSAGE_KIND]: colors.blue[50],
  [AUTOMATIC_MESSAGE_KIND]: colors.grey[200],
}

const LIST_OF_CATEGORIES = [
  'Comportamento Ético',
  'Condições de Trabalho',
  'Desrespeito a Normas e Regulamentos',
  'Desvios de Conduta',
  'Direitos do Consumidor',
  'Discriminação e Assédio',
  'Privacidade e Proteção de Dados',
  'Saúde e Meio Ambiente',
  'Violação de Direitos Humanos',
  'Violência e Segurança',
  'Elogio',
  'Sugestões',
  'Outros',
]

const COMPLAINT_CATEGORIES = [
  { id: 1, name: 'Comportamento Ético' },
  { id: 2, name: 'Condições de Trabalho' },
  { id: 3, name: 'Desrespeito a Normas e Regulamentos' },
  { id: 4, name: 'Desvios de Conduta' },
  { id: 5, name: 'Direitos do Consumidor' },
  { id: 6, name: 'Discriminação e Assédio' },
  { id: 7, name: 'Privacidade e Proteção de Dados' },
  { id: 8, name: 'Saúde e Meio Ambiente' },
  { id: 9, name: 'Violação de Direitos Humanos' },
  { id: 10, name: 'Violência e Segurança' },
  { id: 11, name: 'Elogio' },
  { id: 12, name: 'Sugestões' },
  { id: 13, name: 'Outros' },
]

const COMPLAINT_SUBCATEGORIES = [
  { id: 1, name: 'Corrupção' },
  { id: 2, name: 'Conflito de interesses' },
  { id: 3, name: 'Fraude financeira' },
  { id: 4, name: 'Uso indevido de recursos da empresa' },
  { id: 5, name: 'Violação de políticas internas' },
  { id: 6, name: 'Condições inseguras de trabalho' },
  { id: 7, name: 'Exposição a ambientes insalubres' },
  { id: 8, name: 'Falta de equipamentos de proteção individual (EPI)' },
  { id: 9, name: 'Jornada de trabalho excessiva' },
  { id: 10, name: 'Violação de normas trabalhistas' },
  { id: 11, name: 'Não conformidade com regulamentos de saúde e segurança' },
  { id: 12, name: 'Não cumprimento de contratos' },
  { id: 13, name: 'Comportamento antiético ou impróprio' },
  { id: 14, name: 'Uso de linguagem ofensiva' },
  { id: 15, name: 'Abuso de poder' },
  { id: 16, name: 'Publicidade enganosa' },
  { id: 17, name: 'Cobrança indevida' },
  { id: 18, name: 'Produto ou serviço defeituoso' },
  { id: 19, name: 'Atendimento inadequado' },
  { id: 20, name: 'Discriminação racial' },
  { id: 21, name: 'Discriminação de gênero' },
  { id: 22, name: 'Discriminação por orientação sexual' },
  { id: 23, name: 'Discriminação por idade' },
  { id: 24, name: 'Discriminação por deficiência' },
  { id: 25, name: 'Assédio moral' },
  { id: 26, name: 'Assédio sexual' },
  { id: 27, name: 'Vazamento de informações pessoais' },
  { id: 28, name: 'Uso indevido de dados' },
  { id: 29, name: 'Falta de transparência no tratamento de dados pessoais' },
  { id: 30, name: 'Poluição ambiental' },
  { id: 31, name: 'Descarte inadequado de resíduos' },
  { id: 32, name: 'Violação de normas sanitárias' },
  { id: 33, name: 'Contaminação de recursos naturais' },
  { id: 34, name: 'Trabalho escravo ou infantil' },
  { id: 35, name: 'Tráfico de pessoas' },
  { id: 36, name: 'Exploração de trabalhadores' },
  { id: 37, name: 'Agressão física' },
  { id: 38, name: 'Ameaça ou intimidação' },
  { id: 39, name: 'Violação de protocolos de segurança' },
]

export default {
  STATUS_LABEL,
  ALL_STATUS_ID,
  ALL_STATUS_LABEL,
  COMPANY_RELATION_ANSWER_KIND,
  REPORT_CLASSIFICATION_ANSWER_KIND,
  DESCRIPTION_ANSWER_KIND,
  LIST_OF_CATEGORIES,
  DPO_MESSAGE_KIND,
  AUTOMATIC_MESSAGE_KIND,
  ALL_MESSAGE_KINDS,
  BOX_MESSAGE_COLOR,
  COMPLAINT_CATEGORIES,
  COMPLAINT_SUBCATEGORIES,
}
