import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2.5),
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.25),
  },
  inputDays: {
    width: '20%',
    [theme.breakpoints.down('lg')]: {
      width: '25%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}))

export default styles
