import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: ({ currentStep }) => ({
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'flex-start',
    minHeight: '100vh',
    paddingTop: currentStep === 1 ? 40 : currentStep === 6 ? 70 : 152,
    [theme.breakpoints.down('md')]: {
      paddingTop: currentStep === 1 ? 40 : currentStep === 6 ? 70 : 50,
    },
  }),
}))

export default styles
