import constants from 'constants/index'

const getTreatmentAgentLabelByEnum = (treatmentAgentEnum) => {
  if (!treatmentAgentEnum) return

  const treatmentAgents = constants.dataTreatmentAgents.TRANSLATED_TYPE

  return treatmentAgents[treatmentAgentEnum.toUpperCase()]
}

export default getTreatmentAgentLabelByEnum
