import PropTypes from 'prop-types'
import { colors, makeStyles } from '@material-ui/core'

import constants from 'constants/index'

const { CHIP_STATUS_COLOR } = constants.documents

const styles = (status) =>
  makeStyles((theme) => ({
    title: {
      marginRight: theme.spacing(2),
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      gridGap: theme.spacing(2),
      padding: '16px !important',
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    chip: {
      width: '100%',
      fontWeight: 600,
      textTransform: 'uppercase',
      backgroundColor: CHIP_STATUS_COLOR[status] || colors.grey[300],
      color: theme.palette.common.white,
    },
    categoryChip: {
      textTransform: 'uppercase',
      fontSize: theme.typography.caption.fontSize,
      paddingInline: theme.spacing(1),
      backgroundColor: colors.lightBlue[100],
      color: colors.blue[600],
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    lineBar: {
      height: 7,
      borderRadius: 5,
      backgroundColor: theme.palette.subscription.primary.light,
    },
    percentage: {
      lineHeight: 0,
    },
  }))

styles.propTypes = {
  status: PropTypes.string,
}

export default styles
