import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { isEmpty } from 'lodash'
import { Alert } from '@material-ui/lab'
import { Box, Button, Tab, Tabs, Tooltip } from '@material-ui/core'

import {
  Container,
  ContentHeader,
  DriveTour,
  FilterButton,
  LoadingFeedback,
  NoData2,
  Page,
  Permitted,
} from 'components'
import {
  DepreciatedDocuments,
  DocumentsFilters,
  DocumentsTemplate,
  MyDocuments,
} from './components'

import helpers from 'helpers'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import usePermissions from 'hooks/usePermissions'

import theme from 'theme'
import { routes } from 'Routes'
import * as service from 'service'
import constants from 'constants/index'

const DocumentsMain = () => {
  const {
    CATEGORIES_CARD_TABS,
    DOCUMENTS_CATEGORY_ID,
    DOCUMENTS_TEMPLATES_CATEGORY_ID,
    DEPRECIATED_DOCUMENTS_CATEGORY_ID,
  } = constants.documents

  const {
    MAIN_STEPS_OBJECT,
    MAIN_STEP_1,
    MAIN_STEP_2,
    MAIN_STEP_3,
    MAIN_STEP_4,
    MAIN_STEP_5,
    MAIN_STEP_6,
  } = constants.tours.documents

  const { PRIVACY_POLICIES, DOCUMENTS_TEMPLATE, DOCUMENT } =
    constants.permissions

  const [loading, setLoading] = useState()
  const [openTour, setOpenTour] = useState(false)
  const [currentTab, setCurrentTab] = useState(null)
  const [hasDocument, setHasDocument] = useState(false)
  const [renderFilter, setRenderFilter] = useState(true)

  const history = useHistory()
  const filter = useFilter()
  const permissions = usePermissions()

  const isTemplates = currentTab === DOCUMENTS_TEMPLATES_CATEGORY_ID
  const isDepreciatedDocuments =
    currentTab === DEPRECIATED_DOCUMENTS_CATEGORY_ID

  const docCreatePermission = permissions.permitted(PRIVACY_POLICIES.CREATE)
  const docListPermission = permissions.permitted(PRIVACY_POLICIES.LIST)
  const modelListPermission = permissions.permitted(DOCUMENTS_TEMPLATE.LIST)
  const oldDocListPermission = permissions.permitted(DOCUMENT.LIST)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.documentsTemplate.getCategories,
    {},
    [isTemplates, isDepreciatedDocuments],
    !!isTemplates || !!isDepreciatedDocuments,
  )

  const documentCategories = response?.data?.documentCategories || []

  const {
    response: oldDocumentsResponse,
    refresh: oldDocumentsRefresh,
    isLoading: oldDocumentsIsLoading,
  } = useFetch(
    service.dponet.companyDocuments.get,
    {
      page: 1,
      perPage: 1,
    },
    [],
    oldDocListPermission,
  )

  const oldDocuments = oldDocumentsResponse?.data?.documents

  const isLoadingTemplates = filter.drawerOpen && isLoading

  const hasSomePermission =
    docListPermission || modelListPermission || oldDocListPermission

  const handleNavigationForCreation = () => history.push(routes.documents.new)

  const handleTabChange = (_, clickedTab) => setCurrentTab(clickedTab)

  const handleOpenTour = () => {
    const where = 'Documentos'
    const params = {
      action: 'tour-listagem-documentos',
    }

    helpers.mixpanel.track(where, 'Tour', params)
    setOpenTour(true)
  }

  const filteredSteps = MAIN_STEPS_OBJECT.filter((step) => {
    if (!docCreatePermission) return step.element !== '#' + MAIN_STEP_2
    if (!docListPermission) return step.element !== '#' + MAIN_STEP_3
    if (!modelListPermission) return step.element !== '#' + MAIN_STEP_4
    if (!oldDocListPermission) return step.element !== '#' + MAIN_STEP_5
    if (!hasDocument) return step.element !== '#' + MAIN_STEP_6

    return true
  })

  useEffect(() => {
    if (docListPermission) {
      setCurrentTab(DOCUMENTS_CATEGORY_ID)
    } else if (modelListPermission) {
      setCurrentTab(DOCUMENTS_TEMPLATES_CATEGORY_ID)
    } else if (oldDocListPermission) {
      setCurrentTab(DEPRECIATED_DOCUMENTS_CATEGORY_ID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setRenderFilter(false)
    const timer = setTimeout(() => {
      setRenderFilter(true)
    }, 100)

    return () => clearTimeout(timer)
  }, [currentTab])

  return (
    <Page title="Documentos">
      <LoadingFeedback open={loading || isLoadingTemplates} />
      <Container maxWidth={false}>
        <ContentHeader title="Documentos" gridGap={theme.spacing(2)}>
          <Box>
            <Tooltip title="Iniciar o tour guiado">
              <Button
                variant="contained"
                color="primary"
                startIcon={<HelpCircleIcon size={20} />}
                onClick={handleOpenTour}
                id={MAIN_STEP_1}
                fullWidth
              >
                Tutorial
              </Button>
            </Tooltip>
          </Box>
          <Permitted tag={PRIVACY_POLICIES.CREATE}>
            <Button
              color="primary"
              variant="contained"
              onClick={handleNavigationForCreation}
              id={MAIN_STEP_2}
            >
              Novo documento
            </Button>
          </Permitted>
          <FilterButton setDrawerOpen={filter.setDrawerOpen} />
        </ContentHeader>
        {!isEmpty(oldDocuments) && !oldDocumentsIsLoading && (
          <Alert severity="info" component={Box} mb={3}>
            Alguns de seus documentos estão desatualizados. Atualize-os para o
            novo padrão da plataforma, pois em breve deixarão de estar
            disponíveis.
          </Alert>
        )}
        {!!currentTab && (
          <Box display="flex" flexDirection="column">
            <Tabs
              scrollButtons="auto"
              value={currentTab}
              variant="scrollable"
              textColor="primary"
              indicatorColor="primary"
              onChange={handleTabChange}
            >
              {CATEGORIES_CARD_TABS.filter((tab) =>
                permissions.permitted(tab.permission),
              )?.map((tab, index) => (
                <Tab
                  key={index}
                  id={tab.id}
                  label={tab.label}
                  value={tab.value}
                />
              ))}
            </Tabs>
            {currentTab === DOCUMENTS_CATEGORY_ID && (
              <MyDocuments
                setLoading={setLoading}
                setHasDocument={setHasDocument}
                filter={filter}
              />
            )}
            {currentTab === DOCUMENTS_TEMPLATES_CATEGORY_ID && (
              <DocumentsTemplate filter={filter} />
            )}
            {currentTab === DEPRECIATED_DOCUMENTS_CATEGORY_ID && (
              <DepreciatedDocuments
                filter={filter}
                setLoading={setLoading}
                documentCategories={documentCategories}
                documentCategoriesRefresh={refresh}
                oldDocumentsRefresh={oldDocumentsRefresh}
              />
            )}
          </Box>
        )}
        {!hasSomePermission && (
          <NoData2
            title="Você não tem permissão para visualizar nenhum tipo de documento."
            subTitle="Para obter acesso, contate um superior da empresa e solicite a permissão."
          />
        )}
      </Container>
      {renderFilter && (
        <DocumentsFilters
          key={`documents-filters-${currentTab}`}
          filter={filter}
          categories={documentCategories}
          kind={currentTab}
        />
      )}
      <DriveTour
        stepsMatrix={filteredSteps}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default DocumentsMain
