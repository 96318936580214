import calculateRisk from './calculateRisk'
import convertFragilityValue from './convertFragilityValue'
import fragilityEntitiesLabel from './fragilityEntitiesLabel'
import idToLabel from './idToLabel'
import idToValue from './idToValue'
import valueToId from './valueToId'

const fragilities = {
  calculateRisk,
  convertFragilityValue,
  fragilityEntitiesLabel,
  idToLabel,
  idToValue,
  valueToId,
}

export default fragilities
