const statusChipColor = (status, classes) => {
  const convertStatus = {
    pending: classes.pending,
    in_progress: classes.inProgress,
    adopted: classes.adopted,
    overdue: classes.overdue,
    risk_assumed: classes.riskAssumed,
    treated: classes.treated,
    conclusion: classes.conclusion,
    initial: classes.initial,
    headway: classes.headway,
    waiting_answer: classes.waitingAnswer,
  }

  return convertStatus[status] || classes.pending
}

export default statusChipColor
