import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { reverse } from 'named-urls'
import { routes } from 'Routes'
import {
  Box,
  CircularProgress,
  Typography,
  Button,
  Chip,
} from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { MenuButton, Permitted } from 'components'
import DialogChangeStatus from '../DialogChangeStatus'
import DialogDestroy from '../DialogDestroy'

import helpers from 'helpers'

import useStyles from '../../styles'
import constants from 'constants/index'

const TableRowDepartment = ({
  department,
  setDialogOpen,
  setOpenDestroy,
  setDepartment,
  setActive,
  ...rest
}) => {
  const history = useHistory()
  const classes = useStyles()

  const navigateToEdit = (departmentId) => {
    history.push(
      reverse(routes.department.edit, {
        departmentId: departmentId,
      }),
    )
  }

  const handleOpenDialog = (active, departmentId) => {
    setActive(active)
    setDepartment(departmentId)
    setDialogOpen(true)
  }

  const handleDestroy = (departmentId) => {
    setDepartment(departmentId)
    setOpenDestroy(true)
  }

  return (
    <TableRow {...rest}>
      <TableCell>{department.id}</TableCell>
      <TableCell>
        <Typography>{department.name}</Typography>
      </TableCell>
      <TableCell>
        <Chip
          size="small"
          className={helpers.departments.statusChipColor(
            department.active,
            classes,
          )}
          label={helpers.departments.status(department.active)}
        />
      </TableCell>
      <TableCell>{helpers.formatters.date(department.createdAt)}</TableCell>
      <Permitted
        someTags={[
          constants.permissions.DEPARTMENTS.ACTIVATE_OR_INACTIVATE,
          constants.permissions.DEPARTMENTS.CREATE,
        ]}
      >
        <TableCell align="right">
          <MenuButton id={constants.departments.DEPARTMENT_MAIN_DRIVER_STEP_2}>
            <Permitted tag={constants.permissions.DEPARTMENTS.CREATE}>
              <Button
                color="secondary"
                onClick={() => navigateToEdit(department.id)}
                fullWidth
                size="small"
              >
                Editar
              </Button>
            </Permitted>
            <Permitted
              tag={constants.permissions.DEPARTMENTS.ACTIVATE_OR_INACTIVATE}
            >
              <Button
                color="secondary"
                onClick={() =>
                  handleOpenDialog(department.active, department.id)
                }
                fullWidth
                size="small"
              >
                {department.active ? 'Inativar' : 'Ativar'}
              </Button>
            </Permitted>
            <Permitted
              someTags={[
                constants.permissions.DEPARTMENTS.CREATE,
                constants.permissions.DEPARTMENTS.DESTROY,
              ]}
            >
              <Button
                color="secondary"
                onClick={() => handleDestroy(department.id)}
                fullWidth
                size="small"
              >
                Excluir
              </Button>
            </Permitted>
          </MenuButton>
        </TableCell>
      </Permitted>
    </TableRow>
  )
}

const DepartmentsTable = ({ departments, isLoading, onEvent }) => {
  const [department, setDepartment] = useState()
  const [active, setActive] = useState()
  const [dialogChangeStatus, setDialogChangeStatus] = useState(false)
  const [dialogDestroy, setDialogDestroy] = useState(false)

  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box width="100%">
          <PerfectScrollbar
            options={{ wheelPropagation: false, useBothWheelAxes: true }}
          >
            <Table
              size="small"
              emptyMessage="Nenhum departamento encontrado"
              isLoading={isLoading}
              noWrap
            >
              <TableHead>
                <TableRow>
                  <TableCell width="20%">Identificador</TableCell>
                  <TableCell width="30%">Departamento</TableCell>
                  <TableCell width="10%">Status</TableCell>
                  <TableCell width="20%">Criado em</TableCell>
                  <Permitted
                    someTags={[
                      constants.permissions.DEPARTMENTS.ACTIVATE_OR_INACTIVATE,
                      constants.permissions.DEPARTMENTS.CREATE,
                      constants.permissions.DEPARTMENTS.DESTROY,
                    ]}
                  >
                    <TableCell width="20%" align="right">
                      Ações
                    </TableCell>
                  </Permitted>
                </TableRow>
              </TableHead>

              <TableBody>
                {departments &&
                  departments.map((department) => (
                    <TableRowDepartment
                      department={department}
                      key={department.id}
                      setDepartment={setDepartment}
                      setActive={setActive}
                      setDialogOpen={setDialogChangeStatus}
                      setOpenDestroy={setDialogDestroy}
                      hover
                    />
                  ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
          {departments && (
            <>
              <DialogChangeStatus
                active={active}
                departmentId={department}
                open={dialogChangeStatus}
                setOpen={setDialogChangeStatus}
                onEvent={onEvent}
              />
              <DialogDestroy
                departmentId={department}
                open={dialogDestroy}
                setOpen={setDialogDestroy}
                onEvent={onEvent}
              />
            </>
          )}
        </Box>
      )}
    </>
  )
}

export default DepartmentsTable
