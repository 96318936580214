import constants from 'constants/index'

const getEnumRiskByLevel = (riskLevel) => {
  if (!riskLevel) return

  const risks = constants.thirdPartyManagements.RISKS_LIST

  return risks.find((risk) => risk.value === riskLevel)?.enum
}

export default getEnumRiskByLevel
