import he from 'he'

const formatContent = (htmlString = '') =>
  he
    .decode(htmlString)
    .replace(/<\/(p|div|h[1-6]|li|ul|ol|blockquote|pre)>/g, '\n')
    .replace(/<br\s*\/?>/g, '\n')
    .replace(/<[^>]+>/g, '')
    .replace(/\n\s*\n/g, '\n')

export default { formatContent }
