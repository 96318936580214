import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'

import { X as CloseIcon } from 'react-feather'

import constants from 'constants/index'
import useStyles from './styles'

const BaseDialog = ({
  actionButton = () => {},
  actionButtonText = '',
  children,
  closeButtonText = '',
  closeIconButton = false,
  companySupplier,
  dialogActions,
  dialogSize = 'sm',
  disableButton = false,
  formRef,
  fullWidth = false,
  justifyActions = 'flex-start',
  loading = false,
  mixpanelTrack,
  open,
  resetFields,
  setOpen = () => {},
  tag = '',
  title = '',
  withoutBottomDivider = false,
}) => {
  const classes = useStyles()
  const newTag = tag === constants.lastUpdates.KINDS_LABELS.N

  const handleClose = () => {
    if (companySupplier?.canResendSolicitation === true) {
      mixpanelTrack()
    }
    if (resetFields) {
      resetFields(null)
    }
    setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={dialogSize}
      fullWidth={!!fullWidth}
      PaperProps={{
        className: classes.dialogRoot,
      }}
    >
      <DialogTitle
        disableTypography
        classes={{ root: classes.muiDialogTitleOverride }}
      >
        <Box className={classes.boxTitle}>
          <Box className={classes.boxTitleTwo}>
            {tag && (
              <Box>
                <Chip
                  className={newTag ? classes.new : classes.improvement}
                  label={tag}
                />
              </Box>
            )}
            <Box>
              <Tooltip
                title={title}
                placement="top"
                disableHoverListener={!tag}
              >
                <Typography className={classes.title} variant="h4">
                  {title}
                </Typography>
              </Tooltip>
            </Box>
          </Box>
          {closeIconButton && (
            <IconButton onClick={actionButton}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ padding: tag && '10px 40px' }}>
        {children}
      </DialogContent>
      {!withoutBottomDivider && (
        <>
          <Divider />
          <DialogActions>
            <Box display="flex" justifyContent={justifyActions} width="100%">
              {dialogActions ?? (
                <Fragment>
                  <Box pl={2} pr={1}>
                    <Button
                      type="button"
                      variant="outlined"
                      onClick={handleClose}
                    >
                      {closeButtonText}
                    </Button>
                  </Box>
                  {actionButtonText && (
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      onClick={actionButton}
                      disabled={loading || disableButton}
                      form={formRef}
                    >
                      {actionButtonText}
                    </Button>
                  )}
                </Fragment>
              )}
            </Box>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

BaseDialog.propTypes = {
  actionButton: PropTypes.func,
  actionButtonText: PropTypes.string,
  children: PropTypes.node.isRequired,
  closeButtonText: PropTypes.string,
  closeIconButton: PropTypes.bool,
  companySupplier: PropTypes.object,
  dialogActions: PropTypes.node,
  dialogSize: PropTypes.oneOf(['lg', 'md', 'sm', 'xl', 'xs']),
  disableButton: PropTypes.bool,
  formRef: PropTypes.string,
  fullWidth: PropTypes.bool,
  justifyActions: PropTypes.string,
  loading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  tag: PropTypes.string,
  title: PropTypes.string,
  withoutBottomDivider: PropTypes.bool,
}

export default BaseDialog
