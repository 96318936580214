import React, { Fragment, useState } from 'react'
import {
  PlusCircle as PlusCircleIcon,
  MinusCircle as MinusCircleIcon,
} from 'react-feather'
import PropTypes from 'prop-types'
import { Box, Button, Divider } from '@material-ui/core'

import { DialogConsentField } from 'components'

import useStyles from '../../styles'

const FieldMenu = ({
  selectedItems,
  setSelectedItems,
  customFields,
  setCustomFields,
  disabled = false,
}) => {
  const [openDialog, setOpenDialog] = useState(false)

  const classes = useStyles()

  const handleToggle = (field) => {
    const index = selectedItems.findIndex(
      (fieldSought) => fieldSought.name === field.name,
    )
    const newSelectedItems = [...selectedItems]

    if (index === -1) {
      newSelectedItems.push({
        ...customFields.find((fieldSought) => fieldSought.name === field.name),
        selected: true,
      })
    } else {
      if (field.name === 'E-mail') return

      newSelectedItems.splice(index, 1)
    }

    setSelectedItems(newSelectedItems)
  }

  const handleOpenDialog = () => {
    setOpenDialog(!openDialog)
  }

  return (
    <>
      <Box className={classes.menuListBox}>
        <Fragment>
          {customFields?.map((item) => (
            <Box key={item.name}>
              <Button
                role="listitem"
                onClick={() => handleToggle(item)}
                className={classes.buttonField}
                startIcon={
                  selectedItems.some(
                    (selectedItem) =>
                      selectedItem.name === item.name && selectedItem.selected,
                  ) ? (
                    <MinusCircleIcon size={20} />
                  ) : (
                    <PlusCircleIcon size={20} />
                  )
                }
                fullWidth
                disabled={disabled}
              >
                {item.name}
              </Button>
              <Divider />
            </Box>
          ))}
          <Box>
            <Button
              onClick={handleOpenDialog}
              className={classes.buttonField}
              startIcon={<PlusCircleIcon size={20} />}
              fullWidth
              disabled={disabled}
            >
              Criar novo campo
            </Button>
          </Box>
        </Fragment>
      </Box>
      <DialogConsentField
        open={openDialog}
        onClose={handleOpenDialog}
        action={'create'}
        setCustomFields={setCustomFields}
        customFields={customFields}
      />
      <Box />
    </>
  )
}

FieldMenu.propTypes = {
  selectedItems: PropTypes.arrayOf(PropTypes.object),
  setSelectedItems: PropTypes.func.isRequired,
  customFields: PropTypes.arrayOf(PropTypes.object),
  setCustomFields: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

export default FieldMenu
