import allowDocumentUpload from './allowDocumentUpload'
import convertSourceDescription from './convertSourceDescription'
import convertSourceDescriptionToSource from './convertSourceDescriptionToSource'
import convertSourceDescriptionToText from './convertSourceDescriptionToText'
import convertSourceKind from './convertSourceKind'
import covertDataSourcesToOptions from './covertDataSourcesToOptions'
import discardMode from './discardMode'
import driverJs from './driverJs'
import formatAnswerField from './formatAnswerField'
import formatByNameInFilter from './formatByNameInFilter'
import formatLegalFrameworksFilterOptions from './formatLegalFrameworksFilterOptions'
import fragilityColor from './fragilityColor'
import fragilityLabel from './fragilityLabel'
import getSourceFilterLabel from './getSourceFilterLabel'
import iconDataProcessSources from './iconDataProcessSources'
import labelDataProcessSourcesTable from './labelDataProcessSourcesTable'
import labelSource from './labelSource'
import messagesCopyDepartments from './messagesCopyDepartments'
import mountDataProcessSources from './mountDataProcessSources'
import mountTitle from './mountTitle'
import numberGrid from './numberGrid'
import possibleMinor from './possibleMinor'
import retentionFinality from './retentionFinality'
import selectSourceOptions from './selectSourceOptions'
import selectVolumetryOptions from './selectVolumetryOptions'
import status from './status'
import statusColor from './statusColor'
import statusesCheck from './statusesCheck'
import storageModeType from './storageModeType'
import storageTimeValidation from './storageTimeValidation'
import textFragilityColor from './textFragilityColor'
import textStatusColor from './textStatusColor'
import orderLegalFrameworks from './orderLegalFrameworks'

export default {
  allowDocumentUpload,
  convertSourceDescription,
  convertSourceDescriptionToSource,
  convertSourceDescriptionToText,
  convertSourceKind,
  covertDataSourcesToOptions,
  discardMode,
  driverJs,
  formatAnswerField,
  formatByNameInFilter,
  formatLegalFrameworksFilterOptions,
  fragilityColor,
  fragilityLabel,
  getSourceFilterLabel,
  iconDataProcessSources,
  labelDataProcessSourcesTable,
  labelSource,
  messagesCopyDepartments,
  mountDataProcessSources,
  mountTitle,
  numberGrid,
  possibleMinor,
  retentionFinality,
  selectSourceOptions,
  selectVolumetryOptions,
  status,
  statusColor,
  statusesCheck,
  storageModeType,
  storageTimeValidation,
  textFragilityColor,
  textStatusColor,
  orderLegalFrameworks,
}
