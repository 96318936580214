import React from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'

import constants from 'constants/index'

const QuestionnaireCheckbox = ({ questionnaire, checked, onChange }) => {
  const fundamentalRequirement =
    constants.questionControl.KIND.BASIC_REQUIREMENT === questionnaire.name
  const label = fundamentalRequirement
    ? `${questionnaire.name} (obrigatório)`
    : questionnaire.name

  return (
    <FormControlLabel
      key={questionnaire.id}
      control={
        <Checkbox
          name={`${questionnaire.id}`}
          checked={checked || fundamentalRequirement}
          onChange={onChange}
          disabled={fundamentalRequirement}
        />
      }
      label={label}
    />
  )
}

export default QuestionnaireCheckbox
