import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto minmax(0, 1fr)',
    gridGap: theme.spacing(2),
  },
  infoBox: {
    marginTop: 2,
  },
  iconBox: {
    width: 40,
    height: 40,
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  typographyOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  typographyBreakLine: {
    whiteSpace: 'pre-wrap',
  },
}))

export default styles
