import axios from 'axios'
import api from 'service/api'
import * as service from 'service'
import { getBaseURL } from 'service/env'

const dponetAPI = api.create('dponet')
const baseURL = getBaseURL('dponet')

const get = async ({ userId = '', ...params }) => {
  return await dponetAPI.get(`/users/${userId}`, { params })
}

const create = async ({ ...data }) => {
  return await dponetAPI.post('/users', data)
}

const importUsers = async ({ fileToImport, profileId }) => {
  var formData = new FormData()

  formData.append('file_to_import', fileToImport)
  formData.append('profile_id', profileId)

  const config = {
    headers: {
      'Content-Type': fileToImport.type,
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }
  return await axios({
    method: 'POST',
    url: `${baseURL}/users/import_users`,
    data: formData,
    ...config,
  })
}

const edit = async ({ userId = '', ...data }) => {
  return await dponetAPI.put(`/users/${userId}`, data)
}

const editSelf = async ({ ...data }) => {
  return await dponetAPI.put('/users/update_self', data)
}

const newPassword = async (data) => {
  return await dponetAPI.put(`/users/password`, { api_user: data })
}

const permissions = async () => {
  return await dponetAPI.get('/user/permissions')
}

const me = async () => {
  return await dponetAPI.get('/user/me')
}

const changeStatus = async ({ userId }) => {
  return await dponetAPI.put(`/users/${userId}/change_status`)
}

const updatePassword = async (data) => {
  return await dponetAPI.post(`/users/update_password`, data)
}

const createStep = async (userId) => {
  return await dponetAPI.post(`/users/${userId}/users_steps/`)
}

const updateStep = async ({ userId, stepId, ...data }) => {
  return await dponetAPI.put(`/users/${userId}/users_steps/${stepId}`, {
    userStep: data,
  })
}

const changeResponsable = async (data) => {
  return await dponetAPI.post(`/users/change_responsable`, {
    user: data,
  })
}

const solicitationBannerCookies = async ({ userId }) => {
  return await dponetAPI.get(
    `/users/${userId}/users_steps/solicitation_banner_cookies`,
  )
}

export default {
  changeResponsable,
  changeStatus,
  create,
  createStep,
  edit,
  editSelf,
  get,
  importUsers,
  me,
  newPassword,
  permissions,
  solicitationBannerCookies,
  updatePassword,
  updateStep,
}
