import * as yup from 'yup'

import constants from 'constants/index'
import { isEmpty } from 'lodash'

const schema = yup.object().shape({
  mode: yup.string().required(),
  email: yup
    .string()
    .email()
    .when(['mode', 'authorizedEmails'], {
      is: (mode, authorizedEmails) =>
        mode === constants.documents.RESTRICTED_SHARING_MODE_ID &&
        isEmpty(authorizedEmails),
      then: (schemaItem) => schemaItem.required(),
    }),
  authorizedEmails: yup.array().of(yup.string().email()),
})

export default schema
