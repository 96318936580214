import { makeStyles } from '@material-ui/core'

const styles = makeStyles(() => ({
  root: {
    color: 'white',
    textTransform: 'uppercase',
  },
  pending: {
    backgroundColor: '#FF9800',
  },
  accepted: {
    backgroundColor: '#4CAF50',
  },
  refused: {
    backgroundColor: '#E53555',
  },
}))

export default styles
