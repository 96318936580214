import React from 'react'
import { Box, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import theme from 'theme'

const SkeletonPage = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={5}>
        <Box display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
          <Skeleton variant="rect" height={15} width={280} />
          <Skeleton variant="rect" height={240} />
        </Box>
      </Grid>
      <Grid item xs={12} lg={7}>
        <Box display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
          <Skeleton variant="rect" height={15} width={400} />
          <Skeleton variant="rect" height={240} />
        </Box>
      </Grid>
    </Grid>
  )
}

export default SkeletonPage
