import api from 'service/api'

const dponetAPINoTreatError = api.create('dponet', false)

const manage = async ({ ...data }) => {
  return await dponetAPINoTreatError.patch(
    `/complaints/configurations/manage`,
    data,
  )
}

const mainConfiguration = async () => {
  return await dponetAPINoTreatError.get(`/complaints/configurations/main`)
}

const logs = async () => {
  return await dponetAPINoTreatError.get(
    `/complaints/configurations/config_logs`,
  )
}

export default { logs, mainConfiguration, manage }
