const INDICATED_STATUS = 'INDICATED'
const APPROVED_STATUS = 'APPROVED'
const REFUSED_STATUS = 'REFUSED'
const INACTIVE_STATUS = 'INACTIVE'
const SUGGESTED_STATUS = 'SUGGESTED'
const DISAPPROVED_STATUS = 'DISAPPROVED'

const INDICATED_LABEL = 'Indicado'
const APPROVED_LABEL = 'Aprovado'
const REFUSED_LABEL = 'Negado'
const INACTIVE_LABEL = 'Inativo'
const SUGGESTED_LABEL = 'Sugestão'
const DISAPPROVED_LABEL = 'Reprovado'

const ADVISOR_CARD_SUPPLIER = 'advisor-supplier'
const ADVISOR_CARD_CLIENT = 'advisor-client'
const ADVISOR_CARD_SUGGESTED = 'advisor-suggested'
const ADVISOR_CARD_CLOSED = 'closed'

const GENERAL_INFORMATION_VALUE = 'general_information'
const GENERAL_INFORMATION_LABEL = 'Informações Gerais'

const CREATE_ACTION = 'create'
const APPROVE_ACTION = 'approve'
const REFUSE_ACTION = 'refuse'
const INACTIVATE_ACTION = 'inactivate'
const DESTROY_ACTION = 'destroy'

const REFUSED_LOG_TEXT = 'O fornecedor negou o vínculo'
const APPROVED_LOG_TEXT = 'O fornecedor aceitou o vínculo'
const INACTIVE_LOG_TEXT = 'O vínculo foi inativado'

const STATUSES = {
  [INDICATED_STATUS]: INDICATED_STATUS,
  [APPROVED_STATUS]: APPROVED_STATUS,
  [REFUSED_STATUS]: REFUSED_STATUS,
  [INACTIVE_STATUS]: INACTIVE_STATUS,
}

const STATUSES_LABEL = {
  [INDICATED_STATUS]: INDICATED_LABEL,
  [APPROVED_STATUS]: APPROVED_LABEL,
  [REFUSED_STATUS]: REFUSED_LABEL,
  [INACTIVE_STATUS]: INACTIVE_LABEL,
}

const STATUSES_LABEL_LIST = [
  {
    key: INDICATED_STATUS,
    label: INDICATED_LABEL,
  },
  {
    key: APPROVED_STATUS,
    label: APPROVED_LABEL,
  },
  {
    key: REFUSED_STATUS,
    label: REFUSED_LABEL,
  },
  {
    key: INACTIVE_STATUS,
    label: INACTIVE_LABEL,
  },
]

const UNABLE_STEP = 'UNABLE'
const FIRST_STEP = 'FIRST_STEP'
const QUESTIONNAIRE_STEP = 'QUESTIONNAIRE'

const CREATION_TEXT = `O fornecedor será notificado por e-mail para confirmar que é seu fornecedor e responder aos questionários.

Caso ele não responda esta primeira notificação, serão feitas mais três tentativas a cada sete dias.

Se não houver resposta, avisaremos você por e-mail.
`
const TEXT_MULT_SUPPLIERS = `Os parceiros cadastrados serão notificados por e-mail para confirmar que é seu fornecedor e responder aos questionários.

Caso eles não respondam esta primeira notificação, serão feitas mais três tentativas a cada sete dias.

Se não houver resposta, avisaremos você por e-mail. Você pode acompanhar as notificações no histórico destes parceiros.`

const APPROVE_BASE_TEXT =
  'Ao confirmar o vínculo você irá disponibilizar as medidas que são adotadas por sua empresa, de acordo com as respostas dos seus questionários.'

const APPROVE_CLIENT_TEXT =
  'Os RMCs de questionários finalizados vão ser exibidos como medidas adotadas. Por isso, lembre-se de revisar os seus RMCs de questionários e atualizá-los de acordo com a realidade da sua empresa.'

const MY_SUPPLIERS_MAIN_DRIVER_STEP_1 = 'my-suppliers-main-driver-step-1'
const MY_SUPPLIERS_MAIN_DRIVER_STEP_2 = 'my-suppliers-main-driver-step-2'
const MY_SUPPLIERS_MAIN_DRIVER_STEP_3 = 'my-suppliers-main-driver-step-3'
const MY_SUPPLIERS_MAIN_DRIVER_STEP_4 = 'my-suppliers-main-driver-step-4'
const MY_SUPPLIERS_MAIN_DRIVER_STEP_5 = 'my-suppliers-main-driver-step-5'
const MY_SUPPLIERS_MAIN_DRIVER_STEP_6 = 'my-suppliers-main-driver-step-6'

const MY_SUPPLIERS_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + MY_SUPPLIERS_MAIN_DRIVER_STEP_1,
    popover: {
      description:
        'Um fornecedor, segundo a LGPD, é qualquer entidade com quem você compartilha dados. Você e o fornecedor têm responsabilidade solidária pela proteção dos dados. Garantir a conformidade dos parceiros com a LGPD é essencial para evitar violações. Através desta função, você pode cadastrar, monitorar e garantir a conformidade dos parceiros, protegendo as informações da empresa.',
    },
  },
  {
    element: '#' + MY_SUPPLIERS_MAIN_DRIVER_STEP_2,
    popover: {
      description:
        'Importe parceiros utilizando nossa planilha modelo, disponível para download ao clicar no botão. Você pode incluir parceiros internacionais, mas lembre-se de cadastrá-los separadamente dos nacionais.',
    },
  },
  {
    element: '#' + MY_SUPPLIERS_MAIN_DRIVER_STEP_3,
    popover: {
      description:
        'Para cadastrar um novo fornecedor, preencha o nome, documento e e-mail de um responsável, e selecione uma data de disparo (o e-mail será enviado durante 5 semanas consecutivas). Além disso, há a opção de cadastrar parceiros internacionais.',
    },
  },
]

const MY_SUPPLIERS_MAIN_DRIVER_STEP_OBJECT_4 = {
  element: '#' + MY_SUPPLIERS_MAIN_DRIVER_STEP_4,
  popover: {
    title: 'Aprovado',
    description:
      'Visualize a página do seu fornecedor ou inative o vínculo se não houver mais relação com sua empresa.',
  },
}

const MY_SUPPLIERS_MAIN_DRIVER_STEP_OBJECT_5 = {
  element: '#' + MY_SUPPLIERS_MAIN_DRIVER_STEP_5,
  popover: {
    title: 'Inativo',
    description:
      'Ao reativar o vínculo, uma nova solicitação será enviada ao fornecedor para ele aceitar ou recusar.',
  },
}

const MY_SUPPLIERS_MAIN_DRIVER_STEP_OBJECT_6 = {
  element: '#' + MY_SUPPLIERS_MAIN_DRIVER_STEP_6,
  popover: {
    title: 'Indicado ou Recusado',
    description:
      'Você pode reenviar a solicitação se não houver resposta, ou excluir o fornecedor.',
  },
}

const MY_SUPPLIERS_DRIVER_STATUS_ID = {
  [APPROVED_STATUS]: MY_SUPPLIERS_MAIN_DRIVER_STEP_4,
  [INACTIVE_STATUS]: MY_SUPPLIERS_MAIN_DRIVER_STEP_5,
  [INDICATED_STATUS]: MY_SUPPLIERS_MAIN_DRIVER_STEP_6,
}

const MY_SUPPLIERS_SELECTED_STEPS = {
  [APPROVED_STATUS]: MY_SUPPLIERS_MAIN_DRIVER_STEP_OBJECT_4,
  [INACTIVE_STATUS]: MY_SUPPLIERS_MAIN_DRIVER_STEP_OBJECT_5,
  [INDICATED_STATUS]: MY_SUPPLIERS_MAIN_DRIVER_STEP_OBJECT_6,
}

const SUPPLIERS_MAIN_DRIVER_STEP_1 = 'faqs_documentarion-main-driver-step-1'

const SUPPLIERS_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + SUPPLIERS_MAIN_DRIVER_STEP_1,
    popover: {
      description:
        'Esta funcionalidade permite que você visualize e aprove os vínculos com as empresas que o indicaram como fornecedor. Como fornecedor, você pode oferecer serviços, produtos ou atuar como terceirizado em diversas atividades.',
    },
  },
]

const SUPPLIERS_SUGGESTIONS_MAIN_DRIVER_STEP_1 =
  'faqs_documentarion-main-driver-step-1'
const SUPPLIERS_SUGGESTIONS_MAIN_DRIVER_STEP_2 =
  'faqs_documentarion-main-driver-step-2'

const SUPPLIERS_SUGGESTIONS_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + SUPPLIERS_SUGGESTIONS_MAIN_DRIVER_STEP_1,
    popover: {
      description:
        'Quando uma organização é cadastrada como agente de tratamento, ela aparece como uma sugestão de fornecedor.',
    },
  },
  {
    element: '#' + SUPPLIERS_SUGGESTIONS_MAIN_DRIVER_STEP_2,
    popover: {
      description:
        'Você pode aceitar ou recusar a solicitação. Se aceitar, um e-mail será enviado ao fornecedor e ele será adicionado à sua lista de parceiros. Se recusar, o status será alterado para "Recusado".',
    },
  },
]

const companySuppliers = {
  ADVISOR_CARD_CLIENT,
  ADVISOR_CARD_CLOSED,
  ADVISOR_CARD_SUPPLIER,
  APPROVE_ACTION,
  APPROVE_BASE_TEXT,
  APPROVE_CLIENT_TEXT,
  APPROVED_LABEL,
  APPROVED_LOG_TEXT,
  APPROVED_STATUS,
  CREATE_ACTION,
  CREATION_TEXT,
  DESTROY_ACTION,
  FIRST_STEP,
  GENERAL_INFORMATION_LABEL,
  GENERAL_INFORMATION_VALUE,
  INACTIVATE_ACTION,
  INACTIVE_LABEL,
  INACTIVE_LOG_TEXT,
  INACTIVE_STATUS,
  INDICATED_LABEL,
  INDICATED_STATUS,
  QUESTIONNAIRE_STEP,
  REFUSE_ACTION,
  REFUSED_LABEL,
  REFUSED_LOG_TEXT,
  REFUSED_STATUS,
  STATUSES,
  STATUSES_LABEL,
  STATUSES_LABEL_LIST,
  UNABLE_STEP,
  TEXT_MULT_SUPPLIERS,
  ADVISOR_CARD_SUGGESTED,
  SUGGESTED_STATUS,
  SUGGESTED_LABEL,
  DISAPPROVED_LABEL,
  DISAPPROVED_STATUS,
  MY_SUPPLIERS_DRIVER_STEPS_OBJECT,
  MY_SUPPLIERS_DRIVER_STATUS_ID,
  MY_SUPPLIERS_MAIN_DRIVER_STEP_1,
  MY_SUPPLIERS_MAIN_DRIVER_STEP_2,
  MY_SUPPLIERS_MAIN_DRIVER_STEP_3,
  MY_SUPPLIERS_SELECTED_STEPS,
  SUPPLIERS_DRIVER_STEPS_OBJECT,
  SUPPLIERS_MAIN_DRIVER_STEP_1,
  SUPPLIERS_SUGGESTIONS_DRIVER_STEPS_OBJECT,
  SUPPLIERS_SUGGESTIONS_MAIN_DRIVER_STEP_1,
  SUPPLIERS_SUGGESTIONS_MAIN_DRIVER_STEP_2,
}

export default companySuppliers
