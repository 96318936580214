import React from 'react'
import { Box, Grid } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import theme from 'theme'

const SkeletonPage = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" gridGap={theme.spacing(3)}>
          <Skeleton variant="circle" width={100} height={100} />

          <Box display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
            <Skeleton variant="rect" height={20} width={300} />
            <Skeleton variant="text" width={200} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rect" height={90} />
      </Grid>
    </Grid>
  )
}

export default SkeletonPage
