import React from 'react'

import { BarChart, DashboardChartPaper } from 'components'

import theme from 'theme'

const DashboardQuestionnaireProgress = ({ supplierStats }) => {
  const questionnaires = supplierStats?.complianceByQuestionnaires

  const kinds = questionnaires?.map(
    (questionnaire) => questionnaire.questionnaireKind,
  )
  const series = questionnaires?.map((questionnaire) =>
    Math.round(questionnaire.averageComplianceGap),
  )

  return (
    <DashboardChartPaper
      title="Conformidade dos seus parceiros por questionários (%)"
      heightContainer="fit-content"
      noMaxHeight
    >
      <BarChart
        colors={[theme.palette.primary.main]}
        series={[{ name: 'Conformidade (%)', data: series }]}
        names={kinds}
        heightValue={255}
        enabledTotal
        max={100}
        opacity={1}
      />
    </DashboardChartPaper>
  )
}

export default DashboardQuestionnaireProgress
