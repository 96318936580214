import axios from 'axios'
import qs from 'qs'

import { getBaseURL, getMarketPlaceToken } from './env'
import { routes } from 'Routes'
import * as service from './index'
import history from 'service/history'
import changeCaseKeys from 'helpers/changeCase/keys'

export const create = (projectTag, treatError = true) => {
  let api = axios.create({ baseURL: getBaseURL(projectTag) })
  let authToken = ''

  api.interceptors.request.use((reqConfig) => {
    if (projectTag === 'dponet') {
      authToken = service.dponet.auth.getToken()

      if (!authToken) {
        authToken = service.dponet.auth.getSupplierInviteToken()
      }
    }

    if (projectTag === 'marketPlace') {
      authToken = getMarketPlaceToken()
    }

    if (projectTag === 'cookies') {
      authToken = service.cookies.auth.getToken()
    }

    reqConfig.headers.Authorization = authToken ? `${authToken}` : undefined
    reqConfig.headers.crossDomain = true
    reqConfig.data = changeCaseKeys.snakeCase(reqConfig.data)
    reqConfig.params = changeCaseKeys.snakeCase(reqConfig.params)

    reqConfig.paramsSerializer = (params) => {
      return qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: false,
      })
    }

    return reqConfig
  })

  api.interceptors.response.use(
    (resp) => changeCaseKeys.camelCase(resp),
    (error) => {
      if (treatError) {
        if (error?.response?.status === 401) {
          service.dponet.auth.logout()
          return Promise.reject(changeCaseKeys.camelCase(error))
        }
        if (error?.response?.status === 404) {
          history.push(routes.notFound)
          return Promise.reject(changeCaseKeys.camelCase(error))
        }

        if (error?.response?.status === 403) {
          history.push(routes.forbidden)
          return Promise.reject(changeCaseKeys.camelCase(error))
        }
      }
      return Promise.reject(changeCaseKeys.camelCase(error))
    },
  )

  return api
}

export default { create }
