const isAnyFieldModified = (defaultValues, formValues) =>
  Object.keys(formValues).some((key) => formValues[key] !== defaultValues[key])

const normalize = (value) =>
  value === undefined || value === null || value === '' ? '' : value

const getChangedValues = (currentValues, defaultValues) => {
  const changedValues = {}

  Object.keys(currentValues).forEach((key) => {
    if (normalize(currentValues[key]) !== normalize(defaultValues[key])) {
      changedValues[key] = currentValues[key]
    }
  })

  return changedValues
}

export default { isAnyFieldModified, normalize, getChangedValues }
