import React from 'react'
import { Chip } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from './styles'
import constants from 'constants/index'

const ChipStatus = ({ status }) => {
  const classes = useStyles()
  const supplierSuggestionConstants = constants.supplierSuggestion

  return (
    <Chip
      className={clsx(classes.root, {
        [classes.suggested]:
          status === supplierSuggestionConstants.STATUSES.SUGGESTED,
        [classes.approved]:
          status === supplierSuggestionConstants.STATUSES.APPROVED,
        [classes.disapproved]:
          status === supplierSuggestionConstants.STATUSES.DISAPPROVED,
      })}
      label={supplierSuggestionConstants.STATUSES_LABEL[status]}
      size="small"
    />
  )
}

export default ChipStatus
