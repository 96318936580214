import React, { useState } from 'react'
import { Box, Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'

import { MenuButton, Permitted } from 'components'
import { Head as HeadComponent } from 'views/ThirdPartyManagements/components'

import { routes } from 'Routes'
import { PartnerCompliancePdf } from './components'
import permissions from 'constants/permissions'

const Head = ({ supplierInvite }) => {
  const [generateReport, setGenerateReport] = useState(false)

  const history = useHistory()

  const supplierName = supplierInvite?.supplier?.name || ''

  const handleEdit = () => {
    history.push(
      reverse(routes.thirdPartyManagements.partners.edit, {
        supplierInviteId: supplierInvite?.id,
      }),
    )
  }

  return (
    <HeadComponent
      title={supplierName}
      subtitle={supplierInvite?.supplier?.document}
      showActions
    >
      <Box>
        <MenuButton>
          <Permitted tag={permissions.COMPANY_SUPPLIERS.UPDATE}>
            <Button color="secondary" onClick={handleEdit} fullWidth>
              Editar
            </Button>
          </Permitted>

          <Button
            color="secondary"
            onClick={() => setGenerateReport(true)}
            fullWidth
          >
            Baixar Relatório
          </Button>
        </MenuButton>
      </Box>

      {generateReport && (
        <PartnerCompliancePdf
          supplierInvite={supplierInvite}
          generateReport={generateReport}
          setGenerateReport={setGenerateReport}
        />
      )}
    </HeadComponent>
  )
}

export default Head
