import React, { useState } from 'react'
import clsx from 'clsx'

import {
  Box,
  Button,
  Chip,
  Grid,
  TextField,
  Typography,
  Paper,
} from '@material-ui/core'
import {
  DescriptionLegendColor,
  FragilityEntities,
  QuestionControls,
} from './components'
import { GradientSlider, LoadingFeedback, SwitchController } from 'components'

import { useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import useCompanyFragility from 'hooks/useCompanyFragility'
import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import useStyles from '../CompanyFragilitiesTable/styles'

import * as service from 'service'
import constants from 'constants/index'
import { routes } from 'Routes'

import schema from './schema'
import fragilities from 'constants/fragilities'
import theme from 'theme'

const CompanyFagilityForm = () => {
  const history = useHistory()
  const snackbar = useSnackbar()

  const { companyFragility, measuresSelected, fragilitiesSelected } =
    useCompanyFragility()

  const [isLoading, setIsLoading] = useState(false)

  const { handleSubmit, control, setValue, watch, errors } = useForm({
    validationSchema: schema,
    defaultValues: {
      name: companyFragility?.name || '',
      description: companyFragility?.description || '',
      impactId:
        helpers.fragilities.idToValue(companyFragility?.impactId) ||
        constants.fragilities.UNDEFINED_DEFAULT,
      probabilityId:
        helpers.fragilities.idToValue(companyFragility?.probabilityId) ||
        constants.fragilities.UNDEFINED_DEFAULT,
      status: companyFragility?.status || constants.fragilities.ACTIVE,
    },
  })

  const classes = useStyles()

  const impactId = watch('impactId')
  const probabilityId = watch('probabilityId')

  const riskValue = helpers.fragilities.calculateRisk(impactId, probabilityId)

  const onSubmit = async (data) => {
    try {
      if (!companyFragility?.id) {
        data.questionControls = measuresSelected
        data.fragilityEntities = fragilitiesSelected
        await service.dponet.companyFragilities.create({
          companyFragility: {
            ...data,
            impactId: helpers.fragilities.valueToId(data.impactId),
            probabilityId: helpers.fragilities.valueToId(data.probabilityId),
          },
        })
      } else {
        await service.dponet.companyFragilities.update({
          companyFragilityId: companyFragility?.id,
          ...data,
          impactId: helpers.fragilities.valueToId(data.impactId),
          probabilityId: helpers.fragilities.valueToId(data.probabilityId),
        })
      }

      snackbar.open({
        message: `Ameaça ${
          !!companyFragility?.id ? 'alterada' : 'criada'
        } com sucesso`,
        variant: 'success',
      })
      history.push(routes.companyFragilities.all)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
  }

  const options = [
    {
      value: 0,
      color: theme.palette.fragility.undefined,
      label: fragilities.UNDEFINED,
    },
    {
      value: fragilities.LOW_ID,
      color: theme.palette.fragility.low,
      label: fragilities.LOW,
    },
    {
      value: fragilities.MEDIUM_ID,
      color: theme.palette.fragility.medium,
      label: fragilities.MEDIUM,
    },
    {
      value: fragilities.HIGH_ID,
      color: theme.palette.fragility.high,
      label: fragilities.HIGH,
    },
    {
      value: fragilities.SEVERE_ID,
      color: theme.palette.fragility.severe,
      label: fragilities.SEVERE,
    },
  ]

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingFeedback open={isLoading} />
      <Paper variant="outlined" component={Box} px={2} pt={4} pb={2}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box pb={2}>
              <Typography variant="h4">Status</Typography>
            </Box>
            <SwitchController
              control={control}
              dynamicChecked={watch('status')}
              staticChecked={constants.fragilities.ACTIVE}
              option={{
                left: constants.fragilities.INACTIVE,
                right: constants.fragilities.ACTIVE,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Box pb={2}>
              <Typography variant="h4">Nome da ameaça</Typography>
            </Box>

            <Controller
              as={
                <TextField
                  type="text"
                  fullWidth
                  error={!!errors.name}
                  helperText={errors?.name?.message}
                />
              }
              control={control}
              mode="onChange"
              name="name"
            />
          </Grid>
          <Grid item xs={12}>
            <Box py={2}>
              <Typography variant="h4">Descrição</Typography>
            </Box>

            <Controller
              as={
                <TextField
                  type="text"
                  fullWidth
                  multiline
                  minRows={3}
                  error={!!errors.description}
                  helperText={errors?.description?.message}
                />
              }
              control={control}
              mode="onChange"
              name="description"
            />
          </Grid>
          <Grid
            item
            xs={12}
            id={constants.fragilities.NEW_FRAGILITY_DRIVER_STEP_1}
          >
            <Grid container spacing={3} component={Box} pb={4}>
              <Grid item xs={12} md={5}>
                <Box pt={2}>
                  <Typography variant="h4">Impacto</Typography>
                </Box>
                <Box mt={1}>
                  <Controller
                    as={
                      <GradientSlider
                        options={options}
                        min={0}
                        max={4}
                        defaultValue={
                          helpers.fragilities.idToValue(
                            companyFragility?.impactId,
                          ) || constants.fragilities.UNDEFINED_DEFAULT
                        }
                        setControllerValue={setValue}
                        name="impactId"
                      />
                    }
                    control={control}
                    name="impactId"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Box pt={2}>
                  <Typography variant="h4">Probabilidade</Typography>
                </Box>
                <Box mt={1}>
                  <Controller
                    as={
                      <GradientSlider
                        options={options}
                        min={0}
                        max={4}
                        defaultValue={
                          helpers.fragilities.idToValue(
                            companyFragility?.probabilityId,
                          ) || constants.fragilities.UNDEFINED_DEFAULT
                        }
                        setControllerValue={setValue}
                        name="probabilityId"
                      />
                    }
                    control={control}
                    name="probabilityId"
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={2}>
                <Box pt={2}>
                  <Typography variant="h4">Nível da Ameaça</Typography>
                </Box>

                <Box mt={1.5}>
                  <Chip
                    className={clsx(
                      classes.chip,
                      helpers.dataProcess.fragilityColor(
                        riskValue?.id,
                        classes,
                      ),
                      helpers.dataProcess.textFragilityColor(
                        riskValue?.id,
                        classes,
                      ),
                    )}
                    align="center"
                    size="small"
                    label={helpers.functions.upperText(riskValue?.name)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography variant="h6">
                  Descrição do nível de Impacto
                </Typography>
                <Box marginTop={1}>
                  {Object.entries(fragilities.IMPACT_CAPTION).map(
                    ([object, { level, description, levelId }]) => (
                      <DescriptionLegendColor
                        title={level}
                        description={description}
                        levelId={levelId}
                      />
                    ),
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={7}>
                <Typography variant="h6">
                  Descrição do nível de Probabilidade
                </Typography>
                <Box marginTop={1}>
                  {Object.entries(fragilities.PROBABILITY_CAPTION).map(
                    ([object, { level, description, levelId }]) => (
                      <DescriptionLegendColor
                        title={level}
                        description={description}
                        levelId={levelId}
                      />
                    ),
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper variant="outlined" component={Box} mt={4}>
        <Box
          id={constants.fragilities.NEW_FRAGILITY_DRIVER_STEP_2}
          px={2}
          pt={2}
        >
          <QuestionControls setLoading={setIsLoading} />
        </Box>
        <Box
          id={constants.fragilities.NEW_FRAGILITY_DRIVER_STEP_3}
          px={2}
          pb={2}
        >
          <FragilityEntities setLoading={setIsLoading} loading={isLoading} />
        </Box>
      </Paper>

      <Box display="flex" mt={4} width="100%" justifyContent="flex-end">
        <Button onClick={() => history.push(routes.companyFragilities.all)}>
          Voltar
        </Button>
        <Box ml={2}>
          <Button type="submit" variant="contained" color="primary">
            Salvar
          </Button>
        </Box>
      </Box>
    </form>
  )
}

export default CompanyFagilityForm
