import React from 'react'
import { Chip } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from './styles'
import constants from 'constants/index'

const ChipStatus = ({ status }) => {
  const classes = useStyles()
  const supplierInviteConstants = constants.companySuppliers

  return (
    <Chip
      className={clsx(classes.root, {
        [classes.approved]:
          status === supplierInviteConstants.STATUSES.APPROVED,
        [classes.inactive]:
          status === supplierInviteConstants.STATUSES.INACTIVE,
      })}
      label={supplierInviteConstants.STATUSES_LABEL[status]}
      size="small"
    />
  )
}

export default ChipStatus
