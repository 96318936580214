import React, { useCallback, useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { flattenDeep, isNil, map } from 'lodash'
import { Grid, Button } from '@material-ui/core'

import {
  BannerConfigurationCard,
  BannerCustomizationCard,
  BannerInstallationCard,
  CookieValidationCard,
} from './components'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import theme from 'theme'
import schema from './schema'
import * as service from 'service'
import constants from 'constants/index'

const BannerForm = ({ banner, loading, setLoading, refresh }) => {
  const [descriptions, setDescriptions] = useState(
    helpers.cookies.banner.mergeDescriptions(banner?.descriptions || []),
  )
  const snackbar = useSnackbar()
  const cookies = flattenDeep(
    map(banner?.classification, ({ cookies, name, id }) =>
      map(cookies, (cookie) => ({ ...cookie, category: { id, name } })),
    ),
  )

  const handleDescriptionChange = useCallback((languageCode, value) => {
    setDescriptions((prevDescriptions) =>
      prevDescriptions.map((desc) =>
        desc.language.code === languageCode
          ? { ...desc, description: value }
          : desc,
      ),
    )
  }, [])

  const formMethods = useForm({
    validationSchema: schema,
    defaultValues: {
      name: banner?.name || '',
      url: banner?.url || '',
      cookies: map(
        cookies,
        ({ id, platform, category: { id: categoryId } }) => ({
          id,
          platform: platform ?? undefined,
          categoryId: categoryId ?? undefined,
        }),
      ),
      primaryColor: banner?.primaryColor || theme.palette.primary.main,
      secondaryColor: banner?.secondaryColor || theme.palette.primary.hover,
      logo: undefined,
      position:
        banner?.position || constants.cookies.banner.BANNER_LEFT_POSITION,
      hideOnAccept: banner?.hideOnAccept || false,
    },
  })

  const {
    handleSubmit,
    reset,
    formState: { dirtyFields },
  } = formMethods

  const isMisconfigured =
    banner?.status === constants.cookies.banner.INCOMPLETE_STATUS_ID ||
    isNil(banner?.status)

  const onSubmit = useCallback(
    async (data, isPublish = false) => {
      try {
        setLoading(true)

        const bannerData = { ...data, descriptions }
        if (!!data?.cookies) {
          await service.cookies.cookie.update({
            bannerId: banner?.id,
            cookies: data?.cookies,
          })
        }

        delete bannerData.cookies

        if (bannerData?.logo) {
          await service.cookies.banner.uploadLogo({
            bannerId: banner?.id,
            logo: bannerData.logo,
          })

          delete bannerData?.logo
        }

        await service.cookies.banner.update({
          bannerId: banner?.id,
          banner: {
            ...bannerData,
            ...(isMisconfigured && isPublish && { status: 'active' }),
          },
        })

        snackbar.open({
          message: `Banner de cookies atualizado${
            isMisconfigured && isPublish ? ' e publicado' : ''
          } com sucesso`,
          variant: 'success',
        })
        reset({})
        refresh()
      } catch (error) {
        console.error(error)
        snackbar.open({
          message: helpers.formatters.errorMessage(
            error?.response?.data?.error,
          ),
          variant: 'error',
        })
      } finally {
        setLoading(false)
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dirtyFields, descriptions],
  )

  return (
    <FormContext {...formMethods}>
      <Grid
        container
        spacing={4}
        component="form"
        id="cookie-configuration"
        onSubmit={handleSubmit((data) => onSubmit(data))}
      >
        <Grid item xs={12}>
          <BannerConfigurationCard />
        </Grid>
        <Grid item xs={12}>
          <BannerCustomizationCard
            banner={banner}
            descriptions={descriptions}
            onDescriptionChange={handleDescriptionChange}
          />
        </Grid>
        <Grid item xs={12}>
          <BannerInstallationCard bannerToken={banner?.token} />
        </Grid>
        <Grid item xs={12}>
          <CookieValidationCard
            banner={banner}
            cookies={cookies}
            refresh={refresh}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item xs={12} md={3} lg={2} xl={1}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                fullWidth
              >
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormContext>
  )
}

BannerForm.propTypes = {
  banner: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
}

export default BannerForm
