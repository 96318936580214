import * as yup from 'yup'
import helpers from 'helpers'
import constants from 'constants/index'

const schema = yup.object().shape({
  hireDate: yup.date().nullable().typeError('Preencha uma data válida'),
  renewDate: yup
    .date()
    .nullable()
    .typeError('Preencha uma data válida')
    .when('hireDate', (hireDate, schema) =>
      hireDate
        ? schema.min(
            hireDate,
            'A data de renovação não pode ser menor que a data de contratação',
          )
        : schema,
    ),
  description: yup.string(),
  segment: yup
    .object()
    .shape({
      id: yup.number().required(),
      name: yup.string().required(),
    })
    .nullable(),
  treatmentAgent: yup.string().nullable(),
  supplierAudit: yup.boolean(),
  supplierAuditFrequency: yup
    .number()
    .nullable()
    .when('supplierAudit', {
      is: true,
      then: yup
        .number()
        .required()
        .min(1, 'A frequência da auditoria deve ser maior que zero'),
    }),
  supplierAuditFrequencyType: yup.string().nullable().when('supplierAudit', {
    is: true,
    then: yup.string().required(),
  }),
  supplierRisk: yup.number().nullable(),
  document: yup
    .mixed()
    .test('fileSize', 'O documento deve ter no máximo 10 MB', (value) =>
      helpers.validates.file.size('MB', 10, value),
    )
    .test(
      'fileType',
      `Somente arquivos ${helpers.formatters.file.allowedTypes(
        constants.validations.ALL_FILE_TYPES,
      )} são permitidos`,
      (value) =>
        helpers.validates.file.type(
          constants.validations.ALL_FILE_MIMES_TYPES,
          value,
        ),
    ),
})

export default schema
