import React, { useEffect, useState } from 'react'
import { Box } from '@material-ui/core'

import { Page, Container, LoadingFeedback } from 'components'
import { VideoPlayer } from './components'

import { useLocation } from 'react-router-dom'
import useFetch from 'hooks/useFetch'
import useStyles from './styles'

import * as service from 'service'
import constants from 'constants/index'

const SupplierStep = () => {
  const [renderedContent, setRenderedContent] = useState()
  const classes = useStyles()
  const location = useLocation()

  const searchParams = new URLSearchParams(location.search)
  const skipConfirmation = searchParams.get('skipConfirmation') === 'true'

  const { response, isLoading } = useFetch(
    service.dponet.suppliers.supplierIndex,
    {
      status: constants.companySuppliers.INDICATED_STATUS,
    },
    [],
    !skipConfirmation,
  )

  const companySuppliers = response?.data?.companySuppliers || []
  const client = companySuppliers[0]?.company
  const supplierId = companySuppliers[0]?.id
  const loading = skipConfirmation ? false : isLoading

  const handleRenderedContent = () => {
    return setRenderedContent(
      <VideoPlayer
        setRenderedContent={setRenderedContent}
        client={client}
        supplierId={supplierId}
        skipConfirmation={skipConfirmation}
      />,
    )
  }

  useEffect(() => {
    handleRenderedContent()
    // eslint-disable-next-line
  }, [response])

  return (
    <Page title="Jornada de parceiros" className={classes.root}>
      <Container>
        <LoadingFeedback open={loading} />
        <Box display="flex" alignItems="center" flexDirection="column">
          {renderedContent}
        </Box>
      </Container>
    </Page>
  )
}

export default SupplierStep
