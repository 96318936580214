import auditList from './auditList'
import companyFragilities from './companyFragilities'
import companyList from './companyList'
import companySuppliers from './companySuppliers'
import complaintList from './complaintList'
import consentList from './consentList'
import consentReceiptList from './consentReceiptList'
import dashboardList from './dashboardList'
import dataProcessList from './dataProcessList'
import departmentList from './departmentList'
import diagnosticsList from './diagnosticsList'
import documentList from './documentList'
import documentTemplateList from './documentTemplateList'
import incidentList from './incidentList'
import lastUpdate from './lastUpdate'
import marketplaceList from './marketplaceList'
import nonComplianceReportList from './nonComplianceReportList'
import paymentList from './paymentList'
import privacyPolicyList from './privacyPolicyList'
import profileList from './profileList'
import questionnaireList from './questionnaireList'
import ripdList from './ripdList'
import taskList from './taskList'
import trainingList from './trainingList'
import userList from './userList'

const permisssionsList = {
  auditList,
  companyFragilities,
  companyList,
  companySuppliers,
  complaintList,
  consentList,
  consentReceiptList,
  dashboardList,
  dataProcessList,
  departmentList,
  diagnosticsList,
  documentList,
  documentTemplateList,
  incidentList,
  lastUpdate,
  marketplaceList,
  nonComplianceReportList,
  paymentList,
  privacyPolicyList,
  profileList,
  questionnaireList,
  ripdList,
  taskList,
  trainingList,
  userList,
}

export default permisssionsList
