import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Dialog,
  Card,
  Typography,
  Box,
  Button,
  IconButton,
} from '@material-ui/core'
import { X as XIcon } from 'react-feather'

import useStyles from './styles'
import helpers from 'helpers'

const BaseConfirmationDialog = ({
  open,
  title,
  text,
  actionButtonText,
  variant,
  actionButton,
  setOpen,
  dialogSize,
  formRef,
  loading,
  displayedButtons,
  displayedIcon,
  children,
}) => {
  const classes = useStyles()
  // const { mixpanel } = helpers

  // const mixpanelTrack = () => {
  //   mixpanel.track(
  //     'Fornecedores',
  //     supplierStatus === constants.companySuppliers.INDICATED_STATUS
  //       ? 'Excluir fornecedor'
  //       : supplierStatus === constants.companySuppliers.APPROVED_STATUS
  //       ? 'Inativar fornecedor'
  //       : 'Reativar vínculo',
  //     {
  //       action: 'clique no botão voltar',
  //     },
  //   )
  // }

  const handleClose = () => {
    // if (actionSupplier === true) {
    //   mixpanelTrack()
    // }
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={dialogSize} fullWidth>
      <Card
        className={clsx(
          classes.root,
          helpers.functions.getColorByVariant(classes, variant),
        )}
      >
        <Box display="flex" justifyContent="flex-end" mr={-2}>
          <IconButton onClick={handleClose}>
            <XIcon color="white" />
          </IconButton>
        </Box>

        {displayedIcon && (
          <Box className={classes.alignCenterBox} pb={1.5}>
            {helpers.functions.getIconByVariant(variant)}
          </Box>
        )}

        {title && (
          <Box width="100%" pb={2.5} className={classes.alignCenterBox}>
            <Typography variant="h3" component="h1" className={classes.title}>
              {title}
            </Typography>
          </Box>
        )}
        {text && (
          <Box width="100%" className={classes.alignCenterBox} pb={2.5}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              {text}
            </Typography>
          </Box>
        )}

        {children ?? (
          <Box>
            {displayedButtons && (
              <Box display="flex" justifyContent="space-between">
                <Button
                  className={classes.buttonTextColor}
                  type="button"
                  onClick={handleClose}
                >
                  Voltar
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  onClick={actionButton}
                  disabled={loading}
                  form={formRef}
                >
                  {actionButtonText}
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Card>
    </Dialog>
  )
}

BaseConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  text: PropTypes.string,
  actionButtonText: PropTypes.string,
  variant: PropTypes.oneOf([
    'error',
    'success',
    'warning',
    'blueConfirmation',
    'default',
  ]),
  actionButton: PropTypes.func,
  setOpen: PropTypes.func,
  dialogSize: PropTypes.string,
  formRef: PropTypes.node,
  loading: PropTypes.bool,
  displayerButtons: PropTypes.bool,
  actionSupplier: PropTypes.bool,
}

BaseConfirmationDialog.defaultProps = {
  title: '',
  text: '',
  actionButtonText: '',
  variant: 'default',
  actionButton: () => {},
  setOpen: () => {},
  dialogSize: 'sm',
  loading: false,
  displayedButtons: true,
  displayedIcon: true,
  actionSupplier: false,
}

export default BaseConfirmationDialog
