import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Box, Divider } from '@material-ui/core'

import {
  DataCollected,
  DataTreatment,
  GeneralInformation,
  DataLifeCycle,
  PaperHeader,
  DataTreatmentAgent,
} from '../../../Show'
import {
  LegalFrameworks,
  Description,
  ShowNecessityProportionality,
  Fragilities,
} from '../../../'

import DataCollectedModalProvider from 'providers/DataCollectedModalProvider'
import DataProcessTemplateQuestionProvider from 'providers/DataProcessTemplateQuestionProvider'
import DocumentsProvider from 'providers/DocumentsProvider'

import constants from 'constants/index'

const PaperBody = ({
  isLoading,
  dataProcess,
  origin,
  onlyShow = false,
  refresh = () => {},
  dataProcessTemplate = [],
}) => {
  const history = useHistory()

  const handleBack = () => {
    history.goBack({
      search: localStorage.getItem('ProcessFilters'),
    })
  }

  const dataCollectedsTemplateAnswers = dataProcess.dataCollecteds.flatMap(
    (dataCollected) => {
      return dataCollected.dataProcessTemplateAnswers
    },
  )

  return (
    <>
      <Box id={constants.dataProcess.DATA_PROCESS_NEW_DRIVER_STEP_11}>
        <PaperHeader data={dataProcess} isLoading={isLoading} />
        <DataProcessTemplateQuestionProvider
          step="general_information"
          dataProcessTemplate={dataProcessTemplate}
        >
          <GeneralInformation
            data={dataProcess}
            isLoading={isLoading}
            origin={origin}
            onlyShow={onlyShow}
            hiddenSuggestionAlert={
              !dataProcess?.suggestedChangeStats?.dataProcess
            }
            dataProcessTemplateAnswers={dataProcess.dataProcessTemplateAnswers}
          />
        </DataProcessTemplateQuestionProvider>
      </Box>
      <Divider />
      <Description dataProcess={dataProcess} />
      <Divider />
      <DocumentsProvider documents={dataProcess.documents}>
        <DataCollectedModalProvider dataCollecteds={dataProcess.dataCollecteds}>
          <DataCollected
            dataCollecteds={dataProcess.dataCollecteds}
            isLoading={isLoading}
            dataProcessId={dataProcess.id}
            hiddenSuggestionAlert={
              !dataProcess.suggestedChangeStats.dataCollected
            }
            dataProcessTemplateAnswers={dataCollectedsTemplateAnswers}
          />
        </DataCollectedModalProvider>
      </DocumentsProvider>
      <Divider />
      <DataTreatmentAgent data={dataProcess} />
      <Divider />

      <DataLifeCycle
        data={dataProcess}
        isLoading={isLoading}
        hiddenSuggestionAlert={!dataProcess.suggestedChangeStats.lifeCycle}
      />

      <Divider />

      <DataTreatment
        isLoading={isLoading}
        dataTreatments={dataProcess.dataTreatments}
        dataProcessId={dataProcess.id}
        hiddenSuggestionAlert={!dataProcess.suggestedChangeStats.dataTreatment}
      />

      <Divider />
      {dataProcess?.statusId === constants.dataProcess.APPROVED_STATUS &&
        !onlyShow && (
          <ShowNecessityProportionality data={dataProcess} refresh={refresh} />
        )}
      <Fragilities
        dataProcessId={dataProcess.id}
        onlyShow={onlyShow}
        origin={origin}
      />
      {!onlyShow && (
        <LegalFrameworks data={dataProcess} isLoading={isLoading} />
      )}
      {!onlyShow && (
        <Button variant="outlined" type="button" onClick={handleBack}>
          Voltar
        </Button>
      )}
    </>
  )
}

export default PaperBody
