import React, { useEffect, useState } from 'react'
import {
  Grid as GridIcon,
  Menu as MenuIcon,
  Search as SearchIcon,
} from 'react-feather'
import { isEmpty } from 'lodash'
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core'

import { NoData2 } from 'components'
import { DocumentCard, DocumentTable } from './components'

import helpers from 'helpers'

import useFetch from 'hooks/useFetch'

import * as service from 'service'
import useStyles from './styles'
import theme from 'theme'
import PropTypes from 'prop-types'

const DocumentsTemplate = ({ filter }) => {
  const [listType, setListType] = useState('cards')
  const [searchInput, setSearchInput] = useState('')
  const [wasResetFilters, setWasResetFilters] = useState(false)

  const classes = useStyles()

  const { response, isLoadingStart } = useFetch(
    service.dponet.documentsTemplate.get,
    {
      perPage: 1000,
      ...filter.filters,
    },
    [filter.filters, wasResetFilters],
    !!wasResetFilters,
  )

  const documents = response?.data?.documents

  const filteredDocuments = documents?.filter((template) =>
    template?.title.toLowerCase().includes(searchInput.toLowerCase()),
  )

  const handleListTables = () => setListType('table')
  const handleListCards = () => setListType('cards')

  const handleSearchValue = (event) => setSearchInput(event.target.value)

  const redirectToDocument = (link) =>
    window.open(helpers.formatters.website(link), '_blank')

  const resetFilters = async (filters = {}) => await filter.setFilters(filters)

  useEffect(() => {
    resetFilters({})
    setWasResetFilters(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
      <Box>
        <TextField
          fullWidth
          value={searchInput}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon size={20} />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          onChange={handleSearchValue}
        />
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <IconButton onClick={handleListTables} className={classes.iconButton}>
            <MenuIcon size={16} />
          </IconButton>
          <IconButton onClick={handleListCards} className={classes.iconButton}>
            <GridIcon size={16} />
          </IconButton>
        </Box>
      </Box>
      {isEmpty(filteredDocuments) && !isLoadingStart ? (
        <NoData2 title="Nenhum documento modelo foi encontrado." />
      ) : listType === 'cards' ? (
        <Grid container spacing={2}>
          {isLoadingStart
            ? [...Array(6)].map((_, index) => (
                <Grid key={index} item xs={12} md={6} lg={4}>
                  <DocumentCard skeleton />
                </Grid>
              ))
            : filteredDocuments?.map((document, index) => (
                <Grid key={index} item xs={12} md={6} lg={4}>
                  <DocumentCard
                    document={document}
                    redirectToDocument={redirectToDocument}
                  />
                </Grid>
              ))}
        </Grid>
      ) : (
        <DocumentTable
          documents={filteredDocuments}
          redirectToDocument={redirectToDocument}
        />
      )}
    </Box>
  )
}

DocumentsTemplate.propTypes = {
  filter: PropTypes.object.isRequired,
}

export default DocumentsTemplate
