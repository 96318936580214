import { colors, makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  chip: {
    textTransform: 'uppercase',
    fontSize: theme.typography.caption.fontSize,
    paddingInline: theme.spacing(1),
    backgroundColor: colors.lightBlue[100],
    color: colors.blue[600],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  title: {
    width: 'fit-content',
    maxWidth: '100%',
  },
  descriptionText: {
    fontWeight: 'normal',
    width: 'fit-content',
    maxWidth: '100%',
  },
  alert: {
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
  },
}))
