import React, { useState } from 'react'
import clsx from 'clsx'
import {
  Box,
  Button,
  InputLabel,
  TextField,
  Typography,
} from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'

import useSupplierInvite from 'hooks/useSupplierInvite'
import useSnackbar from 'hooks/useSnackbar'

import useStyles from './styles'
import theme from 'theme'
import schema from './schema'
import * as service from 'service'
import constants from 'constants/index'

const EmailForm = ({ companyToken }) => {
  const [isLoading, setIsLoading] = useState(false)

  const classes = useStyles()
  const snackbar = useSnackbar()

  const { handleSubmit, errors, control } = useForm({
    validationSchema: schema(),
    defaultValues: {
      email: '',
    },
  })

  const { supplierData, setSupplierData, setCurrentStep } = useSupplierInvite()

  const onSubmit = async (data) => {
    setIsLoading(true)

    try {
      const response = await service.dponet.supplierInvite.checkPermission({
        companyToken,
        supplierId: supplierData.companyId,
        email: data.email,
      })

      setSupplierData((supplierData) => ({
        ...supplierData,
        userCompanyId: response.data?.userCompanyId,
        email: data.email,
      }))

      snackbar.open({
        message: 'E-mail validado com sucesso',
        variant: 'success',
      })

      setCurrentStep(constants.supplierInvite.LOGIN_STEP)
    } catch (error) {
      setCurrentStep(constants.supplierInvite.NOT_PERMITED_FUNCTIONALITY_STEP)
    }
  }

  return (
    <Box>
      <Box>
        <Box className={classes.groupTypography}>
          <Typography className={clsx(classes.text, classes.textWelcome)}>
            Informe seu e-mail para continuar
          </Typography>
          <Typography className={classes.text}></Typography>
        </Box>
      </Box>

      <Box display="flex" justifyContent="center">
        <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
          <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
            <Box>
              <InputLabel className={classes.label}>E-mail</InputLabel>

              <Controller
                as={
                  <TextField
                    className={classes.input}
                    type="email"
                    variant="outlined"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                  />
                }
                control={control}
                name="email"
                mode="onBlur"
              />
            </Box>

            <Button
              type="submit"
              variant="contained"
              className={classes.roundedButton}
            >
              {isLoading ? 'Carregando...' : 'Avançar'}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  )
}

export default EmailForm
