import { makeStyles } from '@material-ui/styles'

const styles = (options, currentOption) => {
  const gradientStops = options
    .map((option) => {
      return option.color
    })
    .join(', ')

  return makeStyles((theme) => ({
    thumb: {
      height: theme.spacing(2),
      width: theme.spacing(2),
      border: `1px solid #FFF`,
      background: 'transparent',
      marginLeft: theme.spacing(-1),
      marginTop: theme.spacing(-0.5),
      backgroundColor: currentOption?.color || 'transparent',
    },
    valueLabel: {
      left: '-50%',
      top: 'calc(100% + 5px)',
      '& *': {
        background: 'transparent',
        color: '#000',
      },
    },
    mark: {
      backgroundColor: 'inherit',
    },
    markActive: {
      backgroundColor: 'inherit',
    },
    track: {
      height: theme.spacing(1),
      borderRadius: theme.spacing(1),
      backgroundColor: 'transparent',
    },
    rail: {
      height: theme.spacing(1),
      borderRadius: theme.spacing(1),
      background: `linear-gradient(90deg, ${gradientStops})`,
      opacity: 1,
    },
  }))()
}

export default styles
