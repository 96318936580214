import constants from 'constants/index'

const { CONTENT_COMPARATOR_ALERT_MESSAGE } = constants.diffViewer
const setAlertContent = ({ sameChangesHtml, sameChanges }) => {
  if (sameChangesHtml) return CONTENT_COMPARATOR_ALERT_MESSAGE.notFound
  if (sameChanges) return CONTENT_COMPARATOR_ALERT_MESSAGE.noContentDiff
  return CONTENT_COMPARATOR_ALERT_MESSAGE.normal
}

export default {
  setAlertContent,
}
