import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  statusLabelAuthorized: {
    color: '#16A63C',
    backgroundColor: '#B4FFC8',
  },
  statusLabelRevoked: {
    color: '#DC3545',
    backgroundColor: '#FFE0E3',
  },
  chip: {
    fontWeight: 600,
    textTransform: 'uppercase',
  },
}))

export default styles
