import { colors, makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1),
    backgroundColor: colors.grey[100],
  },
}))

export default styles
