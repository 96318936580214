import { Box, Grid, Typography } from '@material-ui/core'
import { Card } from 'components'
import { isEmpty } from 'lodash'
import React from 'react'

const QuestionnaireDetail = ({ questionnaire }) => {
  const complianceQuestions = questionnaire?.complianceQuestions
  const noncomplianceQuestions = questionnaire?.noncomplianceQuestions

  return (
    <Box>
      {isEmpty(questionnaire) ? (
        <Typography variant="button">
          Nenhum questionário selecionado
        </Typography>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card title="Medidas Adotadas">
              <Grid container spacing={1}>
                {isEmpty(complianceQuestions) ? (
                  <Grid item xs={12}>
                    <Typography variant="button">
                      Nenhuma medida adotada
                    </Typography>
                  </Grid>
                ) : (
                  complianceQuestions.map((complianceQuestion) => (
                    <Grid item xs={12}>
                      <Box border="1px solid #e0e0e0" p={2} borderRadius={2}>
                        <Typography variant="h6" gutterBottom>
                          {complianceQuestion.theme}
                        </Typography>
                        <Typography variant="body1">
                          {complianceQuestion.affirmative ||
                            complianceQuestion.question}
                        </Typography>
                      </Box>
                    </Grid>
                  ))
                )}
              </Grid>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Card title="Medidas Não Adotadas">
              <Grid container spacing={1}>
                {isEmpty(noncomplianceQuestions) ? (
                  <Grid item xs={12}>
                    <Typography variant="button">
                      Nenhuma medida não adotada
                    </Typography>
                  </Grid>
                ) : (
                  noncomplianceQuestions.map((noncomplianceQuestion) => (
                    <Grid item xs={12}>
                      <Box border="1px solid #e0e0e0" p={2} borderRadius={2}>
                        <Typography variant="h6" gutterBottom>
                          {noncomplianceQuestion.theme}
                        </Typography>
                        <Typography variant="body1">
                          {noncomplianceQuestion.affirmative ||
                            noncomplianceQuestion.question}
                        </Typography>
                      </Box>
                    </Grid>
                  ))
                )}
              </Grid>
            </Card>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default QuestionnaireDetail
