const STATUSES = {
  SUGGESTED: 'SUGGESTED',
  APPROVED: 'APPROVED',
  DISAPPROVED: 'DISAPPROVED',
}

const STATUSES_LABEL = {
  SUGGESTED: 'Sugestão',
  APPROVED: 'Aprovado',
  DISAPPROVED: 'Reprovado',
}

export default {
  STATUSES,
  STATUSES_LABEL,
}
