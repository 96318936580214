import helpers from 'helpers'
import formattedValueHelpers from './formattedValueHelpers'

const formattedValue = (field, value, registerType) => {
  if (value === null) return '-'

  switch (true) {
    case formattedValueHelpers.isDeadlineDateForNonCompliance(
      field,
      registerType,
    ):
      return value
    case formattedValueHelpers.isCompanySupplierStatus(field, registerType):
      return helpers.companySuppliers.label(value)
    case formattedValueHelpers.isQuestionStatus(field, registerType):
      return helpers.logs.questionStatus(registerType, value)
    case formattedValueHelpers.isComplaintCategory(field, registerType):
      return helpers.complaint.categoryName(value)
    case formattedValueHelpers.isComplaintSubCategory(field, registerType):
      return helpers.complaint.subcategoryName(value)
    case formattedValueHelpers.isComplaintKind(field, registerType):
    case formattedValueHelpers.isComplaintStatus(field, registerType):
      return value
    case formattedValueHelpers.isComplaintLastAccess(field, registerType):
      return formattedValueHelpers.formatComplaintLastAccess(value)
    case typeof value === 'object':
      return formattedValueHelpers.formatObjectValue(value)
    case typeof value === 'boolean':
      return formattedValueHelpers.formatBooleanValue(
        field,
        registerType,
        value,
      )
    default:
      return formattedValueHelpers.formatFieldValue(field, value)
  }
}

export default formattedValue
