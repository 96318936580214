import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import {
  Box,
  Grid,
  TablePagination,
  Typography,
  useMediaQuery,
} from '@material-ui/core'

import { ConfirmationDialog, NoData2 } from 'components'
import { DocumentCard, DocumentModal } from './components'

import helpers from 'helpers'

import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'
import usePagination from 'hooks/usePagination'

import * as service from 'service'
import useStyles from './styles'
import { routes } from 'Routes'
import theme from 'theme'

const DepreciatedDocuments = ({
  filter,
  setLoading,
  documentCategories,
  documentCategoriesRefresh,
  oldDocumentsRefresh,
}) => {
  const [documentData, setDocumentData] = useState({})
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [wasResetFilters, setWasResetFilters] = useState(false)

  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { handleChangePage, handleChangeRowsPerPage, page, perPage } =
    usePagination(5)

  const { response, refresh, isLoadingStart } = useFetch(
    service.dponet.companyDocuments.get,
    {
      page,
      perPage,
      ...filter.filters,
    },
    [page, perPage, filter.filters, wasResetFilters],
    !!wasResetFilters,
  )

  const documents = response?.data?.documents

  const handleRedirectToDocument = (link) =>
    window.open(helpers.formatters.website(link), '_blank')

  const handleDocumentUpdateRedirection = (depreciatedId = 'null') =>
    history.push(`${routes.documents.new}?depreciatedId=${depreciatedId}`)

  const resetFilters = async (filters = {}) => await filter.setFilters(filters)

  const handleOpenPreviewDialog = (currentDocument) => {
    setDocumentData(currentDocument)
    setOpenPreviewDialog(true)
  }

  const handleOpenDeleteDialog = (id) => {
    setDocumentData({ id })
    setOpenDeleteDialog(true)
  }

  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false)

  const handleDocumentDeletion = async () => {
    try {
      setLoading(true)

      await service.dponet.companyDocuments.destroy({
        documentId: documentData?.id,
      })

      snackbar.open({
        message: 'Documento desatualizado excluído com sucesso!',
        variant: 'success',
      })

      refresh()
      oldDocumentsRefresh()
      documentCategoriesRefresh()
      handleCloseDeleteDialog()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Ocorreu uma falha ao tentar excluir o documento desatualizado',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    resetFilters({ page, perPage })
    setWasResetFilters(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box>
      {isEmpty(documents) && !isLoadingStart ? (
        <NoData2 title="Nenhum documento desatualizado foi encontrado." />
      ) : (
        <Grid container spacing={2}>
          {isLoadingStart
            ? [...Array(6)].map((_, index) => (
                <Grid key={index} item xs={12} md={6} lg={4}>
                  <DocumentCard skeleton />
                </Grid>
              ))
            : documents?.map((currentDocument, index) => (
                <Grid key={index} item xs={12} md={6} lg={4}>
                  <DocumentCard
                    currentDocument={currentDocument}
                    handleRedirectToDocument={handleRedirectToDocument}
                    documentUpdateRedirection={handleDocumentUpdateRedirection}
                    handleOpenPreviewDialog={handleOpenPreviewDialog}
                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                  />
                </Grid>
              ))}
        </Grid>
      )}
      {!isEmpty(documents) && (
        <TablePagination
          component="div"
          count={response?.data?.meta?.totalCount || 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          rowsPerPage={perPage}
          rowsPerPageOptions={[5, 10, 25, 100]}
          labelRowsPerPage={isDesktop ? 'Por página' : ''}
          nextIconButtonProps={{ size: 'small' }}
          backIconButtonProps={{ size: 'small' }}
        />
      )}
      <ConfirmationDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        text={
          <Typography variant="h3">
            Tem certeza de que deseja excluir este documento?&nbsp;
            <span className={classes.textError}>
              Esta ação não poderá ser desfeita!
            </span>
          </Typography>
        }
        textButton="Excluir"
        actionAcceptButton={handleDocumentDeletion}
        hiddenIcon
        disableBackdropClick
        variant="error"
      />
      {openPreviewDialog && (
        <DocumentModal
          open={openPreviewDialog}
          setOpen={setOpenPreviewDialog}
          categories={documentCategories}
          documentData={documentData}
        />
      )}
    </Box>
  )
}

DepreciatedDocuments.propTypes = {
  documentCategories: PropTypes.arrayOf(PropTypes.object),
  documentCategoriesRefresh: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  oldDocumentsRefresh: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
}

export default DepreciatedDocuments
