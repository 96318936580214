import { colors } from '@material-ui/core'

const styles = (theme) => ({
  descriptionBox: {
    backgroundColor: theme.palette.primary.light,
    padding: theme.spacing(1),
    borderRadius: 8,
  },
  headerBox: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    borderRadius: '10px 10px 0px 0px',
    padding: '30px 0px ',
    marginBottom: theme.spacing(2),
  },
  authorizeButton: {
    backgroundColor: colors.green[500],
    color: colors.grey[50],
    width: 200,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: colors.green[500],
      opacity: 0.8,
    },
  },
  lineDescription: {
    marginTop: theme.spacing(1),
  },
})

export default styles
