import formatDate from './formatDate'
import getAuditFrequencyByEnum from './getAuditFrequencyByEnum'
import getEnumRiskByLevel from './getEnumRiskByLevel'
import getRiskLabelByEnum from './getRiskLabelByEnum'
import getRiskLevelByEnum from './getRiskLevelByEnum'
import getTreatmentAgentLabelByEnum from './getTreatmentAgentLabelByEnum'
import mountBodyParams from './mountBodyParams'
import mountDefaultValues from './mountDefaultValues'

const thirdPartyManegementPartners = {
  formatDate,
  getAuditFrequencyByEnum,
  getEnumRiskByLevel,
  getRiskLabelByEnum,
  getRiskLevelByEnum,
  getTreatmentAgentLabelByEnum,
  mountBodyParams,
  mountDefaultValues,
}

export default thirdPartyManegementPartners
