import React from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Typography } from '@material-ui/core'

import { BaseDialog } from 'components'

import theme from 'theme'
import useStyles from './styles'

const ContentLossModal = ({
  open,
  setOpen,
  handleTabChange,
  handleCloseModal,
}) => {
  const classes = useStyles()

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      title="Alterações não salvas"
      dialogActions={
        <Box display="flex" px={2} gridGap={theme.spacing(1)}>
          <Button variant="outlined" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleTabChange}
            className={classes.discardButton}
          >
            Descartar
          </Button>
        </Box>
      }
    >
      <Box>
        <Typography variant="body1">
          Existem alterações pendentes que não foram salvas
        </Typography>
        <Typography color="error" variant="h6">
          Tem certeza de que deseja descartar essas alterações?
        </Typography>
      </Box>
    </BaseDialog>
  )
}

ContentLossModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
}

export default ContentLossModal
