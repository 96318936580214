import React from 'react'
import { ExternalLink } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import { Paper, IconButton } from '@material-ui/core'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'

import useStyles from './styles'

const DocumentTable = ({ documents, redirectToDocument }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.paper}>
      <PerfectScrollbar
        options={{ useBothWheelAxes: true, suppressScrollX: false }}
      >
        <Table noWrap emptyMessage="Nenhum documento encontrado!">
          <TableHead>
            <TableRow>
              <TableCell width="10%">Identificador</TableCell>
              <TableCell width="80%">Documento</TableCell>
              <TableCell width="10%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents?.map((document) => (
              <TableRow key={document.id}>
                <TableCell>{document.id}</TableCell>
                <TableCell>{document.title}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => redirectToDocument(document.link)}>
                    <ExternalLink size={16} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Paper>
  )
}

DocumentTable.propTypes = {
  document: PropTypes.object,
  redirectToDocument: PropTypes.func.isRequired,
}

export default DocumentTable
