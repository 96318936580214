import React from 'react'
import { Box, Grid, Paper } from '@material-ui/core'

import { Container, Page } from 'components'
import { Footer } from './components'
import {
  Head,
  QuestionnairesCompliance,
  SkeletonPage,
  RequiredSection,
  OptionalSection,
  CompanyCompliance,
  QuestionnairesProgress,
} from './components/partner/show'

import useFetch from 'hooks/useFetch'

import * as service from 'service'

const ThirdPartyManagementPartnerShow = ({ match }) => {
  const { supplierInviteId } = match.params

  const { response, isLoading } = useFetch(
    service.dponet.supplierInvite.get,
    {
      supplierInviteId: supplierInviteId,
      allRelations: true,
    },
    [supplierInviteId],
  )

  const supplierInvite = response?.data?.supplierInvite || {}

  return (
    <Page title="Gestão de Terceiros - Visualização">
      <Container maxWidth={false} component={Box} mt={3}>
        <Paper component={Box} p={3}>
          {isLoading ? (
            <SkeletonPage />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Head supplierInvite={supplierInvite} />
              </Grid>

              <Grid item xs={12}>
                <QuestionnairesCompliance
                  supplierInvite={supplierInvite}
                  questionnaires={supplierInvite?.questionnaires}
                />
              </Grid>

              <Grid item xs={12}>
                <RequiredSection supplierInvite={supplierInvite} showTitle />
              </Grid>

              <Grid item xs={12}>
                <OptionalSection supplierInvite={supplierInvite} />
              </Grid>

              <Grid item xs={12} lg={5}>
                <CompanyCompliance
                  compliance={supplierInvite?.supplier?.complianceGap}
                />
              </Grid>

              <Grid item xs={12} lg={7}>
                <QuestionnairesProgress
                  questionnaires={supplierInvite?.questionnaires}
                />
              </Grid>
            </Grid>
          )}
        </Paper>

        <Footer />
      </Container>
    </Page>
  )
}

export default ThirdPartyManagementPartnerShow
