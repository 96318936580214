import React, { useEffect, useState } from 'react'
import { isNil } from 'lodash'

import { BaseConfirmationDialog, LoadingFeedback } from 'components'
import { Simplified } from 'views/Incidents/components/IncidentsForm/components'

import useIncidentManegement from 'hooks/useIncidentManegement'
import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

const InvestigationStep = () => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [updateDpoNotified, setUpdateDpoNotified] = useState(false)
  const [notifyDpo, setNotifyDpo] = useState(false)

  const snackbar = useSnackbar()

  const {
    wantsToAdvanceStep,
    resetAdvanceStep,
    incident,
    setIncident,
    handleNext,
  } = useIncidentManegement()

  const onSubmit = async (data) => {
    setLoading(true)

    try {
      const response = await service.dponet.incidents.put({
        incidentId: incident?.id,
        incident: {
          ...data,
          simplifiedForm: true,
        },
      })

      const incidentResponse = response?.data?.incident
      setIncident(incidentResponse)

      handleNext()
    } catch (error) {
      snackbar.open({
        message: 'Ocorreu um erro',
        variant: 'error',
      })
    }

    setLoading(false)
  }

  const snackError = () => {
    setOpen(false)
    return snackbar.open({
      variant: 'error',
      message: 'Preencha todos os campos corretamente.',
    })
  }

  const handleOpenConfirmationDialog = () => {
    setOpen(true)
    resetAdvanceStep()
  }

  useEffect(() => {
    if (wantsToAdvanceStep && !incident.dpoNotified) {
      return setNotifyDpo(true)
    }

    if (wantsToAdvanceStep && incident.dpoNotified) {
      return setUpdateDpoNotified(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wantsToAdvanceStep, incident])

  return (
    <>
      <LoadingFeedback open={loading} />
      <Simplified
        incident={incident}
        handleSubmit={onSubmit}
        setNotifyDpo={setNotifyDpo}
        setUpdateDpoNotified={setUpdateDpoNotified}
        updateDpoNotified={updateDpoNotified}
        notifyDpo={notifyDpo}
        handleOpenConfirmationDialog={handleOpenConfirmationDialog}
        snackError={snackError}
      />
      <BaseConfirmationDialog
        open={open}
        setOpen={setOpen}
        title="O DPO será notificado!"
        text="Comunicaremos o DPO do registro deste novo incidente. Você será notificado quando o parecer for concluído."
        actionButtonText="Continuar"
        variant="blueConfirmation"
        dialogSize="sm"
        formRef="incident-simplified-form"
      />
    </>
  )
}

export default InvestigationStep
