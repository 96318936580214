import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { Box, Button, Grid, InputLabel, Typography } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'

import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import { getGoogleRecaptchaToken } from 'service/env'
import { routes } from 'Routes'

import {
  LoadingFeedback,
  PasswordInputField,
  PasswordValidator,
} from 'components'

import useSnackbar from 'hooks/useSnackbar'
import useStyles from './styles'
import useSupplierInvite from 'hooks/useSupplierInvite'
import useAuth from 'hooks/useAuth'

import * as service from 'service'
import ReCAPTCHA from 'react-google-recaptcha'
import schema from './schema'
import helpers from 'helpers'
import constants from 'constants/index'

const PasswordForm = ({ isNewUser, companyToken }) => {
  const [recaptcha, setRecaptcha] = useState(undefined)
  const [justSubmitted, setJustSubmitted] = useState(false)

  const recaptchaRef = useRef(null)
  const { supplierData, isLoading, setIsLoading } = useSupplierInvite()

  const production = process.env.REACT_APP_API === 'production'
  const disableSubmitButton = production ? !recaptcha || isLoading : false

  const snackbar = useSnackbar()
  const classes = useStyles()
  const history = useHistory()
  const { loadData } = useAuth()

  const { handleSubmit, errors, control, watch } = useForm({
    validationSchema: schema(isNewUser),
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  })

  const onSubmit = async (data) => {
    try {
      setIsLoading(true)

      const response =
        await service.dponet.supplierInvite.createSupplierRelations(
          {
            supplierInvite: {
              companyName: supplierData.companyName,
              document: supplierData.document,
              email: supplierData.email,
              password: data.password,
              confirmPassword: data.confirmPassword || null,
            },
          },
          companyToken,
        )

      await service.dponet.auth.login({
        email: supplierData.email,
        password: data.password,
        recaptcha,
      })

      const userCompanyResponse =
        await service.dponet.usersCompanies.selectCompany(
          response?.data?.userCompany?.id,
        )

      const authToken = userCompanyResponse?.data?.authToken

      if (!authToken) {
        return snackbar.open({
          message: 'Ocorreu um erro ao acessar a empresa!',
          variant: 'error',
        })
      }

      service.dponet.auth.setToken(authToken)

      service.dponet.auth.setCompany(response?.data?.userCompany?.companyId)

      service.dponet.auth.removeSupplierInviteToken()

      await loadData()

      if (
        response?.data?.userCompany?.kind !== constants.company.SUPPLIER_KIND
      ) {
        return history.push(routes.root)
      }

      return history.push({
        pathname: reverse(routes.supplierStep),
        search: 'skipConfirmation=true',
      })
    } catch (error) {
      if (production) resetRecaptcha()
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) ||
          `Ocorreu um erro ao realizar o ${isNewUser ? 'cadastro' : 'login'}`,
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const resetRecaptcha = () => {
    recaptchaRef.current.reset()
    setRecaptcha(undefined)
  }

  const handleAcceptedRecaptcha = (recaptchaToken) => {
    setRecaptcha(recaptchaToken)
  }

  const disableRecaptcha = () => setRecaptcha(undefined)

  const handleSubmitForm = (event) => {
    setJustSubmitted(true)
    handleSubmit(onSubmit)(event)
  }

  return (
    <>
      <LoadingFeedback open={isLoading} />

      {!isLoading && (
        <Box>
          <Box className={classes.groupTypography}>
            <Typography className={clsx(classes.text, classes.textWelcome)}>
              {isNewUser ? 'Crie sua senha!' : 'Faça Login!'}
            </Typography>
            <Typography className={classes.text}>
              {isNewUser
                ? 'A criação desta senha é fundamental para que você possa acessar a plataforma posteriormente'
                : 'identificamos que seu usuário já existe na plataforma. Faça o login para continuar'}
            </Typography>
          </Box>
          <Grid justifyContent="center" container spacing={1}>
            <form onSubmit={handleSubmitForm} className={classes.form}>
              {isNewUser && (
                <Grid item xs={12}>
                  <Box mt={4} display="flex" justifyContent="center">
                    <PasswordValidator
                      passwordInput={watch('password')}
                      justSubmitted={justSubmitted}
                      isSupplierStep
                    />
                  </Box>
                </Grid>
              )}
              <Box display="flex" flexDirection="column">
                <Grid item xs={12}>
                  <Box mt={4}>
                    <InputLabel className={classes.label}>Senha</InputLabel>
                  </Box>
                  <Controller
                    as={
                      <PasswordInputField
                        className={classes.input}
                        type="password"
                        variant="outlined"
                        margin="normal"
                        error={isNewUser ? !!errors.password : ''}
                      />
                    }
                    control={control}
                    name="password"
                    mode="onBlur"
                  />
                </Grid>

                {isNewUser && (
                  <Grid item xs={12}>
                    <Box mt={2}>
                      <InputLabel className={classes.label}>
                        Confirme sua senha
                      </InputLabel>
                    </Box>
                    <Controller
                      as={
                        <PasswordInputField
                          className={classes.input}
                          type="password"
                          variant="outlined"
                          margin="normal"
                          error={!!errors.passwordConfirmation}
                          helperText={errors?.passwordConfirmation?.message}
                        />
                      }
                      control={control}
                      name="passwordConfirmation"
                      mode="onBlur"
                    />
                  </Grid>
                )}
                {production && (
                  <Box
                    mt={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      render="explicit"
                      sitekey={getGoogleRecaptchaToken()}
                      onChange={handleAcceptedRecaptcha}
                      onExpired={disableRecaptcha}
                    />
                  </Box>
                )}
                <Box mt={4} mb={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.roundedButton}
                    disabled={disableSubmitButton}
                  >
                    {isLoading ? 'Carregando...' : 'Acessar'}
                  </Button>
                </Box>
              </Box>
            </form>
          </Grid>
        </Box>
      )}
    </>
  )
}

PasswordForm.propTypes = {
  isNewUser: PropTypes.bool,
  companyToken: PropTypes.string,
}

export default PasswordForm
