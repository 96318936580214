import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  buttonErrorVariant: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  subTitle: {
    width: '100%',
  },
}))

export default useStyles
