import constants from 'constants/index'

const getRiskLabelByEnum = (riskEnum) => {
  if (!riskEnum) return

  const risks = constants.thirdPartyManagements.RISKS_LIST

  return risks.find((risk) => risk.enum === riskEnum)?.label
}

export default getRiskLabelByEnum
