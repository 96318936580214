import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  trashIconCursor: {
    cursor: 'pointer',
    transition: 'opacity 0.3s ease',
    opacity: 0,
  },
  boxtrashIcon: {
    display: 'none',
    alignItems: 'center',
    marginLeft: '5px',
  },
  boxInputSubCategories: {
    marginBottom: '10px',
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    '&:hover $trashIconCursor': {
      opacity: 1,
    },
    '&:hover $boxtrashIcon': {
      display: 'flex',
    },
  },
}))
