import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  formLabelSupplierAudit: {
    fontWeight: theme.typography.h6.fontWeight,
    color: theme.typography.h6.color,
  },
}))

export default styles
