import React from 'react'
import { Button } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from './styles'

const FatButton = ({
  children,
  variant = 'text',
  color = 'primary',
  className = {},
  ...rest
}) => {
  const classes = useStyles()

  return (
    <Button
      className={clsx(classes.root, className)}
      variant={variant}
      color={color}
      {...rest}
    >
      {children}
    </Button>
  )
}

export default FatButton
