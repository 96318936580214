import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button, Grid } from '@material-ui/core'
import { HelpCircle as HelpCircleIcon } from 'react-feather'

import {
  Container,
  ContentHeader,
  DriveTour,
  Page,
  Permitted,
} from 'components'
import { FatButton, Footer, AdvisorCard } from './components'
import {
  PartnerTable,
  ImPartnerTable,
  Filters,
} from './components/partner/list'

import usePermissions from 'hooks/usePermissions'
import useFilter from 'hooks/useFilter'

import { routes } from 'Routes'
import constants from 'constants/index'
import groupStreamlineIcon from 'images/group_streamline_icon.svg'
import theme from 'theme'
import useStyles from './components/partner/list/styles'
import permissions from 'constants/permissions'

const ThirdPartyManagementPartner = () => {
  const { isSupplier } = usePermissions()

  const [selectedButton, setSelectedButton] = useState(
    isSupplier() ? 'im-supplier' : 'my-suppliers',
  )
  const [openTour, setOpenTour] = useState(false)

  const history = useHistory()
  const classes = useStyles()
  const filter = useFilter()

  const handleRedirect = (route) => {
    history.push(route)
  }

  const handleSelectButton = (type) => {
    if (type === selectedButton) return

    filter.setFilters({})
    setSelectedButton(type)
  }

  const handleOpenTour = () => setOpenTour(true)

  return (
    <Page title="Gestão de Terceiros - Parceiros">
      <Container maxWidth={false}>
        <ContentHeader title="Parceiros">
          <Box display="flex" gridGap={theme.spacing(1)}>
            {!isSupplier() && (
              <Button
                id={constants.tours.thirdPartyManagements.STEPS.PARTNERS[1]}
                variant="contained"
                color="primary"
                startIcon={<HelpCircleIcon size={20} />}
                onClick={handleOpenTour}
              >
                Tutorial
              </Button>
            )}

            <DriveTour
              stepsMatrix={
                constants.tours.thirdPartyManagements.STEPS_OBJECT.PARTNERS
              }
              open={openTour}
              setOpen={setOpenTour}
            />

            {!isSupplier() && (
              <Permitted tag={permissions.COMPANY_SUPPLIERS.UPDATE}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    handleRedirect(routes.thirdPartyManagements.partners.new)
                  }
                >
                  Novo Parceiro
                </Button>
              </Permitted>
            )}

            <Filters filter={filter} selectedButton={selectedButton} />
          </Box>
        </ContentHeader>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <AdvisorCard
              imageSrc={groupStreamlineIcon}
              text={constants.thirdPartyManagements.BANNERS.PARTNER}
            />
          </Grid>

          {!isSupplier() && (
            <Grid item xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gridGap={theme.spacing(3)}
              >
                <FatButton
                  id={constants.tours.thirdPartyManagements.STEPS.PARTNERS[3]}
                  className={classes.button}
                  variant={
                    selectedButton === 'my-suppliers' ? 'contained' : 'outlined'
                  }
                  onClick={() => handleSelectButton('my-suppliers')}
                >
                  Meus Parceiros
                </FatButton>
                <FatButton
                  id={constants.tours.thirdPartyManagements.STEPS.PARTNERS[4]}
                  className={classes.button}
                  variant={
                    selectedButton === 'im-supplier' ? 'contained' : 'outlined'
                  }
                  onClick={() => handleSelectButton('im-supplier')}
                >
                  Sou Parceiro
                </FatButton>
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            {selectedButton === 'my-suppliers' ? (
              <PartnerTable filter={filter} />
            ) : (
              <ImPartnerTable filter={filter} />
            )}
          </Grid>
        </Grid>

        <Footer />
      </Container>
    </Page>
  )
}

export default ThirdPartyManagementPartner
