import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Box, Grid } from '@material-ui/core'

import { BaseDiffViewer, Select } from 'components'

import formatters from 'helpers/formatters'
import validates from 'helpers/validates'

import useFetch from 'hooks/useFetch'

import constants from 'constants/index'
import * as service from 'service'
import useStyles from './styles'
import theme from 'theme'

const ContentComparator = ({
  defaultOriginVersion,
  documentId,
  documentVersionsList,
}) => {
  const { formatContent } = validates.comparator

  const classes = useStyles()
  const { control, watch } = useForm({
    defaultValues: {
      originVersion: defaultOriginVersion,
      comparativeVersion: documentVersionsList[0]?.id,
    },
  })

  const selectedItems = documentVersionsList?.map((item) => ({
    id: item.id,
    name: `Versão ${item?.version} - Criado em ${formatters.ptBrFormatDateTime(
      item?.createdAt,
    )}`,
  }))

  const originVersion = watch('originVersion')
  const comparativeVersion = watch('comparativeVersion')

  const compare = !!originVersion && !!comparativeVersion

  const { response: versionsResponse, isLoading: versionsIsLoading } = useFetch(
    service.dponet.privacyPolicies.listVersions,
    {
      privacyPolicyId: documentId,
      ids: [originVersion, comparativeVersion],
      fullList: true,
    },
    [originVersion, comparativeVersion],
    !!compare,
  )

  const documentVersions = versionsResponse?.data?.documentVersions || []

  const originDoc = documentVersions?.find((v) => v.id === originVersion) || {}

  const comparativeDoc =
    documentVersions?.find((v) => v.id === comparativeVersion) || {}

  return (
    <Box>
      <Grid container spacing={4} component={Box} pb={2}>
        {constants.documents.CONTENT_COMPARATOR_SELECT_ITEMS.map(
          (item, index) => (
            <Grid key={index} item xs={12} sm={6}>
              <Controller
                as={
                  <Select
                    items={selectedItems}
                    label={item?.label}
                    fullWidth
                    className={classes.selectInput}
                  />
                }
                control={control}
                name={item?.name}
              />
            </Grid>
          ),
        )}
      </Grid>
      {!versionsIsLoading && (
        <BaseDiffViewer
          oldHtmlValue={originDoc?.content}
          newHtmlValue={comparativeDoc?.content}
          hasAlert
        />
      )}
    </Box>
  )
}

ContentComparator.propTypes = {
  defaultOriginVersion: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  documentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  documentVersionsList: PropTypes.arrayOf(PropTypes.object),
}

export default ContentComparator
