import React from 'react'
import { BasePermissionsForm, BaseMultiPermissionsForm } from '../'

import { Box, Typography } from '@material-ui/core'

import constants from 'constants/index'
import PropTypes from 'prop-types'

const PermissionsForm = ({
  register,
  permissions,
  handleChange,
  activeProfile,
  isEdit,
}) => {
  const permission = constants.permissionsLists

  return (
    <>
      <Box mt={2} mb={3}>
        <Typography variant="h4">Permissões</Typography>
      </Box>
      <BasePermissionsForm
        title="Atividades"
        permissionsList={permission.taskList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Canal de denúncias"
        permissionsList={permission.complaintList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Consentimentos"
        permissionsList={permission.consentList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Dados cadastrais da empresa"
        permissionsList={permission.companyList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Dados financeiros"
        permissionsList={permission.paymentList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Departamentos"
        permissionsList={permission.departmentList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BaseMultiPermissionsForm
        title="Diagnóstico"
        permissionsLists={[
          {
            title: 'Medidas de segurança',
            permissions: permission.diagnosticsList,
          },
          { title: 'Questionário', permissions: permission.questionnaireList },
          { title: 'Ameaças', permissions: permission.companyFragilities },
        ]}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Documentos"
        permissionsList={permission.privacyPolicyList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Modelos de Documento"
        permissionsList={permission.documentTemplateList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Documentos desatualizados"
        permissionsList={permission.documentList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Parceiros"
        permissionsList={permission.companySuppliers}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Incidentes"
        permissionsList={permission.incidentList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Marketplace"
        permissionsList={permission.marketplaceList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Minha LGPD"
        permissionsList={permission.dashboardList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Perfis"
        permissionsList={permission.profileList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BaseMultiPermissionsForm
        title="Processos"
        permissionsList={permission.dataProcessList}
        permissionsLists={[
          {
            permissions: permission.dataProcessList,
          },
          { title: 'Auditoria', permissions: permission.auditList },
        ]}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Recibos de consentimentos"
        permissionsList={permission.consentReceiptList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Relatórios"
        permissionsList={permission.ripdList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Treinamentos"
        permissionsList={permission.trainingList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Últimas atualizações"
        permissionsList={permission.lastUpdate}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
      <BasePermissionsForm
        title="Usuários"
        permissionsList={permission.userList}
        register={register}
        permissions={permissions}
        handleChange={handleChange}
        activeProfile={activeProfile}
        isEdit={isEdit}
      />
    </>
  )
}

PermissionsForm.propTypes = {
  register: PropTypes.func,
  permissions: PropTypes.object,
  handleChange: PropTypes.func,
  activeProfile: PropTypes.bool,
  isEdit: PropTypes.bool,
}

export default PermissionsForm
