import palette from 'theme/palette'

import { boxImage } from '../../assets/boxImage64'

import {
  WIDTH as WIDTHPdf,
  HEIGHT as HEIGHTPdf,
  PADDING,
} from '../../constants'

import { ratio } from '../../helpers'

const biggerSupplierBox = (pdf, positionY, mySuppliersData, imSupplierData) => {
  const WIDTH = WIDTHPdf(pdf)
  const HEIGHT = HEIGHTPdf(pdf)

  pdf.setTextColor(palette.white)
  pdf.setFillColor(palette.primary.main)
  pdf.rect(0, positionY, WIDTH, HEIGHT, 'F')
  pdf.addImage(
    boxImage,
    'png',
    PADDING,
    positionY + ratio(320),
    ratio(560),
    ratio(540),
  )
  pdf.setFontSize(ratio(180))

  pdf.setFont('Raleway-Bold')
  pdf.text(
    mySuppliersData,
    PADDING + ratio(220),
    positionY + ratio(600),
    null,
    null,
    'left',
  )

  pdf.text(
    imSupplierData,
    WIDTH - PADDING * 3,
    positionY + ratio(600),
    null,
    null,
    'left',
  )

  pdf.setFont('Raleway-Regular')
  pdf.setFontSize(ratio(70))

  pdf.text(
    'MEUS PARCEIROS',
    PADDING,
    positionY + ratio(700),
    null,
    null,
    'left',
  )

  pdf.text(
    'SOU FORNECEDOR',
    WIDTH - PADDING * 4,
    positionY + ratio(700),
    null,
    null,
    'left',
  )
}

export default biggerSupplierBox
