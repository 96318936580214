import React, { useEffect, useState } from 'react'
import DOMPurify from 'dompurify'
import { useHistory } from 'react-router-dom'
import { Controller, FormContext, useForm } from 'react-hook-form'
import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { FileUploadInput, Label, LoadingFeedback } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import { routes } from 'Routes'
import * as service from 'service'

import schema from './schema'
import constants from 'constants/index'

import useStyles from './styles'

const ConsentAnswerForm = ({ consentForm }) => {
  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()

  const [loading, setLoading] = useState(false)

  const formMethods = useForm({
    validationSchema: schema(consentForm),
    mode: 'onChange',
    defaultValues: {
      document: undefined,
    },
  })

  const { control, handleSubmit, setValue, errors } = formMethods

  const components = JSON.parse(consentForm?.components)

  const handleReturn = () => {
    history.push(routes.consent.consentReceipt)
  }

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      data.consentFormId = consentForm?.id

      await service.dponet.consent.createReceipt({
        consentFormId: consentForm?.id,
        ...data,
      })

      snackbar.open({
        message: 'Recibo de consentimento criado com sucesso!',
        variant: 'success',
      })

      handleReturn()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error, false) ||
          error?.error?.message,
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setValue('observation', '')
    setValue('document', undefined)

    Object.keys(components).forEach((key) => {
      setValue(key, '')
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentForm?.components])

  return (
    <FormContext {...formMethods}>
      <LoadingFeedback open={loading} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} className={classes.root}>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="textPrimary">
              Texto do consentimento
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box className={classes.descriptionBox}>
              <PerfectScrollbar
                options={{ wheelPropagation: false, useBothWheelAxes: true }}
              >
                <Box
                  fontFamily="Roboto"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(consentForm?.content),
                  }}
                />
              </PerfectScrollbar>
            </Box>
          </Grid>
          {Object.entries(components).map(([key, value]) => (
            <Grid item xs={12} key={key}>
              <Grid container spacing={2}>
                <Label
                  title={`${value?.required ? key + ' *' : key}`}
                  description={value?.subtitle}
                  item
                  xs={12}
                >
                  <Controller
                    as={
                      <TextField
                        error={!!errors[key]}
                        helperText={errors[key]?.message}
                        fullWidth
                      />
                    }
                    control={control}
                    name={key}
                    mode="onChange"
                  />
                </Label>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Label title="Observação do Consentimento" />
            <Controller
              as={
                <TextField
                  fullWidth
                  multiline
                  error={!!errors.observation}
                  helperText={errors?.observation?.message}
                />
              }
              control={control}
              name="observation"
              mode="onBlur"
            />
          </Grid>
          <Grid item xs={12}>
            <Box marginTop={1}>
              <Label title="Upload de documento" />
            </Box>
            <FileUploadInput
              title="Arraste e solte ou selecione o documento a ser anexado"
              controlName="document"
              accept={constants.consent.SUPPORTED_DOCUMENT_FILE_INPUT_TYPES}
              multiple
            />
          </Grid>
          <Box mt={2} width="100%" display="flex" justifyContent="flex-end">
            <Box pr={1}>
              <Button type="button" variant="outlined" onClick={handleReturn}>
                Voltar
              </Button>
            </Box>
            <Box>
              <Button color="primary" type="submit" variant="contained">
                Salvar
              </Button>
            </Box>
          </Box>
        </Grid>
      </form>
    </FormContext>
  )
}

export default ConsentAnswerForm
