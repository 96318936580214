import React from 'react'
import { Box, Button } from '@material-ui/core'

import { BaseConfirmationDialog } from 'components'

import useStyles from './styles'

const NoticeDialog = ({ open, onClose, responsibleEmail }) => {
  const classes = useStyles()

  return (
    <BaseConfirmationDialog
      open={open}
      setOpen={onClose}
      title="Atenção"
      text={
        <>
          Ressaltamos que esta organização já possui uma conta ativa na DPOnet.
          O responsável cadastrado é&nbsp;
          <span className={classes.highlight}>{responsibleEmail}</span>.
          Enviamos uma notificação para que ele(a) avalie e aprove o vínculo.
        </>
      }
      variant="success"
      displayedButtons={false}
      displayedIcon={false}
    >
      <Box display="flex" justifyContent="flex-end">
        <Button variant="contained" onClick={onClose}>
          OK
        </Button>
      </Box>
    </BaseConfirmationDialog>
  )
}

export default NoticeDialog
