import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { reverse } from 'named-urls'

import {
  BaseConfirmationDialog,
  LoadingFeedback,
  MenuButton,
  Permitted,
} from 'components'

import useSnackbar from 'hooks/useSnackbar'

import { routes } from 'Routes'
import * as service from 'service'
import constants from 'constants/index'
import helpers from 'helpers'
import permissions from 'constants/permissions'

const Actions = ({ companySupplier = {}, refresh = () => {} }) => {
  const [openInactivateDialog, setOpenInactivateDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const snackbar = useSnackbar()
  const history = useHistory()

  const companySupplierConstants = constants.companySuppliers

  const companySupplierStatus = companySupplier?.status
  const isCompanySupplierApproved =
    companySupplierStatus === companySupplierConstants.APPROVED_STATUS

  const handleVisualize = () => {
    history.push(
      reverse(routes.thirdPartyManagements.companies.show, {
        companySupplierId: companySupplier?.id,
      }),
    )
  }

  const handleOpenInactivateDialog = () => setOpenInactivateDialog(true)

  const handleInactivate = async () => {
    try {
      setLoading(true)
      await service.dponet.suppliers.inactivate({
        supplierId: companySupplier?.id,
      })

      snackbar.open({
        message: 'Parceiro inativado com sucesso',
        variant: 'success',
      })
      setOpenInactivateDialog(false)
      refresh()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Ocorreu um erro ao inativar o parceiro',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <MenuButton>
      <LoadingFeedback open={loading} />

      <Button color="secondary" fullWidth onClick={handleVisualize}>
        Visualizar
      </Button>

      <Permitted tag={permissions.COMPANY_SUPPLIERS.UPDATE}>
        {isCompanySupplierApproved && (
          <Button
            color="secondary"
            fullWidth
            onClick={handleOpenInactivateDialog}
          >
            Inativar
          </Button>
        )}
      </Permitted>

      {openInactivateDialog && (
        <BaseConfirmationDialog
          open={openInactivateDialog}
          setOpen={setOpenInactivateDialog}
          title="Inativar vínculo?"
          text="Deseja confirmar a inativação de vínculo com o parceiro?"
          actionButtonText="Confirmar"
          variant="warning"
          actionButton={handleInactivate}
          loading={loading}
        />
      )}
    </MenuButton>
  )
}

export default Actions
