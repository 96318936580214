import React, { useState } from 'react'
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Controller, useFormContext } from 'react-hook-form'

import {
  AutocompleteTypeAndSearch,
  FileUploadInput,
  GradientSlider,
  Select as SelectComponent,
} from 'components'

import constants from 'constants/index'
import useStyles from './styles'
import * as service from 'service'

const OptionalForm = ({ isEdit }) => {
  const [treatmentAgent, setTreatmentAgent] = useState('')
  const [supplierAudit, setSupplierAudit] = useState(false)

  const classes = useStyles()
  const { control, watch, setValue, errors } = useFormContext()

  const dataTreatmentAgents = constants.dataTreatmentAgents
  const thirdPartyManagements = constants.thirdPartyManagements

  const handleChangeTreatmentAgent = (e) => {
    setTreatmentAgent(e.target.value)
  }

  const handleChangeSupplierAudit = () => {
    setSupplierAudit((supplierAudit) => !supplierAudit)
  }

  const fetchSegments = async (inputValue) => {
    try {
      const response = await service.dponet.segments.get({
        name: inputValue,
      })

      return response.data.segments
    } catch {
      return []
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Grid
          id={constants.tours.thirdPartyManagements.STEPS.CREATION.DEFAULT[3]}
          container
          spacing={3}
        >
          <Grid item xs={12}>
            <Typography variant="h6">Contrato</Typography>
          </Grid>
          <Grid item xs={6}>
            <Controller
              as={
                <KeyboardDatePicker
                  fullWidth
                  format="DD/MM/yyyy"
                  label="Data da contratação"
                  helperText={errors?.hireDate?.message}
                  error={!!errors?.hireDate}
                />
              }
              defaultValue={watch('hireDate') || null}
              control={control}
              name="hireDate"
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              as={
                <KeyboardDatePicker
                  fullWidth
                  format="DD/MM/yyyy"
                  label="Data da renovação do contrato"
                  helperText={errors?.renewDate?.message}
                  error={!!errors?.renewDate}
                />
              }
              defaultValue={watch('renewDate') || null}
              control={control}
              name="renewDate"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={
                <TextField
                  fullWidth
                  multiline
                  minRows={4}
                  label="Descrição"
                  helperText="Use este espaço para descrever o papel deste parceiro em sua organização - suas atividades e serviços oferecidos"
                />
              }
              control={control}
              name="description"
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteTypeAndSearch
              textField={{ label: 'Segmento', variant: 'outlined' }}
              requestToLoadOptions={fetchSegments}
              name="segment"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          id={constants.tours.thirdPartyManagements.STEPS.CREATION.DEFAULT[4]}
          container
          spacing={3}
        >
          <Grid item xs={12}>
            <Typography variant="h6">Agente de Tratamento</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={
                <RadioGroup
                  onChange={handleChangeTreatmentAgent}
                  value={treatmentAgent}
                >
                  {dataTreatmentAgents.OPTIONS_TYPES.map((option) => (
                    <FormControlLabel
                      key={option.id}
                      value={option.id.toLowerCase()}
                      control={<Radio />}
                      label={option.name}
                    />
                  ))}
                </RadioGroup>
              }
              control={control}
              name="treatmentAgent"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          id={constants.tours.thirdPartyManagements.STEPS.CREATION.DEFAULT[5]}
          container
          spacing={3}
        >
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Controller
                  as={
                    <Switch
                      color="primary"
                      onChange={handleChangeSupplierAudit}
                    />
                  }
                  control={control}
                  name="supplierAudit"
                />
              }
              value={supplierAudit}
              label="Auditoria de Fornecedor"
              labelPlacement="end"
              classes={{ label: classes.formLabelSupplierAudit }}
            />
          </Grid>

          {watch('supplierAudit') && (
            <>
              <Grid item xs={12}>
                <Typography variant="body1">
                  Após o fornecedor responder aos questionários, com que
                  frequência você deseja que o notifiquemos para revisar e
                  atualizar as respostas conforme a nova situação da
                  organização?
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Controller
                  as={
                    <TextField
                      fullWidth
                      type="number"
                      error={!!errors.supplierAuditFrequency}
                      helperText={errors.supplierAuditFrequency?.message}
                    />
                  }
                  control={control}
                  name="supplierAuditFrequency"
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <Controller
                  as={
                    <SelectComponent
                      fullWidth
                      error={!!errors.supplierAuditFrequencyType}
                      helperText={errors.supplierAuditFrequencyType?.message}
                      items={
                        thirdPartyManagements.SUPPLIER_AUDIT_FREQUENCY_TYPES
                      }
                    />
                  }
                  control={control}
                  name="supplierAuditFrequencyType"
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          id={constants.tours.thirdPartyManagements.STEPS.CREATION.DEFAULT[6]}
          container
          spacing={3}
        >
          <Grid item xs={12}>
            <Typography variant="h6">Nível do Risco</Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              as={
                <GradientSlider
                  options={thirdPartyManagements.RISKS_LIST}
                  setControllerValue={setValue}
                  name="supplierRisk"
                  defaultValue={watch('supplierRisk')}
                />
              }
              control={control}
              name="supplierRisk"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h6">Documentos</Typography>
          </Grid>
          <Grid item xs={12}>
            <FileUploadInput
              title="Arraste e solte ou selecione o documento"
              controlName="document"
              isViewFile
              hasDownloadFile
              disabled={isEdit}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default OptionalForm
