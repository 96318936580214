import React, { Fragment } from 'react'
import { File as FileIcon } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Chip,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import theme from 'theme'
import useStyles from './styles'

const DocumentCard = ({
  document,
  skeleton = false,
  redirectToDocument = () => {},
}) => {
  const classes = useStyles()

  return (
    <Paper
      component={Box}
      py={2}
      pl={2}
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(2)}
    >
      <Box display="flex" gridGap={theme.spacing(2)} alignItems="center" mr={2}>
        {skeleton ? (
          <Fragment>
            <Skeleton variant="circle" width={24} height={24} />
            <Skeleton variant="rect" width={180} height={24} />
          </Fragment>
        ) : (
          <Fragment>
            <Box display="flex" alignItems="center">
              <FileIcon />
            </Box>
            <Tooltip
              title={document?.documentCategory?.name.toUpperCase()}
              placement="top"
            >
              <Chip
                label={document?.documentCategory?.name}
                className={classes.chip}
                size="small"
              />
            </Tooltip>
          </Fragment>
        )}
      </Box>
      <PerfectScrollbar>
        <Box height={56} pr={2} textAlign="justify">
          {skeleton ? (
            [...Array(3)].map((_, index) => (
              <Skeleton key={index} variant="text" />
            ))
          ) : (
            <Typography variant="h5">
              #{document?.id} {document?.title}
            </Typography>
          )}
        </Box>
      </PerfectScrollbar>
      <Box>
        {skeleton ? (
          <Skeleton variant="rect" width={180} height={36} />
        ) : (
          <Button onClick={() => redirectToDocument(document?.link)}>
            Abrir documento
          </Button>
        )}
      </Box>
    </Paper>
  )
}

DocumentCard.propTypes = {
  document: PropTypes.object,
  skeleton: PropTypes.bool,
  redirectToDocument: PropTypes.func,
}

export default DocumentCard
