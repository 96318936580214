import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Tooltip,
} from '@material-ui/core'

import { BaseDialog, LoadingFeedback } from 'components'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import schema from './schema'
import useStyles from './styles'
import * as service from 'service'
import constants from 'constants/index'

const DocumentDownloadModal = ({ open, setOpen, currentDocument }) => {
  const { DOWNLOAD_SNACKBAR_MESSAGE } = constants.documents

  const [loading, setLoading] = useState(false)

  const classes = useStyles()
  const snackbar = useSnackbar()

  const { control, errors, watch, handleSubmit } = useForm({
    validationSchema: schema,
    defaultValues: {
      documentCopy: false,
      scienceReport: false,
    },
  })

  const documentCopy = watch('documentCopy')
  const scienceReport = watch('scienceReport')

  const disabledSubmit = !documentCopy && !scienceReport

  const getSnackbarMessage = (documentCopy, scienceReport) => {
    if (documentCopy && scienceReport) return DOWNLOAD_SNACKBAR_MESSAGE.both
    if (documentCopy) return DOWNLOAD_SNACKBAR_MESSAGE.document
    if (scienceReport) return DOWNLOAD_SNACKBAR_MESSAGE.report
  }

  const onSubmit = async (data) => {
    try {
      setLoading(true)

      if (data?.documentCopy) {
        const response = await service.dponet.privacyPolicies.exportDocument({
          privacyPolicyId: currentDocument?.id,
        })

        const fileUrl = response?.data?.fileUrl

        window.open(fileUrl, '_blank')
      }

      if (data?.scienceReport) {
        const response = await service.dponet.consent.acceptanceReport({
          consentFormId: currentDocument?.consentFormId,
        })

        const fileUrl = response?.data?.fileUrl

        window.open(fileUrl, '_blank')
      }

      const snackbarMessage = getSnackbarMessage(
        data?.documentCopy,
        data?.scienceReport,
      )

      snackbar.open({
        message: snackbarMessage,
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      title="Baixar documentos"
      closeButtonText="Cancelar"
      justifyActions="end"
      actionButtonText="Baixar"
      formRef="document-download-modal-form"
      disableButton={disabledSubmit}
    >
      <Box
        display="flex"
        flexDirection="column"
        component="form"
        id="document-download-modal-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <LoadingFeedback open={loading} />
        {constants.documents.DOCUMENT_DOWNLOAD_CONTROL_ITEMS.map(
          (item, index) => {
            const noHasAccepted =
              item?.name === 'scienceReport' && !currentDocument?.consentFormId

            return (
              <Controller
                key={index}
                as={
                  <Tooltip
                    title={
                      noHasAccepted
                        ? 'Este documento não possui coleta de aceites!'
                        : ''
                    }
                    placement="right"
                  >
                    <FormControlLabel
                      control={<Checkbox />}
                      label={item?.label}
                      className={classes.formControlLabel}
                      disabled={noHasAccepted}
                    />
                  </Tooltip>
                }
                name={item?.name}
                control={control}
              />
            )
          },
        )}
        {(!!errors?.documentCopy || !!errors?.scienceReport) && (
          <FormHelperText
            error={!!errors?.documentCopy || !!errors?.scienceReport}
          >
            {
              <>
                {errors?.documentCopy?.message ||
                  errors?.scienceReport?.message}
              </>
            }
          </FormHelperText>
        )}
      </Box>
    </BaseDialog>
  )
}

DocumentDownloadModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  currentDocument: PropTypes.object,
}

export default DocumentDownloadModal
