import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    gridGap: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
  },
  textBox: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}))
