import { makeStyles } from '@material-ui/core'

const styles = ({ disabled }) =>
  makeStyles((theme) => ({
    typography: {
      color: disabled ? theme.palette.grey[500] : theme.palette.secondary.main,
    },
  }))

export default styles
