const formatTimeSpent = (timeInSeconds) => {
  if (timeInSeconds < 60) {
    return `${timeInSeconds} segundo${timeInSeconds === 1 ? '' : 's'}`
  }

  const minutes = Math.floor(timeInSeconds / 60)
  const seconds = timeInSeconds % 60

  if (minutes < 60) {
    return `${minutes} minuto${minutes === 1 ? '' : 's'}${
      seconds > 0 ? ` e ${seconds} segundo${seconds === 1 ? '' : 's'}` : ''
    }`
  }

  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60

  return `${hours} hora${hours === 1 ? '' : 's'}${
    remainingMinutes > 0
      ? ` e ${remainingMinutes} minuto${remainingMinutes === 1 ? '' : 's'}`
      : ''
  }`
}

export default formatTimeSpent
