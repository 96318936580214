import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { Box, Button, Tab, Tabs, Tooltip } from '@material-ui/core'

import {
  Container,
  ContentHeader,
  DriveTour,
  LoadingFeedback,
  Page,
} from 'components'
import { DocumentForm } from './components'

import helpers from 'helpers'

import useFetch from 'hooks/useFetch'

import { routes } from 'Routes'
import * as service from 'service'
import constants from 'constants/index'

const DocumentsNew = () => {
  const { DOCUMENTS_CATEGORY_ID } = constants.documents

  const [currentTab, setCurrentTab] = useState(DOCUMENTS_CATEGORY_ID)
  const [openTour, setOpenTour] = useState(false)
  const [loading, setLoading] = useState(false)

  const history = useHistory()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const depreciatedId = Number(queryParams.get('depreciatedId'))

  const { response, isLoading } = useFetch(
    service.dponet.companyDocuments.get,
    {
      documentId: depreciatedId,
    },
    [depreciatedId],
    !!depreciatedId,
  )

  const depreciatedDocument = response?.data?.document

  const handleOpenTour = () => {
    const where = 'Documentos'
    const params = {
      action: 'tour-gerenciamento-documentos',
    }

    helpers.mixpanel.track(where, 'Tour', params)
    setOpenTour(true)
  }

  const handleTabChange = (_, clickedTab) => setCurrentTab(clickedTab)

  const handleBackRedirection = () => history.push(routes.documents.all)

  return (
    <Page title="Novo documento">
      <Container maxWidth={false}>
        <LoadingFeedback open={loading || isLoading} />
        <ContentHeader title="Novo documento">
          <Box>
            <Tooltip title="Iniciar o tour guiado">
              <Button
                variant="contained"
                color="primary"
                startIcon={<HelpCircleIcon size={20} />}
                onClick={handleOpenTour}
                fullWidth
                id={constants.tours.documents.MANAGEMENT_STEP_1}
              >
                Tutorial
              </Button>
            </Tooltip>
          </Box>
        </ContentHeader>
        <Tabs
          scrollButtons="auto"
          value={currentTab}
          variant="scrollable"
          textColor="primary"
          indicatorColor="primary"
          onChange={handleTabChange}
        >
          <Tab value={DOCUMENTS_CATEGORY_ID} label="Documentos" />
        </Tabs>
        {!isLoading && (
          <DocumentForm
            setLoading={setLoading}
            handleBackRedirection={handleBackRedirection}
            depreciatedDocument={depreciatedDocument}
          />
        )}
      </Container>
      <DriveTour
        stepsMatrix={constants.tours.documents.MANAGEMENT_STEPS_OBJECT}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default DocumentsNew
