import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  formLabel: {
    fontWeight: 600,
  },
  icon: {
    fontSize: 16,
    marginLeft: theme.spacing(0.5),
    color: theme.palette.text.linkHelp,
  },
}))

export default styles
