import React from 'react'
import { Box, lighten, Paper, Typography } from '@material-ui/core'

import { RadialGradientChart } from 'components'

import useStyles from './styles'
import theme from 'theme'

const CompanyCompliance = ({ compliance = 0 }) => {
  const classes = useStyles()

  const complianceValue = Math.round(compliance)

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Conformidade da empresa
      </Typography>

      <Paper className={classes.paper}>
        <RadialGradientChart
          value={complianceValue}
          height={210}
          hollowSize="70%"
          fontSize="40px"
          showValue
          secondaryColor={lighten(theme.palette.azure, 0.5)}
        />
      </Paper>
    </Box>
  )
}

export default CompanyCompliance
