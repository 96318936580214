import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import {
  Box,
  Grid,
  TablePagination,
  Typography,
  useMediaQuery,
} from '@material-ui/core'

import { DocumentDownloadModal, DocumentPreviewCard } from './components'
import { ConfirmationDialog, NoData2 } from 'components'

import helpers from 'helpers'

import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'
import usePagination from 'hooks/usePagination'

import theme from 'theme'
import useStyles from './styles'
import * as service from 'service'
import constants from 'constants/index'

const MyDocuments = ({ filter, setLoading, setHasDocument }) => {
  const { INACTIVE_STATUS_ID, ACTIVE_STATUS_ID } = constants.documents

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  const [openInactiveDialog, setOpenInactiveDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openDownloadModal, setOpenDownloadModal] = useState(false)
  const [wasResetFilters, setWasResetFilters] = useState(false)
  const [documentData, setDocumentData] = useState()

  const snackbar = useSnackbar()
  const classes = useStyles()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoadingStart, refresh } = useFetch(
    service.dponet.privacyPolicies.get,
    {
      page,
      perPage,
      ...filter.filters,
    },
    [page, perPage, filter.filters, wasResetFilters],
    !!wasResetFilters,
  )

  const documents = response?.data?.privacyPolicies

  const isInactive = documentData?.status === INACTIVE_STATUS_ID

  const handleOpenInactiveDialog = (id, status) => {
    setDocumentData({ id, status })
    setOpenInactiveDialog(true)
  }

  const handleOpenDeleteDialog = (id) => {
    setDocumentData({ id })
    setOpenDeleteDialog(true)
  }

  const handleOpenDownloadModal = (currentDocument) => {
    setDocumentData(currentDocument)
    setOpenDownloadModal(true)
  }

  const handleCloseInactiveDialog = () => setOpenInactiveDialog(false)
  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false)

  const resetFilters = async (filters = {}) => await filter.setFilters(filters)

  const handleDocumentInactivation = async () => {
    try {
      setLoading(true)

      const status = isInactive ? ACTIVE_STATUS_ID : INACTIVE_STATUS_ID

      await service.dponet.privacyPolicies.put({
        privacyPolicyId: documentData?.id,
        status,
      })

      snackbar.open({
        message: `Documento ${
          isInactive ? 'ativado' : 'inativado'
        } com sucesso!`,
        variant: 'success',
      })

      handleCloseInactiveDialog()
      refresh()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          `Ocorreu uma falha ao tentar ${
            isInactive ? 'ativar' : 'inativar'
          } o documento`,
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleDocumentDeletion = async () => {
    try {
      setLoading(true)

      await service.dponet.privacyPolicies.destroy({
        privacyPolicyId: documentData?.id,
      })

      snackbar.open({
        message: 'Documento excluído com sucesso!',
        variant: 'success',
      })

      handleCloseDeleteDialog()
      refresh()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Ocorreu uma falha ao tentar excluir o documento',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    resetFilters({ page, perPage })
    setWasResetFilters(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isEmpty(documents)) setHasDocument(true)
    else setHasDocument(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents])

  return (
    <Box>
      {isEmpty(documents) && !isLoadingStart ? (
        <NoData2 title="Nenhum documento foi encontrado." />
      ) : (
        <Grid container spacing={2}>
          {isLoadingStart
            ? [...Array(6)].map((_, index) => (
                <Grid key={index} item xs={12} md={6} lg={4}>
                  <DocumentPreviewCard skeleton />
                </Grid>
              ))
            : documents?.map((currentDocument) => (
                <Grid key={currentDocument?.id} item xs={12} md={6} lg={4}>
                  <DocumentPreviewCard
                    currentDocument={currentDocument}
                    handleOpenInactiveDialog={handleOpenInactiveDialog}
                    handleOpenDeleteDialog={handleOpenDeleteDialog}
                    handleOpenDownloadModal={handleOpenDownloadModal}
                  />
                </Grid>
              ))}
        </Grid>
      )}
      {!isEmpty(documents) && (
        <TablePagination
          component="div"
          count={response?.data?.meta?.totalCount || 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          rowsPerPage={perPage}
          rowsPerPageOptions={[5, 10, 25, 100]}
          labelRowsPerPage={isDesktop ? 'Por página' : ''}
          nextIconButtonProps={{ size: 'small' }}
          backIconButtonProps={{ size: 'small' }}
        />
      )}
      <ConfirmationDialog
        open={openInactiveDialog}
        setOpen={setOpenInactiveDialog}
        text={`Tem certeza de que deseja ${
          isInactive ? 'ativar' : 'inativar'
        } este documento?`}
        actionAcceptButton={handleDocumentInactivation}
        textButton={isInactive ? 'Ativar' : 'Inativar'}
        textButtonCancel="Cancelar"
        hiddenIcon
        disableBackdropClick
      />
      <ConfirmationDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        text={
          <Typography variant="h3">
            Tem certeza de que deseja excluir este documento?&nbsp;
            <span className={classes.textError}>
              Esta ação não poderá ser desfeita!
            </span>
          </Typography>
        }
        textButton="Excluir"
        actionAcceptButton={handleDocumentDeletion}
        hiddenIcon
        disableBackdropClick
        variant="error"
      />
      <DocumentDownloadModal
        open={openDownloadModal}
        setOpen={setOpenDownloadModal}
        currentDocument={documentData}
      />
    </Box>
  )
}

MyDocuments.propTypes = {
  filter: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
  setHasDocument: PropTypes.func.isRequired,
}

export default MyDocuments
