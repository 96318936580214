import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ departmentId = '', ...params }) => {
  return await dponetAPI.get(`/departments/${departmentId}`, { params })
}

const getAll = async (params) => {
  return await dponetAPI.get(`/departments/all`, { params })
}

const create = async ({ ...data }) => {
  return await dponetAPI.post('/departments', data)
}

const put = async ({ departmentId = '', ...data }) => {
  return await dponetAPI.put(`/departments/${departmentId}`, data)
}

const destroy = async (departmentId) => {
  return await dponetAPI.delete(`/departments/${departmentId}`)
}

const availableUsers = async (params) => {
  return await dponetAPI.get(`/departments/available_users`, {
    params,
  })
}

const linkUser = async ({ departmentId = '', ...data }) => {
  return await dponetAPI.post(`/departments/${departmentId}/users`, {
    ...data,
  })
}

const unlinkUser = async ({ departmentId = '', userId = '' }) => {
  return await dponetAPI.delete(`/departments/${departmentId}/users/${userId}`)
}

const getCode = async ({ departmentId = '' }) => {
  return await dponetAPI.get(
    `/departments/${departmentId}/next_data_process_code`,
  )
}

const changeStatus = async ({ departmentId = '' }) => {
  return await dponetAPI.put(
    `/departments/${departmentId}/activate_or_inactivate`,
  )
}

const byProcess = async () => {
  return await dponetAPI.get('/departments/list_by_process')
}

export default {
  get,
  getAll,
  create,
  put,
  availableUsers,
  linkUser,
  unlinkUser,
  getCode,
  changeStatus,
  byProcess,
  destroy,
}
