import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Alert } from '@material-ui/lab'
import { Box, TextField, Typography } from '@material-ui/core'

import { BaseDialog, Select } from 'components'

import schema from './schema'
import theme from 'theme'

const DocumentModal = ({ open, setOpen, categories = [], documentData }) => {
  const { control, errors, watch } = useForm({
    validationSchema: schema,
    defaultValues: {
      title: documentData?.title || '',
      link: documentData?.link || '',
      privateAccess: documentData?.privateAccess || false,
      documentCategoryId: documentData?.documentCategory?.id || '',
      otherDocumentCategory: '',
    },
  })

  const PRIVATE_ACCESS_OPTIONS = [
    {
      id: true,
      name: 'Documento privado',
    },
    {
      id: false,
      name: 'Documento público',
    },
  ]

  const DOCUMENT_CONTROL_ITEMS = [
    {
      controlName: 'title',
      label: 'Nome do documento',
    },
    {
      controlName: 'link',
      label: 'Link',
      type: 'url',
    },
  ]

  const documentCategoryId = watch('documentCategoryId')
  const privateAccess = watch('privateAccess')

  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      title="Visualizar documento"
      closeButtonText="Voltar"
      dialogSize="md"
      fullWidth
    >
      <Box
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(2)}
        py={2}
      >
        {DOCUMENT_CONTROL_ITEMS.map((item, index) => (
          <Controller
            key={index}
            control={control}
            name={item?.controlName}
            as={
              <TextField
                label={item?.label}
                type={item?.type || 'text'}
                error={!!errors?.[item?.controlName]}
                helperText={<>{errors?.[item?.controlName]?.message}</>}
                fullWidth
                disabled
              />
            }
            mode="onBlur"
          />
        ))}
        <Controller
          control={control}
          name="documentCategoryId"
          as={
            <Select
              label="Categoria"
              items={categories}
              error={!!errors?.documentCategoryId}
              helperText={<>{errors?.documentCategoryId?.message}</>}
              fullWidth
              anotherOption
              disabled
            />
          }
          mode="onBlur"
        />
        {documentCategoryId === 'another' && (
          <Controller
            control={control}
            name="otherDocumentCategory"
            as={
              <TextField
                label="Especifique a categoria"
                error={!!errors.otherDocumentCategory}
                helperText={<>{errors?.otherDocumentCategory?.message}</>}
                fullWidth
                disabled
              />
            }
            mode="onBlur"
          />
        )}
        <Box
          pt={1}
          display="flex"
          flexDirection="column"
          gridGap={theme.spacing(2)}
        >
          <Typography variant="h4">Restrições</Typography>
          <Controller
            control={control}
            name="privateAccess"
            as={
              <Select
                label="Categoria"
                items={PRIVATE_ACCESS_OPTIONS}
                error={!!errors?.privateAccess}
                helperText={<>{errors?.otherDocumentCategory?.message}</>}
                defaultValue={documentData?.privateAccess || false}
                fullWidth
                disabled
              />
            }
            mode="onBlur"
          />
        </Box>
        {privateAccess && (
          <Alert severity="info" icon={false}>
            <strong>Documento privado: </strong>Verifique as permissões de
            acesso e compartilhamento, junto ao seu serviço de nuvem. Não
            recomendamos que documentos privados estejam acessíveis a todos os
            usuários
          </Alert>
        )}
      </Box>
    </BaseDialog>
  )
}

DocumentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  categories: PropTypes.array,
  documentData: PropTypes.object.isRequired,
}

export default DocumentModal
