import theme from 'theme'

const CONTENT_COMPARATOR_ALERT_MESSAGE = {
  normal:
    'O conteúdo foi convertido para texto sem formatação para facilitar a comparação.',
  notFound:
    'Nenhuma diferença encontrada entre as versões selecionadas. Verifique se as versões são realmente diferentes.',
  noContentDiff:
    'Não é possível mostrar as diferenças de conteúdo, pois apenas a formatação (HTML) foi alterada.',
}

const CUSTOM_STYLES_COMPONENT = {
  contentText: {
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    wordBreak: 'break-all',
  },
  lineNumber: {
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    wordBreak: 'break-all',
  },
  gutter: {
    fontFamily: theme.typography.body2.fontFamily,
    fontSize: theme.typography.body2.fontSize,
    wordBreak: 'break-all',
  },
}

export default {
  CONTENT_COMPARATOR_ALERT_MESSAGE,
  CUSTOM_STYLES_COMPONENT,
}
