import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
  useMediaQuery,
} from '@material-ui/core'

import BannerHeadCard from '../BannerHeadCard'
import {
  BannerPreview,
  ColorCustomization,
  DescriptionCustomization,
  LanguageCustomization,
  LogoPreview,
  LogoUpload,
  PositionCustomization,
  PositionPreview,
} from './components'

import theme from 'theme'
import styles from './styles'
import constants from 'constants/index'

const BannerCustomizationCard = ({
  banner,
  descriptions,
  onDescriptionChange,
}) => {
  const [openBanner, setOpenBanner] = useState(false)
  const [selectedTab, setSelectedTab] = useState(
    constants.cookies.banner.COLOR_CUSTOMIZATION_CARD_ID,
  )
  const [selectedLanguage, setSelectedLanguage] = useState(
    constants.cookies.language.DEFAULT_LANGUAGE,
  )
  const [localDescription, setLocalDescription] = useState('')

  const useStyles = styles({ selectedTab })
  const classes = useStyles()

  const smDown = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  })
  const mdUp = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })

  const logoPreview =
    selectedTab === constants.cookies.banner.LOGO_CUSTOMIZATION_CARD_ID

  const positionPreview =
    selectedTab === constants.cookies.banner.POSITION_CUSTOMIZATION_CARD_ID

  const handleTabChange = (_, clickedTab) => setSelectedTab(clickedTab)

  useEffect(() => {
    const currentDescription = descriptions.find(
      (description) => description.language.code === selectedLanguage,
    )
    if (currentDescription) {
      setLocalDescription(currentDescription.description)
    }
  }, [descriptions, selectedLanguage])

  return (
    <Paper
      variant="outlined"
      id={constants.cookies.banner.COOKIES_BANNER_SETTING_DRIVER_STEP_3}
    >
      <BannerHeadCard name="Personalização do Banner" stage="02" />
      <Divider />
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(2)}
      >
        <Tabs
          value={selectedTab}
          variant="scrollable"
          onChange={handleTabChange}
        >
          {constants.cookies.banner.CUSTOMIZATION_CARD_TABS.map((tab) => (
            <Tab key={tab.id} label={tab.tabName} value={tab.id} />
          ))}
        </Tabs>
        <Grid container spacing={smDown ? 2 : 0}>
          <Grid
            item
            xs={12}
            md={positionPreview ? 12 : 5}
            lg={positionPreview ? 12 : logoPreview ? 6 : 5}
            xl={positionPreview ? 4 : 6}
          >
            <Box className={classes.colorCustomizationContainer}>
              <ColorCustomization />
            </Box>
            <Box className={classes.logoUploadContainer}>
              <LogoUpload />
            </Box>
            <Box className={classes.descriptionCustomizationContainer}>
              <Box
                display="flex"
                width="100%"
                flexDirection="column"
                alignItems="flex-start"
              >
                <LanguageCustomization
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                />
                <DescriptionCustomization
                  selectedLanguage={selectedLanguage}
                  localDescription={localDescription}
                  setLocalDescription={setLocalDescription}
                  onDescriptionChange={onDescriptionChange}
                />
              </Box>
              {mdUp && <Divider orientation="vertical" variant="middle" />}
            </Box>
            <Box className={classes.positionCustomizationContainer}>
              <PositionCustomization />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={positionPreview ? 12 : 7}
            lg={positionPreview ? 12 : logoPreview ? 6 : 7}
            xl={positionPreview ? 8 : 6}
          >
            <Box className={classes.bannerPreviewContainer}>
              <BannerPreview
                openBanner={openBanner}
                setOpenBanner={setOpenBanner}
                language={selectedLanguage}
                description={localDescription}
              />
            </Box>
            <Box className={classes.logoPreviewContainer}>
              <LogoPreview banner={banner} />
            </Box>
            <Box className={classes.positionPreviewContainer}>
              <PositionPreview description={localDescription} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

BannerCustomizationCard.propTypes = {
  banner: PropTypes.object,
  updateDescriptions: PropTypes.func.isRequired,
}

export default BannerCustomizationCard
