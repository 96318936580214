import audits from './audits'
import cardFlags from './cardFlags'
import comparator from './comparator'
import consents from './consents'
import diffViewer from './diffViewer'
import file from './file'
import form from './form'
import jodit from './jodit'
import lodash from './lodash'
import settings from './settings'

export default {
  audits,
  cardFlags,
  comparator,
  consents,
  diffViewer,
  file,
  form,
  jodit,
  lodash,
  settings,
}
