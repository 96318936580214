import React from 'react'

import { FilterButton, Filters } from 'components'

const FiltersComponent = ({ selectedButton, filter = {} }) => {
  return (
    <>
      {selectedButton === 'my-suppliers' ? (
        <Filters filter={filter} key={selectedButton}>
          <input textfieldinput="true" label="Identificador" name="id" />

          <input
            textfieldinput="true"
            label="Razão Social"
            name="supplier_name"
          />

          <input textfieldinput="true" label="Documento" name="document" />
        </Filters>
      ) : (
        <Filters filter={filter} key={selectedButton}>
          <input textfieldinput="true" label="Identificador" name="id" />

          <input
            textfieldinput="true"
            label="Razão Social"
            name="company_name"
          />

          <input
            textfieldinput="true"
            label="Documento"
            name="company_document"
          />
        </Filters>
      )}

      <FilterButton setDrawerOpen={filter.setDrawerOpen} />
    </>
  )
}

export default FiltersComponent
