import { alpha, colors, makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  descriptionBox: {
    backgroundColor: theme.palette.primary.light,
    borderRadius: 8,
    lineHeight: 1.625,
  },
  headerBox: {
    backgroundColor: theme.palette.primary.hover,
    border: `2px solid ${alpha(theme.palette.common.white, 0.3)}`,
    borderBottom: 0,
    color: theme.palette.layout.textPrimary,
    borderRadius: '20px 20px 0px 0px',
    padding: theme.spacing(3),
    textTransform: 'uppercase',
  },
  contentBox: {
    border: `1px solid ${theme.palette.custom.border}`,
    borderRadius: '0px 0px 20px 20px',
    boxShadow: '0 15px 10px 0px rgba(0, 0, 0, 0.3)',
  },
  authorizeButton: {
    backgroundColor: colors.lightGreen[700],
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    fontSize: 18,
    '&:hover': {
      backgroundColor: colors.lightGreen[900],
    },
  },
  avatar: {
    width: 110,
    height: 110,
    backgroundColor: theme.palette.primary.hover,
    fontFamily: 'Raleway',
    fontSize: theme.typography.h1.fontSize,
    fontWeight: 'bold',
  },
  title: {
    fontFamily: 'Raleway',
    fontWeight: 700,
  },
  document: {
    fontWeight: 'normal',
  },
  requiredSpan: {
    color: theme.palette.error.main,
  },
  labelButton: {
    color: colors.grey[700],
    fontSize: 16,
    fontWeight: 700,
  },
  labelDescription: {
    color: colors.grey[600],
    fontSize: 16,
  },
  inputText: {
    padding: 20,
    border: `2px solid ${colors.grey[500]}`,
    borderRadius: 12,
    fontFamily: 'Roboto',
    backgroundColor: colors.grey[200],
    cursor: 'not-allowed',
  },
  scrollBar: {
    maxHeight: 600,
  },
}))

export default styles
