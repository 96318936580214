import permissions from '../permissions'

const AUDITS = [
  {
    name: 'Visualizar',
    tag: permissions.AUDITS.VIEW,
  },
  {
    name: 'Gerenciar',
    tag: permissions.AUDITS.MANAGE,
  },
]

export default AUDITS
