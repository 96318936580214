const INCIDENT_VIOLATION_TYPES = [
  {
    value: 1,
    name: 'Confidencialidade',
    description: 'Houve acesso não autorizado aos dados, violando seu sigilo.',
  },
  {
    value: 2,
    name: 'Integridade',
    description:
      'Houve alteração ou destruição de dados de maneira não autorizada ou acidental.',
  },
  {
    value: 3,
    name: 'Disponibilidade',
    description:
      'Houve perda ou dificuldade de acesso aos dados por período significativo.',
  },
]

const GENERAL_INFORMATION = 'Informações gerais'
const SCIENCE_OCCURRENCE = 'Ciência da Ocorrência'
const TIMPERTIVITY_COMMUNICATION = 'Tempestividade e Comunicação'
const DESCRIPTION_IMPACT = 'Descrição e Impacto'
const RISKS_CONSEQUENCES = 'Riscos e Consequências'
const SECURITY_MEASURES = 'Medidas de Segurança'

const GENERAL_INFORMATION_STEP = 0
const SCIENCE_OCCURRENCE_STEP = 1
const TIMPERTIVITY_COMMUNICATION_STEP = 2
const DESCRIPTION_IMPACT_STEP = 3
const RISKS_CONSEQUENCES_STEP = 4
const SECURITY_MEASURES_STEP = 5

const INCIDENT_INFORMATION_STEPS = [
  {
    title: GENERAL_INFORMATION,
    step: GENERAL_INFORMATION,
    buttonId: 'id-incident-generalinformation',
  },
  {
    title: SCIENCE_OCCURRENCE,
    step: SCIENCE_OCCURRENCE,
    buttonId: 'id-incident-scienceOccurrence',
  },
  {
    title: TIMPERTIVITY_COMMUNICATION,
    step: TIMPERTIVITY_COMMUNICATION,
    buttonId: 'id-incident-timberCommunication',
  },
  {
    title: DESCRIPTION_IMPACT,
    step: DESCRIPTION_IMPACT,
    buttonId: 'id-incident-descriptionImpact',
  },
  {
    title: RISKS_CONSEQUENCES,
    step: RISKS_CONSEQUENCES,
    buttonId: 'id-incident-risksConsequence',
  },
  {
    title: SECURITY_MEASURES,
    step: SECURITY_MEASURES,
    buttonId: 'id-incident-securityMeasures',
  },
]

const TYPE_COMMUNICATION_OPTIONS = [
  {
    id: 'complete',
    name: 'Completa',
    description:
      'Todas as informações do incidente estão disponíveis <strong>e a comunicação aos titulares já foi realizada.</strong>',
  },
  {
    id: 'preliminary',
    name: 'Preliminar',
    description:
      'Nem todas as informações sobre o incidente estão disponíveis, justificadamente, ou ' +
      '<strong>a comunicação aos titulares ainda não foi realizada.</strong> A complementação deverá ser encaminhada em até ' +
      '<strong>30 dias corridos</strong> da comunicação preliminar.',
  },
  {
    id: 'additional',
    name: 'Complementar',
    description:
      'Complementação de informações prestadas em comunicação preliminar. ' +
      '<strong>A comunicação complementar deve ser protocolada no mesmo processo que a comunicação preliminar.</strong>',
  },
]

const AVAILABLE_RISK_OPTIONS = [
  {
    id: 'risk_holder',
    name: 'O incidente de segurança pode acarretar risco ou dano relevante aos titulares.',
  },
  {
    id: 'non_risk_holder',
    name: 'O incidente não acarretou risco ou dano relevante aos titulares. (<strong>Comunicação complementar</strong>)',
  },
  {
    id: 'verify_risk_holder',
    name: 'O risco do incidente aos titulares ainda está sendo apurado. (<strong>Comunicação preliminar</strong>)',
  },
]

const COMMUNICATION_HOLDER_OPTIONS = [
  {
    id: 'yes',
    name: 'Sim.',
  },
  {
    id: 'not_risk',
    name: 'Não, por não haver risco ou dano relevante a eles',
  },
  {
    id: 'comunication_progress',
    name: 'Não, mas o processo de comunicação está em andamento.',
  },
  {
    id: 'risk_investigated',
    name: 'Não, vez que o risco do incidente ainda está sendo apurado. (comunicação preliminar)',
  },
]

const COMMUNICATION_REQUIRED = [
  {
    id: 'true',
    name: 'Sim',
  },
  {
    id: 'false',
    name: 'Não',
  },
]

const IMPACT_HOLDER = [
  {
    id: 'may_not_take_damage',
    name: 'Podem não sofrer danos negligenciáveis ou superáveis sem dificuldade.',
  },
  {
    id: 'may_suffer_damage',
    name: 'Podem sofrer danos, superáveis com certa dificuldade.',
  },
  {
    id: 'may_suffer_significant_damage',
    name: 'Podem sofrer danos importantes, superáveis com muita dificuldade.',
  },
  {
    id: 'may_suffer_injury',
    name:
      'Podem sofrer lesão ou ofensa a direitos ou interesses difusos, coletivos ou individuais, que dadas as ' +
      'circunstâncias, ocasionam ou tem potencial para ocasionar dano significativo ou irreversível.',
  },
]

const SECURITY_OPTIONS = [
  {
    id: 'yes_complete',
    name: '<strong>Sim</strong>, integralmente protegidos por criptografia / pseudonimização / etc.',
    status:
      'Sim, integralmente protegidos por criptografia / pseudonimização / etc.',
  },
  {
    id: 'yes_partial',
    name: '<strong>Sim</strong>, parcialmente protegidos por criptografia / pseudonimização / etc',
    status:
      'Sim, parcialmente protegidos por criptografia / pseudonimização / etc',
  },
  {
    id: 'no',
    name: '<strong>Não</strong>',
    status: 'Não',
  },
]

const ANSWERS = {
  YES: 'sim',
  NO: 'nao',
  DONT_KNOW: 'nao sei',
}

const SIMPLIFIED_FORM = 'Simplificado'
const COMPLETE_ANPD = 'Completo ANPD'

const CONFIDENTIAL_VIOLATION_TYPE = INCIDENT_VIOLATION_TYPES[0].value

const INCIDENT_MAIN_DRIVER_STEP_1 = 'incident-main-driver-step-1'
const INCIDENT_MAIN_DRIVER_STEP_2 = 'incident-main-driver-step-2'
const INCIDENT_MAIN_DRIVER_STEP_3 = 'incident-main-driver-step-3'
const INCIDENT_MAIN_DRIVER_STEP_4 = 'incident-main-driver-step-4'

const NEW_INCIDENT_DRIVER_STEP = 'new-incident-driver-step'

const EDIT_INCIDENT_DRIVER_STEP_0 = 'edit-incident-driver-step-00'
const EDIT_INCIDENT_DRIVER_STEP_1 = 'edit-incident-driver-step-01'
const EDIT_INCIDENT_DRIVER_STEP_2 = 'edit-incident-driver-step-02'
const EDIT_INCIDENT_DRIVER_STEP_3 = 'edit-incident-driver-step-03'
const EDIT_INCIDENT_DRIVER_STEP_4 = 'edit-incident-driver-step-04'
const EDIT_INCIDENT_DRIVER_STEP_5 = 'edit-incident-driver-step-05'
const EDIT_INCIDENT_DRIVER_STEP_6 = 'edit-incident-driver-step-06'
const EDIT_INCIDENT_DRIVER_STEP_7 = 'edit-incident-driver-step-07'
const EDIT_INCIDENT_DRIVER_STEP_8 = 'edit-incident-driver-step-08'
const EDIT_INCIDENT_DRIVER_STEP_9 = 'edit-incident-driver-step-09'
const EDIT_INCIDENT_DRIVER_STEP_10 = 'edit-incident-driver-step-10'
const EDIT_INCIDENT_DRIVER_STEP_11 = 'edit-incident-driver-step-11'
const EDIT_INCIDENT_DRIVER_STEP_12 = 'edit-incident-driver-step-12'
const EDIT_INCIDENT_DRIVER_STEP_13 = 'edit-incident-driver-step-13'

const INCIDENT_MAIN_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + INCIDENT_MAIN_DRIVER_STEP_1,
    popover: {
      description:
        'Bem-vindo a tela de <strong>INCIDENTES</strong>! O reconhecimento de um incidente ocorre quando um evento compromete a confidencialidade, integridade ou disponibilidade de dados pessoais, sendo avaliado caso a caso. Recomendamos que os incidentes sempre sejam registrados na plataforma, para a melhor gestão de tais acontecimentos, e para que haja a avaliação jurídica de notificar, ou não, à ANPD.',
    },
  },
  {
    element: '#' + INCIDENT_MAIN_DRIVER_STEP_2,
    popover: {
      description:
        'Aconteceu um incidente com dados pessoais? Cadastre aqui o incidente para controle.',
    },
  },
]

const INCIDENT_MAIN_DRIVER_STEPS_OBJECT_3 = {
  element: '#' + INCIDENT_MAIN_DRIVER_STEP_3,
  popover: {
    title: SIMPLIFIED_FORM,
    description:
      'Formulário Simplificado: Ao cadastrar um incidente ele vem como simplificado, edite, exporte relatório. Responda ao formulário completo <strong>somente quando o DPO responsável achar necessário.</strong>',
  },
}

const INCIDENT_MAIN_DRIVER_STEPS_OBJECT_4 = {
  element: '#' + INCIDENT_MAIN_DRIVER_STEP_4,
  popover: {
    title: COMPLETE_ANPD,
    description:
      'Formulário Completo ANPD: Edite o formulário de acordo com as medidas adotadas e exporte o relatório.',
  },
}

const NEW_INCIDENT_DRIVER_STEPS_OBJECT = {
  element: '#' + NEW_INCIDENT_DRIVER_STEP,
  popover: {
    description:
      'Ao cadastrar um novo incidente, certifique-se de preencher os campos obrigatórios. Os demais detalhes podem ser inseridos posteriormente, acessando a opção de edição.',
  },
}

const EDIT_INCIDENT_DRIVER_STEPS_OBJECT_1 = [
  {
    element: '#' + EDIT_INCIDENT_DRIVER_STEP_1,
    popover: {
      title: 'Incidente',
      description:
        'Realize a edição do incidente, descreva fatos, medidas adotadas, impactos e dados sensíveis violados.',
    },
  },
  {
    element: '#' + EDIT_INCIDENT_DRIVER_STEP_2,
    popover: {
      title: 'Tratamento',
      description:
        'Faça o <strong>tratamento do incidente</strong>, defina um prazo, identifique a causa, adicione riscos específicos, elabore um plano de ação e selecione documentos.',
    },
  },
]

const EDIT_INCIDENT_DRIVER_STEPS_OBJECT_2 = [
  {
    element: '#' + EDIT_INCIDENT_DRIVER_STEP_3,
    popover: {
      title: 'Prazo e Causa',
      description:
        'Defina um prazo para o tratamento do incidente e a sua causa.',
    },
  },
  {
    element: '#' + EDIT_INCIDENT_DRIVER_STEP_4,
    popover: {
      title: 'Riscos específicos',
      description:
        'Identifique e adicione os riscos existentes na sua empresa.',
    },
  },
  {
    element: '#' + EDIT_INCIDENT_DRIVER_STEP_5,
    popover: {
      title: 'Plano de ação',
      description:
        'Inclua um plano de ação, especificando prazo, responsável, ações corretivas e evidências de implementação.',
    },
  },
  {
    element: '#' + EDIT_INCIDENT_DRIVER_STEP_6,
    popover: {
      title: 'Documentos',
      description: 'Selecione documentos para evidenciar o incidente.',
    },
  },
  {
    element: '#' + EDIT_INCIDENT_DRIVER_STEP_7,
    popover: {
      description:
        'Caso não tenha concluído o tratamento, clique em <strong>Salvar</strong>. Se realizou todas as modificações necessárias, clique em <strong>Finalizar</strong>. O botão <strong>Finalizar</strong> estará disponível após a definição do prazo, assim que salvar pela primeira vez.',
    },
  },
]

const EDIT_COMPLETE_INCIDENT_DRIVER_STEPS_OBJECT_1 = {
  element: '#' + EDIT_INCIDENT_DRIVER_STEP_0,
  popover: {
    description:
      'Caso seu <strong>DPO Responsavél orientar</strong> o preenchimento do formulário completo, utilize essa funcionalidade.',
  },
}

const EDIT_COMPLETE_INCIDENT_DRIVER_STEPS_OBJECT_2 = [
  {
    element: '#' + EDIT_INCIDENT_DRIVER_STEP_8,
    popover: {
      title: GENERAL_INFORMATION,
      description: 'Descreva com mais detalhes o que ocorreu no incidente.',
    },
  },
  {
    element: '#' + EDIT_INCIDENT_DRIVER_STEP_9,
    popover: {
      title: SCIENCE_OCCURRENCE,
      description:
        'Avalie o risco, identifique como ocorreu a ciência do incidente, informe se foi comunicado por um operador ao controlador e identifique-o.',
    },
  },
  {
    element: '#' + EDIT_INCIDENT_DRIVER_STEP_10,
    popover: {
      title: TIMPERTIVITY_COMMUNICATION,
      description:
        'Informe os dados sobre as datas referentes ao incidente, a comunicação ou não da ANPD e a comunicação dos titulares afetados.',
    },
  },
  {
    element: '#' + EDIT_INCIDENT_DRIVER_STEP_11,
    popover: {
      title: DESCRIPTION_IMPACT,
      description:
        'Especifique qual o tipo de incidente ocorreu, como, porque e medidas adotadas para correção, identifique os impactos sobre os dados pessoais e dados violados.',
    },
  },
  {
    element: '#' + EDIT_INCIDENT_DRIVER_STEP_12,
    popover: {
      title: RISKS_CONSEQUENCES,
      description:
        'Indique se foi elaborado um Relatório de Impacto. Identifique os titulares e descreva as consequências e impactos do incidente. Descreva as medidas adotadas para reduzir o risco causado pelo incidente aos titulares.',
    },
  },
  {
    element: '#' + EDIT_INCIDENT_DRIVER_STEP_13,
    popover: {
      title: SECURITY_MEASURES,
      description:
        'Verifique se a proteção adotada é suficiente para a não identificação dos titulares. Descreva os meios utilizados para protegê-los, informe sobre as medidas de segurança adotadas e verifique se as atividades de tratamento de dados afetadas estão sujeitas a regulamentações de segurança setoriais.',
    },
  },
]

const INCIDENT_DRIVER_ID = {
  [SIMPLIFIED_FORM]: INCIDENT_MAIN_DRIVER_STEP_3,
  [COMPLETE_ANPD]: INCIDENT_MAIN_DRIVER_STEP_4,
  [GENERAL_INFORMATION]: EDIT_INCIDENT_DRIVER_STEP_8,
  [SCIENCE_OCCURRENCE]: EDIT_INCIDENT_DRIVER_STEP_9,
  [TIMPERTIVITY_COMMUNICATION]: EDIT_INCIDENT_DRIVER_STEP_10,
  [DESCRIPTION_IMPACT]: EDIT_INCIDENT_DRIVER_STEP_11,
  [RISKS_CONSEQUENCES]: EDIT_INCIDENT_DRIVER_STEP_12,
  [SECURITY_MEASURES]: EDIT_INCIDENT_DRIVER_STEP_13,
}

const INCIDENT_MAIN_DRIVER_SELECTED_STEPS = {
  [SIMPLIFIED_FORM]: INCIDENT_MAIN_DRIVER_STEPS_OBJECT_3,
  [COMPLETE_ANPD]: INCIDENT_MAIN_DRIVER_STEPS_OBJECT_4,
}

export default {
  ANSWERS,
  AVAILABLE_RISK_OPTIONS,
  COMMUNICATION_HOLDER_OPTIONS,
  COMMUNICATION_REQUIRED,
  COMPLETE_ANPD,
  CONFIDENTIAL_VIOLATION_TYPE,
  DESCRIPTION_IMPACT_STEP,
  EDIT_COMPLETE_INCIDENT_DRIVER_STEPS_OBJECT_1,
  EDIT_COMPLETE_INCIDENT_DRIVER_STEPS_OBJECT_2,
  EDIT_INCIDENT_DRIVER_STEP_0,
  EDIT_INCIDENT_DRIVER_STEP_1,
  EDIT_INCIDENT_DRIVER_STEP_10,
  EDIT_INCIDENT_DRIVER_STEP_11,
  EDIT_INCIDENT_DRIVER_STEP_12,
  EDIT_INCIDENT_DRIVER_STEP_13,
  EDIT_INCIDENT_DRIVER_STEP_2,
  EDIT_INCIDENT_DRIVER_STEP_3,
  EDIT_INCIDENT_DRIVER_STEP_4,
  EDIT_INCIDENT_DRIVER_STEP_5,
  EDIT_INCIDENT_DRIVER_STEP_6,
  EDIT_INCIDENT_DRIVER_STEP_7,
  EDIT_INCIDENT_DRIVER_STEP_8,
  EDIT_INCIDENT_DRIVER_STEP_9,
  EDIT_INCIDENT_DRIVER_STEPS_OBJECT_1,
  EDIT_INCIDENT_DRIVER_STEPS_OBJECT_2,
  GENERAL_INFORMATION_STEP,
  IMPACT_HOLDER,
  INCIDENT_DRIVER_ID,
  INCIDENT_INFORMATION_STEPS,
  INCIDENT_MAIN_DRIVER_SELECTED_STEPS,
  INCIDENT_MAIN_DRIVER_STEP_1,
  INCIDENT_MAIN_DRIVER_STEP_2,
  INCIDENT_MAIN_DRIVER_STEP_3,
  INCIDENT_MAIN_DRIVER_STEP_4,
  INCIDENT_MAIN_DRIVER_STEPS_OBJECT,
  INCIDENT_VIOLATION_TYPES,
  NEW_INCIDENT_DRIVER_STEP,
  NEW_INCIDENT_DRIVER_STEPS_OBJECT,
  RISKS_CONSEQUENCES_STEP,
  SCIENCE_OCCURRENCE_STEP,
  SECURITY_MEASURES_STEP,
  SECURITY_OPTIONS,
  SIMPLIFIED_FORM,
  TIMPERTIVITY_COMMUNICATION_STEP,
  TYPE_COMMUNICATION_OPTIONS,
}
