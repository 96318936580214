import React, { useState } from 'react'
import { Box, Grid, Paper } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'

import { Container, Page } from 'components'
import { Footer, Head, QuestionnaireCard } from './components'
import {
  QuestionnaireDetail,
  SkeletonPage,
} from './components/partner/showQuestionnaires'

import useFetch from 'hooks/useFetch'

import * as service from 'service'

const ThirdPartyManagementPartnerShowQuestionnaires = ({ match }) => {
  const location = useLocation()
  const search = qs.parse(location.search)

  const { supplierInviteId } = match.params

  const [selectedQuestionnaireId, setSelectedQuestionnaireId] = useState(
    parseInt(search?.questionnaireId),
  )

  const {
    response: supplierInviteResponse,
    isLoading: supplierInviteIsLoading,
  } = useFetch(service.dponet.supplierInvite.get, {
    supplierInviteId: supplierInviteId,
    allRelations: true,
  })

  const supplierInvite = supplierInviteResponse?.data?.supplierInvite || {}

  const { response, isLoading } = useFetch(
    service.dponet.suppliers.supplierQuestionnaires,
    {
      supplierId: supplierInvite?.companySupplierDetail?.companySupplier?.id,
    },
    [supplierInvite],
    !!supplierInvite.id,
  )

  const questionnaires = response?.data?.questionnaires || []
  const selectedQuestionnaire =
    questionnaires.find(
      (questionnaire) => questionnaire?.id === selectedQuestionnaireId,
    ) || {}

  const handleSelectQuestionnaire = (questionnaireId) => {
    if (selectedQuestionnaireId === questionnaireId) return

    setSelectedQuestionnaireId(questionnaireId)
  }

  return (
    <Page title="Gestão de Terceiros - Visualização dos Questionários">
      <Container maxWidth={false} component={Box} mt={3}>
        <Paper component={Box} p={3}>
          {supplierInviteIsLoading || isLoading ? (
            <SkeletonPage />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Head
                  title={supplierInvite?.supplier?.name}
                  subtitle={supplierInvite?.supplier?.document}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {questionnaires.map((questionnaire) => (
                    <Grid item xs={12} md={4} key={questionnaire?.id}>
                      <QuestionnaireCard
                        questionnaire={questionnaire}
                        selected={selectedQuestionnaireId === questionnaire?.id}
                        handleClick={() =>
                          handleSelectQuestionnaire(questionnaire?.id)
                        }
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <QuestionnaireDetail questionnaire={selectedQuestionnaire} />
              </Grid>
            </Grid>
          )}
        </Paper>

        <Footer />
      </Container>
    </Page>
  )
}

export default ThirdPartyManagementPartnerShowQuestionnaires
