import constants from 'constants/index'

const calculateRisk = (impactId = 0, probabilityId = 0) => {
  const value = impactId + probabilityId

  if (impactId === 0 || probabilityId === 0) {
    return constants.fragilities.FRAGILITIES.undefined
  }

  if (value < 4) {
    return constants.fragilities.FRAGILITIES.low
  }

  if (value < 6) {
    return constants.fragilities.FRAGILITIES.medium
  }

  if (value === 6) {
    return constants.fragilities.FRAGILITIES.high
  }

  return constants.fragilities.FRAGILITIES.severe
}

export default calculateRisk
