import React from 'react'
import { Grid, Paper, TablePagination } from '@material-ui/core'
import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { Actions, ChipStatus } from './components'

import usePagination from 'hooks/usePagination'
import useFetch from 'hooks/useFetch'

import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'

const SuggestionTable = ({ filter }) => {
  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.suggestedSuppliers.get,
    {
      perPage,
      page,
      ...filter.filters,
    },
    [filter.filters, page, perPage],
  )

  const supplierSuggestions = response?.data?.supplierSuggestions || []

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Paper>
          <PerfectScrollbar>
            <Table
              emptyMessage="Nenhuma sugestão encontrada"
              isLoading={isLoading}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Identificador</TableCell>
                  <TableCell>Razão Social</TableCell>
                  <TableCell>Documento</TableCell>
                  <TableCell align="center">Status do vínculo</TableCell>
                  <TableCell
                    id={
                      constants.tours.thirdPartyManagements.STEPS.SUGGESTIONS[2]
                    }
                    align="right"
                  >
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {supplierSuggestions.map((supplierSuggestion) => (
                  <TableRow key={supplierSuggestion.id}>
                    <TableCell>{supplierSuggestion.id}</TableCell>
                    <TableCell>{supplierSuggestion.name}</TableCell>
                    <TableCell>
                      {helpers.formatters.documentFormatter(
                        supplierSuggestion?.document,
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <ChipStatus status={supplierSuggestion.status} />
                    </TableCell>
                    <TableCell align="right">
                      <Actions
                        supplierSuggestion={supplierSuggestion}
                        refresh={refresh}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </PerfectScrollbar>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default SuggestionTable
