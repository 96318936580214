import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  highlight: {
    backgroundColor: theme.palette.white,
    color: theme.palette.black,
    borderRadius: '2px',
  },
}))

export default styles
