import React, { useState } from 'react'
import { Button } from '@material-ui/core'

import {
  BaseConfirmationDialog,
  LoadingFeedback,
  MenuButton,
  Permitted,
} from 'components'
import { ApprovedDialog } from './components'

import useSnackbar from 'hooks/useSnackbar'
import usePermissions from 'hooks/usePermissions'

import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'
import permissions from 'constants/permissions'

const Actions = ({ supplierSuggestion, refresh = () => {} }) => {
  const [openApproveDialog, setOpenApproveDialog] = useState(false)
  const [openRefuseDialog, setOpenRefuseDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const snackbar = useSnackbar()
  const { permitted } = usePermissions()

  const suggestionSupplierConstants = constants.supplierSuggestion
  const hasCompanySuppliersUpdatePermission = permitted(
    permissions.COMPANY_SUPPLIERS.UPDATE,
  )

  const isApproved =
    supplierSuggestion.status === suggestionSupplierConstants.STATUSES.APPROVED
  const isDisapproved =
    supplierSuggestion.status ===
    suggestionSupplierConstants.STATUSES.DISAPPROVED
  const isSuggested =
    supplierSuggestion.status === suggestionSupplierConstants.STATUSES.SUGGESTED

  const handleOpenApproveDialog = () => setOpenApproveDialog(true)
  const handleOpenRefuseDialog = () => setOpenRefuseDialog(true)

  const handleRefuseSuggested = async () => {
    try {
      setLoading(true)
      await service.dponet.suggestedSuppliers.acceptedOrDeclined({
        supplierSuggestionId: supplierSuggestion?.id,
        supplier: {
          status: constants.supplierSuggestion.STATUSES.DISAPPROVED,
        },
      })

      snackbar.open({
        message: 'Sugestão reprovada com sucesso',
        variant: 'success',
      })
      setOpenRefuseDialog(false)
      refresh()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Ocorreu um erro ao reprovar sugestão',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <MenuButton>
      <LoadingFeedback open={loading} />

      {(isApproved || !hasCompanySuppliersUpdatePermission) && (
        <Button color="secondary" fullWidth disabled>
          Nenhuma ação disponível
        </Button>
      )}

      <Permitted tag={permissions.COMPANY_SUPPLIERS.UPDATE}>
        {(isDisapproved || isSuggested) && (
          <Button color="secondary" fullWidth onClick={handleOpenApproveDialog}>
            Aprovar
          </Button>
        )}

        {isSuggested && (
          <Button color="secondary" fullWidth onClick={handleOpenRefuseDialog}>
            Recusar
          </Button>
        )}
      </Permitted>

      {openApproveDialog && (
        <ApprovedDialog
          supplierSuggestion={supplierSuggestion}
          refresh={refresh}
          open={openApproveDialog}
          setOpen={setOpenApproveDialog}
        />
      )}

      {openRefuseDialog && (
        <BaseConfirmationDialog
          open={openRefuseDialog}
          setOpen={setOpenRefuseDialog}
          variant="warning"
          title="Reprovar sugestão de parceiro"
          text="Você tem certeza de que deseja aprovaressa organização?"
          actionButton={handleRefuseSuggested}
          actionButtonText="Salvar"
          loading={loading}
        />
      )}
    </MenuButton>
  )
}

export default Actions
