import { Globe as GlobeIcon, Lock as LockIcon } from 'react-feather'
import { colors } from '@material-ui/core'

import theme from 'theme'
import tours from './tours'
import permissions from './permissions'

const {
  MAIN_STEP_3,
  MAIN_STEP_4,
  MAIN_STEP_5,
  MANAGEMENT_ACCEPTED_STEP_2,
  MANAGEMENT_ACCEPTED_STEP_3,
  MANAGEMENT_ACCEPTED_STEP_4,
} = tours.documents

const ADVISOR_CARD_DOCUMENT = 'advisor-company-document'
const ADVISOR_CARD_CLOSED = 'closed'

const DOCUMENTS_CATEGORY_ID = 'documents'
const DOCUMENTS_TEMPLATES_CATEGORY_ID = 'model-documents'
const DEPRECIATED_DOCUMENTS_CATEGORY_ID = 'depreciated-documents'
const ACCEPTED_DOCUMENTS_CATEGORY_ID = 'accepted'

const DRAFT_STATUS_ID = '1'
const ACTIVE_STATUS_ID = '2'
const INACTIVE_STATUS_ID = '3'

const PRIVACY_POLICY_CATEGORY_ID = 'privacy_notice'
const COOKIE_POLICY_CATEGORY_ID = 'cookie_policy'
const TERMS_OF_USE_CATEGORY_ID = 'terms_of_use'
const OTHER_CATEGORY_ID = 'other'

const PUBLIC_SHARING_MODE_ID = 'public'
const RESTRICTED_SHARING_MODE_ID = 'restricted'

const CATEGORY_OPTIONS = [
  { id: PRIVACY_POLICY_CATEGORY_ID, name: 'Aviso de privacidade' },
  { id: COOKIE_POLICY_CATEGORY_ID, name: 'Política de cookies' },
  { id: TERMS_OF_USE_CATEGORY_ID, name: 'Termos de uso' },
  { id: OTHER_CATEGORY_ID, name: 'Outras' },
]

const TRANSLATION_CATEGORY = {
  [PRIVACY_POLICY_CATEGORY_ID]: 'Aviso de privacidade',
  [COOKIE_POLICY_CATEGORY_ID]: 'Política de cookies',
  [TERMS_OF_USE_CATEGORY_ID]: 'Termos de uso',
  [OTHER_CATEGORY_ID]: 'Outras',
}

const CATEGORIES_CARD_TABS = [
  {
    id: MAIN_STEP_3,
    value: DOCUMENTS_CATEGORY_ID,
    label: 'Meus documentos',
    permission: permissions.PRIVACY_POLICIES.LIST,
  },
  {
    id: MAIN_STEP_4,
    value: DOCUMENTS_TEMPLATES_CATEGORY_ID,
    label: 'Documentos modelo',
    permission: permissions.DOCUMENTS_TEMPLATE.LIST,
  },
  {
    id: MAIN_STEP_5,
    value: DEPRECIATED_DOCUMENTS_CATEGORY_ID,
    label: 'Documentos desatualizados',
    permission: permissions.DOCUMENT.LIST,
  },
]

const STATUS_OPTIONS = [
  { id: ACTIVE_STATUS_ID, name: 'Ativo' },
  { id: DRAFT_STATUS_ID, name: 'Rascunho' },
  { id: INACTIVE_STATUS_ID, name: 'Inativo' },
]

const CHIP_STATUS_COLOR = {
  [ACTIVE_STATUS_ID]: theme.palette.tertiary.main,
  [INACTIVE_STATUS_ID]: colors.blueGrey[400],
  [DRAFT_STATUS_ID]: theme.palette.info.main,
}

const CHIP_STATUS_LABEL = {
  [ACTIVE_STATUS_ID]: 'ativo',
  [INACTIVE_STATUS_ID]: 'inativo',
  [DRAFT_STATUS_ID]: 'rascunho',
}

const DOCUMENT_DOWNLOAD_CONTROL_ITEMS = [
  {
    name: 'documentCopy',
    label: 'Cópia do documento',
  },
  {
    name: 'scienceReport',
    label: 'Relatório de aceites',
  },
]

const PERMISSIONS_CONTROL_ITEMS = [
  {
    name: 'publicDocument',
    label:
      'Publicar este documento no seu canal de atendimento ao titular de dados',
  },
  {
    name: 'collectAccepted',
    label: 'Coletar aceite dos termos deste documento',
  },
  {
    name: 'allowAutomaticEditing',
    label: 'Permitir edição automática do sistema',
  },
]

const ACCEPTANCE_STEPS = [
  { id: MANAGEMENT_ACCEPTED_STEP_2, label: 'Campos' },
  { id: MANAGEMENT_ACCEPTED_STEP_3, label: 'Pré-visualização' },
  { id: MANAGEMENT_ACCEPTED_STEP_4, label: 'Compartilhamento' },
]

const ACCEPTANCE_SHARING_OPTIONS = [
  {
    title: 'Acesso com o link',
    subtitle: 'Qualquer pessoa com o link pode visualizar',
    icon: GlobeIcon,
    value: PUBLIC_SHARING_MODE_ID,
  },
  {
    title: 'Acesso restrito',
    subtitle: 'Somente pessoas autorizadas por e-mail',
    icon: LockIcon,
    value: RESTRICTED_SHARING_MODE_ID,
  },
]

const DOWNLOAD_SNACKBAR_MESSAGE = {
  both: 'Cópia do documento e relatório de ciência baixados com sucesso! Caso o documento não abra, ative os pop-ups.',
  document:
    'Cópia do documento baixada com sucesso! Caso o documento não abra, ative os pop-ups.',
  report:
    'Relatório de ciência baixado com sucesso! Caso o documento não abra, ative os pop-ups.',
}

const CONTENT_COMPARATOR_SELECT_ITEMS = [
  { label: 'Versão de origem', name: 'originVersion' },
  { label: 'Versão a comparar', name: 'comparativeVersion' },
]

const documents = {
  ACCEPTANCE_SHARING_OPTIONS,
  ACCEPTANCE_STEPS,
  ACCEPTED_DOCUMENTS_CATEGORY_ID,
  ACTIVE_STATUS_ID,
  ADVISOR_CARD_CLOSED,
  ADVISOR_CARD_DOCUMENT,
  CATEGORIES_CARD_TABS,
  CATEGORY_OPTIONS,
  CHIP_STATUS_COLOR,
  CHIP_STATUS_LABEL,
  DEPRECIATED_DOCUMENTS_CATEGORY_ID,
  DOCUMENT_DOWNLOAD_CONTROL_ITEMS,
  DOCUMENTS_CATEGORY_ID,
  DOCUMENTS_TEMPLATES_CATEGORY_ID,
  DRAFT_STATUS_ID,
  INACTIVE_STATUS_ID,
  OTHER_CATEGORY_ID,
  PERMISSIONS_CONTROL_ITEMS,
  PRIVACY_POLICY_CATEGORY_ID,
  PUBLIC_SHARING_MODE_ID,
  RESTRICTED_SHARING_MODE_ID,
  STATUS_OPTIONS,
  TRANSLATION_CATEGORY,
  DOWNLOAD_SNACKBAR_MESSAGE,
  CONTENT_COMPARATOR_SELECT_ITEMS,
}

export default documents
