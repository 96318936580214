import { Box, TextField } from '@material-ui/core'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import useStyles from './styles'
import { Autocomplete } from '@material-ui/lab'

const ComplaintSubCategories = ({
  index,
  indexCategory,
  subCategorySelectObj,
  name,
  disabled,
}) => {
  const classes = useStyles()

  const { control, errors } = useFormContext()

  const errorPathObject =
    errors?.subConfigurations?.[indexCategory]?.subCategories

  const errorMessaSubCategory =
    errorPathObject?.message || errorPathObject?.types?.message

  return (
    <Box key={index} className={classes.boxInputSubCategories}>
      <Controller
        control={control}
        name={name}
        onChange={([, data]) => {
          return data
        }}
        as={
          <Autocomplete
            fullWidth
            multiple
            disabled={disabled}
            options={subCategorySelectObj || []}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) =>
              option.id === value.id || value.id === 0
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Subcategoria"
                variant="outlined"
                error={
                  !!errors?.subConfigurations?.[indexCategory]?.subCategories
                }
                helperText={errorMessaSubCategory}
              />
            )}
          />
        }
        mode="onChange"
      />
    </Box>
  )
}

export default ComplaintSubCategories
