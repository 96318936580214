import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ privacyPolicyId = '', ...params }) => {
  return await dponetAPI.get(`/privacy_policies/${privacyPolicyId}`, { params })
}

const create = async ({ ...data }) => {
  return await dponetAPI.post('/privacy_policies', data)
}

const put = async ({ privacyPolicyId = '', ...data }) => {
  return await dponetAPI.put(`/privacy_policies/${privacyPolicyId}`, data)
}

const destroy = async ({ privacyPolicyId = '' }) => {
  return await dponetAPI.delete(`/privacy_policies/${privacyPolicyId}`)
}

const exportDocument = async ({ privacyPolicyId = '' }) => {
  return await dponetAPI.get(`/privacy_policies/${privacyPolicyId}/export`)
}

const listVersions = async ({ privacyPolicyId = '', ...params }) => {
  return await dponetAPI.get(`/privacy_policies/${privacyPolicyId}/versions`, {
    params,
  })
}

export default {
  get,
  create,
  put,
  destroy,
  exportDocument,
  listVersions,
}
