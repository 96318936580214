import React from 'react'
import qs from 'query-string'
import { reverse } from 'named-urls'
import { useHistory } from 'react-router-dom'
import { Box, Grid, Typography } from '@material-ui/core'

import { QuestionnaireCard } from 'views/ThirdPartyManagements/components'

import { routes } from 'Routes'

const QuestionnairesCompliance = ({
  supplierInvite = {},
  questionnaires = [],
}) => {
  const history = useHistory()

  const handleShowQuestionnaire = (questionnaire) => {
    history.push({
      pathname: reverse(
        routes.thirdPartyManagements.partners.showQuestionnaires,
        {
          supplierInviteId: supplierInvite?.id,
        },
      ),
      search: qs.stringify({
        questionnaireId: questionnaire?.id,
      }),
    })
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Conformidade por questionário
      </Typography>

      <Grid container spacing={2}>
        {questionnaires.map((questionnaire) => (
          <Grid item xs={12} md={4} key={questionnaire.id}>
            <QuestionnaireCard
              questionnaire={questionnaire}
              handleClick={() => handleShowQuestionnaire(questionnaire)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default QuestionnairesCompliance
