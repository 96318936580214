import React, { useState } from 'react'
import { Box, Button, Chip, Typography } from '@material-ui/core'
import { isNil } from 'lodash'
import PerfectScrollbar from 'react-perfect-scrollbar'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { ConfirmLinkWithTitular, ProvideHolderData } from './components'

import useStyles from './styles'

import constants from 'constants/index'
import helpers from 'helpers'

const TitularsSolicitationsTable = ({ tickets, refresh }) => {
  const classes = useStyles()

  const [openConfirmLinkWithTitular, setOpenConfirmLinkWithTitular] =
    useState(false)
  const [openProvideData, setOpenProvideData] = useState(false)
  const [chosenTicket, setChosenTicket] = useState({})

  const handleOpenConfirmLinkWithTitular = (ticket) => {
    setOpenConfirmLinkWithTitular(true)
    setChosenTicket(ticket)
  }

  const handleOpenProvideData = (ticket) => {
    setOpenProvideData(true)
    setChosenTicket(ticket)
  }

  const hasTitularData = (ticket) => {
    if (ticket?.configuration?.hasTitularData)
      return <Typography>Sim</Typography>
    if (
      !ticket?.configuration?.hasTitularData &&
      !isNil(ticket?.configuration?.hasTitularData)
    )
      return <Typography>Não</Typography>
    if (
      ticket?.status?.id !==
      constants.tickets.AWAITING_CONFIRMATION_IDENTITY_STATUS_ID
    )
      return <Typography>-</Typography>

    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        id={constants.tickets.TITULAR_SOLICITATIONS_MAIN_DRIVER_STEP_3}
        onClick={() => handleOpenConfirmLinkWithTitular(ticket)}
      >
        RESPONDER
      </Button>
    )
  }

  const hasDataProvided = (ticket) => {
    if (
      ticket?.status?.id ===
        constants.tickets.AWAITING_COMPANY_REPLY_STATUS_ID &&
      ticket?.configuration?.hasTitularData &&
      !ticket?.configuration?.dataWasProvided
    ) {
      return (
        <Button
          variant="contained"
          color="primary"
          size="small"
          id={constants.tickets.TITULAR_SOLICITATIONS_MAIN_DRIVER_STEP_2}
          onClick={() => handleOpenProvideData(ticket)}
        >
          RESPONDER
        </Button>
      )
    }

    if (ticket?.configuration?.dataWasProvided) {
      return <Typography>Sim</Typography>
    }

    return <Typography>-</Typography>
  }

  const classChipStatus = (status) => {
    if (status === 'RESPONDIDO') {
      return classes.answered
    }

    if (['FINALIZADO', 'CANCELADO'].includes(status)) {
      return classes.finalized
    }

    return classes.pending
  }

  return (
    <Box width="100%" mt={2}>
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table
          size="medium"
          noWrap
          emptyMessage="Nenhuma solicitação de titular encontrada"
        >
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography align="center" variant="h6">
                  ID
                </Typography>
              </TableCell>
              <TableCell>
                <Typography align="center" variant="h6">
                  Prazo
                </Typography>
              </TableCell>
              <TableCell>
                <Typography align="center" variant="h6">
                  Status
                </Typography>
              </TableCell>
              <TableCell>
                <Typography align="center" variant="h6">
                  Resposta automática
                </Typography>
              </TableCell>
              <TableCell>
                <Typography align="center" variant="h6">
                  Dados Fornecidos
                </Typography>
              </TableCell>
              <TableCell>
                <Typography align="center" variant="h6">
                  Possui dados do titular?
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tickets?.map((ticket) => {
              const status = helpers.tickets.formattedStatus(ticket?.status?.id)
              return (
                <TableRow key={ticket?.id}>
                  <TableCell>
                    <Typography align="center">{ticket?.ticketRef}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography align="center">
                      {ticket?.companyDeadlineDate
                        ? helpers.formatters.date(ticket?.companyDeadlineDate)
                        : '-'}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Chip className={classChipStatus(status)} label={status} />
                  </TableCell>
                  <TableCell>
                    <Typography align="center">
                      {ticket.configuration?.automaticAnswerEnabled
                        ? 'Sim'
                        : 'Não'}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {hasDataProvided(ticket)}
                  </TableCell>
                  <TableCell align="center">{hasTitularData(ticket)}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      <ConfirmLinkWithTitular
        open={openConfirmLinkWithTitular}
        setOpen={setOpenConfirmLinkWithTitular}
        ticket={chosenTicket}
        refresh={refresh}
      />
      <ProvideHolderData
        open={openProvideData}
        title="Forneça os dados do titular"
        setOpen={setOpenProvideData}
        ticket={chosenTicket}
        refresh={refresh}
      />
    </Box>
  )
}

export default TitularsSolicitationsTable
