import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'

import { Container, ContentHeader, Page } from 'components'
import { RegisterForm, RegisterTypeForm } from './components/partner/new'
import { SkeletonForm } from './components/partner/edit'

import useFetch from 'hooks/useFetch'

import constants from 'constants/index'
import * as service from 'service'
import useConvertToFile from 'hooks/useConvertToFile'

const ThirdPartyManagementPartnerEdit = ({ match }) => {
  const { supplierInviteId } = match.params
  const registerTypes = constants.thirdPartyManagements.REGISTER_TYPES

  const [registerType, setRegisterType] = useState(registerTypes.REGISTER)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.supplierInvite.get,
    {
      supplierInviteId: supplierInviteId,
    },
  )

  const supplierInvite = response?.data?.supplierInvite || {}

  const { file, loading } = useConvertToFile(
    supplierInvite?.companySupplierDetail?.attachment,
  )

  useEffect(() => {
    if (supplierInvite) {
      if (
        supplierInvite?.preAvaliation &&
        registerType !== registerTypes.PRE_AVALIATION
      ) {
        setRegisterType(registerTypes.PRE_AVALIATION)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierInvite])

  return (
    <Page title="Gestão de Terceiros - Atualizar Parceiro">
      <Container maxWidth={false}>
        <ContentHeader title="Atualizar Parceiro" />

        {isLoading || loading ? (
          <SkeletonForm />
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <RegisterTypeForm
                registerType={registerType}
                setRegisterType={setRegisterType}
                isEdit
              />
            </Grid>
            <Grid item xs={12}>
              <RegisterForm
                registerType={registerType}
                isEdit
                supplierInvite={supplierInvite}
                supplierFile={file}
                questionnaires={
                  supplierInvite?.questionnairesParams?.questionnaireIds
                }
                reload={refresh}
              />
            </Grid>
          </Grid>
        )}
      </Container>
    </Page>
  )
}

export default ThirdPartyManagementPartnerEdit
