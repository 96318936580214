import React from 'react'
import { Tab, Tabs } from '@material-ui/core'

import constants from 'constants/index'

const StatusesTab = ({ selectedTab, setSelectedTab }) => {
  const supplierInviteConstants = constants.supplierInvite

  const tabs = [
    {
      value: 'all',
      label: 'Todos',
    },
    {
      value: supplierInviteConstants.STATUSES.PENDING,
      label: supplierInviteConstants.STATUSES_LABEL.pending,
    },
    {
      value: supplierInviteConstants.STATUSES.REFUSED,
      label: supplierInviteConstants.STATUSES_LABEL.refused,
    },
  ]

  const handleSelectTab = (tab) => {
    if (tab === selectedTab) return

    setSelectedTab(tab)
  }

  return (
    <Tabs
      value={selectedTab}
      scrollButtons="auto"
      variant="scrollable"
      textColor="primary"
      indicatorColor="primary"
    >
      {tabs.map((tab) => (
        <Tab
          key={tab.value}
          label={tab.label}
          value={tab.value}
          onClick={() => handleSelectTab(tab.value)}
        />
      ))}
    </Tabs>
  )
}

export default StatusesTab
