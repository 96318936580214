import helpers from 'helpers'

const changeLogs = (logs = []) => {
  const logsGeneral = logs.map((log) => {
    const logChange = log.changes.map((element) => {
      if (
        element[0] === 'status' &&
        !['Incident', 'Complaint'].includes(log?.auditableType)
      ) {
        const newElement = element[1].map((statusId) => {
          statusId = helpers.nonComplianceReports.status(statusId)
          return statusId
        })
        return [element[0], newElement]
      }

      if (element[0] === 'deadline_date') {
        const formattedDeadline = element[1].map((deadlineDate) => {
          if (deadlineDate !== null) {
            deadlineDate = helpers.formatters.date(deadlineDate)
            return deadlineDate
          } else {
            return null
          }
        })
        return [element[0], formattedDeadline]
      }

      if (element[0] === 'incident_datetime') {
        const formattedDeadline = element[1].map((deadlineDate) => {
          if (deadlineDate !== null) {
            deadlineDate = `${helpers.formatters.date(
              deadlineDate,
            )} ${helpers.formatters.hours(deadlineDate)}`
            return deadlineDate
          } else {
            return null
          }
        })
        return [element[0], formattedDeadline]
      }
      return element
    })

    return {
      ...log,
      changes: logChange,
    }
  })

  return logsGeneral
}

export default changeLogs
