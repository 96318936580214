import React, { useRef } from 'react'
import Chart from 'react-apexcharts'
import { Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import theme from 'theme'

const RadialChart = ({
  value = 0,
  height = 370,
  hollowSize = '65%',
  startAngle = 0,
  endAngle = 360,
  fontSize = '44px',
  fontFamily = 'Raleway, Roboto, Arial, sans-serif',
  primaryColor = theme.palette.primary.main,
  showValue = true,
  valueFormat = (val) => `${val}%`,
  trackBackground = 'transparent',
  offsetY,
}) => {
  const ref = useRef(null)

  const options = {
    chart: {
      type: 'radialBar',
      toolbar: { show: false },
    },
    colors: [primaryColor],
    plotOptions: {
      radialBar: {
        startAngle,
        endAngle,
        hollow: {
          size: hollowSize,
        },
        track: {
          background: trackBackground,
          strokeWidth: '100%',
        },
        dataLabels: {
          name: { show: false },
          value: {
            show: showValue,
            fontSize,
            fontWeight: 'bold',
            fontFamily,
            color: primaryColor,
            formatter: valueFormat,
            offsetY: offsetY,
          },
        },
      },
    },
    stroke: {},
    fill: {
      type: 'solid',
      opacity: 1,
    },
  }

  return (
    <Box width="100%" ref={ref}>
      <Chart
        ref={ref}
        options={options}
        series={[value]}
        type="radialBar"
        height={height}
      />
    </Box>
  )
}

RadialChart.propTypes = {
  value: PropTypes.number,
  height: PropTypes.number,
  hollowSize: PropTypes.string,
  startAngle: PropTypes.number,
  endAngle: PropTypes.number,
  fontSize: PropTypes.string,
  fontFamily: PropTypes.string,
  primaryColor: PropTypes.string,
  showValue: PropTypes.bool,
  valueFormat: PropTypes.func,
  trackBackground: PropTypes.string,
  legendText: PropTypes.string,
}

export default RadialChart
