import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { ChevronDown, ChevronUp } from 'react-feather'
import PropTypes from 'prop-types'
import { Box, Button, IconButton, Switch, Typography } from '@material-ui/core'

import { MenuButton } from 'components'

import useStyles from '../../styles'

const SelectedItem = ({
  item,
  order,
  handleSwitchChange,
  handleUp,
  handleDown,
  handleOpenDialog,
  selectedItems,
  handleFieldDestroy,
  disabled,
}) => {
  const classes = useStyles()
  const [selected, setSelected] = useState(!!item?.required)

  const { control, watch } = useFormContext()

  const handleSelect = (e) => {
    setSelected(e.target.checked)
    return handleSwitchChange(e, item, order)
  }

  return (
    <Box
      margin={1}
      padding={1}
      className={classes.attributeBox}
      key={item?.name}
    >
      <Box>
        <Typography variant="subtitle2">{item?.name}</Typography>
        <Typography variant="caption">{item?.description}</Typography>
        <Box>
          <Controller
            control={control}
            name={item?.name}
            defaultValue={{
              subtitle: item?.description,
              required: !!item?.required,
              order,
            }}
            as={
              <Box display="flex" alignItems="center">
                <Switch
                  key={item.name}
                  color="primary"
                  checked={selected ?? watch(item?.name)?.required}
                  disabled={disabled}
                />
                <Typography>Obrigatório</Typography>
              </Box>
            }
            onChange={(event) => handleSelect(event[0])}
            mode="onChange"
          />
        </Box>
      </Box>
      {!disabled && (
        <Box display="flex" alignItems="center" pr={1.5}>
          <Box
            width="50%"
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
          >
            <IconButton
              disabled={order === 0 || disabled}
              size="small"
              onClick={() => handleUp(order, selectedItems)}
            >
              <ChevronUp size={16} />
            </IconButton>
            <IconButton
              disabled={order === selectedItems.length - 1 || disabled}
              size="small"
              onClick={() => handleDown(order, selectedItems)}
            >
              <ChevronDown size={16} />
            </IconButton>
          </Box>
          <Box width="50%">
            <MenuButton>
              <Button
                color="secondary"
                fullWidth
                size="small"
                onClick={() => handleOpenDialog(item)}
                disabled={disabled}
              >
                Editar
              </Button>
              <Button
                color="secondary"
                fullWidth
                size="small"
                onClick={() => handleFieldDestroy(item)}
                disabled={disabled}
              >
                Excluir
              </Button>
            </MenuButton>
          </Box>
        </Box>
      )}
    </Box>
  )
}

SelectedItem.propTypes = {
  item: PropTypes.object,
  order: PropTypes.number,
  handleSwitchChange: PropTypes.func,
  handleUp: PropTypes.func,
  handleDown: PropTypes.func,
  handleOpenDialog: PropTypes.func,
  selectedItems: PropTypes.arrayOf(PropTypes.object),
  handleFieldDestroy: PropTypes.func,
  disabled: PropTypes.bool,
}

export default SelectedItem
