import permissionsConstants from 'constants/permissions'

const ALL_PRIVACY_URL =
  'https://comunidadeallprivacy.circle.so/join?invitation_token=3081f061198ecc26fbb0bdedd9959489f83133df-b6ed1f06-5414-45bf-ba61-8e9ca8f12cd1'

const GRAPH_IDS = {
  general: {
    welcome: 'welcome-card',
    incidentCard: 'incident-card',
  },
  process: {
    processCards: 'process-cards',
    riskTable: 'process-risks-table',
    riskByDepartment: 'process-risk-by-department',
    departmentByRisk: 'process-department-by-risk',
    statuses: 'status-by-process',
  },
  nonComplianceReports: {
    statuses: 'rmc-status-table',
    finalizedByDepartment: 'rmc-finalized-by-department',
    statusByDepartment: 'rmc-status-by-department',
    overdueByDeparmtnet: 'rmc-overdue-by-department',
    rmcByQuestionnaire: 'rmc-by-questionnaire',
  },
  suppliers: {
    cardStats: 'supplier-card-stats',
    mySupplierCompliance: 'supplier-my-compliancers',
    highCompliancers: 'supplier-my-higher-compliancers',
    lowCompliancers: 'supplier-my-lower-compliancers',
  },
  gap: {
    complianceCard: 'gap-compliance-card',
    questionCard: 'gap-question-card',
    progress: 'gap-progress-by-questionnaire',
    pendingRmc: 'gap-pending-rmcs',
    rmcStatus: 'gap-rmc-status',
    compliance: 'gap-compliance',
    pieChart: 'gap-question-pie-chart',
  },
  pdf: {
    departmentsRmcFinalizeds: 'rmc-finalized-pie-chart',
    departmentsRmcsOverdued: 'rmc-overdued-pie-chart',
    statusRmcsByDepartment: 'rmc-status-bar-chart',
  },
  securityMeasures: {
    statuses: 'security-measures-statuses',
    statusesByCategories: 'security-measures-by-categories',
  },
  fragilities: {
    bySeverities: 'fragilities-by-severities',
    withSecurityMeasures: 'fragilities-with-security-measure',
    departmentsWithHighestFragilities:
      'fragilities-departments-with-highest-fragilities',
    departmentsWithLowestFragilities:
      'fragilities-departments-with-lowest-fragilities',
    departmentsWithHighestSeverities:
      'fragilities-departments-with-highest-severities',
    departmentsWithLowestSeverities:
      'fragilities-departments-with-lowest-severities',
  },
}

const DASHBOARD_MAIN_TAB = 'dashboard-main-tab'
const DASHBOARD_PROCESS_TAB = 'dashboard-process-tab'
const DASHBOARD_DIAGNOSTIC_TAB = 'dashboard-diagnostic-tab'
const DASHBOARD_SUPPLIER_TAB = 'dashboard-supplier-tab'

const HOME_DRIVER_STEP_1 = 'home-step-1'
const HOME_DRIVER_STEP_2 = 'home-step-2'
const HOME_DRIVER_STEP_3 = 'home-step-3'
const HOME_DRIVER_STEP_4 = 'home-step-4'
const HOME_DRIVER_STEP_5 = 'home-step-5'
const HOME_DRIVER_STEP_6 = 'home-step-6'
const HOME_DRIVER_STEP_7 = 'home-step-7'
const HOME_DRIVER_STEP_8 = 'home-step-8'
const HOME_DRIVER_STEP_9 = 'home-step-9'

const DRIVER_STEPS_OBJECT_WITH_PERMISSIONS = {
  ['#' + HOME_DRIVER_STEP_5]:
    permissionsConstants.HOME.SHOW_COMMUNICATION_TRAIL,
  ['#' + HOME_DRIVER_STEP_7]: permissionsConstants.TASKS.LIST,
  ['#' + HOME_DRIVER_STEP_8]: permissionsConstants.TASKS.CREATE,
  ['#' + HOME_DRIVER_STEP_9]: permissionsConstants.TASKS.LIST,
}

const DRIVER_STEPS_OBJECT = [
  {
    element: '#' + HOME_DRIVER_STEP_1,
    popover: {
      title: 'Bem-vindo a plataforma',
      description:
        'Vamos guiá-lo pelo sistema para que você consiga dar os primeiro passos.',
    },
  },
  {
    element: '#' + HOME_DRIVER_STEP_2,
    popover: {
      description:
        'Explore seu perfil! Ajuste suas configurações; personalize as informações do perfil, incluindo dados pessoais e dados da empresa.',
    },
  },
  {
    element: '#' + HOME_DRIVER_STEP_3,
    popover: {
      title: 'Mantenha-se atualizado',
      description: 'Visite as novas funcionalidades e melhorias na plataforma.',
    },
  },
  {
    element: '#' + HOME_DRIVER_STEP_4,
    popover: {
      description:
        'Realize o acesso inicial para descobrir benefícios exclusivos, incluindo recursos educacionais sobre a LGPD. Aprofunde-se na legislação, aprenda melhores práticas através de vídeos. Destaque-se ao obter o certificado e selo, demonstrando comprometimento com a proteção de dados.',
    },
  },
  {
    element: '#' + HOME_DRIVER_STEP_5,
    popover: {
      description:
        'Configure seu canal de comunicação de forma personalizada! Adicione a identidade visual ao anexar sua logomarca e informe a URL do seu site. Configure o banner de cookies. Edite o "Aviso de Privacidade", um dos documentos exigidos pela lei, clicando em "editar".',
    },
  },
  {
    element: '#' + HOME_DRIVER_STEP_6,
    popover: {
      title: 'Conheça a LGPD',
      description: 'Clique, aprenda e obtenha seu certificado.',
    },
  },
  {
    element: '#' + HOME_DRIVER_STEP_7,
    popover: {
      description:
        'As atividades são guias essenciais para sua jornada na plataforma, proporcionando um passo a passo para uma implementação mais organizada da LGPD. Visualize suas tarefas de acordo com os diferentes status, e os modifique manualmente.',
    },
  },
  {
    element: '#' + HOME_DRIVER_STEP_8,
    popover: {
      description:
        'Crie tarefas personalizadas conforme a necessidade da sua empresa. Atribua um responsável, defina datas de início e fim e descreva as instruções detalhadas, garantindo uma gestão eficiente das atividades.',
    },
  },
  {
    element: '#' + HOME_DRIVER_STEP_9,
    popover: {
      description:
        'Acesse as opções: ao clicar nos três pontos, escolha entre as ações disponíveis para alterar uma atividade, explore os detalhes da tarefa no ícone de visualização.',
    },
  },
]

const DASHBOARD_DRIVER_STEP_1 = 'dashboard-driver-step-1'
const DASHBOARD_DRIVER_STEP_2 = 'dashboard-driver-step-2'

const DASHBOARD_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + DASHBOARD_DRIVER_STEP_1,
    popover: {
      description:
        'O Dashboard é uma ferramenta de visualização de dados que apresenta informações de forma consolidada e acessível, facilitando o monitoramento do desempenho, a identificação de tendências, a análise de métricas e a tomada de decisões embasadas nos dados apresentados.',
    },
  },
  {
    element: '#' + DASHBOARD_DRIVER_STEP_2,
    popover: {
      description:
        'Utilize os filtros para acessar os dados de <strong>todos, processos, diagnóstico e parceiros.</strong>',
    },
  },
]

export default {
  GRAPH_IDS,
  DRIVER_STEPS_OBJECT,
  DRIVER_STEPS_OBJECT_WITH_PERMISSIONS,
  DASHBOARD_MAIN_TAB,
  DASHBOARD_PROCESS_TAB,
  DASHBOARD_DIAGNOSTIC_TAB,
  DASHBOARD_SUPPLIER_TAB,
  HOME_DRIVER_STEP_1,
  HOME_DRIVER_STEP_2,
  HOME_DRIVER_STEP_3,
  HOME_DRIVER_STEP_4,
  HOME_DRIVER_STEP_5,
  HOME_DRIVER_STEP_6,
  HOME_DRIVER_STEP_7,
  HOME_DRIVER_STEP_8,
  HOME_DRIVER_STEP_9,
  DASHBOARD_DRIVER_STEP_1,
  DASHBOARD_DRIVER_STEP_2,
  DASHBOARD_DRIVER_STEPS_OBJECT,
  ALL_PRIVACY_URL,
}
