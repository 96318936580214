import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    whiteSpace: 'nowrap',
    boxShadow: '2px 4px 18.5px 0px #00000040',
  },
}))

export default useStyles
