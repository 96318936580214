import React, { useState } from 'react'
import { Box, Button, Grid, Paper } from '@material-ui/core'
import { HelpCircle as HelpCircleIcon } from 'react-feather'

import { Container, ContentHeader, DriveTour, Page } from 'components'
import { AdvisorCard } from './components'
import { ActionButtons, Stats } from './components/intermediator'

import useAuth from 'hooks/useAuth'
import useClipboard from 'hooks/useClipboard'

import constants from 'constants/index'
import enterpriseStreamlineIcon from 'images/enterprise_streamline_icon.svg'
import useStyles from './components/intermediator/styles'
import { getApplicationUrl } from 'service/env'

const ThirdPartyManagementIntemediator = () => {
  const [openTour, setOpenTour] = useState(false)

  const classes = useStyles()
  const { company } = useAuth()
  const { copy } = useClipboard()

  const handleCopyLink = () => {
    copy(`${getApplicationUrl()}/supplier-invite/${company.token}`)
  }

  const handleOpenTour = () => setOpenTour(true)

  return (
    <Page title="Gestão de Terceiros">
      <Container maxWidth={false}>
        <ContentHeader title="Gestão de Terceiros">
          <Button
            id={constants.tours.thirdPartyManagements.STEPS.INTERMEDIATOR[1]}
            variant="contained"
            color="primary"
            startIcon={<HelpCircleIcon size={20} />}
            onClick={handleOpenTour}
          >
            Tutorial
          </Button>

          <DriveTour
            stepsMatrix={
              constants.tours.thirdPartyManagements.STEPS_OBJECT.INTERMEDIATOR
            }
            open={openTour}
            setOpen={setOpenTour}
          />
        </ContentHeader>

        <Paper component={Box} p={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <ActionButtons />
            </Grid>

            <Grid item xs={12}>
              <AdvisorCard
                id={
                  constants.tours.thirdPartyManagements.STEPS.INTERMEDIATOR[7]
                }
                imageSrc={enterpriseStreamlineIcon}
                text={constants.thirdPartyManagements.BANNERS.INTERMEDIATOR}
                action={
                  <Button
                    variant="contained"
                    className={classes.greenButton}
                    onClick={handleCopyLink}
                  >
                    Copiar Link
                  </Button>
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Stats />
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Page>
  )
}

export default ThirdPartyManagementIntemediator
