import React, { useEffect } from 'react'
import { Controller, FormContext, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { isEmpty, size } from 'lodash'
import { Alert } from '@material-ui/lab'
import { Box, Typography } from '@material-ui/core'

import { CopyInput, DetailedSelect, TextFieldList } from 'components'

import helpers from 'helpers'

import useConsent from 'hooks/useConsent'
import useSnackbar from 'hooks/useSnackbar'

import theme from 'theme'
import schema from './schema'
import * as service from 'service'
import constants from 'constants/index'
import { getBaseURL } from 'service/env'

const SharingStep = ({
  setLoading,
  refresh = () => {},
  consentFormRefresh = () => {},
  isShow = false,
  currentDocument,
}) => {
  const {
    PUBLIC_SHARING_MODE_ID,
    RESTRICTED_SHARING_MODE_ID,
    ACCEPTANCE_STEPS,
  } = constants.documents

  const { activeStep, dataConsentForm, setToNextStep, toNextStep } =
    useConsent()
  const portalURL = getBaseURL('privacyPortal')
  const snackbar = useSnackbar()

  const isLastStep = activeStep === size(ACCEPTANCE_STEPS) - 1

  const handleNextStep = () => {
    if (!isLastStep) setToNextStep(true)
  }

  const formMethods = useForm({
    validationSchema: schema,
    defaultValues: {
      mode: isEmpty(dataConsentForm?.authorizedEmails)
        ? PUBLIC_SHARING_MODE_ID
        : RESTRICTED_SHARING_MODE_ID,
      email: '',
      authorizedEmails: dataConsentForm?.authorizedEmails || [],
    },
  })

  const { control, errors, handleSubmit, watch } = formMethods

  const mode = watch('mode')

  const onSubmit = async (data) => {
    try {
      setLoading(true)

      await service.dponet.consent.update({
        consentFormId: dataConsentForm?.id,
        authorizedEmails: data?.authorizedEmails,
        components: JSON.stringify(dataConsentForm?.components),
        buttonText: dataConsentForm?.buttonText,
      })

      snackbar.open({
        message: `Aceite atualizado com sucesso!`,
        variant: 'success',
      })
      handleNextStep()
      refresh()
      consentFormRefresh()
      setToNextStep(false)
    } catch (error) {
      console.error(error)
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Ocorreu uma falha ao atualizar o aceite! ',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (toNextStep) {
      handleSubmit(onSubmit)()
      if (errors) setToNextStep(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toNextStep])

  return (
    <FormContext {...formMethods}>
      <Box
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(2)}
        component="form"
        id="sharing-step-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography variant="h6">Compartilhamento</Typography>
        <Controller
          as={
            <DetailedSelect
              error={!!errors.mode}
              helperText={<>{errors?.mode?.message}</>}
              items={constants.documents.ACCEPTANCE_SHARING_OPTIONS}
              disabled={isShow}
            />
          }
          control={control}
          name="mode"
        />
        {mode === PUBLIC_SHARING_MODE_ID && (
          <CopyInput
            content={`${portalURL}/consent-forms?token=${dataConsentForm?.token}`}
          />
        )}
        {mode === RESTRICTED_SHARING_MODE_ID && (
          <TextFieldList
            label="Adicionar e-mail"
            controlName="email"
            arrayControlName="authorizedEmails"
            containerTitle="E-mails adicionados"
            disabled={isShow}
          />
        )}
        {!currentDocument?.collectAccepted && (
          <Alert variant="standard" severity="info">
            A coleta de aceites dos termos deste documento está desativada!
            Portanto, o link de compartilhamento não funcionará.
          </Alert>
        )}
      </Box>
    </FormContext>
  )
}

SharingStep.propTypes = {
  setLoading: PropTypes.func,
  refresh: PropTypes.func,
  consentFormRefresh: PropTypes.func,
  isShow: PropTypes.bool,
  currentDocument: PropTypes.object,
}

export default SharingStep
