const DEFAULT_BUTTONS = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'eraser',
  '|',
  'ul',
  'ol',
  '|',
  'font',
  'fontsize',
  'paragraph',
  'lineHeight',
  'superscript',
  'subscript',
  '\n',
  'speechRecognize',
  'spellcheck',
  '|',
  'cut',
  'copy',
  'paste',
  'selectall',
  'copyformat',
  '|',
  'hr',
  'table',
  'link',
  'symbols',
  '|',
  'indent',
  'outdent',
  'left',
  '|',
  'brush',
  'undo',
  'redo',
  '|',
  'find',
  '|',
  'source',
  '|',
  'fullsize',
  'preview',
  'print',
]

const CAPTURES_BUTTONS = ['|', 'file', 'image', 'video']

const EDITOR_OPTIONS = [
  ...DEFAULT_BUTTONS.slice(0, DEFAULT_BUTTONS.indexOf('subscript') + 1),
  ...CAPTURES_BUTTONS,
  ...DEFAULT_BUTTONS.slice(DEFAULT_BUTTONS.indexOf('subscript') + 1),
]

const jodit = {
  CAPTURES_BUTTONS,
  DEFAULT_BUTTONS,
  EDITOR_OPTIONS,
}

export default jodit
