import React, { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Trash2 as Trash2Icon } from 'react-feather'
import PropTypes from 'prop-types'
import { Box, Chip, IconButton, TableBody } from '@material-ui/core'

import { orderBy } from 'lodash'

import { Table, TableHead, TableRow, TableCell } from 'components/Table'
import { Permitted } from 'components'
import DialogRemoveUser from '../DialogRemoveUser'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import constants from 'constants/index'
import * as service from 'service'

import useStyles from '../../styles'

const DepartmentUsersTable = ({
  departmentId,
  loading,
  refresh = () => {},
  setLoading = () => {},
  users = [],
  availableUsersRefresh = () => {},
}) => {
  const classes = useStyles()

  const snackbar = useSnackbar()
  const [open, setOpen] = useState(false)
  const [userId, setUserId] = useState(null)

  const openDialog = (userId) => {
    setOpen(true)
    setUserId(userId)
  }

  const handleCloseModal = () => setOpen(false)

  const unlinkUser = async (departmentId, userId) => {
    try {
      setLoading(true)
      await service.dponet.departments.unlinkUser({ departmentId, userId })
      snackbar.open({
        message: 'Usuário removido do departamento com sucesso!',
        variant: 'success',
      })
      handleCloseModal()
      availableUsersRefresh()
      refresh()
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box width="100%" maxHeight={300}>
      <PerfectScrollbar>
        <Table
          size="small"
          emptyMessage="Não há nenhum usuário vinculado a este departamento"
          isLoading={loading}
          stickyHeader
          noWrap
        >
          <TableHead>
            <TableRow>
              <TableCell width="30%">Nome</TableCell>
              <TableCell width="40%">Email</TableCell>
              <TableCell width="40%">Status</TableCell>
              <TableCell width="20%" align="right">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderBy(users, 'active', 'desc')?.map((user) => (
              <TableRow key={user?.id}>
                <TableCell>{user?.name}</TableCell>
                <TableCell>{user?.email}</TableCell>
                <TableCell>
                  <Chip
                    size="small"
                    className={helpers.departments.statusChipColor(
                      user.active,
                      classes,
                    )}
                    label={helpers.departments.status(user.active)}
                  />
                </TableCell>
                <TableCell align="right">
                  <Permitted tag={constants.permissions.DEPARTMENTS.LINK_USERS}>
                    <IconButton
                      aria-label="Remover"
                      variant="outlined"
                      color="secondary"
                      onClick={() => openDialog(user?.id)}
                    >
                      <Trash2Icon size={20} />
                    </IconButton>
                  </Permitted>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      <DialogRemoveUser
        departmentId={departmentId}
        userId={userId}
        open={open}
        handleClose={handleCloseModal}
        unlinkUser={unlinkUser}
      />
    </Box>
  )
}

DepartmentUsersTable.propTypes = {
  departmentId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  loading: PropTypes.bool.isRequired,
  refresh: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
  availableUsersRefresh: PropTypes.func.isRequired,
}

export default DepartmentUsersTable
