import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { routes } from 'Routes'
import { reverse } from 'named-urls'
import { Box, Typography, Chip, Button, Link } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { MenuButton, Permitted, ConfirmationDialog } from 'components'
import { ReopenDialog, RiskDialog } from '../'

import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'

import constants from 'constants/index'
import * as service from 'service'
import styles from './styles'

const QuestionTableRow = ({ questionControl, refresh, ...rest }) => {
  const snackbar = useSnackbar()
  const history = useHistory()
  const classes = styles()
  const [reopenDialog, setReopenDialog] = useState({
    questionControlId: null,
    open: false,
  })
  const [riskDialog, setRiskDialog] = useState(false)
  const [riskData, setRiskData] = useState(false)
  const [loadingDialog, setLoadingDialog] = useState(false)
  const [destroyOpen, setDestroyOpen] = useState(false)
  const [questionControlId, setQuestionControlId] = useState()

  const navigateAction = (questionControlId, type) =>
    history.push(
      reverse(routes.questionControls[type], {
        questionControlId,
      }),
    )

  const handleRisk = (questionControl) => {
    setRiskData(questionControl)
    setRiskDialog(true)
  }

  const handleReopen = async (questionControlId) => {
    setLoadingDialog(true)
    try {
      await service.dponet.questionControls.enableWrite({ questionControlId })

      navigateAction(questionControlId, 'edit')
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Erro ao habilitar a edição da medida de segurança!',
        variant: 'error',
      })
    } finally {
      setLoadingDialog(false)
    }
  }

  const redirectQuestionnaire = (reference) => {
    history.push(
      reverse(routes.questionnaire.reply, {
        questionnaireId: reference?.questionnaireId,
      }),
    )
  }

  const handleDestroy = async () => {
    try {
      await service.dponet.questionControls.destroy(questionControlId)
      setQuestionControlId()
      setDestroyOpen(false)
      refresh()

      snackbar.open({
        message: 'Medida de segurança excluída com sucesso',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    }
  }

  const handleConfirmDestroy = (questionControlId) => {
    setDestroyOpen(true)
    setQuestionControlId(questionControlId)
  }

  return (
    <>
      <TableRow {...rest}>
        <TableCell>
          <Typography className={classes.rowText}>
            #{questionControl?.id}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography className={classes.rowText}>
            {questionControl?.title}
          </Typography>
        </TableCell>
        <TableCell>
          <Link
            className={clsx({ [classes.link]: !!questionControl?.reference })}
            underline={!!questionControl?.reference ? 'always' : 'never'}
            onClick={() =>
              !!questionControl?.reference &&
              redirectQuestionnaire(questionControl?.reference)
            }
          >
            <Typography className={classes.rowText}>
              {questionControl?.questionnaireKind}
            </Typography>
          </Link>
        </TableCell>
        <TableCell>
          <Chip
            id={helpers.questionControls.statusDriverId(
              questionControl?.status,
            )}
            size="small"
            className={[
              helpers.questionControls.statusChipColor(
                questionControl?.status,
                classes,
              ),
              classes.chip,
            ]}
            label={helpers.questionControls.status(questionControl?.status)}
          />
        </TableCell>
        <TableCell>
          <Typography className={classes.rowText}>
            {helpers.formatters.date(questionControl?.deadlineDate) || '-'}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <MenuButton
            id={constants.questionControl.QUESTION_CONTROL_DRIVER_STEP_3}
          >
            <Button
              color="secondary"
              onClick={() => navigateAction(questionControl?.id, 'show')}
              fullWidth
              size="small"
            >
              visualizar
            </Button>
            {questionControl?.openWrite ? (
              <Permitted tag={constants.permissions.DIAGNOSTICS.UPDATE}>
                <Button
                  color="secondary"
                  onClick={() => navigateAction(questionControl?.id, 'edit')}
                  fullWidth
                  size="small"
                >
                  Editar
                </Button>
              </Permitted>
            ) : (
              <Permitted tag={constants.permissions.DIAGNOSTICS.OPEN_WRITE}>
                <Button
                  color="secondary"
                  onClick={() =>
                    setReopenDialog({
                      questionControlId: questionControl?.id,
                      open: true,
                    })
                  }
                  fullWidth
                  size="small"
                >
                  Reabrir
                </Button>
              </Permitted>
            )}
            {constants.questionControl.RISK_ACTION.includes(
              questionControl?.status,
            ) && (
              <>
                {questionControl?.takeRisk?.accept ? (
                  <Button
                    color="secondary"
                    onClick={() => handleRisk(questionControl)}
                    fullWidth
                    size="small"
                  >
                    Informações
                  </Button>
                ) : (
                  <Permitted tag={constants.permissions.DIAGNOSTICS.TAKE_RISK}>
                    <Button
                      color="secondary"
                      onClick={() => handleRisk(questionControl)}
                      fullWidth
                      size="small"
                    >
                      Assumir risco
                    </Button>
                  </Permitted>
                )}
              </>
            )}
            {!questionControl?.reference && (
              <Permitted tag={constants.permissions.DIAGNOSTICS.UPDATE}>
                <Button
                  color="secondary"
                  fullWidth
                  size="small"
                  onClick={() => handleConfirmDestroy(questionControl?.id)}
                >
                  Excluir
                </Button>
              </Permitted>
            )}
          </MenuButton>
        </TableCell>
      </TableRow>
      {reopenDialog?.open && (
        <ReopenDialog
          changeDialog={reopenDialog}
          setChangeDialog={setReopenDialog}
          handleConfirmation={handleReopen}
          loading={loadingDialog}
        />
      )}

      {riskDialog && (
        <RiskDialog
          changeDialog={riskDialog}
          setChangeDialog={setRiskDialog}
          refresh={refresh}
          riskData={riskData}
        />
      )}
      <ConfirmationDialog
        open={destroyOpen}
        setOpen={setDestroyOpen}
        text={'Você tem certeza que deseja excluir essa medida de segurança?'}
        textButtonCancel="Cancelar"
        textButton="Confirmar"
        actionAcceptButton={handleDestroy}
      />
    </>
  )
}

const QuestionTable = ({ questionControls, refresh }) => {
  const classes = styles()

  return (
    <>
      <Box width="100%">
        <PerfectScrollbar>
          <Table size="small" emptyMessage="Nenhum usuário encontrado" noWrap>
            <TableHead>
              <TableRow>
                <TableCell width="5%">
                  <Box my={1}>
                    <Typography className={classes.headText}>ID</Typography>
                  </Box>
                </TableCell>
                <TableCell width="30%">
                  <Typography className={classes.headText}>
                    Medidas de segurança
                  </Typography>
                </TableCell>
                <TableCell width="35%">
                  <Typography className={classes.headText}>
                    Formulário
                  </Typography>
                </TableCell>
                <TableCell width="10%">
                  <Typography className={classes.headText}>Status</Typography>
                </TableCell>
                <TableCell width="10%">
                  <Typography className={classes.headText}>Prazo</Typography>
                </TableCell>
                <TableCell width="10%" align="right">
                  <Typography className={classes.headText}>Ações</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questionControls?.map((questionControl) => (
                <QuestionTableRow
                  questionControl={questionControl}
                  key={questionControl?.id}
                  refresh={refresh}
                />
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Box>
    </>
  )
}

QuestionTableRow.propTypes = {
  questionControl: PropTypes.object,
  refresh: PropTypes.func,
}

QuestionTableRow.defaultProps = {
  refresh: () => {},
}

QuestionTable.propTypes = {
  questionControls: PropTypes.object,
  refresh: PropTypes.func,
}

QuestionTable.defaultProps = {
  refresh: () => {},
}

export default QuestionTable
