import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  InputLabel,
} from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { isEmpty } from 'lodash'

import { LoadingFeedback } from 'components'

import useSnackbar from 'hooks/useSnackbar'
import useSupplierInvite from 'hooks/useSupplierInvite'

import * as service from 'service'
import constants from 'constants/index'
import helpers from 'helpers'
import schema from './schema'
import useStyles from './styles'

const ConfirmDocumentForm = ({ inviter, companyToken }) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const { setCurrentStep, setSupplierData, isLoading, setIsLoading } =
    useSupplierInvite()
  const { mixpanel } = helpers

  const { control, handleSubmit, errors } = useForm({
    validationSchema: schema,
  })

  const onSubmit = async (formData) => {
    setIsLoading(true)

    mixpanel.track('Convite de Fornecedores', 'Confirme seu documento', {
      action: 'Avançar-Confirme_seu_documento',
    })

    try {
      const response = await service.dponet.supplierInvite.checkInvite(
        {
          supplierInvite: {
            document: formData.document,
          },
        },
        companyToken,
      )

      const supplierInvite = response?.data?.supplierInvite
      const supplierInviteToken = supplierInvite?.documentToken
      const supplier = supplierInvite?.supplier

      setSupplierData((supplierData) => ({
        ...supplierData,
        companyName: supplier?.name || supplierData.companyName,
        responsibleUserEmail: supplier?.responsibleUserEmail,
        companyId: supplier?.id,
        document: formData.document,
        status: supplierInvite?.status,
        supplierAlredyExists: !isEmpty(supplier),
        editionPermitted: supplierInvite?.editionPermitted,
      }))

      service.dponet.auth.setSupplierInviteToken(supplierInviteToken)

      snackbar.open({
        message: 'Documento confirmado com sucesso',
        variant: 'success',
      })

      if (supplierInvite?.editionPermitted) {
        setCurrentStep(constants.supplierInvite.DATA_CONFIRMATION_STEP)
      } else {
        setCurrentStep(constants.supplierInvite.EMAIL_STEP)
      }
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || 'Ocorreu um erro ao confirmar o seu documento',
        variant: 'error',
      })
      setCurrentStep(constants.supplierInvite.NOT_LINKED_STEP)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <LoadingFeedback open={isLoading} />
      {!isLoading && (
        <Grid container justifyContent="center" alignItems="center">
          <Box className={classes.groupTypography}>
            <Typography className={clsx(classes.text, classes.textWelcome)}>
              Vamos verificar seu cadastro!
            </Typography>
            <Typography className={classes.text}>
              Informe seu documento para confirmarmos seu vínculo com{' '}
              <span className={classes.highlight}>{inviter?.name}</span>
            </Typography>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box mt={4}>
                  <Box mb={1}>
                    <InputLabel className={classes.label}>Documento</InputLabel>
                  </Box>
                  <Controller
                    as={
                      <TextField
                        className={classes.input}
                        type="text"
                        error={!!errors.document}
                        helperText={errors?.document?.message}
                        fullWidth
                      />
                    }
                    name="document"
                    onChange={([e]) => {
                      return helpers.formatters.documentFormatter(
                        e.target.value,
                      )
                    }}
                    mode="onChange"
                    control={control}
                  />
                </Box>
              </Grid>
            </Grid>
            <Box mt={4} mb={2}>
              <Button
                className={classes.roundedButton}
                type="submit"
                disabled={isLoading}
                fullWidth
              >
                Procurar
              </Button>
            </Box>
          </form>
        </Grid>
      )}
    </>
  )
}

ConfirmDocumentForm.propTypes = {
  inviter: PropTypes.object,
  companyToken: PropTypes.string,
}

export default ConfirmDocumentForm
