import React from 'react'
import PropTypes from 'prop-types'
import { Box, Chip, TextField } from '@material-ui/core'

import { BaseDialog, SectionCard } from 'components'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'

import useStyles from './styles'
import theme from 'theme'

const AcceptedDetailsDialog = ({ open, setOpen, receipt }) => {
  const classes = useStyles()

  return (
    <BaseDialog
      title="Visualização de aceites"
      closeButtonText="Fechar"
      open={open}
      setOpen={setOpen}
      justifyActions="flex-end"
    >
      <Box
        py={2}
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(2)}
      >
        <TextField
          label="E-mail vinculado"
          disabled
          fullWidth
          defaultValue={receipt?.userEmail}
        />
        <SectionCard title="Aceites vinculados" isTable>
          <Table emptyMessage="Nenhum recibo encontrado!">
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  width="80%"
                  className={classes.initialContent}
                >
                  Versão
                </TableCell>
                <TableCell
                  align="center"
                  width="20%"
                  className={classes.finalContent}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {receipt?.versionsAccepted?.versions?.map((version, index) => (
                <TableRow key={index}>
                  <TableCell
                    align="left"
                    width="80%"
                    className={classes.initialContent}
                  >
                    {version}
                  </TableCell>
                  <TableCell
                    align="center"
                    width="20%"
                    className={classes.finalContent}
                  >
                    <Chip
                      label="ACEITO"
                      size="small"
                      className={classes.statusLabelAuthorized}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </SectionCard>
      </Box>
    </BaseDialog>
  )
}

AcceptedDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  receipt: PropTypes.object,
}

export default AcceptedDetailsDialog
