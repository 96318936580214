import Main from './DocumentsMain'
import New from './DocumentsNew'
import Edit from './DocumentsEdit'
import Show from './DocumentsShow'
import Comparator from './DocumentComparator'

export default {
  Main,
  New,
  Edit,
  Show,
  Comparator,
}
