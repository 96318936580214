import constants from 'constants/index'

const getAuditFrequencyByEnum = (auditFrequencyEnum) => {
  if (!auditFrequencyEnum) return

  const auditFrequencyTypes =
    constants.thirdPartyManagements.SUPPLIER_AUDIT_FREQUENCY_TYPES

  return auditFrequencyTypes
    .find((auditFrequencyType) => auditFrequencyType.id === auditFrequencyEnum)
    ?.name?.toLowerCase()
}

export default getAuditFrequencyByEnum
