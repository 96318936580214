import React from 'react'
import clsx from 'clsx'
import { useHistory } from 'react-router-dom'
import { Box } from '@material-ui/core'
import {
  Plus as PlusIcon,
  Users as UsersIcon,
  Send as SendIcon,
  FileText as FileTextIcon,
} from 'react-feather'
import { WbIncandescentOutlined as BulbIcon } from '@material-ui/icons'

import { Permitted } from 'components'
import { FatButton } from 'views/ThirdPartyManagements/components'

import usePermissions from 'hooks/usePermissions'

import theme from 'theme'
import useStyles from './styles'
import { routes } from 'Routes'
import permissions from 'constants/permissions'
import constants from 'constants/index'

const ActionButtons = () => {
  const history = useHistory()
  const classes = useStyles()
  const { permitted } = usePermissions()

  const handleRedirect = (route) => {
    history.push(route)
  }

  const hasCompanySuppliersUpdatePermission = permitted(
    permissions.COMPANY_SUPPLIERS.UPDATE,
  )

  return (
    <Box
      className={clsx({
        [classes.buttonsBox]: hasCompanySuppliersUpdatePermission,
        [classes.buttonsBoxWithoutPermission]:
          !hasCompanySuppliersUpdatePermission,
      })}
      gridGap={theme.spacing(3)}
    >
      <Permitted tag={permissions.COMPANY_SUPPLIERS.UPDATE}>
        <FatButton
          fullWidth
          variant="outlined"
          startIcon={<PlusIcon size={20} />}
          onClick={() =>
            handleRedirect(routes.thirdPartyManagements.partners.new)
          }
          id={constants.tours.thirdPartyManagements.STEPS.INTERMEDIATOR[2]}
        >
          Novo
        </FatButton>
      </Permitted>

      <FatButton
        fullWidth
        startIcon={<UsersIcon size={20} />}
        onClick={() =>
          handleRedirect(routes.thirdPartyManagements.partners.all)
        }
        id={constants.tours.thirdPartyManagements.STEPS.INTERMEDIATOR[3]}
      >
        Parceiros
      </FatButton>
      <FatButton
        fullWidth
        startIcon={<SendIcon size={20} />}
        onClick={() => handleRedirect(routes.thirdPartyManagements.invites)}
        id={constants.tours.thirdPartyManagements.STEPS.INTERMEDIATOR[4]}
      >
        Convites
      </FatButton>
      <FatButton
        fullWidth
        startIcon={<BulbIcon fontSize="large" className={classes.bulbIcon} />}
        onClick={() => handleRedirect(routes.thirdPartyManagements.suggestions)}
        id={constants.tours.thirdPartyManagements.STEPS.INTERMEDIATOR[5]}
      >
        Sugestões
      </FatButton>
      <FatButton
        fullWidth
        startIcon={<FileTextIcon size={20} />}
        onClick={() =>
          handleRedirect(routes.thirdPartyManagements.preAvaliations)
        }
        id={constants.tours.thirdPartyManagements.STEPS.INTERMEDIATOR[6]}
      >
        Pré-Avaliação
      </FatButton>
    </Box>
  )
}

export default ActionButtons
