import React, { useState } from 'react'
import { Search as SearchIcon } from 'react-feather'
import { isEmpty } from 'lodash'

import {
  Card,
  Typography,
  Box,
  Divider,
  makeStyles,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import { ListCompanies } from '../'

import helpers from 'helpers'
import { LoadingFeedback } from 'components'

import styles from './styles'

const useStyles = makeStyles(styles)

const CardCompanies = ({ usersCompanies, isLoading }) => {
  const classes = useStyles()

  const [searchInput, setSearchInput] = useState('')

  const searchItems = (searchValue) => {
    setSearchInput(searchValue)
  }
  const filteredUsersCompanies = !isEmpty(searchInput)
    ? usersCompanies.filter((userCompany) => {
        const companyName = userCompany?.company?.name.toLowerCase()
        const companyDocument = helpers.functions.cnpjCpf(
          userCompany?.company?.document,
          'strip',
        )

        return (
          companyName.includes(searchInput.toLowerCase()) ||
          companyDocument.startsWith(searchInput.replace(/[./-]/g, ''))
        )
      })
    : usersCompanies

  return (
    <>
      <LoadingFeedback open={isLoading} />
      <Card className={classes.root}>
        <Box mx={2} mt={2}>
          <Typography variant="h4">
            Qual empresa você deseja acessar?
          </Typography>
        </Box>
        <Box mx={2} mt={2}>
          <TextField
            fullWidth
            placeholder="Pesquise pelo nome ou documento da empresa"
            value={searchInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon size={20} />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            autoFocus
            onChange={(e) => searchItems(e.target.value)}
          />
        </Box>
        <Box mt={2}>
          <Divider />
          {!isLoading && (
            <ListCompanies usersCompanies={filteredUsersCompanies} />
          )}
        </Box>
      </Card>
    </>
  )
}

export default CardCompanies
