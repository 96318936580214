import helpers from 'helpers'
import { isEmpty } from 'lodash'

const registerForm = (supplierInvite, isEdit) => {
  const supplier = supplierInvite?.supplier || {}
  const companySupplierDetail = supplierInvite?.companySupplierDetail || {}

  const defaultDocument = () => {
    const document = supplier?.document || supplierInvite?.document

    if (!document) return ''

    if (supplier?.international) {
      return document
    }

    return helpers.formatters.documentFormatter(document)
  }

  return {
    simplified: (isEdit && isEmpty(supplier)) || false,
    international: supplier?.international || false,
    name: supplier?.name || '',
    document: defaultDocument(),
    email: supplier?.email || '',
    date: companySupplierDetail?.companySupplier?.dateToSendEmails || null,
  }
}

const optionalForm = (supplierInvite, fileList) => {
  const companySupplierDetail = supplierInvite?.companySupplierDetail || {}

  return {
    hireDate: companySupplierDetail?.hireDate || null,
    renewDate: companySupplierDetail?.renewDate || null,
    description: companySupplierDetail?.description || '',
    segment: companySupplierDetail?.segment || null,
    treatmentAgent: companySupplierDetail?.treatmentAgentType || null,
    supplierAudit: companySupplierDetail?.enableAudit || false,
    supplierAuditFrequency: companySupplierDetail?.auditFrequency || 12,
    supplierAuditFrequencyType:
      companySupplierDetail?.auditFrequencyType || 'months',
    supplierRisk:
      helpers.thirdPartyManegementPartners.getRiskLevelByEnum(
        companySupplierDetail?.riskLevel,
      ) || 1,
    document: fileList || undefined,
  }
}

export default {
  registerForm,
  optionalForm,
}
