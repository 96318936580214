const DOCUMENTS_CATEGORY_ID = 'documents'
const ACCEPTED_DOCUMENTS_CATEGORY_ID = 'accepted'

const MAIN_STEP_1 = 'document-main-driver-step-1'
const MAIN_STEP_2 = 'document-main-driver-step-2'
const MAIN_STEP_3 = 'document-main-driver-step-3'
const MAIN_STEP_4 = 'document-main-driver-step-4'
const MAIN_STEP_5 = 'document-main-driver-step-5'
const MAIN_STEP_6 = 'document-main-driver-step-6'

const MAIN_STEPS_OBJECT = [
  {
    element: '#' + MAIN_STEP_1,
    popover: {
      description:
        'A gestão de documentos permite organizar e controlar todos os arquivos importantes de forma centralizada e segura. Para que você mantenha os documentos atualizados e assegurar que todos estejam alinhados com as políticas de privacidade e conformidade.',
    },
  },
  {
    element: '#' + MAIN_STEP_2,
    popover: {
      description: 'Crie um novo documento aqui.',
    },
  },
  {
    element: '#' + MAIN_STEP_3,
    popover: {
      description: 'Verifique todos os seus documentos nesta seção.',
    },
  },
  {
    element: '#' + MAIN_STEP_4,
    popover: {
      description:
        'A plataforma disponibiliza modelos de documentos para ajudá-lo na elaboração, oferecendo uma base para facilitar a criação de seus próprios documentos.',
    },
  },
  {
    element: '#' + MAIN_STEP_5,
    popover: {
      description:
        'Os documentos antigos referem-se aos arquivos anteriores à atualização. Lembre-se de atualizá-los, pois em breve serão removidos da plataforma.',
    },
  },
  {
    element: '#' + MAIN_STEP_6,
    popover: {
      description:
        'Para visualizar ou editar um documento, clique nos botões correspondentes. Ao clicar nos três pontos, você pode inativar, ativar ou excluir o documento. Para verificar os aceites no documento, clique em "Visualizar".',
    },
  },
]

const MANAGEMENT_STEP_1 = 'document-management-driver-step-1'
const MANAGEMENT_STEP_2 = 'document-management-driver-step-2'
const MANAGEMENT_STEP_3 = 'document-management-driver-step-3'
const MANAGEMENT_STEP_4 = 'document-management-driver-step-4'

const MANAGEMENT_STEPS_OBJECT = [
  {
    element: '#' + MANAGEMENT_STEP_1,
    popover: {
      description:
        'Tela para gerenciamento de documentos, onde é possível escrever o conteúdo, definir um título e decidir se haverá coleta de aceites ou publicação na área Privacidade e Você.',
    },
  },
  {
    element: '#' + MANAGEMENT_STEP_2,
    popover: {
      description:
        'Se desejar publicar o documento no canal de privacidade e/ou coletar aceites, ative os respectivos botões de seleção.',
    },
  },
  {
    element: '#' + MANAGEMENT_STEP_3,
    popover: {
      description:
        'Selecione uma categoria para o documento. Caso a categoria desejada não esteja disponível, utilize "Outros" e especifique a nova categoria.',
    },
  },
  {
    element: '#' + MANAGEMENT_STEP_4,
    popover: {
      description: 'Dê um título ao documento e escreva seu conteúdo.',
    },
  },
]

const MANAGEMENT_ACCEPTED_STEP_1 = 'document-management-accepted-driver-step-1'
const MANAGEMENT_ACCEPTED_STEP_2 = 'document-management-accepted-driver-step-2'
const MANAGEMENT_ACCEPTED_STEP_3 = 'document-management-accepted-driver-step-3'
const MANAGEMENT_ACCEPTED_STEP_4 = 'document-management-accepted-driver-step-4'

const MANAGEMENT_ACCEPTED_STEPS_OBJECT = [
  {
    element: '#' + MANAGEMENT_ACCEPTED_STEP_1,
    popover: {
      description:
        'Nesta seção, defina as informações essenciais para a coleta de aceites do seu documento.',
    },
  },
  {
    element: '#' + MANAGEMENT_ACCEPTED_STEP_2,
    popover: {
      description:
        'Especifique as informações necessárias para a coleta de aceites. O campo de e-mail é obrigatório, mas você pode marcar outros campos como obrigatórios conforme necessário.',
    },
  },
  {
    element: '#' + MANAGEMENT_ACCEPTED_STEP_3,
    popover: {
      description:
        'Pré-visualize o documento para conferir como ele será exibido. Lembre-se de que aqui há um limite de caracteres para apresentação, mas o documento completo estará disponível ao final.',
    },
  },
  {
    element: '#' + MANAGEMENT_ACCEPTED_STEP_4,
    popover: {
      description:
        'Defina com quem o documento será compartilhado: escolha entre um grupo restrito de pessoas ou disponibilizá-lo publicamente.',
    },
  },
]

const CHOOSE_MANAGEMENT_STEPS_OBJECT = {
  [DOCUMENTS_CATEGORY_ID]: MANAGEMENT_STEPS_OBJECT,
  [ACCEPTED_DOCUMENTS_CATEGORY_ID]: MANAGEMENT_ACCEPTED_STEPS_OBJECT,
}

export default {
  MAIN_STEPS_OBJECT,
  MAIN_STEP_1,
  MAIN_STEP_2,
  MAIN_STEP_3,
  MAIN_STEP_4,
  MAIN_STEP_5,
  MAIN_STEP_6,
  MANAGEMENT_STEPS_OBJECT,
  MANAGEMENT_STEP_1,
  MANAGEMENT_STEP_2,
  MANAGEMENT_STEP_3,
  MANAGEMENT_STEP_4,
  MANAGEMENT_ACCEPTED_STEPS_OBJECT,
  MANAGEMENT_ACCEPTED_STEP_1,
  MANAGEMENT_ACCEPTED_STEP_2,
  MANAGEMENT_ACCEPTED_STEP_3,
  MANAGEMENT_ACCEPTED_STEP_4,
  CHOOSE_MANAGEMENT_STEPS_OBJECT,
}
