import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  InputLabel,
} from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'

import useSnackbar from 'hooks/useSnackbar'
import useSupplierInvite from 'hooks/useSupplierInvite'
import useStyles from './styles'

import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'
import schemaSupplier from './schema'

const SupplierForm = ({ companyToken }) => {
  const [submitting, setSubmitting] = useState(false)
  const classes = useStyles()
  const snackbar = useSnackbar()
  const { setCurrentStep, supplierData, setSupplierData } = useSupplierInvite()
  const { mixpanel } = helpers

  const { control, handleSubmit, errors } = useForm({
    validationSchema: schemaSupplier,
    defaultValues: {
      document: supplierData.document,
      companyName: supplierData.companyName || '',
    },
  })

  const onSubmit = async (formData) => {
    setSubmitting(true)

    mixpanel.track('Convite de Fornecedores', 'Confirme seus dados', {
      action: 'Avançar-Confirme_seus_dados',
    })

    try {
      setSupplierData(formData)

      const response =
        await service.dponet.supplierInvite.checkSupplierRelations(
          {
            supplierInvite: {
              document: formData.document,
              email: formData.email,
            },
          },
          companyToken,
        )

      const { userExists, userIsResponsible, companyExists, userFirstAccess } =
        response?.data || {}

      if (!userExists && !companyExists) {
        setCurrentStep(constants.supplierInvite.CREATE_PASSWORD_STEP)
      }
      if (
        (!userExists && companyExists) ||
        (userExists && !userIsResponsible)
      ) {
        setCurrentStep(constants.supplierInvite.NOT_PERMITED_STEP)
      }
      if (
        (userExists &&
          userIsResponsible &&
          companyExists &&
          !userFirstAccess) ||
        (userExists && !companyExists)
      ) {
        setCurrentStep(constants.supplierInvite.LOGIN_STEP)
      }
      if (userExists && userIsResponsible && companyExists && userFirstAccess) {
        setCurrentStep(constants.supplierInvite.CREATE_PASSWORD_STEP)
      }
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || 'Ocorreu um erro ao cadastrar seus dados',
        variant: 'error',
      })
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <Box>
      <Box className={classes.groupTypography}>
        <Typography className={clsx(classes.text, classes.textWelcome)}>
          Encontramos o seu vínculo!
        </Typography>
        <Typography className={classes.text}>
          Por favor, informe seus dados.
        </Typography>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.formTag}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={6} md={6} lg={12}>
            <Box mt={1} mb={1}>
              <InputLabel className={classes.label}>Razão Social</InputLabel>
            </Box>
            <Controller
              as={
                <TextField
                  className={classes.input}
                  type="text"
                  error={!!errors.companyName}
                  helperText={errors?.companyName?.message}
                  fullWidth
                />
              }
              name="companyName"
              mode="onChange"
              control={control}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={12}>
            <Box mt={1}>
              <Box mb={1}>
                <InputLabel className={classes.label}>
                  Email do responsável pela LGPD na sua empresa
                </InputLabel>
              </Box>
              <Controller
                as={
                  <TextField
                    className={classes.input}
                    type="text"
                    error={!!errors.email}
                    helperText={errors?.email?.message}
                    fullWidth
                  />
                }
                name="email"
                mode="onChange"
                control={control}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mt={1}>
              <Box mb={1}>
                <InputLabel className={classes.label}>Documento</InputLabel>
              </Box>
              <Controller
                as={
                  <TextField
                    className={classes.input}
                    type="text"
                    error={!!errors.document}
                    helperText={errors?.document?.message}
                    fullWidth
                    value={supplierData.document}
                    disabled
                  />
                }
                name="document"
                control={control}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box mt={1}>
              <Box mb={1}>
                <InputLabel className={classes.label}>
                  Telefone do responsável
                </InputLabel>
              </Box>
              <Controller
                as={
                  <TextField
                    className={classes.input}
                    type="text"
                    error={!!errors.phone}
                    helperText={errors?.phone?.message}
                    fullWidth
                  />
                }
                name="phone"
                onChange={([e]) =>
                  helpers.formatters.phoneWithoutCountryCode(e.target.value)
                }
                mode="onChange"
                control={control}
              />
            </Box>
          </Grid>
        </Grid>
        <Box mt={4} mb={2}>
          <Button
            className={classes.roundedButton}
            type="submit"
            disabled={submitting}
            fullWidth
          >
            Avançar
          </Button>
        </Box>
      </form>
    </Box>
  )
}

SupplierForm.propTypes = {
  companyToken: PropTypes.string,
}

export default SupplierForm
