import api from 'service/api'

const dponetAPI = api.create('dponet', false)

const get = async ({ complaintId = '', ...params }) => {
  return await dponetAPI.get(`/complaints/${complaintId}`, {
    params,
  })
}

const registerResponsible = async ({ complaintId = '', ...params }) => {
  return await dponetAPI.put(`/complaints/${complaintId}/responsible`, params)
}

const activateComplaints = async (data) => {
  return await dponetAPI.put(`/complaints/activate_complaints`, data)
}

const logs = async ({ complaintId }) => {
  return await dponetAPI.get(`/complaints/${complaintId}/logs`)
}

const lastAccess = async ({ complaintId, ...params }) => {
  return await dponetAPI.patch(`/complaints/${complaintId}/last_access`, params)
}

export default {
  activateComplaints,
  get,
  lastAccess,
  logs,
  registerResponsible,
}
