import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  item: {
    paddingInline: theme.spacing(2),
    paddingBlock: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  paperContainer: {
    padding: theme.spacing(2),
    maxHeight: 200,
  },
  buttonError: {
    backgroundColor: theme.palette.error.main,
    '&:hover': { backgroundColor: theme.palette.error.dark },
  },
}))

export default styles
