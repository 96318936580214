import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  avatar: {
    width: 100,
    height: 100,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,

    [theme.breakpoints.down('sm')]: {
      width: 50,
      height: 50,
    },
  },
}))

export default styles
