import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { BaseDialog } from 'components'

import theme from 'theme'

const VersionAlertDialog = ({ open, setOpen, onSubmit }) => {
  return (
    <BaseDialog
      open={open}
      setOpen={setOpen}
      title="Tem certeza que deseja gerar uma nova versão do documento?"
      closeButtonText="Cancelar"
      actionButtonText="Salvar"
      actionButton={onSubmit}
      justifyActions="flex-end"
      dialogSize="md"
    >
      <Box
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(2)}
        py={1}
      >
        <Typography>
          Ao alterar o conteúdo deste documento, os consentimentos antigos
          continuarão salvos, mas as pessoas serão notificadas, pois será
          necessário atualizar o consentimento para a nova versão.
        </Typography>
        <Alert variant="outlined" severity="info">
          A nova versão do documento <strong>não será gerada</strong> se ele
          estiver como <strong>rascunho</strong> ou <strong>inativo</strong>, ou
          se a opção&nbsp;
          <strong>"Coletar aceite dos termos deste documento"</strong> estiver
          desativada. Se preferir, salve o documento como rascunho ou desative a
          coleta de aceites e faça as edições necessárias antes de publicar uma
          nova versão.
        </Alert>
      </Box>
    </BaseDialog>
  )
}

VersionAlertDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default VersionAlertDialog
