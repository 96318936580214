import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, TablePagination, useMediaQuery } from '@material-ui/core'

import { LoadingFeedback, SectionCard } from 'components'
import { AcceptedTable } from './components'
import SharingStep from '../SharingStep'

import usePagination from 'hooks/usePagination'
import useConsent from 'hooks/useConsent'
import useFetch from 'hooks/useFetch'

import theme from 'theme'
import * as service from 'service'

const AcceptedSection = ({ consentFormId, currentDocument }) => {
  const [loading, setLoading] = useState(false)
  const [loadingDefaultValues, setLoadingDefaultValues] = useState(true)

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { setDataConsentForm } = useConsent()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { response, isLoading } = useFetch(
    service.dponet.consent.getAcceptedList,
    {
      consentFormId: consentFormId,
      page,
      perPage,
    },
    [consentFormId],
    !!consentFormId,
  )

  const receipts = response?.data?.consentFormAnswers

  useEffect(() => {
    setLoadingDefaultValues(true)
    const defaultValues = {
      authorizedEmails: currentDocument?.consentForm?.authorizedEmails,
      token: currentDocument?.consentForm?.token,
    }

    setDataConsentForm({ ...defaultValues })
    setLoadingDefaultValues(false)

    handleChangePage({}, 0)
  }, [])

  return (
    <Box display="flex" flexDirection="column" gridGap={theme.spacing(4)}>
      <LoadingFeedback open={isLoading || loading} />
      {!loadingDefaultValues && (
        <SectionCard title="Informações do aceites">
          <SharingStep isShow currentDocument={currentDocument} />
        </SectionCard>
      )}
      {!isLoading && (
        <SectionCard title="Recibos" isTable hasPagination>
          <AcceptedTable
            receipts={receipts}
            isLoading={isLoading}
            setLoading={setLoading}
          />
          <TablePagination
            component="div"
            count={response?.data?.meta?.totalCount || 0}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={perPage}
            rowsPerPageOptions={[5, 10, 25, 100]}
            labelRowsPerPage={isDesktop ? 'Por página' : ''}
            nextIconButtonProps={{ size: 'small' }}
            backIconButtonProps={{ size: 'small' }}
          />
        </SectionCard>
      )}
    </Box>
  )
}

AcceptedSection.propTypes = {
  consentFormId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentDocument: PropTypes.object,
}

export default AcceptedSection
