import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { isEmpty } from 'lodash'

import useFetch from 'hooks/useFetch'

import constants from 'constants/index'
import * as service from 'service'

import useStyles from './styles'
import { routes } from 'Routes'

const DialogNewForm = ({ open, setOpen, templates, setTemplates }) => {
  const classes = useStyles()
  const history = useHistory()
  const { handleSubmit, control, errors, watch } = useForm()

  const onSubmit = async (data) => {
    if (data.formType === constants.consent.BLANK_FORM) {
      history.push(routes.consent.new)

      return
    }

    if (
      data.formType === constants.consent.FORM_TEMPLATE &&
      !!data?.templateId?.id
    ) {
      history.push({
        pathname: routes.consent.new,
        state: { templateId: data.templateId.id },
      })
    }
  }

  const { response, isLoading } = useFetch(
    service.dponet.consent.listTemplates,
    { perPage: 9999 },
    [],
    isEmpty(templates) && open,
  )

  useEffect(() => {
    if (!isLoading) {
      setTemplates(response?.data?.consentForms)
    }
  }, [response, isLoading, setTemplates])

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <Box>
            <Typography variant="h5">Novo formulário</Typography>
          </Box>
        </DialogTitle>
        <DialogContent dividers className={classes.dialogContent}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <Controller
                  control={control}
                  name="formType"
                  defaultValue=""
                  as={
                    <RadioGroup>
                      <FormControlLabel
                        value={constants.consent.BLANK_FORM}
                        control={<Radio />}
                        className={classes.optionBox}
                        label={
                          <>
                            <Typography
                              variant="body1"
                              className={classes.optionTitle}
                            >
                              Formulário em Branco
                            </Typography>
                            <Typography variant="caption">
                              Crie seu formulário do zero
                            </Typography>
                          </>
                        }
                      />
                      <FormControlLabel
                        value={constants.consent.FORM_TEMPLATE}
                        control={<Radio />}
                        className={classes.optionBox}
                        label={
                          <>
                            <Typography
                              variant="body1"
                              className={classes.optionTitle}
                            >
                              Modelo de Formulário
                            </Typography>
                            <Typography variant="caption">
                              Crie a partir de um modelo disponível para edição
                            </Typography>
                          </>
                        }
                      />
                    </RadioGroup>
                  }
                  mode="onChange"
                />
              </FormControl>
            </Grid>
            {watch('formType') === constants.consent.FORM_TEMPLATE && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="templateId"
                  mode="onChange"
                  onChange={([, data]) => {
                    return data
                  }}
                  as={
                    <Autocomplete
                      options={templates || []}
                      getOptionLabel={(option) => option.title || ''}
                      getOptionSelected={(option, value) =>
                        !value || option.id === value.id || value.id === 0
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errors.userCompany}
                          helperText={
                            errors?.userCompany
                              ? 'Selecione um formulário template'
                              : ''
                          }
                          label="Selecione o formulário que deseja utilizar como template"
                          variant="outlined"
                        />
                      )}
                    />
                  }
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions p={2} className={classes.buttonBox}>
          <Box pl={2} pr={1}>
            <Button type="button" variant="text" onClick={() => setOpen(false)}>
              Cancelar
            </Button>
          </Box>
          <Button variant="contained" type="submit" color="primary">
            Criar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default DialogNewForm
