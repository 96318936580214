import React from 'react'
import { Eye as EyeIcon } from 'react-feather'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Chip, IconButton } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import helpers from 'helpers'

import { routes } from 'Routes'
import useStylesMain from '../../styles'

const ComplaintTable = ({ complaints = [], isLoading = false }) => {
  const history = useHistory()
  const mainClasses = useStylesMain()

  const handleRedirectEdit = (complaintId) => {
    history.push(
      reverse(routes.complaint.show, {
        complaintId,
      }),
    )
  }

  return (
    <PerfectScrollbar
      options={{ wheelPropagation: false, useBothWheelAxes: true }}
    >
      <Table
        emptyMessage="Nenhuma denúncia encontrada"
        isLoading={isLoading}
        noWrap
      >
        <TableHead>
          <TableRow>
            <TableCell width="5%">ID</TableCell>
            <TableCell width="40%" align="center">
              Categoria
            </TableCell>
            <TableCell width="20%" align="center">
              Data da denúncia
            </TableCell>
            <TableCell width="30%" align="center">
              Status
            </TableCell>
            <TableCell width="5%" align="center">
              Ações
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {complaints.map((complaint) => (
            <TableRow key={complaint?.id}>
              <TableCell width="5%">{complaint?.id}</TableCell>
              <TableCell width="40%" align="center">
                {complaint?.category?.name}
              </TableCell>
              <TableCell width="20%" align="center">
                {helpers.formatters.date(complaint?.createdAt)}
              </TableCell>
              <TableCell width="30%" align="center">
                <Chip
                  size="small"
                  label={helpers.complaint.statusLabel(complaint?.status)}
                  className={clsx(
                    mainClasses.chip,
                    helpers.complaint.statusColor(
                      complaint?.status,
                      mainClasses,
                    ),
                  )}
                />
              </TableCell>
              <TableCell width="5%" align="center">
                <IconButton onClick={() => handleRedirectEdit(complaint?.id)}>
                  <EyeIcon size={20} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </PerfectScrollbar>
  )
}

ComplaintTable.propTypes = {
  complaints: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool.isRequired,
}

export default ComplaintTable
