import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  button: {
    maxWidth: '500px',
    width: '100%',
  },
}))

export default styles
