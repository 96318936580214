import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { reverse } from 'named-urls'
import { Alert } from '@material-ui/lab'
import { Box, Button } from '@material-ui/core'

import { Container, ContentHeader, LoadingFeedback, Page } from 'components'
import { ContentComparator } from './components'

import useFetch from 'hooks/useFetch'

import * as service from 'service'

import { routes } from 'Routes'
import theme from 'theme'

const DocumentComparator = ({ match }) => {
  const { documentId } = match?.params

  const history = useHistory()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const documentVersionId = Number(queryParams.get('documentVersionId'))

  const { response, isLoading } = useFetch(
    service.dponet.privacyPolicies.listVersions,
    {
      privacyPolicyId: documentId,
      minimal: true,
    },
    [documentId],
    !!documentId,
  )

  const documentVersions = response?.data?.documentVersions || []

  const handleBackRedirection = () => history.goBack()

  return (
    <Page title="Comparador de versão">
      <Container maxWidth={false}>
        <ContentHeader title="Comparador de versão" />
        <LoadingFeedback open={isLoading} />
        <Box display="flex" flexDirection="column" gridGap={theme.spacing(4)}>
          <Alert severity="info" variant="standard">
            Na <strong>caixa à esquerda</strong>, você verá a versão anterior do
            documento. As linhas destacadas em vermelho indicam o que foi
            modificado ou removido. Na <strong>caixa à direita</strong>, você
            verá a nova versão do documento. As linhas destacadas em verde
            representam o conteúdo adicionado ou corrigido.
          </Alert>
          {!isLoading && (
            <ContentComparator
              defaultOriginVersion={documentVersionId}
              documentId={documentId}
              documentVersionsList={documentVersions}
            />
          )}
          <Box display="flex" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="primary"
              onClick={handleBackRedirection}
            >
              Voltar
            </Button>
          </Box>
        </Box>
      </Container>
    </Page>
  )
}

export default DocumentComparator
