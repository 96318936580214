import permissions from 'constants/permissions'

const STEPS = {
  INTERMEDIATOR: {
    1: 'intermediator-step-1',
    2: 'intermediator-step-2',
    3: 'intermediator-step-3',
    4: 'intermediator-step-4',
    5: 'intermediator-step-5',
    6: 'intermediator-step-6',
    7: 'intermediator-step-7',
    8: 'intermediator-step-8',
  },
  PARTNERS: {
    1: 'partners-step-1',
    2: 'partners-step-2',
    3: 'partners-step-3',
    4: 'partners-step-4',
    5: 'partners-step-5',
  },
  INVITES: {
    1: 'invites-step-1',
    2: 'invites-step-2',
    3: 'invites-step-3',
  },
  SUGGESTIONS: {
    1: 'suggestions-step-1',
    2: 'suggestions-step-2',
  },
  PRE_AVALIATION: {
    1: 'pre-avaliations-step-1',
    2: 'pre-avaliations-step-2',
    3: 'pre-avaliations-step-3',
    4: 'pre-avaliations-step-4',
    5: 'pre-avaliations-step-5',
  },
  CREATION: {
    DEFAULT: {
      1: 'creation-default-step-1',
      2: 'creation-default-step-2',
      3: 'creation-default-step-3',
      4: 'creation-default-step-4',
      5: 'creation-default-step-5',
      6: 'creation-default-step-6',
      7: 'creation-default-step-7',
    },
    IMPORT: {
      1: 'creation-import-step-1',
      2: 'creation-import-step-2',
      3: 'creation-import-step-3',
    },
  },
}

const STEPS_OBJECT = {
  INTERMEDIATOR: [
    {
      element: '#' + STEPS.INTERMEDIATOR[1],
      popover: {
        description:
          'A gestão de parceiros é essencial para acompanhar a conformidade das empresas com as quais você compartilha dados pessoais. Nesta tela, você pode gerenciar, monitorar e garantir a segurança dessas parcerias de forma eficiente.',
      },
    },
    {
      element: '#' + STEPS.INTERMEDIATOR[2],
      popover: {
        description: 'Cadastre um novo parceiro aqui.',
      },
      tag: permissions.COMPANY_SUPPLIERS.UPDATE,
    },
    {
      element: '#' + STEPS.INTERMEDIATOR[3],
      popover: {
        description:
          'Na aba de Parceiros, você encontra todas as empresas com as quais possui vínculo e compartilha dados pessoais.',
      },
    },
    {
      element: '#' + STEPS.INTERMEDIATOR[4],
      popover: {
        description:
          'Gerencie seus convites nesta tela, onde estão centralizados todos os cadastros e convites enviados aos seus parceiros.',
      },
    },
    {
      element: '#' + STEPS.INTERMEDIATOR[5],
      popover: {
        description:
          'Em "Sugestões" encontram-se parceiros mencionados no mapeamento de processos que não foram localizados na gestão de terceiros.',
      },
    },
    {
      element: '#' + STEPS.INTERMEDIATOR[6],
      popover: {
        description:
          'Na aba de Pré-avaliação, é possível enviar questionários ao parceiro antes da contratação, permitindo verificar sua conformidade antes de firmar um contrato.',
      },
    },
    {
      element: '#' + STEPS.INTERMEDIATOR[7],
      popover: {
        description:
          'Envie o link a seguir para que seus parceiros acessem e respondam o questionário de conformidade. Lembre-se de cadastrá-los antes.',
      },
    },
    {
      element: '#' + STEPS.INTERMEDIATOR[8],
      popover: {
        description:
          'Aqui você pode visualizar informações detalhadas sobre seus parceiros, incluindo a média de conformidade de cada um e os questionários que mais foram respondidos. Isso permite acompanhar de perto o desempenho e o nível de aderência de seus parceiros aos requisitos estabelecidos.',
      },
    },
  ],
  PARTNERS: [
    {
      element: '#' + STEPS.PARTNERS[1],
      popover: {
        description:
          'Na aba de Parceiros, você encontra todas as empresas com as quais possui vínculo e compartilha dados pessoais. Aqui, é possível acompanhar a conformidade de cada parceiro, avaliar os riscos associados e monitorar a situação dos documentos e processos envolvidos, garantindo que todas as trocas de dados atendam aos requisitos de segurança e regulamentação.',
      },
    },
    {
      element: '#' + STEPS.PARTNERS[2],
      popover: {
        description:
          'Utilize os filtros por status para acompanhar seus parceiros ativos e inativos, facilitando o monitoramento e gestão de conformidade com cada um.',
      },
    },
    {
      element: '#' + STEPS.PARTNERS[3],
      popover: {
        description: 'Veja quem são seus parceiros aqui!',
      },
    },
    {
      element: '#' + STEPS.PARTNERS[4],
      popover: {
        description: 'Veja de quem você é parceiro aqui!',
      },
    },
    {
      element: '#' + STEPS.PARTNERS[5],
      popover: {
        description:
          'Você pode realizar ações como excluir, visualizar, editar e inativar parceiros. Ao editar e reativar, um novo convite será enviado ao fornecedor, e na visualização você tem acesso a informações detalhadas sobre as respostas e status desse fornecedor.',
      },
    },
  ],
  INVITES: [
    {
      element: '#' + STEPS.INVITES[1],
      popover: {
        description:
          'Gerencie seus convites nesta tela, onde estão centralizados todos os cadastros e convites enviados aos seus parceiros.',
      },
    },
    {
      element: '#' + STEPS.INVITES[2],
      popover: {
        description:
          'Na seção de convites enviados, você encontrará todos os parceiros que foram cadastrados. Aqui é possível editar, excluir, visualizar informações detalhadas e reenviar convites conforme necessário.',
      },
    },
    {
      element: '#' + STEPS.INVITES[3],
      popover: {
        description:
          'Na seção de convites recebidos, estão listados os convites que foram enviados para você. Aqui, é possível aceitar ou recusar cada convite.',
      },
    },
  ],
  SUGGESTIONS: [
    {
      element: '#' + STEPS.SUGGESTIONS[1],
      popover: {
        description:
          'Em "Sugestões" encontram-se parceiros mencionados no mapeamento de processos que não foram localizados na gestão de terceiros.',
      },
    },
    {
      element: '#' + STEPS.SUGGESTIONS[2],
      popover: {
        description: 'Nas ações, é possível aceitar ou recusar cada sugestão.',
      },
    },
  ],
  PRE_AVALIATION: [
    {
      element: '#' + STEPS.PRE_AVALIATION[1],
      popover: {
        description:
          'Na aba de Pré-avaliação, é possível enviar questionários ao parceiro antes da contratação, permitindo verificar sua conformidade antes de firmar um contrato.',
      },
    },
    {
      element: '#' + STEPS.PRE_AVALIATION[2],
      popover: {
        description: 'Inicie uma nova pré-avaliação aqui.',
      },
      tag: permissions.COMPANY_SUPPLIERS.UPDATE,
    },
    {
      element: '#' + STEPS.PRE_AVALIATION[3],
      popover: {
        description: 'Veja quem são seus pré-avaliados aqui!',
      },
    },
    {
      element: '#' + STEPS.PRE_AVALIATION[4],
      popover: {
        description: 'Veja de quem você é pré-avaliado aqui!',
      },
    },
    {
      element: '#' + STEPS.PRE_AVALIATION[5],
      popover: {
        description:
          'Caso esteja marcado como "Respondido", é possível visualizar as respostas para avaliar a conformidade do parceiro. Também é possível excluir, editar ou reenviar a pré-avaliação, conforme necessário.',
      },
    },
  ],
  CREATION: {
    DEFAULT: [
      {
        element: '#' + STEPS.CREATION.DEFAULT[1],
        popover: {
          description:
            'Aqui você pode cadastrar ou importar novos parceiros e realizar uma pré-avaliação para verificar a conformidade antes de formalizar qualquer vínculo.',
        },
      },
      {
        element: '#' + STEPS.CREATION.DEFAULT[2],
        popover: {
          description:
            'As informações obrigatórias são os dados essenciais para o cadastro de um novo parceiro. É possível realizar um cadastro simplificado ou, caso seja necessário, cadastrar um parceiro internacional.',
        },
      },
      {
        element: '#' + STEPS.CREATION.DEFAULT[3],
        popover: {
          description:
            'As informações opcionais, como o próprio nome indica, não são obrigatórias para o cadastro do parceiro. No entanto, é altamente recomendável preenchê-las, pois elas são essenciais para o gerenciamento do relacionamento. Exemplos incluem a data de início do contrato com o parceiro, a data de renovação, uma descrição do parceiro e o segmento de atuação.',
        },
      },
      {
        element: '#' + STEPS.CREATION.DEFAULT[4],
        popover: {
          description:
            'Defina a função dele no tratamento de dados, se ele apenas opera os dados ou os controla.',
        },
      },
      {
        element: '#' + STEPS.CREATION.DEFAULT[5],
        popover: {
          description:
            'Estabeleça um prazo para que o parceiro seja notificado e revise ou atualize as respostas dos questionários. Esse período garante que as informações estejam sempre atualizadas e em conformidade.',
        },
      },
      {
        element: '#' + STEPS.CREATION.DEFAULT[6],
        popover: {
          description:
            'Determine o nível de risco associado a este parceiro e anexe os documentos relevantes para a análise.',
        },
      },
      {
        element: '#' + STEPS.CREATION.DEFAULT[7],
        popover: {
          description:
            'Selecione os questionários que os parceiros devem receber, lembrando que os Requisitos Fundamentais são obrigatórios.',
        },
      },
    ],
    IMPORT: [
      {
        element: '#' + STEPS.CREATION.IMPORT[1],
        popover: {
          description:
            'Aqui você pode cadastrar ou importar novos parceiros e realizar uma pré-avaliação para verificar a conformidade antes de formalizar qualquer vínculo.',
        },
      },
      {
        element: '#' + STEPS.CREATION.IMPORT[2],
        popover: {
          description:
            'As informações obrigatórias são os dados essenciais para o cadastro de um novo parceiro. É possível realizar um cadastro simplificado ou, caso seja necessário, cadastrar um parceiro internacional. Não se esqueça de utilizar a nossa planilha modelo, a fim de garantir que todos os dados sejam inseridos corretamente.',
        },
      },
      {
        element: '#' + STEPS.CREATION.IMPORT[3],
        popover: {
          description:
            'Selecione os questionários que os parceiros devem receber, lembrando que os Requisitos Fundamentais são obrigatórios.',
        },
      },
    ],
  },
}

export default {
  STEPS,
  STEPS_OBJECT,
}
