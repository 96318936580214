import moment from 'moment'

const formatAnswerField = (value) => {
  const regexDate = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/
  // Regex para datas no formato ISO 8601

  const match = value.toString().match(regexDate)

  if (match) {
    return moment(value).format('DD/MM/YYYY [às] HH:mm')
  }

  if (value === 'yes') return 'Sim'
  if (value === 'no') return 'Não'

  return value
}

export default formatAnswerField
