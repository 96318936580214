import React, { useEffect, useState } from 'react'
import { saveAs } from 'file-saver'
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from '@material-ui/core'

import { Download as DownloadIcon } from 'react-feather'

import useAuth from 'hooks/useAuth'

import { getBaseURL } from 'service/env'
import * as service from 'service'
import { LoadingBox } from 'components'

const SocialMedia = () => {
  const [stamp, setStamp] = useState()
  const [imageSrc, setImageSrc] = useState()
  const [loading, setLoading] = useState(false)

  const auth = useAuth()
  const baseURL = getBaseURL('dponet')

  useEffect(() => {
    if (!stamp && !loading) {
      getImpressionStamp()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const generateNewImpressionStamp = async () => {
    const linkStamp = await service.dponet.companies.generateStamp(
      auth.company.id,
    )

    const stampImageResponse = await service.dponet.companyTrails.getImage(
      linkStamp?.data,
    )

    const image = `${baseURL}/${linkStamp?.data}?v=${Math.random()}`

    setImageSrc(image)

    return setStamp(stampImageResponse.data)
  }

  const getImpressionStamp = async () => {
    setLoading(true)
    try {
      const stampImageResponse = await service.dponet.companyTrails.getImage(
        auth.company.impressionStamp,
      )
      const image = `${baseURL}/${
        auth.company.impressionStamp
      }?v=${Math.random()}`

      setImageSrc(image)
      setStamp(stampImageResponse.data)
    } catch (error) {
      if (error.response.status === 404) {
        await generateNewImpressionStamp()
      }
    } finally {
      setLoading(false)
    }
  }

  const onButtonClick = async () => {
    const currentYear = new Date().getFullYear()
    saveAs(stamp, `selo-de-impressao-${currentYear}.png`)
  }

  const isActive = useMediaQuery('(max-width: 1381px)')
  const isActiveMd1 = useMediaQuery('(max-width: 1160px)')
  const isActiveMd2 = useMediaQuery('(max-width: 1013px)')
  const isActiveLg = useMediaQuery('(max-width: 1540px)')

  const flagResponsiveLg = () => {
    return isActive ? 12 : isActiveLg ? 6 : 7
  }

  const flagResponsiveMd = () => {
    return isActiveMd1 ? (isActiveMd2 ? 5 : 5) : 6
  }

  return (
    <Card>
      <Box mt={2}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box px={2} mb={2}>
              <Typography variant="h6" color="textPrimary">
                Publique em seus departamentos
              </Typography>
            </Box>
            <Divider />
          </Grid>
          <Box px={2} mt={2}>
            {loading ? (
              <LoadingBox width="480px" />
            ) : (
              <img
                alt="selo-de-impressão"
                disableCache
                src={imageSrc}
                width="480px"
              />
            )}
          </Box>
          <Grid
            item
            xl={7}
            lg={flagResponsiveLg()}
            md={flagResponsiveMd()}
            sm={12}
            xs={12}
          >
            <Box px={2} mt={2}>
              <Typography variant="body1" color="textPrimary" align="justify">
                Imprima o arquivo a seguir e exiba-o em suas recepções e
                departamentos, permitindo que qualquer pessoa que circule possa
                acessar seu Portal da Privacidade.
              </Typography>
            </Box>
            <Box px={2} mt={1}>
              <Typography variant="body1" color="textPrimary" align="justify">
                Ao escanear o QR Code, o interessado é direcionado ao Portal da
                Privacidade, onde encontrará informações como: o DPO
                (Encarregado de Proteção de Dados) nomeado em sua organização; o
                canal de atendimento aos titulares de dados; o canal de
                atendimento à ANPD; além de acesso aos termos, avisos e
                políticas que foram cadastrados na plataforma.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box mt={2}>
              <Divider />
            </Box>
            <Box px={2} my={2} display="flex" justifyContent="flex-end">
              <Box>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={onButtonClick}
                  startIcon={<DownloadIcon width="18" />}
                  size="large"
                  disabled={loading}
                >
                  DOWNLOAD
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

export default SocialMedia
