import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { HelpCircle as HelpCircleIcon, Info as InfoIcon } from 'react-feather'
import { Box, Button, Tab, Tabs, Tooltip } from '@material-ui/core'

import {
  Container,
  ContentHeader,
  DriveTour,
  LoadingFeedback,
  Page,
} from 'components'
import { DocumentForm, AcceptedForm, ContentLossModal } from './components'

import helpers from 'helpers'
import validates from 'helpers/validates'

import useFetch from 'hooks/useFetch'
import usePermissions from 'hooks/usePermissions'

import theme from 'theme'
import { routes } from 'Routes'
import * as service from 'service'
import constants from 'constants/index'

const DocumentsEdit = ({ match }) => {
  const { documentId } = match?.params

  const { DOCUMENTS_CATEGORY_ID, ACCEPTED_DOCUMENTS_CATEGORY_ID } =
    constants.documents

  const { CONSENTS } = constants.permissions

  const {
    MANAGEMENT_STEP_1,
    MANAGEMENT_ACCEPTED_STEP_1,
    CHOOSE_MANAGEMENT_STEPS_OBJECT,
  } = constants.tours.documents

  const [currentTab, setCurrentTab] = useState(DOCUMENTS_CATEGORY_ID)
  const [hasPermission, setHasPermission] = useState(false)
  const [clickedTab, setClickedTab] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [openTour, setOpenTour] = useState(false)
  const [loading, setLoading] = useState(false)

  const formRef = useRef(null)
  const defaultValuesRef = useRef(null)
  const permissions = usePermissions()

  const history = useHistory()

  const { response, isLoading, refresh } = useFetch(
    service.dponet.privacyPolicies.get,
    {
      privacyPolicyId: documentId,
    },
    [documentId],
  )

  const privacyPolicy = response?.data?.privacyPolicy

  const consentFormId = privacyPolicy?.consentFormId

  const {
    response: consentFormResponse,
    isLoading: consentFormIsLoading,
    refresh: consentFormRefresh,
  } = useFetch(
    service.dponet.consent.get,
    { consentFormId },
    [consentFormId, hasPermission],
    !!consentFormId && hasPermission,
  )

  const consentForm = consentFormResponse?.data?.consentForm

  const hasCollect = privacyPolicy?.collectAccepted

  const isLoadingResponses = isLoading || consentFormIsLoading

  const disabledTab = !hasCollect || !hasPermission

  const CHOOSE_TAB_CONTENT = {
    [DOCUMENTS_CATEGORY_ID]: DocumentForm,
    [ACCEPTED_DOCUMENTS_CATEGORY_ID]: AcceptedForm,
  }

  const TabContent = CHOOSE_TAB_CONTENT[currentTab]

  const filteredSteps = CHOOSE_MANAGEMENT_STEPS_OBJECT[currentTab] || []

  const tutorialButtonId =
    currentTab === DOCUMENTS_CATEGORY_ID
      ? MANAGEMENT_STEP_1
      : MANAGEMENT_ACCEPTED_STEP_1

  const handleOpenTour = () => {
    const where = 'Documentos'
    const params = {
      action: `tour-gerenciamento-${
        currentTab === DOCUMENTS_CATEGORY_ID ? 'documentos' : 'aceites'
      }`,
    }

    helpers.mixpanel.track(where, 'Tour', params)
    setOpenTour(true)
  }

  const handleCloseModal = () => setOpenModal(false)

  const handleTabChange = ({ tab }) => {
    setCurrentTab(tab || clickedTab)
    handleCloseModal()
  }

  const handleTabChangeValidation = (_, tab) => {
    setClickedTab(tab)

    const isModified = validates.form.isAnyFieldModified(
      defaultValuesRef.current.getDefaultValues(),
      formRef.current.getFormValues(),
    )

    if (isModified) setOpenModal(true)
    else handleTabChange({ tab })
  }

  const handleBackRedirection = () => history.push(routes.documents.all)

  useEffect(() => {
    if (
      permissions.permitted(CONSENTS.LIST) &&
      permissions.permitted(CONSENTS.CREATE) &&
      permissions.permitted(CONSENTS.SHOW)
    )
      setHasPermission(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page title="Editar documento">
      <Container maxWidth={false}>
        <LoadingFeedback open={loading || isLoadingResponses} />
        <ContentHeader title="Editar documento">
          <Box>
            <Tooltip title="Iniciar o tour guiado">
              <Button
                variant="contained"
                color="primary"
                startIcon={<HelpCircleIcon size={20} />}
                onClick={handleOpenTour}
                fullWidth
                id={tutorialButtonId}
              >
                Tutorial
              </Button>
            </Tooltip>
          </Box>
        </ContentHeader>
        <Box display="flex">
          <Tabs
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="primary"
            indicatorColor="primary"
            onChange={handleTabChangeValidation}
          >
            <Tab value={DOCUMENTS_CATEGORY_ID} label="Documentos" />
            <Tab
              value={ACCEPTED_DOCUMENTS_CATEGORY_ID}
              label="Aceites"
              disabled={disabledTab}
            />
          </Tabs>
          {disabledTab && (
            <Tooltip
              title={
                !hasPermission
                  ? 'Você não possui permissões suficientes para editar e visualizar os consentimentos. Caso precise de acesso, entre em contato com um superior da empresa.'
                  : 'Você não pode modificar os aceites se não estiver coletando!'
              }
              placement="right"
            >
              <Box>
                <InfoIcon size={18} color={theme.palette.custom.disabled} />
              </Box>
            </Tooltip>
          )}
        </Box>
        {!isLoadingResponses && (
          <TabContent
            defaultValuesRef={defaultValuesRef}
            currentDocument={privacyPolicy}
            consentForm={consentForm}
            documentId={documentId}
            formRef={formRef}
            refresh={refresh}
            consentFormRefresh={consentFormRefresh}
            handleBackRedirection={handleBackRedirection}
            setLoading={setLoading}
            type="edit"
          />
        )}
        <ContentLossModal
          open={openModal}
          setOpen={setOpenModal}
          handleTabChange={handleTabChange}
          handleCloseModal={handleCloseModal}
        />
      </Container>
      <DriveTour
        stepsMatrix={filteredSteps}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default DocumentsEdit
