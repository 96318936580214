import React, { useState } from 'react'
import { Button } from '@material-ui/core'

import { LoadingFeedback, MenuButton } from 'components'

import useSnackbar from 'hooks/useSnackbar'
import usePermissions from 'hooks/usePermissions'

import helpers from 'helpers'
import * as service from 'service'
import constants from 'constants/index'
import permissions from 'constants/permissions'

const Actions = ({ supplierInvite, refresh = () => {} }) => {
  const [loading, setLoading] = useState(false)

  const snackbar = useSnackbar()
  const { permitted } = usePermissions()

  const hasCompanySuppliersUpdatePermission = permitted(
    permissions.COMPANY_SUPPLIERS.UPDATE,
  )

  const handleAccept = async () => {
    try {
      setLoading(true)
      await approveOrRefuseSubmit(constants.companySuppliers.APPROVED_STATUS)

      openSuccessSnackbar('Convite aceito com sucesso')
      refresh()
    } catch (error) {
      openErrorSnackbar(error, 'Ocorreu um erro ao aceitar o convite')
    } finally {
      setLoading(false)
    }
  }

  const handleRefuse = async () => {
    try {
      setLoading(true)
      await approveOrRefuseSubmit(constants.companySuppliers.REFUSED_STATUS)

      openSuccessSnackbar('Convite recusado com sucesso')
      refresh()
    } catch (error) {
      openErrorSnackbar(error, 'Ocorreu um erro ao recusar o convite')
    } finally {
      setLoading(false)
    }
  }

  const approveOrRefuseSubmit = async (status) => {
    await service.dponet.suppliers.approveOrRefuse({
      supplierId: supplierInvite?.companySupplier?.id,
      status,
    })
  }

  const openErrorSnackbar = (error, message) => {
    snackbar.open({
      message:
        helpers.formatters.errorMessage(error.response.data.error) || message,
      variant: 'error',
    })
  }

  const openSuccessSnackbar = (message) => {
    snackbar.open({ message, variant: 'success' })
  }

  return (
    <MenuButton>
      <LoadingFeedback open={loading} />

      {hasCompanySuppliersUpdatePermission ? (
        <>
          <Button color="secondary" fullWidth onClick={handleAccept}>
            Aceitar
          </Button>

          {supplierInvite.status === 'pending' && (
            <Button color="secondary" fullWidth onClick={handleRefuse}>
              Recusar
            </Button>
          )}
        </>
      ) : (
        <Button color="secondary" fullWidth disabled>
          NENHUMA AÇÃO DISPONÍVEL
        </Button>
      )}
    </MenuButton>
  )
}

export default Actions
