import React, { useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import JoditEditor from 'jodit-react'
import { Box, colors, FormHelperText } from '@material-ui/core'

import constants from 'constants/index'
import theme from 'theme'

const TextEditor = ({
  configRest,
  disabled = false,
  error = false,
  helperText,
  onChange,
  value = '',
  ...rest
}) => {
  const editor = useRef(null)

  const config = useMemo(
    () => ({
      language: 'pt_br',
      spellcheck: true,
      showXPathInStatusbar: false,
      buttons: constants.jodit.EDITOR_OPTIONS,
      askBeforePasteHTML: false,
      style: {
        fontFamily: 'Roboto',
        ...(!!error && {
          border: `1px solid ${theme.palette.error.main}`,
          color: theme.palette.error.main,
        }),
        ...(disabled && {
          color: colors.grey['A200'],
        }),
      },
      height: 300,
      zIndex: 1,
      ...(disabled && { readonly: true }),
      ...configRest,
    }),
    [error, configRest, disabled],
  )

  return (
    <Box>
      <JoditEditor
        config={config}
        ref={editor}
        onBlur={(value) => onChange(value)}
        value={value}
        {...rest}
      />
      {!!error && (
        <FormHelperText error={!!error}>{<>{helperText}</>}</FormHelperText>
      )}
    </Box>
  )
}

TextEditor.propTypes = {
  configRest: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.bool.isRequired,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default TextEditor
