import { colors, makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  root: {
    color: 'white',
    textTransform: 'uppercase',
  },
  suggested: {
    backgroundColor: theme.palette.trailWarning,
  },
  approved: {
    backgroundColor: colors.green[500],
  },
  disapproved: {
    backgroundColor: colors.red[500],
  },
}))

export default styles
