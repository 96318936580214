import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  gapCategories: {
    gap: theme.spacing(1.5),
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  buttonAddCategory: {
    padding: theme.spacing(1.5),
    maxHeight: '53px',
  },
  boxTrashIcon: {
    margin: '10px',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
    },
  },
  trashIcon: {
    cursor: 'pointer',
  },
  boxSubCategories: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  form: {
    width: '100%',
  },
  subConfigurationId: {
    display: 'none',
  },
}))
