import { colors } from '@material-ui/core'
import {
  CheckCircle as CheckCircleIcon,
  AlertTriangle as AlertFailedIcon,
  Loader as LoaderIcon,
} from 'react-feather'

import theme from 'theme'
import language from './language'

// BANNER CUSTOMIZATION CARD
const COLOR_CUSTOMIZATION_CARD_ID = 1
const LOGO_CUSTOMIZATION_CARD_ID = 2
const DESCRIPTION_CUSTOMIZATION_CARD_ID = 3
const POSITION_CUSTOMIZATION_CARD_ID = 4

const ACTIVE_STATUS_ID = 'active'
const INCOMPLETE_STATUS_ID = 'unconfigured'
const INACTIVE_STATUS_ID = 'inactive'

// STATUS DE SCANNER LOG
const COMPLETED_SCANNER_STATUS_ID = 'succeded'
const INITIATED_SCANNER_STATUS_ID = 'initiated'
const FAILED_SCANNER_STATUS_ID = 'failed'

// BANNER CUSTOMIZATION POSITION
const BANNER_LEFT_POSITION = 'left'
const BANNER_RIGHT_POSITION = 'right'
const BANNER_CENTER_POSITION = 'center'
const BANNER_FIXED_POSITION = 'fixed'

const DEFAULT_DESCRIPTION_BR =
  'Para melhorar a sua experiência na plataforma e prover serviços personalizados, utilizamos cookies. Ao aceitar, você terá acesso a todas as funcionalidades do site. Se clicar em "Rejeitar", os cookies que não forem estritamente necessários serão desativados. Para escolher quais quer autorizar, clique em "Gerenciar cookies".'

const DEFAULT_DESCRIPTION_EN =
  'To enhance your experience on the platform and provide personalized services, we use cookies. By accepting, you will have access to all site features. If you click "Reject all", non-essential cookies will be disabled. To choose which ones to authorize, click "Manage cookies".'

const DEFAULT_DESCRIPTION_ES =
  'Para mejorar su experiencia en la plataforma y brindar servicios personalizados, utilizamos cookies. Al aceptar, tendrá acceso a todas las funcionalidades del sitio. Si hace clic en "Rechazar todos", las cookies que no sean estrictamente necesarias serán desactivadas. Para elegir cuáles autorizar, haga clic en "Gestionar cookies".'

const DEFAULT_DESCRIPTIONS = [
  {
    description: DEFAULT_DESCRIPTION_BR,
    language: {
      code: language.LANGUAGE_CODES.BR,
    },
  },
  {
    description: DEFAULT_DESCRIPTION_EN,
    language: {
      code: language.LANGUAGE_CODES.US,
    },
  },
  {
    description: DEFAULT_DESCRIPTION_ES,
    language: {
      code: language.LANGUAGE_CODES.ES,
    },
  },
]

const SUPPORTED_IMAGE_FILE_SCHEMA_TYPES = ['image/jpeg', 'image/png']

const SUPPORTED_IMAGE_FILE_INPUT_TYPES = '.jpg,.jpeg,.png,'

const LINK_DOCUMENTATION_TAG_MANAGER =
  'https://documentacao-dpo.notion.site/Configurando-o-banner-de-cookies-via-Google-Tag-Manager-67e524030bbd4263b9d6b128e0e56103'

const LINK_DOCUMENTATION_HIDE_ON_ACCEPT =
  'https://documentacao-dpo.notion.site/Configurando-o-acionamento-manual-do-aviso-de-consentimento-banner-173cf66ae9fc8040b149d9e1fe54bb18'

const CUSTOMIZATION_CARD_TABS = [
  { id: COLOR_CUSTOMIZATION_CARD_ID, tabName: 'Cor' },
  { id: LOGO_CUSTOMIZATION_CARD_ID, tabName: 'Logo' },
  { id: DESCRIPTION_CUSTOMIZATION_CARD_ID, tabName: 'Descrição' },
  { id: POSITION_CUSTOMIZATION_CARD_ID, tabName: 'Posição' },
]

const STATUS_TRANSLATION = {
  [ACTIVE_STATUS_ID]: 'Ativo',
  [INCOMPLETE_STATUS_ID]: 'Incompleto',
  [INACTIVE_STATUS_ID]: 'Inativo',
}

const STATUS_CHIP_COLOR = {
  [ACTIVE_STATUS_ID]: colors.green[100],
  [INCOMPLETE_STATUS_ID]: colors.blue[100],
  [INACTIVE_STATUS_ID]: colors.blueGrey[100],
}

const STATUS_CHIP_FONT_COLOR = {
  [ACTIVE_STATUS_ID]: colors.green[800],
  [INCOMPLETE_STATUS_ID]: colors.blue[800],
  [INACTIVE_STATUS_ID]: colors.blueGrey[800],
}

const SCANNER_STATUS_TRANSLATION = {
  [COMPLETED_SCANNER_STATUS_ID]: 'Completo',
  [FAILED_SCANNER_STATUS_ID]: 'Falha',
  [INITIATED_SCANNER_STATUS_ID]: 'Escaneando',
}

const SCANNER_STATUS_COLOR = {
  [COMPLETED_SCANNER_STATUS_ID]: theme.palette.tertiary.main,
  [FAILED_SCANNER_STATUS_ID]: theme.palette.error.main,
  [INITIATED_SCANNER_STATUS_ID]: theme.palette.primary.main,
}

const SCANNER_STATUS_ICON = {
  [COMPLETED_SCANNER_STATUS_ID]: CheckCircleIcon,
  [FAILED_SCANNER_STATUS_ID]: AlertFailedIcon,
  [INITIATED_SCANNER_STATUS_ID]: LoaderIcon,
}

const COOKIE_CREATION_CARD_ITEMS = [
  {
    label: 'Nome',
    controlName: 'name',
  },
  {
    label: 'URL',
    controlName: 'url',
  },
]

const NEW_BANNER_MODAL_CONTROL_ITEMS = [
  {
    label: 'Nome',
    controlName: 'name',
  },
  {
    label: 'URL',
    controlName: 'url',
  },
]

const COOKIES_BANNERS_MAIN_DRIVER_STEP = 'cookies-banners-main-driver-step'

const COOKIES_BANNERS_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + COOKIES_BANNERS_MAIN_DRIVER_STEP,
    popover: {
      description:
        "Local onde está a listagem dos sites que possuem nosso banner de cookies e onde configurá-lo. Primeiro, clique no botão 'Adicionar Site'. Após o cadastro do site na plataforma, clique nos três pontos e selecione 'Configurar'.",
    },
  },
]

const COOKIES_BANNER_SETTING_DRIVER_STEP_1 =
  'cookies-banner-setting-driver-step-1'
const COOKIES_BANNER_SETTING_DRIVER_STEP_2 =
  'cookies-banner-setting-driver-step-2'
const COOKIES_BANNER_SETTING_DRIVER_STEP_3 =
  'cookies-banner-setting-driver-step-3'
const COOKIES_BANNER_SETTING_DRIVER_STEP_4 =
  'cookies-banner-setting-driver-step-4'
const COOKIES_BANNER_SETTING_DRIVER_STEP_5 =
  'cookies-banner-setting-driver-step-5'

const COOKIES_BANNER_SETTING_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + COOKIES_BANNER_SETTING_DRIVER_STEP_1,
    popover: {
      description:
        'Para a configuração do Banner de cookies, é necessário passar por 4 etapas.',
    },
  },
  {
    element: '#' + COOKIES_BANNER_SETTING_DRIVER_STEP_2,
    popover: {
      description:
        'A primeira etapa é realizada ao adicionar o site para o banner de cookies, onde você deve inserir o nome do banner e a URL do site. Lembre-se de usar um nome específico para facilitar a identificação do site.',
    },
  },
  {
    element: '#' + COOKIES_BANNER_SETTING_DRIVER_STEP_3,
    popover: {
      description:
        'Na etapa de personalização, é possível alterar a cor, a logo e a descrição do banner de cookies.',
    },
  },
  {
    element: '#' + COOKIES_BANNER_SETTING_DRIVER_STEP_4,
    popover: {
      description:
        'Esta é a etapa mais importante, onde você configura o banner no seu site. Você pode instalar o banner via HTML ou Tag Manager, mas é ESSENCIAL contar com um especialista para a instalação correta.',
    },
  },
  {
    element: '#' + COOKIES_BANNER_SETTING_DRIVER_STEP_5,
    popover: {
      description:
        'Após instalar o banner no seu site na etapa anterior, escaneie para uma análise. Aguarde o feedback, que indicará qualquer problema ou confirmará que tudo está correto.',
    },
  },
]

const CUSTOMIZATION_POSITION_RADIO_GROUP_ITEMS = [
  { value: BANNER_LEFT_POSITION, label: 'Canto inferior esquerdo' },
  { value: BANNER_RIGHT_POSITION, label: 'Canto inferior direito' },
  { value: BANNER_CENTER_POSITION, label: 'Inferior central' },
  { value: BANNER_FIXED_POSITION, label: 'Inferior completo' },
]

const banners = {
  BANNER_CENTER_POSITION,
  BANNER_FIXED_POSITION,
  BANNER_LEFT_POSITION,
  BANNER_RIGHT_POSITION,
  COLOR_CUSTOMIZATION_CARD_ID,
  COMPLETED_SCANNER_STATUS_ID,
  COOKIE_CREATION_CARD_ITEMS,
  COOKIES_BANNER_SETTING_DRIVER_STEP_1,
  COOKIES_BANNER_SETTING_DRIVER_STEP_2,
  COOKIES_BANNER_SETTING_DRIVER_STEP_3,
  COOKIES_BANNER_SETTING_DRIVER_STEP_4,
  COOKIES_BANNER_SETTING_DRIVER_STEP_5,
  COOKIES_BANNER_SETTING_DRIVER_STEPS_OBJECT,
  COOKIES_BANNERS_DRIVER_STEPS_OBJECT,
  COOKIES_BANNERS_MAIN_DRIVER_STEP,
  CUSTOMIZATION_CARD_TABS,
  CUSTOMIZATION_POSITION_RADIO_GROUP_ITEMS,
  DEFAULT_DESCRIPTIONS,
  DESCRIPTION_CUSTOMIZATION_CARD_ID,
  FAILED_SCANNER_STATUS_ID,
  INCOMPLETE_STATUS_ID,
  INITIATED_SCANNER_STATUS_ID,
  LINK_DOCUMENTATION_HIDE_ON_ACCEPT,
  LINK_DOCUMENTATION_TAG_MANAGER,
  LOGO_CUSTOMIZATION_CARD_ID,
  NEW_BANNER_MODAL_CONTROL_ITEMS,
  POSITION_CUSTOMIZATION_CARD_ID,
  SCANNER_STATUS_COLOR,
  SCANNER_STATUS_ICON,
  SCANNER_STATUS_TRANSLATION,
  STATUS_CHIP_COLOR,
  STATUS_CHIP_FONT_COLOR,
  STATUS_TRANSLATION,
  SUPPORTED_IMAGE_FILE_INPUT_TYPES,
  SUPPORTED_IMAGE_FILE_SCHEMA_TYPES,
}

export default banners
