import constants from 'constants/index'
import helpers from 'helpers'

const create = (
  registerType,
  requiredFormData,
  optionalFormData,
  selectedQuestionnaires,
) => {
  return {
    companySupplier: {
      name: requiredFormData.name,
      document: requiredFormData.document,
      simplified: requiredFormData.simplified,
      international: requiredFormData.international,
      email: requiredFormData.email,
      dateToSendEmails: helpers.thirdPartyManegementPartners.formatDate(
        requiredFormData.date,
      ),
      preAvaliation:
        registerType ===
        constants.thirdPartyManagements.REGISTER_TYPES.PRE_AVALIATION,
      details: {
        hireDate: helpers.thirdPartyManegementPartners.formatDate(
          optionalFormData.hireDate,
        ),
        renewDate: helpers.thirdPartyManegementPartners.formatDate(
          optionalFormData.renewDate,
        ),
        description: optionalFormData.description,
        segmentId: optionalFormData.segment?.id,
        treatmentAgentType: optionalFormData.treatmentAgent,
        enableAudit: optionalFormData.supplierAudit,
        auditFrequency: optionalFormData.supplierAuditFrequency,
        auditFrequencyType: optionalFormData.supplierAuditFrequencyType,
        riskLevel: helpers.thirdPartyManegementPartners.getEnumRiskByLevel(
          optionalFormData.supplierRisk,
        ),
        attachment: optionalFormData.document,
      },
      questionnaires: selectedQuestionnaires,
    },
  }
}

const update = (requiredFormData, optionalFormData, selectedQuestionnaires) => {
  return {
    companySupplier: {
      name: requiredFormData.name,
      email: requiredFormData.email,
      dateToSendEmails: helpers.thirdPartyManegementPartners.formatDate(
        requiredFormData.date,
      ),
      details: {
        hireDate: helpers.thirdPartyManegementPartners.formatDate(
          optionalFormData.hireDate,
        ),
        renewDate: helpers.thirdPartyManegementPartners.formatDate(
          optionalFormData.renewDate,
        ),
        description: optionalFormData.description,
        segmentId: optionalFormData.segment?.id,
        treatmentAgentType: optionalFormData.treatmentAgent,
        enableAudit: optionalFormData.supplierAudit,
        auditFrequency: optionalFormData.supplierAuditFrequency,
        auditFrequencyType: optionalFormData.supplierAuditFrequencyType,
        riskLevel: helpers.thirdPartyManegementPartners.getEnumRiskByLevel(
          optionalFormData.supplierRisk,
        ),
      },
      questionnaires: selectedQuestionnaires,
    },
  }
}

export default { create, update }
