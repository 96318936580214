import * as yup from 'yup'

import helpers from 'helpers'

import constants from 'constants/index'

const allowedTypes = constants.cookies.banner.SUPPORTED_IMAGE_FILE_INPUT_TYPES

const schema = yup.object({
  name: yup.string().min(4).max(150).required(),
  url: yup.string().min(4).max(256).required(),
  cookies: yup.array().of(
    yup.object().shape({
      id: yup.number(),
      platform: yup.string().required(),
      categoryId: yup
        .number()
        .integer()
        .is(
          constants.cookies.category.VALID_CATEGORIES,
          'Escolha uma categoria conhecida para esse cookie',
        )
        .required(),
    }),
  ),
  primaryColor: yup.string().required().min(4).max(7),
  secondaryColor: yup.string().required().min(4).max(7),
  position: yup.string().required(),
  hideOnAccept: yup.boolean(),
  logo: yup
    .mixed()
    .nullable()
    .test('fileSize', 'A imagem deve ter no máximo 5 MB', (value) =>
      helpers.validates.file.size('MB', 5, value),
    )
    .test(
      'fileType',
      `Somente arquivos ${helpers.formatters.file.allowedTypes(
        allowedTypes,
      )} são permitidos`,
      (value) =>
        helpers.validates.file.type(
          constants.cookies.banner.SUPPORTED_IMAGE_FILE_SCHEMA_TYPES,
          value,
        ),
    ),
})

export default schema
