import React from 'react'
import { Box, Link, Tooltip, Typography } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from './styles'

const InfoItem = ({ label, value, Icon, overflow = true, file = false }) => {
  const classes = useStyles()

  const handleClickLink = (url) => {
    window.open(url, '_blank')
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.iconBox}>
        <Icon size={20} className={classes.icon} />
      </Box>

      <Box className={classes.infoBox}>
        <Tooltip title={label} placement="top">
          <Typography
            variant="body1"
            className={clsx({
              [classes.typographyBreakLine]: !overflow,
              [classes.typographyOverflow]: overflow,
            })}
          >
            {label}
          </Typography>
        </Tooltip>

        {file ? (
          <Tooltip title={value?.name || 'Não informado'} placement="top">
            <Typography
              variant="body2"
              color="secondary"
              className={clsx({
                [classes.typographyBreakLine]: !overflow,
                [classes.typographyOverflow]: overflow,
              })}
            >
              {value?.url ? (
                <Link
                  component="button"
                  onClick={() => handleClickLink(value?.url)}
                >
                  {value?.name || 'Não informado'}
                </Link>
              ) : (
                'Não informado'
              )}
            </Typography>
          </Tooltip>
        ) : (
          <Tooltip title={value || 'Não informado'} placement="top">
            <Typography
              variant="body2"
              color="secondary"
              className={clsx({
                [classes.typographyBreakLine]: !overflow,
                [classes.typographyOverflow]: overflow,
              })}
            >
              {value || 'Não informado'}
            </Typography>
          </Tooltip>
        )}
      </Box>
    </Box>
  )
}

export default InfoItem
