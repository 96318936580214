import React, { useEffect, useState } from 'react'
import { Box, Paper, TextField } from '@material-ui/core'
import { X as XIcon } from 'react-feather'
import { Controller, useFormContext } from 'react-hook-form'
import { Autocomplete } from '@material-ui/lab'
import { isEmpty, size } from 'lodash'

import { Permitted, Select } from 'components'
import { ComplaintSubCategories } from './components'

import useStyles from './styles'
import constants from 'constants/index'

const Configuration = ({
  fields,
  complaintCategories = [],
  responsibles = [],
  index,
  remove,
  configurationField,
  setSubConfigurationsDelete,
  disabled,
}) => {
  const [subCategorySelectObj, setSubCategorySelectObj] = useState([])
  const [isCategoryChanged, setIsCategoryChanged] = useState(false)

  const classes = useStyles()

  const { control, watch, errors, setValue } = useFormContext()

  let categoryId = watch(`subConfigurations[${index}].categoryId`)

  const findSubCategories = () => {
    const complaintCategory = complaintCategories.find(
      (complaintCategory) => complaintCategory.id === categoryId,
    )
    return setSubCategorySelectObj(complaintCategory?.subCategories)
  }

  useEffect(() => {
    if (isCategoryChanged) {
      setValue(`subConfigurations[${index}].subCategories`, [])
      setIsCategoryChanged(false)
    }
    findSubCategories()
    // eslint-disable-next-line
  }, [categoryId])

  const removeSubConfiguration = () => {
    const subConfigurationId = watch(
      `subConfigurations[${index}].subConfigurationId`,
    )
    if (!!subConfigurationId) {
      setSubConfigurationsDelete((prevSubConfigurations) => [
        ...prevSubConfigurations,
        subConfigurationId,
      ])
    }

    remove(index)
  }

  const onChangeCategory = (e) => {
    setIsCategoryChanged(true)

    return e[1].props.value
  }

  return (
    <Paper className={classes.paper}>
      <Controller
        control={control}
        name={`subConfigurations[${index}].subConfigurationId`}
        as={<TextField className={classes.subConfigurationId} />}
      />
      <Box
        display="flex"
        flexDirection="column"
        padding={2}
        className={classes.gapCategories}
        width="100%"
      >
        <Box display="flex" className={classes.gapCategories}>
          <Controller
            name={`subConfigurations[${index}].categoryId`}
            control={control}
            defaultValue={categoryId || ''}
            onChange={(e) => onChangeCategory(e)}
            as={
              <Select
                fullWidth
                items={complaintCategories}
                label="Categoria"
                disabled={disabled}
                error={!!errors?.subConfigurations?.[index]?.categoryId}
                helperText={
                  errors?.subConfigurations?.[index]?.categoryId?.message
                }
              />
            }
            mode="onChange"
          />
          <Controller
            name={`subConfigurations[${index}].responsibles`}
            onChange={([, data]) => {
              return data
            }}
            as={
              <Autocomplete
                fullWidth
                multiple
                disabled={disabled}
                options={responsibles || []}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) =>
                  option.id === value.id || value.id === 0
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Responsáveis"
                    variant="outlined"
                    error={!!errors?.subConfigurations?.[index]?.responsibles}
                    helperText={
                      errors?.subConfigurations?.[index]?.responsibles?.message
                    }
                  />
                )}
              />
            }
            control={control}
            mode="onChange"
          />
        </Box>
        <Box display="flex" flexWrap="wrap">
          {!!categoryId && !isEmpty(subCategorySelectObj) && (
            <ComplaintSubCategories
              key={`sub-categories-${categoryId}`}
              indexCategory={index}
              subCategorySelectObj={subCategorySelectObj}
              name={`subConfigurations[${index}].subCategories`}
              disabled={disabled}
            />
          )}
        </Box>
      </Box>
      <Permitted
        someTags={[
          constants.permissions.COMPLAINTS.MANAGE_CONFIGURATIONS,
          constants.permissions.COMPLAINTS.ACTIVATE_COMPLAINTS,
        ]}
      >
        {(!!configurationField?.subConfigurationId || size(fields) > 1) && (
          <Box className={classes.boxTrashIcon}>
            <XIcon
              size={20}
              onClick={removeSubConfiguration}
              className={classes.trashIcon}
            />
          </Box>
        )}
      </Permitted>
    </Paper>
  )
}

export default Configuration
