import * as yup from 'yup'

export const schemaUser = yup.object().shape({
  title: yup.string().max(255).required(),
  link: yup.string().url().max(500).required(),
  documentCategoryId: yup.string().required(),
  privateAccess: yup.boolean().required(),
  otherDocumentCategory: yup.string().when('documentCategoryId', {
    is: 'another',
    then: yup.string().required(),
  }),
})

export default schemaUser
