import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import { routes } from 'Routes'
import { useHistory } from 'react-router-dom'

import useSnackbar from 'hooks/useSnackbar'
import useConsent from 'hooks/useConsent'

import helpers from 'helpers'
import * as service from 'service'

import schema from './schema'
import useStyles from './styles'
import constants from 'constants/index'
import { getBaseURL } from 'service/env'

const Sharing = ({ tokenPrivacyPortal }) => {
  const history = useHistory()
  const portalURL = getBaseURL('privacyPortal')
  const classes = useStyles()
  const snackbar = useSnackbar()

  const { toNextStep, setToNextStep } = useConsent()

  const {
    handleSubmit,
    errors,
    control,
    formState: { isValid },
  } = useForm({
    validationSchema: schema,
    mode: 'onChange',
  })

  const linkToPortal = `${portalURL}/consent-forms?token=${tokenPrivacyPortal}`

  useEffect(() => {
    if (toNextStep) {
      handleSubmit(onSubmit)()

      if (!isValid) setToNextStep(false)
    }
    // eslint-disable-next-line
  }, [toNextStep])

  const onSubmit = async (data) => {
    try {
      if (data?.email) {
        await service.dponet.consent.sendLink({
          token: tokenPrivacyPortal,
          email: data?.email,
        })
      }

      snackbar.open({
        message: 'Formulário de consentimento criado com sucesso!',
        variant: 'success',
      })
      history.push(routes.consent.all)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error, false) ||
          error?.error?.message,
        variant: 'error',
      })
    }
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(linkToPortal)
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      id={constants.consent.NEW_CONSENT_FORM_DRIVER_STEP_5}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle2" gutterBottom>
            Publicar formulário
          </Typography>
          <Typography>
            Torne o seu formulário público, compartilhando o endereço eletrônico
            do formulário com os titulares de dados.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="copy-field"
            label="Link para o formulário"
            value={linkToPortal}
            fullWidth
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCopy}
                  >
                    Copiar
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.descriptionBox}>
            <Typography variant="subtitle2">Compartilhar por e-mail</Typography>
            <Typography>
              Indique um e-mail para receber um link para o formulário, podendo
              ser compartilhado com os titulares de dados
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                error={!!errors.email}
                helperText={errors?.email?.message}
                label="Compartilhar por e-mail"
                fullWidth
              />
            }
            control={control}
            name="email"
            mode="onChange"
          />
        </Grid>
      </Grid>
    </form>
  )
}

Sharing.propTypes = {
  tokenPrivacyPortal: PropTypes.string.isRequired,
}

export default Sharing
