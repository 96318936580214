import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import { isEmpty } from 'lodash'

import { Label } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import * as service from 'service'
import schema from './schema'
import constants from 'constants/index'
import theme from 'theme'

const ApprovedDialog = ({ open, setOpen, supplierSuggestion, refresh }) => {
  const snackbar = useSnackbar()

  const isRequiredEmail = isEmpty(supplierSuggestion?.email)

  const { control, errors, handleSubmit } = useForm({
    validationSchema: schema(isRequiredEmail),
    defaultValues: {
      email: '',
    },
  })

  const onSubmit = async (data) => {
    try {
      await service.dponet.suggestedSuppliers.acceptedOrDeclined({
        supplierSuggestionId: supplierSuggestion?.id,
        supplier: {
          email: supplierSuggestion?.email || data?.email,
          status: constants.supplierSuggestion.STATUSES.APPROVED,
        },
      })

      refresh()
      snackbar.open({
        message: 'Sugestão aprovada com sucesso',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Ocorreu um erro ao aprovar sugestão',
        variant: 'error',
      })
    }
  }

  const handleCloseDialog = () => setOpen(false)

  return (
    <Dialog open={open} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
      <DialogTitle>Aprovar sugestão de parceiro</DialogTitle>
      <DialogContent dividers>
        <Label title="Para aprovar essa organização como parceira, é necessário fornecer o e-mail do responsável">
          <form onSubmit={handleSubmit(onSubmit)} id="suggestedForm">
            <Controller
              as={
                <TextField
                  label="E-mail do responsável"
                  color="primary"
                  variant="outlined"
                  error={!!errors.email}
                  helperText={errors?.email?.message}
                  fullWidth
                />
              }
              control={control}
              name="email"
              mode="onChange"
            />
          </form>
        </Label>
      </DialogContent>
      <DialogActions p={2}>
        <Box display="flex" gridGap={theme.spacing(2)}>
          <Button type="button" variant="outlined" onClick={handleCloseDialog}>
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            form="suggestedForm"
          >
            Salvar
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

export default ApprovedDialog
