import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import qs from 'query-string'
import { Box, Button, Divider, Grid, Typography } from '@material-ui/core'
import moment from 'moment'

import {
  Container,
  ContentHeader,
  Page,
  LoadingFeedback,
  MenuButton,
  DialogLogs,
} from 'components'
import {
  ComplaintMessages,
  Infos,
  MessageForm,
  ResponsibleForm,
} from './components'

import helpers from 'helpers'

import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'
import useAuth from 'hooks/useAuth'

import theme from 'theme'
import { routes } from 'Routes'
import useStyles from './styles'
import * as service from 'service'
import constants from 'constants/index'

import logoNotFound from 'images/not-found-ticket.svg'

const ComplaintShow = ({ match }) => {
  const [loading, setLoading] = useState(false)
  const [openLogDialog, setOpenLogDialog] = useState(false)
  const [loadingLogs, setLoadingLogs] = useState(false)
  const [responseLog, setResponseLog] = useState([])
  const [timeSpent, setTimeSpent] = useState(0)

  const classes = useStyles()
  const history = useHistory()
  const scrollRef = useRef()
  const snackbar = useSnackbar()
  const { user } = useAuth()

  const { complaintId } = match?.params

  const { response, isLoading, refresh } = useFetch(
    service.dponet.complaint.get,
    {
      complaintId,
    },
    [complaintId],
  )

  const complaint = response?.data?.complaint

  const { response: userCompaniesResponse, isLoading: userCompanyIsLoading } =
    useFetch(
      service.dponet.usersCompanies.list,
      { listByCompany: true, showSelf: true },
      [complaintId],
    )

  const userCompanies = userCompaniesResponse?.data?.userCompanies

  const reportClassification = helpers.complaint.findAnswer(
    complaint,
    constants?.complaint?.REPORT_CLASSIFICATION_ANSWER_KIND,
  )

  const description = helpers.complaint.findAnswer(
    complaint,
    constants.complaint?.DESCRIPTION_ANSWER_KIND,
  )

  const handleRedirectIncident = () => {
    const query = qs.stringify({
      complaintId,
      convertedComplaintIncident: true,
    })

    history.push(routes.incidents.new + `?${query}`)
  }

  const complaintInfos = helpers.complaint.mountComplaintInfos(
    complaint,
    classes,
    handleRedirectIncident,
  )

  const backToListComplaints = () => history.push(routes.complaint.all)

  const handleOpenLogs = async () => {
    try {
      setLoadingLogs(true)
      const responseLogs = await service.dponet.complaint.logs({
        complaintId,
      })
      const updatedlogs = helpers.logs.changeLogs(
        responseLogs?.data?.dponetAudits || [],
      )
      setResponseLog(updatedlogs)
      setOpenLogDialog(true)
    } catch (error) {
      setOpenLogDialog(false)
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error, false) ||
          error?.error?.message,
        variant: 'error',
      })
    } finally {
      setLoadingLogs(false)
    }
  }

  useLayoutEffect(() => {
    if (scrollRef?.current) scrollRef.current.scrollIntoView()
  }, [])

  useEffect(() => {
    let elapsed = 0

    const timer = setInterval(() => {
      elapsed++
    }, 1000)

    return () => {
      service.dponet.complaint.lastAccess({
        complaintId,
        lastAcessInfo: {
          user: {
            id: user.id,
            name: user.name,
          },
          secondsSpentOnScreen: elapsed,
          date: moment(Date.now()).format('DD/MM/YYYY'),
        },
      })
      clearInterval(timer)
    }
  }, [complaintId, user])

  return (
    <Page title="Visualizar Denúncia">
      <LoadingFeedback open={loading || isLoading || userCompanyIsLoading} />
      <Container>
        <ContentHeader title="Visualizar Denúncia">
          <MenuButton>
            <Button color="secondary" fullWidth onClick={handleOpenLogs}>
              Histórico de alterações
            </Button>
          </MenuButton>
        </ContentHeader>
        <Box display="flex" flexDirection="column" gridGap={theme.spacing(3)}>
          {!isLoading && (
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={8}
                component={Box}
                display="flex"
                flexDirection="column"
                gridGap={theme.spacing(2)}
                order={1}
              >
                <ComplaintMessages
                  title="Descrição do relato"
                  subTitle={`${reportClassification?.answerDecrypt} - ${reportClassification?.complaintAnswerSpecific?.answerDecrypt}`}
                  description={description?.answerDecrypt}
                  attachments={complaint?.attachments}
                />
                <Divider />
                <Box height="100%">
                  {!isEmpty(complaint?.complaintMessages) ? (
                    <PerfectScrollbar
                      className={classes.containerMessages}
                      options={{
                        wheelPropagation: false,
                        useBothWheelAxes: true,
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        gridGap={theme.spacing(2)}
                        maxHeight={400}
                      >
                        {complaint?.complaintMessages?.map(
                          (complaintMessage, index) => (
                            <ComplaintMessages
                              key={index}
                              complaintMessage={complaintMessage}
                            />
                          ),
                        )}
                      </Box>
                      <span ref={scrollRef} />
                    </PerfectScrollbar>
                  ) : (
                    <Box
                      display="flex"
                      flexDirection="column"
                      textAlign="center"
                      gridGap={theme.spacing(3)}
                      justifyContent="center"
                      height="100%"
                    >
                      <img
                        height={150}
                        src={logoNotFound}
                        alt="Nenhuma interação neste ticket"
                      />
                      <Typography
                        variant="h2"
                        className={classes.noInteractionTypography}
                      >
                        Nenhuma interação nesta denúncia
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>
              {(!userCompanyIsLoading || !isLoading) && (
                <Grid
                  item
                  xs={12}
                  md={4}
                  component={Box}
                  display="flex"
                  flexDirection="column"
                  gridGap={theme.spacing(3)}
                  order={2}
                >
                  <ResponsibleForm
                    complaint={complaint}
                    complaintId={complaintId}
                    refresh={refresh}
                    setLoading={setLoading}
                    userCompanies={userCompanies}
                  />
                  <Infos items={complaintInfos} />
                </Grid>
              )}
            </Grid>
          )}
          {!isLoading && (
            <Box>
              <MessageForm
                complaintId={complaintId}
                complaint={complaint}
                setLoading={setLoading}
                refresh={refresh}
              />
            </Box>
          )}
          {!loadingLogs && (
            <DialogLogs
              logs={
                helpers.functions.isEmpty(responseLog) ? undefined : responseLog
              }
              open={openLogDialog}
              setOpen={setOpenLogDialog}
              showRegisterSession={false}
              refresh={handleOpenLogs}
            />
          )}
          <Box display="flex" justifyContent="end">
            <Button variant="contained" onClick={backToListComplaints}>
              Voltar
            </Button>
          </Box>
        </Box>
      </Container>
    </Page>
  )
}

export default ComplaintShow
