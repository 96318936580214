/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Box, Paper, Switch, TextField, Typography } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'

import useStyles from './styles'

const RecurringEmail = ({
  recurring,
  setRecurring,
  recurringEmailDays,
  disabled,
}) => {
  const classes = useStyles()

  const { control, errors, register, setValue } = useFormContext()

  const handleSelect = (e) => {
    setRecurring(e.target.checked)
  }

  useEffect(() => {
    setValue('recurringEmail', recurring)
    setValue('recurringEmailDays', recurringEmailDays)
  }, [recurring])

  return (
    <Paper className={classes.paper}>
      <Typography color="primary" variant="subtitle1">
        E-mail recorrente
      </Typography>
      <Box display="flex" alignItems="center">
        <Switch
          name="recurringEmail"
          checked={recurring}
          onChange={handleSelect}
          color="primary"
          inputRef={register}
          disabled={disabled}
        />
        <Typography>
          Notificação recorrente de pendência de denúncias
        </Typography>
      </Box>
      {recurring && (
        <Box>
          <Controller
            control={control}
            mode="onChange"
            name="recurringEmailDays"
            as={
              <TextField
                className={classes.inputDays}
                type="number"
                color="primary"
                variant="outlined"
                disabled={disabled}
                label="Selecione a quantidade de dias"
                inputProps={{
                  min: 1,
                  max: 5,
                }}
                error={!!errors?.recurringEmailDays}
                helperText={errors?.recurringEmailDays?.message}
              />
            }
          />
        </Box>
      )}
      <Typography>
        Se esta opção estiver ativa, caso alguma denúncia fique sem resposta por
        um período de tempo, a plataforma irá enviar e-mails recorrentes de
        notificação para o(s) responsável(eis).
      </Typography>
    </Paper>
  )
}

export default RecurringEmail
