import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  select: {
    width: '100%',
  },
  table: {
    width: '100%',
  },
  form: {
    width: '100%',
  },
  tableCellFlex: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  tableButton: {
    color: theme.palette.cianDark,
    fontSize: '12px',
  },
  ellipsisTable: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '9rem',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paperModal: {
    width: '70%',
    backgroundColor: theme.palette.white,
    borderWidth: 0,
    outline: 'none',
  },
  paperDescription: {
    backgroundColor: theme.palette.backgroundDefault,
    padding: theme.spacing(2),
  },
  label: {
    marginBottom: '10px',
    marginTop: 10,
  },
  gridModal: {
    padding: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  multiSelect: {
    fontSize: '12px',
    fontFamily: 'Roboto',
  },
  selectAction: {},
  justifyFlexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  fullHeight: {
    height: '100%',
    minHeight: '100%',
  },
  otherSource: {
    color: theme.palette.primary.dark,
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.8,
    },
  },
  otherInputSource: {
    backgroundColor: theme.palette.text.backgroundInputColor,
    margin: theme.spacing(1),
  },
  redBackground: {
    backgroundColor: theme.palette.error.main,
  },
  autoComplete: {
    backgroundColor: theme.palette.background.paper,
  },
  buttonBox: {
    justifyContent: 'end',
  },
  optionTitle: {
    fontWeight: 'bolder',
  },

  optionBox: {
    marginBlock: theme.spacing(1),
  },
  radioError: {
    color: theme.palette.error.main,
  },
}))

export default styles
