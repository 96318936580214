import React, { useState, useEffect } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Box, Typography, Breadcrumbs, Grid, Link } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import useContentHeader from 'hooks/useContentHeader'

import helpers from 'helpers'

import { routes } from 'Routes'
import useStyles from './styles'

const ContentHeader = ({
  title,
  lightColor = false,
  children,
  smFull = false,
  xsFull = true,
  ...rest
}) => {
  const [contentHeaderArray, setContentHeaderArray] = useState([])

  const { setHistoryContentHeader, onChangePage } = useContentHeader()
  const routeMatch = useRouteMatch()
  const history = useHistory()
  const classes = useStyles()
  const childrenIsEmpty = !children

  useEffect(() => {
    const currentPathObjectFormatted =
      helpers.contentHeader.formatters.routesWithLabel(
        routes,
        routeMatch.path,
        routeMatch.url,
      )

    const pathList = `/${routeMatch.path.split('/')[1]}/`

    const currentListPath = helpers.contentHeader.formatters.routesWithLabel(
      routes,
      pathList,
      pathList,
    )
    onChangePage(currentPathObjectFormatted, currentListPath)
    const newArray =
      JSON.parse(localStorage.getItem('lastArrayContentHeader')) || []

    setHistoryContentHeader(newArray)
    setContentHeaderArray(newArray)
    // eslint-disable-next-line
  }, [])

  return (
    <Box
      pt={title && 3}
      mb={title && 4}
      display="flex"
      justifyContent="space-between"
      alignItems="flex-end"
    >
      <Grid container spacing={3} alignItems="center">
        <Grid
          item
          xl={childrenIsEmpty ? 12 : 6}
          lg={childrenIsEmpty ? 12 : 3}
          md={childrenIsEmpty ? 12 : 4}
          sm={childrenIsEmpty ? 12 : smFull ? 12 : 5}
          xs={xsFull ? 12 : 6}
        >
          {contentHeaderArray.length > 1 && (
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              classes={{
                li: clsx({ [classes.textLighten]: lightColor }),
                ol: clsx({ [classes.textLighten]: lightColor }),
              }}
              aria-label="breadcrumb"
            >
              <Typography
                className={clsx(classes.menu, {
                  [classes.textLighten]: lightColor,
                })}
                variant="body1"
                color="textPrimary"
              >
                {helpers.contentHeader.getCurrentScopeByUri(routeMatch.path)}
              </Typography>
              {contentHeaderArray.map((item) => (
                <Link key={item.url} onClick={() => history.push(item.url)}>
                  <Typography
                    className={clsx(classes.title, {
                      [classes.textLighten]: lightColor,
                    })}
                    variant="body1"
                    color="textPrimary"
                  >
                    {item.label}
                  </Typography>
                </Link>
              ))}
            </Breadcrumbs>
          )}
          <Typography
            className={clsx(classes.h3, {
              [classes.textLighten]: lightColor,
            })}
            variant="h3"
            color="textPrimary"
          >
            {title}
          </Typography>
        </Grid>
        {!!children && (
          <Grid
            item
            xl={6}
            lg={9}
            md={8}
            sm={smFull ? 12 : 7}
            xs={xsFull ? 12 : 6}
          >
            <Box display="flex" justifyContent="flex-end" {...rest}>
              {children}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

ContentHeader.propTypes = {
  title: PropTypes.string,
  lightColor: PropTypes.bool,
  children: PropTypes.node,
  smFull: PropTypes.bool,
  xsFull: PropTypes.bool,
}

export default ContentHeader
