const DEFAULT_LANGUAGE = 'pt-BR'

const LANGUAGE_CODES = {
  BR: 'pt-BR',
  US: 'en-US',
  ES: 'es-ES',
}

const DEFAULT_LANGUAGE_CODES = [
  LANGUAGE_CODES.BR,
  LANGUAGE_CODES.US,
  LANGUAGE_CODES.ES,
]

const LANGUAGE_NAMES = {
  BR: 'Brasil',
  US: 'Estados Unidos',
  ES: 'Espanha',
}

const LANGUAGE_FLAGS = {
  BR: 'https://flagcdn.com/w320/br.png',
  US: 'https://flagcdn.com/w320/us.png',
  ES: 'https://flagcdn.com/w320/es.png',
}

const LANGUAGES = [
  {
    code: LANGUAGE_CODES.BR,
    name: LANGUAGE_NAMES.BR,
    flag: LANGUAGE_FLAGS.BR,
  },
  {
    code: LANGUAGE_CODES.US,
    name: LANGUAGE_NAMES.US,
    flag: LANGUAGE_FLAGS.US,
  },
  {
    code: LANGUAGE_CODES.ES,
    name: LANGUAGE_NAMES.ES,
    flag: LANGUAGE_FLAGS.ES,
  },
]

export default {
  DEFAULT_LANGUAGE,
  DEFAULT_LANGUAGE_CODES,
  LANGUAGE_CODES,
  LANGUAGE_NAMES,
  LANGUAGE_FLAGS,
  LANGUAGES,
}
