import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Box, Button, Chip } from '@material-ui/core'

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { MenuButton, Permitted } from 'components'

import helpers from 'helpers'

import constants from 'constants/index'

import styles from './styles'

const ReceiptTable = ({
  receipts,
  handleOpen = () => {},
  navigateAction = () => {},
}) => {
  const { AUTHORIZED_CONSENT, REVOKED_CONSENT, STATUS_LABEL_RECEIPT } =
    constants.consent

  const classes = styles()

  return (
    <>
      <Box width="100%">
        <PerfectScrollbar>
          <Table emptyMessage="Nenhum recibo encontrado!" noWrap>
            <TableHead>
              <TableRow>
                <TableCell width="5%">ID</TableCell>
                <TableCell width="40%">Formulário</TableCell>
                <TableCell width="25%">E-mail</TableCell>
                <TableCell width="15%" align="center">
                  Data do consentimento
                </TableCell>
                <TableCell width="10%" align="center">
                  Status
                </TableCell>
                <TableCell width="5%" align="right">
                  Ações
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {receipts?.map((receipt) => (
                <TableRow key={receipt?.id}>
                  <TableCell width="5%">{receipt?.id}</TableCell>
                  <TableCell width="40%">
                    {receipt?.consentForm?.title}
                  </TableCell>
                  <TableCell width="25%">{receipt?.userEmail}</TableCell>
                  <TableCell width="15%" align="center">
                    {helpers.formatters.date(receipt?.createdAt) || '-'}
                  </TableCell>
                  <TableCell width="10%" align="center">
                    <Chip
                      size="small"
                      className={clsx(classes.chip, {
                        [classes.statusLabelAuthorized]:
                          receipt?.status === AUTHORIZED_CONSENT,
                        [classes.statusLabelRevoked]:
                          receipt?.status === REVOKED_CONSENT,
                      })}
                      label={STATUS_LABEL_RECEIPT[receipt?.status]}
                    />
                  </TableCell>
                  <TableCell width="5%" align="right">
                    <MenuButton>
                      <Button
                        color="secondary"
                        onClick={() =>
                          navigateAction({
                            consentReceiptId: receipt?.id,
                            consentFormId: receipt?.consentForm?.id,
                          })
                        }
                        fullWidth
                        size="small"
                      >
                        Ver detalhes
                      </Button>
                      <Permitted
                        tag={constants.permissions.CONSENT_RECEIPTS.DESTROY}
                      >
                        <Button
                          color="secondary"
                          onClick={() => handleOpen(receipt)}
                          fullWidth
                          size="small"
                        >
                          Excluir
                        </Button>
                      </Permitted>
                    </MenuButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </PerfectScrollbar>
      </Box>
    </>
  )
}

ReceiptTable.propTypes = {
  receipts: PropTypes.arrayOf(PropTypes.object),
  handleOpen: PropTypes.func.isRequired,
  navigateAction: PropTypes.func.isRequired,
}

export default ReceiptTable
