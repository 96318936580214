import React, { useState } from 'react'
import { Box, Button, Grid } from '@material-ui/core'
import { HelpCircle as HelpCircleIcon } from 'react-feather'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'

import { Container, ContentHeader, DriveTour, Page } from 'components'
import {
  ImportForm,
  RegisterForm,
  RegisterTypeForm,
} from './components/partner/new'

import constants from 'constants/index'
import theme from 'theme'

const ThirdPartyManagementPartnerNew = () => {
  const location = useLocation()
  const query = qs.parse(location.search)

  const registerTypes = constants.thirdPartyManagements.REGISTER_TYPES

  const [registerType, setRegisterType] = useState(
    query?.registerType || registerTypes.REGISTER,
  )
  const [openTour, setOpenTour] = useState(false)

  const showRegisterForm = [
    registerTypes.REGISTER,
    registerTypes.PRE_AVALIATION,
  ].includes(registerType)

  const tourStepObject =
    registerType === registerTypes.IMPORT
      ? constants.tours.thirdPartyManagements.STEPS_OBJECT.CREATION.IMPORT
      : constants.tours.thirdPartyManagements.STEPS_OBJECT.CREATION.DEFAULT

  const tourStep =
    registerType === registerTypes.IMPORT
      ? constants.tours.thirdPartyManagements.STEPS.CREATION.IMPORT
      : constants.tours.thirdPartyManagements.STEPS.CREATION.DEFAULT

  const handleOpenTour = () => setOpenTour(true)

  return (
    <Page title="Gestão de Terceiros - Cadastrar Parceiro">
      <Container maxWidth={false}>
        <ContentHeader title="Cadastrar Parceiro">
          <Box display="flex" gridGap={theme.spacing(1)}>
            <Button
              id={tourStep[1]}
              variant="contained"
              color="primary"
              startIcon={<HelpCircleIcon size={20} />}
              onClick={handleOpenTour}
            >
              Tutorial
            </Button>

            <DriveTour
              stepsMatrix={tourStepObject}
              open={openTour}
              setOpen={setOpenTour}
            />
          </Box>
        </ContentHeader>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <RegisterTypeForm
              registerType={registerType}
              setRegisterType={setRegisterType}
            />
          </Grid>

          <Grid item xs={12}>
            {showRegisterForm ? (
              <RegisterForm registerType={registerType} isCreate />
            ) : (
              <ImportForm />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  )
}

export default ThirdPartyManagementPartnerNew
