import React, { useState, useEffect } from 'react'
import { RHFInput } from 'react-hook-form-input'
import { useForm } from 'react-hook-form'
import { PlusCircle as PlusCircleIcon } from 'react-feather'
import { Grid, Box, Typography, Button, makeStyles } from '@material-ui/core'

import { TableDocuments } from '../../TableDocuments'
import { DialogConfirmAddDocument } from 'components'

import useDocuments from 'hooks/useDocuments'
import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'

import styles from './styles'
import * as service from 'service'
import constants from 'constants/index'

const useStyles = makeStyles(styles)

const Documents = ({ dataProcessId }) => {
  const classes = useStyles()
  const snackbar = useSnackbar()
  const [dialogConfirmAddDocument, setDialogConfirmAddDocument] =
    useState(false)
  const documentsHook = useDocuments()
  const { register, setValue } = useForm()

  useEffect(() => {
    if (!dialogConfirmAddDocument) {
      document.getElementById('documents').value = ''
    }
  }, [dialogConfirmAddDocument])

  const onChangeCreate = async () => {
    const documentInput = document.getElementById('documents')
    const files = documentInput.files
    const { allowed, forbidedDocuments } =
      helpers.dataProcess.allowDocumentUpload(files)

    if (files.length === 0) return

    if (!allowed) {
      return snackbar.open({
        message:
          'Ocorreu um erro ao adicionar o(s) documento(s), verifique se a extensão do(s) arquivo(s) é compatível: ' +
          forbidedDocuments.map((forbidedDocument) => ' ' + forbidedDocument),
        variant: 'error',
      })
    }

    try {
      documentsHook.setLoading(true)
      await service.dponet.documents.addDocument({
        dataProcessId: dataProcessId,
        files: files,
      })
      await documentsHook.loadData(dataProcessId)
      documentsHook.setLoading(false)
      snackbar.open({
        message: 'Documento(s) adicionado(s) com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      documentsHook.setLoading(false)
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Ocorreu um erro ao adicionar o(s) documento(s), verifique se a extensão do(s) arquivo(s) é compatível',
        variant: 'error',
      })
    }
  }

  return (
    <>
      <Grid container justifyContent="space-between">
        <Box mt={2}>
          <Typography variant="h5">Documentos</Typography>
          <Typography variant="body2">
            Arquivos suportados:&nbsp;
            {constants.dataProcess.PERMITTED_DOCUMENTS_TYPE}
          </Typography>
        </Box>
        <Box mt={2}>
          <form id="documents-form">
            <RHFInput
              as={
                <Button
                  variant="outlined"
                  startIcon={<PlusCircleIcon size={20} />}
                  htmlFor="documents"
                  size="small"
                  component="label"
                >
                  <Typography>Adicionar documentos</Typography>
                  <input
                    type="file"
                    multiple
                    id="documents"
                    style={{ display: 'none' }}
                    accept={constants.dataProcess.PERMITTED_DOCUMENTS_TYPE}
                  />
                </Button>
              }
              setValue={setValue}
              register={register}
              onChange={() => setDialogConfirmAddDocument(true)}
              name="documents"
            />
          </form>
        </Box>
      </Grid>
      <Grid container className={classes.dataCollectedContainer}>
        <Grid item xs={12}>
          <TableDocuments
            edit
            classes={classes}
            dataProcessId={dataProcessId}
            isLoading={documentsHook.loading}
          />
        </Grid>
      </Grid>
      <DialogConfirmAddDocument
        onChangeCreate={onChangeCreate}
        open={dialogConfirmAddDocument}
        setOpen={setDialogConfirmAddDocument}
      />
    </>
  )
}

export default Documents
