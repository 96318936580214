import React, { useState } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Download as DownloadIcon, Eye as EyeIcon } from 'react-feather'
import PropTypes from 'prop-types'
import { Box, Button } from '@material-ui/core'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { AcceptedDetailsDialog } from './components'
import { MenuButton } from 'components'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import useStyles from './styles'
import * as service from 'service'

const AcceptedTable = ({ receipts, isLoading, setLoading }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [receiptData, setReceiptData] = useState({})

  const classes = useStyles()
  const snackbar = useSnackbar()

  const MENU_BUTTON_ITEMS = [
    {
      name: 'Visualizar',
      Icon: EyeIcon,
      onClick: (receipt) => handleOpenDialog(receipt),
    },
    {
      name: 'Baixar',
      Icon: DownloadIcon,
      onClick: (receipt) => handleDownloadReceipts(receipt),
    },
  ]

  const handleOpenDialog = (receipt) => {
    setReceiptData(receipt)
    setOpenDialog((prevState) => !prevState)
  }

  const handleDownloadReceipts = async (receipt) => {
    try {
      setLoading(true)

      const response = await service.dponet.consent.exportReceipt({
        consentFormId: receipt?.consentForm?.id,
        userEmail: receipt?.userEmail,
      })

      const fileUrl = response?.data?.fileUrl

      window.open(fileUrl, '_blank')

      snackbar.open({
        message:
          'Recibo baixado com sucesso! Caso o documento não abra, ative os pop-ups.',
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table emptyMessage="Nenhum aceite encontrado!" isLoading={isLoading}>
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                width="75%"
                className={classes.initialContent}
              >
                E-mail
              </TableCell>
              <TableCell align="center" width="10%">
                Versão
              </TableCell>
              <TableCell align="center" width="10%">
                Total de aceites
              </TableCell>
              <TableCell
                align="center"
                width="5%"
                className={classes.finalContent}
              >
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {receipts?.map((receipt) => (
              <TableRow key={receipt?.id}>
                <TableCell
                  align="left"
                  width="75%"
                  className={classes.initialContent}
                >
                  {receipt?.userEmail}
                </TableCell>
                <TableCell align="center" width="10%">
                  {receipt?.version ?? '-'}
                </TableCell>
                <TableCell align="center" width="10%">
                  {String(receipt?.versionsAccepted?.total)}
                </TableCell>
                <TableCell
                  align="right"
                  width="5%"
                  className={classes.finalContent}
                >
                  <MenuButton>
                    {MENU_BUTTON_ITEMS.map((item, index) => (
                      <Button
                        key={index}
                        size="small"
                        color="secondary"
                        startIcon={<item.Icon size={16} strokeWidth={2.5} />}
                        fullWidth
                        onClick={() => item.onClick(receipt)}
                      >
                        {item.name}
                      </Button>
                    ))}
                  </MenuButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      <AcceptedDetailsDialog
        open={openDialog}
        setOpen={setOpenDialog}
        receipt={receiptData}
      />
    </Box>
  )
}

AcceptedTable.propTypes = {
  receipts: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
}

export default AcceptedTable
