import React from 'react'
import PropTypes from 'prop-types'
import { Box, colors, MenuItem, TextField, Typography } from '@material-ui/core'

import theme from 'theme'
import styles from './styles'

const DetailedSelect = ({
  items,
  value,
  onChange = () => {},
  disabled,
  ...rest
}) => {
  const useStyles = styles({ disabled })
  const classes = useStyles()

  return (
    <TextField
      select
      id="sharing-select"
      variant="outlined"
      value={value}
      onChange={onChange}
      fullWidth
      SelectProps={{
        SelectDisplayProps: {
          style: {
            height: 'fit-content',
          },
        },
      }}
      disabled={disabled}
      {...rest}
    >
      {items?.map((item, index) => {
        const Icon = item?.icon
        return (
          <MenuItem key={index} value={item?.value}>
            <Box display="flex" alignItems="center" gridGap={theme.spacing(2)}>
              <Box
                bgcolor={
                  disabled ? colors.grey[100] : theme.palette.custom.border
                }
                borderRadius={24}
                lineHeight={0}
                p={1}
              >
                <Icon
                  size={20}
                  color={
                    disabled ? colors.grey[400] : theme.palette.secondary.main
                  }
                />
              </Box>
              <Box>
                <Typography className={classes.typography}>
                  {item?.title}
                </Typography>
                <Typography variant="body2" className={classes.typography}>
                  {item?.subtitle}
                </Typography>
              </Box>
            </Box>
          </MenuItem>
        )
      })}
    </TextField>
  )
}

DetailedSelect.propTypes = {
  error: PropTypes.bool.isRequired,
  helperText: PropTypes.object,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
      icon: PropTypes.object.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ),
  value: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

export default DetailedSelect
