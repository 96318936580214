import React, { useState } from 'react'
import {
  Box,
  Button,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import { Check as CheckIcon } from '@material-ui/icons'

import {
  GeneralInformation,
  ConsentText,
  Settings,
  Sharing,
} from './components'
import { Consents } from 'components'

import useConsent from 'hooks/useConsent'

import constants from 'constants/index'

import styles from './styles'

const ConsentForm = ({ setScreenDescription, setTourSteps }) => {
  const useStyles = makeStyles(styles)
  const classes = useStyles()

  const { activeStep, backStep, setToNextStep } = useConsent()

  const [tokenPrivacyPortal, setTokenPrivacyPortal] = useState('')

  const getTextsSteps = () => {
    return [
      {
        title: 'Novo Formulário',
        step: 'Informações gerais',
        tour: constants.consent
          .NEW_FORM_GENERAL_INFORMATIONS_DRIVER_STEPS_OBJECT,
      },
      {
        title: 'Consentimento',
        step: 'Texto de Consentimento',
        tour: constants.consent.NEW_FORM_CONSENT_TEXT_DRIVER_STEPS_OBJECT,
      },
      {
        title: 'Consentimento',
        step: 'Campos',
        tour: constants.consent.NEW_FORM_FIELDS_DRIVER_STEPS_OBJECT,
      },
      {
        title: 'Consentimento',
        step: 'Pré-visualização',
        tour: constants.consent.NEW_FORM_PREVIEW_DRIVER_STEPS_OBJECT,
      },
      {
        title: 'Consentimento',
        step: 'Configurações',
        tour: constants.consent.NEW_FORM_SETTINGS_DRIVER_STEPS_OBJECT,
      },
      {
        title: 'Consentimento',
        step: 'Compartilhamento',
        tour: constants.consent.NEW_FORM_SHARING_DRIVER_STEPS_OBJECT,
      },
    ]
  }

  const steps = getTextsSteps().map((textStep, index) => {
    return { label: textStep.step, id: `consent-form-stepper-${index}` }
  })

  setScreenDescription(getTextsSteps()[activeStep].step)
  setTourSteps(getTextsSteps()[activeStep].tour)

  return (
    <Box display="flex" width="100%" height="auto">
      <Paper className={classes.fullCardWidth}>
        <Box
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          px={3}
          py={2}
          fontWeight={500}
        >
          <Typography variant="subtitle1">
            <Box fontWeight="bold">{getTextsSteps()[activeStep].title}</Box>
          </Typography>
          <Typography variant="subtitle1">
            Etapa {activeStep + 1} de 6
          </Typography>
        </Box>
        <Divider />
        <Grid container spacing={3} component={Box} p={3}>
          <Grid item xl={9} lg={9} md={8} xs={12}>
            {activeStep === constants.dataProcess.GENERAL_INFORMATION_STEP && (
              <GeneralInformation />
            )}
            {activeStep === constants.dataProcess.DATA_COLLECTED_STEP && (
              <ConsentText />
            )}
            {activeStep === constants.dataProcess.DATA_LIFE_CICLE_STEP && (
              <Consents.Fields />
            )}
            {activeStep === constants.dataProcess.DATA_TREATMENT_STEP && (
              <Consents.Preview />
            )}
            {activeStep === constants.dataProcess.DATA_TREATMENT_AGENT_STEP && (
              <Settings setTokenPrivacyPortal={setTokenPrivacyPortal} />
            )}
            {activeStep === constants.dataProcess.FRAGILITY_ENTITIES_STEP && (
              <Sharing tokenPrivacyPortal={tokenPrivacyPortal} />
            )}
          </Grid>

          <Grid item xl={3} lg={3} md={4} xs={12}>
            <Consents.Stepper steps={steps}>
              <Grid
                container
                component={Box}
                display="flex"
                direction="row"
                justifyContent="space-between"
                py={2}
              >
                <Button
                  variant="outlined"
                  onClick={backStep}
                  className={classes.button}
                  disabled={
                    activeStep ===
                      constants.dataProcess.GENERAL_INFORMATION_STEP ||
                    activeStep === steps.length - 1
                  }
                >
                  VOLTAR
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  startIcon={<CheckIcon />}
                  onClick={() => setToNextStep(true)}
                  id={getTextsSteps()[activeStep].buttonId}
                >
                  {activeStep === steps.length - 1 ? 'SALVAR' : 'PRÓXIMO'}
                </Button>
              </Grid>
            </Consents.Stepper>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default ConsentForm
