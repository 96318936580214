import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Box, Button, colors, TextField, Typography } from '@material-ui/core'

import { CopyInput, Select, TextEditor } from 'components'

import helpers from 'helpers'

import theme from 'theme'
import constants from 'constants/index'

const InformationSection = ({ type, currentDocument, depreciatedDocument }) => {
  const { ACTIVE_STATUS_ID, OTHER_CATEGORY_ID, CATEGORY_OPTIONS } =
    constants.documents

  const { MANAGEMENT_STEP_3, MANAGEMENT_STEP_4 } = constants.tours.documents

  const { control, errors, watch } = useFormContext()

  const category = watch('category')

  const isCreate = type === 'create'
  const isShow = type === 'show'

  const isActive = currentDocument?.status === ACTIVE_STATUS_ID

  const handleOpenDepreciatedDocument = () =>
    window.open(helpers.formatters.website(depreciatedDocument?.link), '_blank')

  return (
    <Box display="flex" flexDirection="column" gridGap={theme.spacing(3)}>
      <Box
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(3)}
        id={MANAGEMENT_STEP_3}
      >
        <Controller
          as={
            <Select
              items={CATEGORY_OPTIONS}
              label="Categoria do documento"
              error={!!errors?.category}
              helperText={<>{errors?.category?.message}</>}
              disabled={isShow}
              fullWidth
            />
          }
          control={control}
          name="category"
        />
        {category === OTHER_CATEGORY_ID && (
          <Controller
            as={
              <TextField
                label="Especifique a categoria"
                error={!!errors?.otherCategory}
                helperText={<>{errors?.otherCategory?.message}</>}
                disabled={isShow}
                fullWidth
              />
            }
            control={control}
            name="otherCategory"
          />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(3)}
        id={MANAGEMENT_STEP_4}
      >
        <Controller
          as={
            <TextField
              label="Título"
              error={!!errors?.title}
              helperText={<>{errors?.title?.message}</>}
              disabled={isShow}
              fullWidth
            />
          }
          control={control}
          name="title"
        />
        <Box display="flex" flexDirection="column">
          <Typography variant="h6" color="secondary" gutterBottom>
            Conteúdo do documento
          </Typography>
          <Controller
            control={control}
            name="content"
            as={
              <TextEditor
                error={!!errors?.content}
                helperText={errors?.content?.message}
                configRest={{
                  height: 450,
                  buttons: constants.jodit.DEFAULT_BUTTONS,
                }}
                disabled={isShow}
              />
            }
            mode="onChange"
          />
        </Box>
      </Box>
      {!!currentDocument?.generateDocumentLink &&
        currentDocument?.publicDocument &&
        isActive && (
          <CopyInput
            label="Link de incorporação"
            content={currentDocument?.generateDocumentLink}
            bgcolor={theme.palette.background.default}
            style={{ backgroundColor: colors.blue[50] }}
          />
        )}
      {isCreate && depreciatedDocument?.link && (
        <Box display="flex" justifyContent="end">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOpenDepreciatedDocument}
          >
            Visualizar o documento desatualizado
          </Button>
        </Box>
      )}
    </Box>
  )
}

InformationSection.propTypes = {
  currentDocument: PropTypes.object,
  depreciatedDocument: PropTypes.object,
  type: PropTypes.oneOf(['create', 'edit', 'show']).isRequired,
}

export default InformationSection
