import helpers from 'helpers'

const formattedValueHelpers = {
  isDeadlineDateForNonCompliance: (field, registerType) =>
    field === 'deadline_date' &&
    ['NonComplianceReport', 'NonComplianceReportAction'].includes(registerType),

  isCompanySupplierStatus: (field, registerType) =>
    registerType === 'CompanySupplier' && field === 'status',

  isQuestionStatus: (field, registerType) =>
    ['QuestionControl', 'Question', 'QuestionPlanActionEvidency'].includes(
      registerType,
    ) && field === 'status',

  isComplaintCategory: (field, registerType) =>
    [
      'Complaint',
      'ComplaintSubConfiguration',
      'ComplaintCategoriesResponsible',
    ].includes(registerType) && field === 'category_id',

  isComplaintSubCategory: (field, registerType) =>
    ['ComplaintSubCategoriesResponsible', 'Complaint'].includes(registerType) &&
    field === 'sub_category_id',

  isComplaintKind: (field, registerType) =>
    ['ComplaintMessage', 'ComplaintAnswer'].includes(registerType) &&
    field === 'kind',

  isComplaintStatus: (field, registerType) =>
    registerType === 'Complaint' && field === 'status',

  isComplaintLastAccess: (field, registerType) =>
    registerType === 'Complaint' && field === 'last_access',

  formatComplaintLastAccess: (value) =>
    `Usuário ${value?.user?.name} acessou por último essa denúncia em ${
      value?.date
    } por ${helpers.complaint.formatTimeSpent(value?.secondsSpentOnScreen)}`,

  formatObjectValue: (value) =>
    Object.values(value).toString()?.replaceAll(',', ', '),

  formatBooleanValue: (field, registerType, value) => {
    if (registerType === 'Incident' && field === 'status') {
      return value ? 'Ativo' : 'Inativo'
    }
    return value ? 'Sim' : 'Não'
  },

  formatFieldValue: (field, value) => {
    switch (field) {
      case 'status_id':
        return helpers.dataProcess.status(parseInt(value))
      case 'fragility_id':
      case 'adopted_fragility_id':
      case 'suggestion_fragility_id':
        return helpers.dataProcess.fragilityLabel(parseInt(value))
      case 'status':
        return helpers.auditProcess.status(value)
      case 'data_type':
        return helpers.auditProcess.dataType(value)
      case 'kind':
        return helpers.logs.kindType(value)
      case 'deadline_date':
      case 'start_date':
      case 'occurred':
      case 'incident_aware':
      case 'informed_holder':
      case 'anpd_notification_date':
        return helpers.formatters.date(value)
      case 'storage_type':
        return helpers.dataProcess.storageModeType(parseInt(value))
      case 'answer':
        return helpers.dataProcess.formatAnswerField(value)
      default:
        return value
    }
  },
}

export default formattedValueHelpers
