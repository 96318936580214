import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  discardButton: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
}))

export default styles
