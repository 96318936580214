import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { Box } from '@material-ui/core'

import { Text } from '../'
import TextEditorTrail from './TextEditorTrail'

import useTrailStep from 'hooks/useTrailStep'

import * as service from 'service'
import constants from 'constants/index'

const PrivacyPolicyForm = () => {
  const [loadingPrivacy, setLoadingPrivacy] = useState(true)

  const { privacyPolicy, setPrivacyPolicy, handleNext } = useTrailStep()

  useEffect(() => {
    const loadPrivacyPolicy = async () => {
      setLoadingPrivacy(true)
      const response = await service.dponet.privacyPolicies.get({
        category: constants.documents.PRIVACY_POLICY_CATEGORY_ID,
      })

      const privacyPolicyResponse = response?.data?.privacyPolicies[0] || null

      setPrivacyPolicy(privacyPolicyResponse)
      setLoadingPrivacy(false)
    }

    if (isEmpty(privacyPolicy)) loadPrivacyPolicy()

    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!loadingPrivacy && !isEmpty(privacyPolicy)) handleNext()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privacyPolicy, loadingPrivacy])

  return (
    !loadingPrivacy &&
    isEmpty(privacyPolicy) && (
      <>
        <Text color="primary" fontSize="7vh" variant="h1">
          Aviso de Privacidade
        </Text>
        <Box my={3}>
          <Text fontSize="3.2vh" variant="h4" gutterBottom>
            O Aviso de Privacidade explica como as informações dos usuários são
            coletadas e usadas por um serviço online, incluindo dados pessoais e
            compartilhamento com terceiros
          </Text>
        </Box>
        <TextEditorTrail />
      </>
    )
  )
}

export default PrivacyPolicyForm
