import { makeStyles, colors } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  pending: {
    backgroundColor: colors.lightBlue[800],
    borderRadius: '44px',
    color: theme.palette.white,
    textTransform: 'uppercase',
    fontWeight: 600,
    height: '26px',
    marginRight: theme.spacing(1),
    fontSize: '12px',
  },
  finalized: {
    backgroundColor: colors.orange[400],
    borderRadius: '44px',
    color: theme.palette.white,
    textTransform: 'uppercase',
    fontWeight: 600,
    height: '26px',
    marginRight: theme.spacing(1),
    fontSize: '12px',
  },
  answered: {
    backgroundColor: colors.blue[400],
    borderRadius: '44px',
    color: theme.palette.white,
    textTransform: 'uppercase',
    fontWeight: 600,
    height: '26px',
    marginRight: theme.spacing(1),
    fontSize: '12px',
  },
}))

export default styles
