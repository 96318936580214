import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import { ArrowRight as ArrowRightIcon } from 'react-feather'
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core'

import { RefusedModal } from './components'
import { getBaseURL } from 'service/env'

import useStyles from './styles'
import useSupplierInvite from 'hooks/useSupplierInvite'

import constants from 'constants/index'
import helpers from 'helpers'

const SupplierInvitedWelcome = ({ inviter }) => {
  const [openConfirmation, setOpenConfirmation] = useState(false)
  const { setCurrentStep } = useSupplierInvite()
  const { mixpanel } = helpers
  const classes = useStyles()
  const inviterLogo = `${getBaseURL('dponet')}/${inviter?.logo}`

  const handleRefusedLinkModal = () => {
    mixpanel.track('Convite de Fornecedores', 'Etapa de Bem-Vindo', {
      action: 'Não_reconhe_esta_empresa-Bem_vindo',
    })
    setOpenConfirmation(true)
  }

  const approvedLink = () => {
    mixpanel.track('Convite de Fornecedores', 'Etapa de Bem-Vindo', {
      action: 'Reconhe_esta_empresa-Bem_vindo',
    })
    setCurrentStep(constants.supplierInvite.VALIDATE_DOCUMENT_STEP)
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Box className={classes.groupTypography}>
        <Box className={classes.logoContainer}>
          {inviter?.logo && (
            <img
              src={inviterLogo}
              alt="Logo da empresa"
              className={classes.logo}
            />
          )}
        </Box>
        <Typography className={classes.text}>
          A empresa <span className={classes.highlight}>{inviter?.name}</span>{' '}
          te indicou como fornecedor para avaliar sua conformidade à LGPD!
        </Typography>
        <Typography className={clsx(classes.text, classes.textQuestion)}>
          Você reconhece esta empresa?
        </Typography>
      </Box>
      <Box className={classes.groupButton}>
        <Paper
          onClick={approvedLink}
          variant="outlined"
          component={Button}
          endIcon={<ArrowRightIcon size={31} />}
          className={classes.button}
          fullWidth
        >
          Sim, reconheço esta empresa
        </Paper>
        <Paper
          onClick={handleRefusedLinkModal}
          variant="outlined"
          component={Button}
          endIcon={<ArrowRightIcon size={31} />}
          className={clsx(classes.button, classes.outlined)}
          fullWidth
        >
          Não reconheço esta empresa
        </Paper>
        <RefusedModal
          inviter={inviter}
          open={openConfirmation}
          setOpen={setOpenConfirmation}
        />
      </Box>
    </Grid>
  )
}

SupplierInvitedWelcome.propTypes = {
  inviter: PropTypes.object,
}

export default SupplierInvitedWelcome
