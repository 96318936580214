import React from 'react'
import PropTypes from 'prop-types'

import { DashboardChartPaper, RadialChart } from 'components'

import theme from 'theme'

const MySuppliersCompliance = ({ supplierStats }) => {
  const complianceValue = Math.round(supplierStats?.compliance || 0)

  return (
    <DashboardChartPaper
      title="Conformidade dos seus parceiros"
      heightContainer="fit-content"
      noMaxHeight
    >
      <RadialChart
        value={complianceValue}
        primaryColor={theme.palette.primary.main}
        trackBackground={theme.palette.primary.light}
        legendText="Conformidade"
        height={290}
        fontFamily="Roboto"
        fontSize="22px"
        hollowSize="63%"
        offsetY={10}
      />
    </DashboardChartPaper>
  )
}

MySuppliersCompliance.propTypes = {
  supplierStats: PropTypes.object,
}

export default MySuppliersCompliance
