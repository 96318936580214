import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Download as DownloadIcon } from 'react-feather'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Box, Button } from '@material-ui/core'
import { CompareArrows as CompareArrowsIcon } from '@material-ui/icons'

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from 'components/Table'
import { MenuButton } from 'components'

import useStyles from './styles'
import helpers from 'helpers'
import { reverse } from 'named-urls'
import { routes } from 'Routes'

const VersionTable = ({
  documentVersions,
  isLoading,
  downloadDocument,
  documentId,
}) => {
  const classes = useStyles()
  const history = useHistory()

  const handleComparatorRedirection = (documentVersionId) =>
    history.push(
      `${reverse(routes.documents.comparator, {
        documentId,
      })}?documentVersionId=${documentVersionId}`,
    )

  return (
    <Box width="100%">
      <PerfectScrollbar
        options={{ wheelPropagation: false, useBothWheelAxes: true }}
      >
        <Table
          emptyMessage="Nenhuma versão encontrada"
          isLoading={isLoading}
          size="small"
        >
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                width="70%"
                className={classes.initialContent}
              >
                Responsável
              </TableCell>
              <TableCell align="center" width="20%">
                Data de modificação
              </TableCell>
              <TableCell
                align="center"
                width="5%"
                className={classes.finalContent}
              >
                Versão
              </TableCell>
              <TableCell
                align="center"
                width="5%"
                className={classes.finalContent}
              >
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documentVersions?.map((documentVersion) => (
              <TableRow key={documentVersion?.id}>
                <TableCell
                  align="left"
                  width="70%"
                  className={classes.initialContent}
                >
                  {documentVersion?.author?.name}
                </TableCell>
                <TableCell align="center" width="20%">
                  {helpers.formatters.ptBrFormatDateTime(
                    documentVersion?.createdAt,
                  )}
                </TableCell>
                <TableCell
                  align="center"
                  width="5%"
                  className={classes.finalContent}
                >
                  {documentVersion?.version}
                </TableCell>
                <TableCell
                  align="right"
                  width="5%"
                  className={classes.finalContent}
                >
                  <MenuButton>
                    <Button
                      size="small"
                      color="secondary"
                      startIcon={<DownloadIcon size={16} strokeWidth={2.5} />}
                      fullWidth
                      onClick={() => downloadDocument(documentVersion?.id)}
                    >
                      Baixar
                    </Button>
                    <Button
                      size="small"
                      color="secondary"
                      startIcon={<CompareArrowsIcon fontSize="large" />}
                      fullWidth
                      onClick={() =>
                        handleComparatorRedirection(documentVersion?.id)
                      }
                    >
                      Comparar
                    </Button>
                  </MenuButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
    </Box>
  )
}

VersionTable.propTypes = {
  documentVersions: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool.isRequired,
  downloadDocument: PropTypes.func.isRequired,
  documentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
}

export default VersionTable
