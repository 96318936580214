import React from 'react'
import { Box, Paper, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { orderBy, isEmpty, flatMap } from 'lodash'

import { Questions, DpoReason } from 'components'

import useSnackbar from 'hooks/useSnackbar'
import useDataProcess from 'hooks/useDataProcess'

import * as service from 'service'
import constants from 'constants/index'
import { routes } from 'Routes'

const DisapprovedLia = ({
  dataProcess = {},
  handleNext = () => {},
  questionsReviewDisapprovedAnswers = [],
  questionsDisapprovedAnswers = [],
  hasBalancingStep = false,
}) => {
  const snackbar = useSnackbar()
  const history = useHistory()
  const { setLoadingReplyLiaAnswer } = useDataProcess()

  const { LEGITIMATE_INTEREST_FINALITY, CONCRETE_SITUATION } =
    constants.liaQuestions
  const { DISAPPROVED, REVIEW_DISAPPROVED } = constants.liaAnswers.STATUSES

  const filterQuestionsLegitimateInterestFinality = (questions) => {
    return questions?.filter((question) => {
      return question?.kind === LEGITIMATE_INTEREST_FINALITY
    })
  }

  const filterQuestionsConcreteSituation = (questions) => {
    return questions?.filter((question) => {
      return question?.kind === CONCRETE_SITUATION
    })
  }

  const filterReviewDisapprovedAnswers = (questions) => {
    const filteredQuestions = questions?.map((question) =>
      question?.liaAnswers?.filter(
        (answer) => answer?.status === REVIEW_DISAPPROVED,
      ),
    )

    return flatMap(filteredQuestions)
  }

  const questionsLegitimateInterestFinality = filterReviewDisapprovedAnswers(
    orderBy(
      filterQuestionsLegitimateInterestFinality(
        questionsReviewDisapprovedAnswers,
      ),
      'order',
    ),
  )

  const questionsConcreteSituation = filterReviewDisapprovedAnswers(
    orderBy(
      filterQuestionsConcreteSituation(questionsReviewDisapprovedAnswers),
      'order',
    ),
  )
  const answersDisapprovedLegitimateInterestFinality = orderBy(
    filterQuestionsLegitimateInterestFinality(questionsDisapprovedAnswers),
    'order',
  )

  const answersDisapprovedConcreteSituation = orderBy(
    filterQuestionsConcreteSituation(questionsDisapprovedAnswers),
    'order',
  )

  const filterDisapprovedAnswers = (liaAnswers) => {
    return liaAnswers.filter((answer) => answer?.status === DISAPPROVED)
  }

  const findQuestionByOrder = (answers, order) => {
    const answer = answers?.find(
      (answer) => answer?.liaQuestion?.order === order,
    )
    return answer?.answer
  }

  const { control, errors, handleSubmit } = useForm({
    defaultValues: {
      question1:
        findQuestionByOrder(questionsLegitimateInterestFinality, 1) || '',
      question2: findQuestionByOrder(questionsConcreteSituation, 2) || '',
      question3: findQuestionByOrder(questionsConcreteSituation, 3) || '',
      question4: findQuestionByOrder(questionsConcreteSituation, 4) || '',
    },
  })

  const onSubmit = async (data) => {
    setLoadingReplyLiaAnswer(true)
    var liaAnswers = []

    Object.keys(data).map((key) => {
      return liaAnswers.push({
        order: key.replace(/^question/, ''),
        answer: data[key],
      })
    })

    try {
      await service.dponet.liaAnswers.create({
        liaAnswers,
        dataProcessId: dataProcess.id,
        reviewDisapproved: true,
        reviewStatusLia: !hasBalancingStep,
      })
      if (hasBalancingStep) {
        return handleNext()
      }
      history.push(routes.dataProcess.all)
    } catch (error) {
      snackbar.open({
        message:
          error?.response?.data?.error?.message ||
          'Erro ao responder as respostas para LIA!',
        variant: 'error',
      })
    } finally {
      setLoadingReplyLiaAnswer(false)
    }
  }

  return (
    <Box mt={2}>
      <form id="lia_form" onSubmit={handleSubmit(onSubmit)}>
        {!isEmpty(answersDisapprovedLegitimateInterestFinality) && (
          <Paper component={Box} padding={2}>
            <Box mb={2}>
              <Typography variant="h5">
                Interesse e finalidades legítimas
              </Typography>
            </Box>
            {answersDisapprovedLegitimateInterestFinality?.map((question) => (
              <Box key={question?.id}>
                <Questions
                  order={question?.order}
                  text={question?.text}
                  control={control}
                  errors={errors}
                />
                {filterDisapprovedAnswers(question?.liaAnswers)?.map(
                  (answer) => (
                    <DpoReason answer={answer} key={answer?.id} />
                  ),
                )}
              </Box>
            ))}
          </Paper>
        )}
        {!isEmpty(answersDisapprovedConcreteSituation) && (
          <Paper component={Box} padding={2} mt={2}>
            <Box mb={2}>
              <Typography variant="h5">Situação concreta</Typography>
            </Box>
            {answersDisapprovedConcreteSituation?.map((question) => (
              <Box key={question?.id}>
                <Questions
                  order={question?.order}
                  text={question?.text}
                  control={control}
                  errors={errors}
                />
                {filterDisapprovedAnswers(question?.liaAnswers)?.map(
                  (answer) => (
                    <DpoReason answer={answer} key={answer?.id} />
                  ),
                )}
              </Box>
            ))}
          </Paper>
        )}
      </form>
    </Box>
  )
}

export default DisapprovedLia
