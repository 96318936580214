import * as yup from 'yup'

import helpers from 'helpers'

const schema = yup.object({
  publicDocument: yup.boolean().required(),
  collectAccepted: yup.boolean().required(),
  category: yup.string().required(),
  otherCategory: yup
    .string()
    .max(255)
    .when('category', {
      is: 'other',
      then: (schemaOption) => schemaOption.required(),
    }),
  title: yup.string().max(255).required(),
  content: yup
    .string()
    .required()
    .test(
      'hasContent',
      'Preencha esse campo',
      helpers.validates.jodit.hasContent,
    ),
  allowAutomaticEditing: yup.boolean(),
})

export default schema
