import React from 'react'
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'
import { Download } from 'react-feather'
import { KeyboardDatePicker } from '@material-ui/pickers'

import { FileUploadInput, Select as SelectComponent } from 'components'

import useStyles from './styles'
import constants from 'constants/index'
import { getBaseURL } from 'service/env'

const PartnerInfoForm = () => {
  const classes = useStyles()
  const { control, errors, watch } = useFormContext()

  const simplifiedRegistration = watch('registerType')
  const internationalRegistration = watch('internationalSupplier')

  const thirdPartyManagements = constants.thirdPartyManagements

  const downloadModelSuppliers = () => {
    const link = simplifiedRegistration
      ? 'modelo_importacao_de_fornecedores_via_link.xlsx'
      : 'modelo_importacao_de_fornecedores.xlsx'

    window.open(`${getBaseURL('dponet')}/${link}/`, '_blank')
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column">
          {!internationalRegistration && (
            <FormControlLabel
              control={
                <Controller
                  as={<Switch color="primary" />}
                  name="registerType"
                  control={control}
                />
              }
              label="Cadastro Simplificado"
              labelPlacement="end"
            />
          )}

          {!simplifiedRegistration && (
            <FormControlLabel
              control={
                <Controller
                  as={<Switch color="primary" />}
                  name="internationalSupplier"
                  control={control}
                />
              }
              label="Parceiro Internacional"
              labelPlacement="end"
            />
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Para importar parceiros, é necessário seguir o modelo de planilha que
          disponibilizamos. Caso não seja seguido, podem ocorrer falhas na
          importação dos dados.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          color="primary"
          variant="contained"
          size="large"
          endIcon={<Download size={20} />}
          onClick={downloadModelSuppliers}
        >
          Baixar Modelo
        </Button>
      </Grid>
      <Grid item xs={12}>
        <FileUploadInput
          accept=".xlsx"
          title="Arraste e solte ou selecione o documento"
          controlName="document"
        />
      </Grid>

      {!simplifiedRegistration && (
        <Grid item xs={12}>
          <Controller
            as={
              <KeyboardDatePicker
                disablePast
                fullWidth
                format="DD/MM/yyyy"
                label="Data para o disparo do e-mail"
                helperText={errors?.date?.message}
                error={!!errors?.date}
                invalidDateMessage="Data inválida"
              />
            }
            defaultValue={watch('date') || null}
            control={control}
            name="date"
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Controller
              as={<Switch color="primary" />}
              control={control}
              name="supplierAudit"
            />
          }
          label="Auditoria de Fornecedor"
          labelPlacement="end"
          classes={{ label: classes.formLabelSupplierAudit }}
        />
      </Grid>

      {watch('supplierAudit') && (
        <>
          <Grid item xs={12}>
            <Typography variant="body1">
              Após o fornecedor responder aos questionários, com que frequência
              você deseja que o notifiquemos para revisar e atualizar as
              respostas conforme a nova situação da organização?
            </Typography>
          </Grid>
          <Grid item xs={6} sm={4}>
            <Controller
              as={
                <TextField
                  fullWidth
                  type="number"
                  error={!!errors.supplierAuditFrequency}
                  helperText={errors.supplierAuditFrequency?.message}
                />
              }
              control={control}
              name="supplierAuditFrequency"
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <Controller
              as={
                <SelectComponent
                  fullWidth
                  error={!!errors.supplierAuditFrequencyType}
                  helperText={errors.supplierAuditFrequencyType?.message}
                  items={thirdPartyManagements.SUPPLIER_AUDIT_FREQUENCY_TYPES}
                />
              }
              control={control}
              name="supplierAuditFrequencyType"
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default PartnerInfoForm
