import React from 'react'
import { Box, Grid, Paper, Typography } from '@material-ui/core'
import {
  AlignJustify as AlignJustifyIcon,
  Mail as MailIcon,
  Search as SearchIcon,
  ShoppingCart as ShoppingCartIcon,
  TrendingUp as TrendingUpIcon,
  User as UserIcon,
  Folder as FolderIcon,
} from 'react-feather'

import { InfoItem } from 'views/ThirdPartyManagements/components'

import useStyles from './styles'
import helpers from 'helpers'

const OptionalSection = ({ supplierInvite }) => {
  const classes = useStyles()

  const companySupplierDetail = supplierInvite?.companySupplierDetail || {}

  const infos = [
    {
      label: 'Risco',
      value: helpers.thirdPartyManegementPartners.getRiskLabelByEnum(
        companySupplierDetail?.riskLevel,
      ),
      Icon: TrendingUpIcon,
    },
    {
      label: 'Segmento',
      value: companySupplierDetail?.segment?.name,
      Icon: ShoppingCartIcon,
    },
    {
      label: 'Agente de tratamento',
      value: helpers.thirdPartyManegementPartners.getTreatmentAgentLabelByEnum(
        companySupplierDetail?.treatmentAgentType,
      ),
      Icon: UserIcon,
    },
    {
      label: 'Auditoria',
      value: companySupplierDetail?.enableAudit
        ? `Sim. Com intervalo de ${
            companySupplierDetail?.auditFrequency
          } ${helpers.thirdPartyManegementPartners.getAuditFrequencyByEnum(
            companySupplierDetail?.auditFrequencyType,
          )}`
        : 'Não',
      Icon: MailIcon,
    },
    {
      label: 'Data da contratação',
      value: helpers.formatters.date(companySupplierDetail?.hireDate),
      Icon: SearchIcon,
    },
    {
      label: 'Data da renovação',
      value: helpers.formatters.date(companySupplierDetail?.renewDate),
      Icon: SearchIcon,
    },
    {
      label: 'Documento',
      value: companySupplierDetail?.attachment,
      Icon: FolderIcon,
      file: true,
    },
  ]

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Dados opcionais
      </Typography>

      <Paper component={Box} p={3} className={classes.paper}>
        <Grid container spacing={3}>
          {infos.map((info, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <InfoItem {...info} />
            </Grid>
          ))}

          <Grid item xs={12}>
            <InfoItem
              label="Descrição"
              value={companySupplierDetail?.description?.trim()}
              Icon={AlignJustifyIcon}
              overflow={false}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default OptionalSection
