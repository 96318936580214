import React, { useState } from 'react'

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Divider,
  Typography,
  Button,
} from '@material-ui/core'

import useSnackbar from 'hooks/useSnackbar'

import * as service from 'service'

import helpers from 'helpers'

const DialogDestroy = ({
  departmentId,
  open,
  setOpen = () => {},
  onEvent = () => {},
}) => {
  const snackbar = useSnackbar()
  const [loading, setLoading] = useState(false)

  const destroy = async (departmentId) => {
    try {
      setLoading(true)
      await service.dponet.departments.destroy(departmentId)
      snackbar.open({
        message: 'Departamento excluído com sucesso!',
        variant: 'success',
      })
      onEvent()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Ocorreu um erro ao excluir o departamento.',
        variant: 'error',
      })
    } finally {
      setOpen(false)
      setLoading(false)
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Box>
          <Typography variant="h4">Excluir departamento</Typography>
        </Box>
      </DialogTitle>
      <Divider />
      <Box mb={1}>
        <DialogContent>
          <Box>
            <Typography>
              Tem certeza que deseja excluir este departamento? Essa ação não
              pode ser desfeita.
            </Typography>
          </Box>
        </DialogContent>
      </Box>
      <Divider />
      <DialogActions p={2}>
        <Box pl={2} pr={1}>
          <Button
            disabled={loading}
            type="button"
            variant="outlined"
            onClick={() => setOpen(false)}
          >
            Cancelar
          </Button>
        </Box>
        <Button
          disabled={loading}
          variant="contained"
          type="submit"
          color="primary"
          onClick={() => destroy(departmentId)}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogDestroy
