import constants from 'constants/index'

const idToValue = (id) => {
  if (constants.fragilities.UNDEFINED_ID === id)
    return constants.fragilities.UNDEFINED_DEFAULT

  return id
}

export default idToValue
