import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import PerfectScrollbar from 'react-perfect-scrollbar'
import ReCAPTCHA from 'react-google-recaptcha'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { orderBy } from 'lodash'
import DOMPurify from 'dompurify'
import { Avatar, Box, Button, Grid, Typography } from '@material-ui/core'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'
import useConsent from 'hooks/useConsent'
import useAuth from 'hooks/useAuth'

import theme from 'theme'
import useStyles from './styles'
import * as service from 'service'
import constants from 'constants/index'
import { getBaseURL, getGoogleRecaptchaToken } from 'service/env'

const Preview = ({
  setLoading,
  isAccepted = false,
  refresh = () => {},
  consentFormRefresh = () => {},
}) => {
  const [recaptcha, setRecaptcha] = useState('')

  const {
    nextStep,
    toNextStep,
    setToNextStep,
    dataConsentForm,
    setDataConsentForm,
  } = useConsent()
  const { company } = useAuth()
  const recaptchaRef = useRef(null)

  const classes = useStyles()
  const snackbar = useSnackbar()

  const logoPath =
    company?.logo?.url && `${getBaseURL('dponet')}${company?.logo?.url}`

  const components = orderBy(
    Object.entries(dataConsentForm.components),
    ([_, item]) => item.order,
  )

  const { handleSubmit, errors } = useForm()

  const expiredRecaptcha = () => setRecaptcha('')

  const handleRecaptcha = (token) => token && setRecaptcha(token)

  const avatarLetters = (company) =>
    (company?.name || 'Empresa cliente')
      .split(' ')
      .map((word) => word[0])
      .join('')

  const onSubmit = async () => {
    if (isAccepted) {
      handleCreateAccept()
    } else {
      nextStep()
      setToNextStep(false)
    }
  }

  const handleCreateAccept = async () => {
    try {
      setLoading(true)
      const creationResponse = await service.dponet.consent.create({
        consentForm: {
          ...dataConsentForm,
          components: JSON.stringify(dataConsentForm?.components),
        },
      })

      const token = creationResponse?.data?.token

      await setDataConsentForm((prevValue) => ({
        ...prevValue,
        token,
      }))

      snackbar.open({
        message: 'Aceite criado com sucesso!',
        variant: 'success',
      })
      refresh()
      consentFormRefresh()
      nextStep()
      setToNextStep(false)
    } catch (error) {
      console.error(error)
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          `Ocorreu uma falha ao tentar criar o aceite!`,
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (toNextStep) {
      handleSubmit(onSubmit)()
      if (errors) setToNextStep(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toNextStep])

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      id={constants.consent.NEW_CONSENT_FORM_DRIVER_STEP_3}
    >
      <Box className={classes.headerBox}>
        <Typography variant="h3" align="center" className={classes.title}>
          {dataConsentForm?.title}
        </Typography>
        {dataConsentForm?.subtitle && (
          <Typography variant="h4" align="center" className={classes.title}>
            {dataConsentForm?.subtitle}
          </Typography>
        )}
      </Box>
      <Box p={5} className={classes.contentBox}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="start"
              alignItems="center"
              width="100%"
              height="100%"
            >
              <Box marginRight={3}>
                <Avatar
                  className={classes.avatar}
                  src={logoPath}
                  variant="rounded"
                  style={{ borderRadius: logoPath ? 0 : 24 }}
                >
                  {avatarLetters(company)[0]}
                  {avatarLetters(company)[avatarLetters(company).length - 1]}
                </Avatar>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
              >
                <Typography variant="h5" className={classes.title}>
                  {company?.name}
                </Typography>
                <Typography variant="h5" className={classes.title}>
                  {company?.companyName}
                </Typography>
                <Box display="flex">
                  <Typography
                    variant="h5"
                    className={clsx(classes.document, classes.title)}
                  >
                    {company?.cnpj ? 'CNPJ: ' : 'CPF: '}
                    <strong>
                      {helpers.formatters.cnpjOrCpf(
                        company?.cnpj || company?.cpf,
                      )}
                    </strong>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box py={2} pl={2} className={classes.descriptionBox}>
              <PerfectScrollbar
                options={{ wheelPropagation: false, useBothWheelAxes: true }}
                className={classes.scrollBar}
              >
                <Box
                  pr={2}
                  fontFamily="Roboto"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(dataConsentForm?.content),
                  }}
                />
              </PerfectScrollbar>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="column"
              gridGap={theme.spacing(2)}
            >
              {components.map(([key, value]) => (
                <Box key={key}>
                  <Box display="flex" flexDirection="column" width="100%">
                    <Typography variant="h6" className={classes.labelButton}>
                      {key}
                      {value?.required && (
                        <span className={classes.requiredSpan}> *</span>
                      )}
                    </Typography>
                    <Typography
                      variant="caption"
                      className={classes.labelDescription}
                    >
                      {value?.subtitle}
                    </Typography>
                    <input type="text" className={classes.inputText} disabled />
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" gridGap={theme.spacing(2)} alignItems="center">
              <ReCAPTCHA
                ref={recaptchaRef}
                theme="light"
                sitekey={getGoogleRecaptchaToken()}
                onChange={(token) => handleRecaptcha(token)}
                onExpired={expiredRecaptcha}
              />
              <Box width="100%">
                <Button
                  className={classes.authorizeButton}
                  disabled={!recaptcha}
                  variant="contained"
                  size="large"
                  fullWidth
                >
                  <Typography variant="h5" noWrap>
                    {dataConsentForm?.buttonText ?? 'EU AUTORIZO'}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

Preview.propTypes = {
  isAccepted: PropTypes.bool,
  setLoading: PropTypes.func,
  refresh: PropTypes.func,
  consentFormRefresh: PropTypes.func,
}

export default Preview
