import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Typography, Box } from '@material-ui/core'

import { Card } from 'components'
import { Table, TableBody, TableCell, TableRow } from 'components/Table'

const BaseMultiPermissionsForm = ({
  title,
  permissionsLists,
  register,
  permissions = {},
  handleChange = () => {},
  activeProfile,
  isEdit,
}) => {
  const handleSwitchOnChange = (event, tag) => {
    if (activeProfile || !isEdit) {
      handleChange(event.target.checked, tag)
    }
  }

  return (
    <Card dropdown title={title}>
      <Table>
        <TableBody>
          {permissionsLists?.map((permissionsList) => (
            <>
              {permissionsList.title && (
                <Box p={2}>
                  <Typography variant="h5">{permissionsList.title}</Typography>
                </Box>
              )}
              {permissionsList.permissions.map((permission) => (
                <TableRow key={permission.tag}>
                  <TableCell width="90%" align="left">
                    <Box ml={2}>
                      <Typography variant="subtitle1">
                        {permission.name}
                      </Typography>
                      <Typography variant="body2">
                        {permission?.description}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell width="10%" align="left" disableTooltip>
                    <Switch
                      color="primary"
                      name={`permissions[${permission.tag}]`}
                      inputRef={register}
                      checked={permissions[permission.tag] || false}
                      onChange={(event) =>
                        handleSwitchOnChange(event, permission.tag)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </>
          ))}
        </TableBody>
      </Table>
    </Card>
  )
}

BaseMultiPermissionsForm.propTypes = {
  title: PropTypes.string,
  permissionsLists: PropTypes.array,
  register: PropTypes.func,
  permissions: PropTypes.object,
  handleChange: PropTypes.func,
  activeProfile: PropTypes.bool,
}

export default BaseMultiPermissionsForm
