import React, { useState } from 'react'
import { Grid, Paper, TablePagination } from '@material-ui/core'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { ChipStatus, StatusesTab } from '../table'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'

import usePagination from 'hooks/usePagination'
import useFetch from 'hooks/useFetch'

import * as service from 'service'
import constants from 'constants/index'
import { Actions } from './components'

const ImPartnerTable = ({ filter, preAvaliation = false }) => {
  const [selectedTab, setSelectedTab] = useState('all')

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  const companySupplierConstants = constants.companySuppliers

  const filterStatus = () => {
    if (selectedTab === 'all')
      return [
        companySupplierConstants.APPROVED_STATUS,
        companySupplierConstants.INACTIVE_STATUS,
      ]

    return selectedTab
  }

  const { response, isLoading } = useFetch(
    service.dponet.suppliers.supplierIndex,
    {
      status: filterStatus(),
      perPage,
      page,
      preAvaliation: preAvaliation,
      ...filter.filters,
    },
    [filter.filters, perPage, page, selectedTab],
  )

  const companySuppliers = response?.data?.companySuppliers || []

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <StatusesTab
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <PerfectScrollbar>
            <Table
              emptyMessage="Nenhum parceiro encontrado"
              isLoading={isLoading}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Identificador</TableCell>
                  <TableCell>Razão Social</TableCell>
                  <TableCell>Documento</TableCell>
                  <TableCell align="center">Status do vínculo</TableCell>
                  <TableCell
                    id={
                      preAvaliation
                        ? constants.tours.thirdPartyManagements.STEPS
                            .PRE_AVALIATION[5]
                        : constants.tours.thirdPartyManagements.STEPS
                            .PARTNERS[5]
                    }
                    align="right"
                  >
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {companySuppliers.map((companySupplier) => (
                  <TableRow key={companySupplier?.id}>
                    <TableCell>{companySupplier?.id}</TableCell>
                    <TableCell>{companySupplier?.company?.name}</TableCell>
                    <TableCell>{companySupplier?.company?.document}</TableCell>
                    <TableCell align="center">
                      <ChipStatus status={companySupplier?.status} />
                    </TableCell>
                    <TableCell align="right">
                      <Actions companySupplier={companySupplier} />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </PerfectScrollbar>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ImPartnerTable
