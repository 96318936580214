import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Box, colors } from '@material-ui/core'

import BannerPreview from '../BannerPreview'

import constants from 'constants/index'

const PositionPreview = ({ description }) => {
  const [openBanner, setOpenBanner] = useState(false)

  const { watch } = useFormContext()

  const position = watch('position')

  const isFixed = position === constants.cookies.banner.BANNER_FIXED_POSITION

  return (
    <Box
      key={isFixed ? 'fixed' : 'not-fixed'}
      bgcolor={colors.grey[200]}
      height="100%"
      borderRadius={8}
      p={!isFixed ? 2 : 0}
    >
      <BannerPreview
        position={position}
        iconProps={{ p: isFixed ? 2 : 0 }}
        openBanner={openBanner}
        setOpenBanner={setOpenBanner}
        description={description}
      />
    </Box>
  )
}

export default PositionPreview
