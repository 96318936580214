import React from 'react'
import { Box, IconButton, Paper, Tooltip, Typography } from '@material-ui/core'
import { Eye as EyeIcon } from 'react-feather'
import clsx from 'clsx'

import useStyles from './styles'

const QuestionnaireCard = ({
  questionnaire = {},
  handleClick = () => {},
  selected = false,
}) => {
  const classes = useStyles()

  return (
    <Paper
      component={Box}
      px={3}
      py={2}
      className={clsx({
        [classes.paper]: !selected,
        [classes.paperSelected]: selected,
      })}
    >
      <Box className={classes.gridContainer}>
        <Box>
          <Tooltip title={questionnaire.kind} placement="top">
            <Typography variant="body1" className={classes.kindTypography}>
              {questionnaire.kind}
            </Typography>
          </Tooltip>
          <Typography variant="body1">
            {questionnaire.answerPercentage === 0
              ? 'Não respondido'
              : `${questionnaire.complianceGap}%`}
          </Typography>
        </Box>
        <IconButton size="small" onClick={handleClick}>
          <EyeIcon
            className={clsx({
              [classes.icon]: !selected,
              [classes.iconSelected]: selected,
            })}
            size={24}
          />
        </IconButton>
      </Box>
    </Paper>
  )
}

export default QuestionnaireCard
