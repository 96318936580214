import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ companyFragilityId = '', ...params }) => {
  return await dponetAPI.get(`/company_fragilities/${companyFragilityId}`, {
    params,
  })
}

const getByDataProcess = async ({ dataProcessId = '', ...params }) => {
  return await dponetAPI.get(
    `/company_fragilities/index_by_data_process/${dataProcessId}`,
    { params },
  )
}

const update = async ({ companyFragilityId, ...data }) => {
  return await dponetAPI.put(`/company_fragilities/${companyFragilityId}`, data)
}

const create = async (data) => {
  return await dponetAPI.post('/company_fragilities', data)
}

const destroy = async (companyFragilityId) => {
  return await dponetAPI.delete(`/company_fragilities/${companyFragilityId}`)
}

const destroyQuestionControl = async ({
  companyFragilityId,
  questionControlId,
}) => {
  return await dponetAPI.delete(
    `/company_fragilities/${companyFragilityId}/question_control/${questionControlId}`,
  )
}

const manageQuestionControl = async ({ companyFragilityId, ...data }) => {
  return await dponetAPI.put(
    `/company_fragilities/${companyFragilityId}/manage_question_controls`,
    data,
  )
}

const destroyFragilityEntity = async ({
  companyFragilityId,
  fragilityEntityId,
}) => {
  return await dponetAPI.delete(
    `/company_fragilities/${companyFragilityId}/fragility_entity/${fragilityEntityId}`,
  )
}

const manageFragilityEntities = async ({ companyFragilityId, ...data }) => {
  return await dponetAPI.put(
    `/company_fragilities/${companyFragilityId}/manage_fragility_entities`,
    data,
  )
}

const manageDataProcess = async ({ dataProcessId, ...data }) => {
  return await dponetAPI.put(
    `company_fragilities/manage_data_processes/${dataProcessId}`,
    data,
  )
}

export default {
  create,
  destroy,
  destroyFragilityEntity,
  destroyQuestionControl,
  get,
  getByDataProcess,
  manageDataProcess,
  manageFragilityEntities,
  manageQuestionControl,
  update,
}
