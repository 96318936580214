import activities from './activities'
import alert from './alert'
import auditProcess from './auditProcess'
import audits from './audits'
import cardFlag from './cardFlags'
import company from './company'
import companySuppliers from './companySuppliers'
import companyTrail from './companyTrail'
import complaint from './complaint'
import consent from './consent'
import cookies from './cookies'
import dataCollected from './dataCollected'
import dataProcess from './dataProcess'
import dataTreatmentAgents from './dataTreatmentAgents'
import date from './date'
import departments from './departments'
import diffViewer from './diffViewer'
import discountCoupon from './discountCoupon'
import documents from './documents'
import documentsValidations from './documentsValidations'
import faqsDocumentations from './faqsDocumentations'
import fragilities from './fragilities'
import fragilityEntities from './fragilityEntities'
import googleTagManager from './googleTagManager'
import incidentAcceptTexts from './incidentAcceptTexts'
import incidentManegement from './incidentManegement'
import incidents from './incidents'
import inviteAdvisor from './inviteAdvisor'
import jodit from './jodit'
import lastUpdates from './lastUpdates'
import legalFrameworks from './legalFrameworks'
import liaAnswers from './liaAnswers'
import liaQuestions from './liaQuestions'
import logs from './logs'
import marketplace from './marketplace'
import moodle from './moodle'
import myLgpd from './myLgpd'
import necessityAndProportionalities from './necessityAndProportionalities'
import nonComplianceReports from './nonComplianceReports'
import order from './order'
import orderFilter from './orderFilter'
import outsourcedDpo from './outsourcedDpo'
import partner from './partner'
import permissions from './permissions'
import permissionsLists from './permissionsLists'
import preRegistrations from './preRegistrations'
import privacyStamps from './privacyStamps'
import profile from './profile'
import questionControl from './questionControl'
import questionnaire from './questionnaire'
import questions from './questions'
import recurrence from './recurrence'
import reports from './reports'
import reportVersions from './reportVersions'
import ripdRisk from './ripdRisk'
import settings from './settings'
import sharedSoftware from './sharedSoftware'
import suggestionChanges from './dataProcessSuggestionChanges'
import supplierInvite from './supplierInvite'
import supplierSuggestion from './supplierSuggestion'
import technicalFaqs from './technicalFaqs'
import theme from './theme'
import thirdPartyManagements from './thirdPartyManagements'
import tickets from './tickets'
import tours from './tours'
import trailsJourney from './trailsJourney'
import unnecessaryData from './unnecessaryData'
import user from './user'
import userCompanies from './userCompanies'
import userSteps from './userSteps'
import userTrailSteps from './userTrailSteps'
import validations from './validations'

const constants = {
  activities,
  alert,
  auditProcess,
  audits,
  cardFlag,
  company,
  companySuppliers,
  companyTrail,
  complaint,
  consent,
  cookies,
  dataCollected,
  dataProcess,
  dataTreatmentAgents,
  date,
  departments,
  diffViewer,
  discountCoupon,
  documents,
  documentsValidations,
  faqsDocumentations,
  fragilities,
  fragilityEntities,
  googleTagManager,
  incidentAcceptTexts,
  incidentManegement,
  incidents,
  inviteAdvisor,
  jodit,
  lastUpdates,
  legalFrameworks,
  liaAnswers,
  liaQuestions,
  logs,
  marketplace,
  moodle,
  myLgpd,
  necessityAndProportionalities,
  nonComplianceReports,
  order,
  orderFilter,
  outsourcedDpo,
  partner,
  permissions,
  permissionsLists,
  preRegistrations,
  privacyStamps,
  profile,
  questionControl,
  questionnaire,
  questions,
  recurrence,
  reports,
  reportVersions,
  ripdRisk,
  settings,
  sharedSoftware,
  suggestionChanges,
  supplierInvite,
  supplierSuggestion,
  technicalFaqs,
  theme,
  tickets,
  tours,
  trailsJourney,
  unnecessaryData,
  user,
  userCompanies,
  userSteps,
  userTrailSteps,
  validations,
  thirdPartyManagements,
}

export default constants
