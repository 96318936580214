import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  valueBox: {
    color: theme.palette.primary.main,
    height: 190,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.white,
    borderRadius: 5,
  },
  valueTypography: {
    fontSize: 70,
  },
  labelBox: {
    textAlign: 'center',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
  supplierContainer: {
    border: '2px solid',
    borderColor: theme.palette.primary.main,
    borderRadius: 5,
  },
  titleTypography: {
    fontWeight: 800,
  },
}))

export default styles
