import React from 'react'
import { Box, lighten, Paper, Typography } from '@material-ui/core'

import { BarChart } from 'components'

import useStyles from './styles'
import theme from 'theme'

const QuestionnairesCompliance = ({ questionnaires = [] }) => {
  const classes = useStyles()

  const kinds = questionnaires.map(
    (questionnaire) => questionnaire.questionnaireKind,
  )
  const series = questionnaires.map((questionnaire) =>
    Math.round(questionnaire.averageComplianceGap),
  )

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Conformidade dos seus parceiros por questionários (%)
      </Typography>

      <Paper className={classes.paper}>
        <BarChart
          colors={[theme.palette.primary.main]}
          series={[{ name: 'Conformidade (%)', data: series }]}
          names={kinds}
          heightValue={205}
          borderRadius={7}
          enabledTotal
          categoryFontSize={10}
          gradient
          gradientColor={lighten(theme.palette.azure, 0.5)}
          max={100}
        />
      </Paper>
    </Box>
  )
}

export default QuestionnairesCompliance
