import React from 'react'
import PropTypes from 'prop-types'

import { Filters } from 'components'

import constants from 'constants/index'

const MyDocumentsFilters = ({ filter }) => {
  const { CATEGORY_OPTIONS, STATUS_OPTIONS } = constants.documents

  return (
    <Filters filter={filter} alphabeticalOrder alphabeticalFieldOrder="title">
      <input textfieldinput="true" label="Identificador" name="id" />
      <input textfieldinput="true" label="Título" name="title" />
      <select textfieldinput="true" label="Categoria" name="category">
        <option value=""></option>
        {CATEGORY_OPTIONS?.map((category) => (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        ))}
      </select>
      <select
        textfieldinput="true"
        label="Canal de atendimento"
        name="publicDocument"
      >
        <option value=""></option>
        <option value={true}>Publicados</option>
        <option value={false}>Não publicados</option>
      </select>
      <select
        textfieldinput="true"
        label="Coleta de aceites"
        name="collectAccepted"
      >
        <option value=""></option>
        <option value={true}>Coletando</option>
        <option value={false}>Não coletando</option>
      </select>
      <select textfieldinput="true" label="Status" name="status">
        <option value=""></option>
        {STATUS_OPTIONS.map((status) => (
          <option key={status?.id} value={status?.id}>
            {`${status?.name}s`}
          </option>
        ))}
      </select>
    </Filters>
  )
}

const TemplateFilters = ({ filter, categories }) => {
  return (
    <Filters filter={filter} alphabeticalOrder alphabeticalFieldOrder="title">
      <input textfieldinput="true" label="Identificador" name="id" />
      <input textfieldinput="true" label="Título" name="title" />
      <select textfieldinput="true" label="Categoria" name="documentCategoryId">
        <option value=""></option>
        {categories?.map((category) => (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        ))}
      </select>
    </Filters>
  )
}

const DocumentFilters = ({ filter, categories, kind }) => {
  const {
    DOCUMENTS_CATEGORY_ID,
    DOCUMENTS_TEMPLATES_CATEGORY_ID,
    DEPRECIATED_DOCUMENTS_CATEGORY_ID,
  } = constants.documents

  const CHOOSE_DOCUMENT_FILTER = {
    [DOCUMENTS_CATEGORY_ID]: MyDocumentsFilters,
    [DOCUMENTS_TEMPLATES_CATEGORY_ID]: TemplateFilters,
    [DEPRECIATED_DOCUMENTS_CATEGORY_ID]: TemplateFilters,
  }

  const ChosenFilter = CHOOSE_DOCUMENT_FILTER[kind] || TemplateFilters

  return <ChosenFilter filter={filter} categories={categories} />
}

DocumentFilters.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  filter: PropTypes.object.isRequired,
  kind: PropTypes.string,
}

TemplateFilters.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object),
  filter: PropTypes.object.isRequired,
}

MyDocumentsFilters.propTypes = {
  filter: PropTypes.object.isRequired,
}

export default DocumentFilters
