import React from 'react'
import {
  Box,
  CircularProgress,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
} from '@material-ui/core'

import { QuestionnaireCheckbox } from './components'

import useFetch from 'hooks/useFetch'

import * as service from 'service'
import constants from 'constants/index'

const QuestionnaireForm = ({
  selectedQuestionnaires,
  setSelectedQuestionnaires,
}) => {
  const { response, isLoading } = useFetch(
    service.dponet.questionnaires.get,
    { perPage: 20 },
    [],
  )

  const questionnaires = response?.data?.questionnaires || []

  const sortedQuestionnaires = questionnaires.sort((a, b) => {
    const fundamentalRequirement =
      constants.questionControl.KIND.BASIC_REQUIREMENT

    if (a.kind === fundamentalRequirement) return -1
    if (b.kind === fundamentalRequirement) return 1

    return 0
  })

  const handleChangeQuestionnaire = (questionnaireId) => {
    setSelectedQuestionnaires((prev) => {
      if (prev.includes(questionnaireId)) {
        return prev.filter((id) => id !== questionnaireId)
      }

      return [...prev, questionnaireId]
    })
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            Selecione quais questionários o fornecedor deverá responder
          </FormLabel>

          <FormGroup>
            {isLoading && (
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="center"
                p={4}
              >
                <CircularProgress color="primary" />
              </Box>
            )}

            {sortedQuestionnaires.map((questionnaire) => (
              <QuestionnaireCheckbox
                key={questionnaire.id}
                questionnaire={questionnaire}
                checked={selectedQuestionnaires.includes(questionnaire.id)}
                onChange={() => handleChangeQuestionnaire(questionnaire.id)}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default QuestionnaireForm
