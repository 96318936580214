import * as yup from 'yup'
import helpers from 'helpers'
import constants from 'constants/index'

const schema = yup.object().shape({
  registerType: yup.boolean().required(),
  internationalSupplier: yup.boolean().required(),
  document: yup
    .mixed()
    .test('fileType', 'Somente arquivos XLSX são permitidos', (value) =>
      helpers.validates.file.type(
        [constants.validations.MIME_TYP_VALIDATION.xlsx],
        value,
      ),
    )
    .required(),
  date: yup.mixed().when('registerType', {
    is: false,
    then: yup.date().required().typeError('Preencha uma data válida'),
  }),
  supplierAudit: yup.boolean(),
  supplierAuditFrequency: yup
    .number()
    .nullable()
    .when('supplierAudit', {
      is: true,
      then: yup
        .number()
        .required()
        .min(1, 'A frequência da auditoria deve ser maior que zero'),
    }),
  supplierAuditFrequencyType: yup.string().nullable().when('supplierAudit', {
    is: true,
    then: yup.string().required(),
  }),
})

export default schema
