import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Button } from '@material-ui/core'

const Footer = () => {
  const history = useHistory()

  const handleReturn = () => {
    history.goBack()
  }

  return (
    <Box display="flex" justifyContent="flex-end" mt={3}>
      <Button variant="outlined" onClick={handleReturn}>
        Voltar
      </Button>
    </Box>
  )
}

export default Footer
