import { Minus as MinusIcon } from 'react-feather'
import { colors } from '@material-ui/core'

import theme from 'theme'
import constants from 'constants/index'
import { unionBy } from 'lodash'

const chipStatusColor = (status) => {
  return (
    (status && constants.cookies.banner.STATUS_CHIP_COLOR[status]) ||
    colors.grey[300]
  )
}

const chipFontStatusColor = (status) => {
  return (
    (status && constants.cookies.banner.STATUS_CHIP_FONT_COLOR[status]) ||
    colors.grey[800]
  )
}

const chipStatusLabel = (status) => {
  return (
    (status && constants.cookies.banner.STATUS_TRANSLATION[status]) ||
    'indeferido'
  ).toUpperCase()
}

const scannerStatusLabel = (status) => {
  return (
    (status && constants.cookies.banner.SCANNER_STATUS_TRANSLATION[status]) ||
    'indeferido'
  ).toUpperCase()
}

const scannerStatusColor = (status) => {
  return (
    (status && constants.cookies.banner.SCANNER_STATUS_COLOR[status]) ||
    theme.palette.custom.dark
  )
}

const scannerStatusIcon = (status) => {
  return (
    (status && constants.cookies.banner.SCANNER_STATUS_ICON[status]) ||
    MinusIcon
  )
}

const mergeDescriptions = (bannerDescriptions) =>
  unionBy(
    bannerDescriptions,
    constants.cookies.banner.DEFAULT_DESCRIPTIONS,
    (desc) => desc.language.code,
  )

const status = {
  chipFontStatusColor,
  chipStatusColor,
  chipStatusLabel,
  mergeDescriptions,
  scannerStatusColor,
  scannerStatusIcon,
  scannerStatusLabel,
}

export default status
