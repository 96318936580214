import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Box, CircularProgress, Paper, Typography } from '@material-ui/core'
import { useLocation } from 'react-router-dom'

import { TextEditor } from 'components'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'
import useConsent from 'hooks/useConsent'

import theme from 'theme'
import schema from './schema'
import useStyles from './styles'
import constants from 'constants/index'

const ConsentText = () => {
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const templateId = location.state?.templateId

  const snackbar = useSnackbar()
  const classes = useStyles()

  const {
    nextStep,
    toNextStep,
    setToNextStep,
    setDataConsentForm,
    dataConsentForm,
  } = useConsent()

  const { errors, control, handleSubmit, reset, getValues } = useForm({
    validationSchema: schema,
    defaultValues: {
      content: dataConsentForm?.content ?? '',
    },
  })

  useEffect(() => {
    if (toNextStep) {
      handleSubmit(onSubmit)()

      if (errors) setToNextStep(false)
    }
    // eslint-disable-next-line
  }, [toNextStep])

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      setLoading(false)

      const mergedData = {
        ...dataConsentForm,
        ...data,
      }

      setDataConsentForm(mergedData)

      snackbar.open({
        message: 'Texto de consentimento criado com sucesso',
        variant: 'success',
      })

      nextStep()
      setToNextStep(false)
    } catch (error) {
      reset(getValues())
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response?.data?.error),
        variant: 'error',
      })
    }
  }

  return (
    <>
      {loading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          id={constants.consent.NEW_CONSENT_FORM_DRIVER_STEP_1}
        >
          <Box display="flex" flexDirection="column" gridGap={theme.spacing(3)}>
            <Paper variant="outlined" className={classes.boxTitle}>
              <Typography variant="subtitle2">
                {dataConsentForm?.title}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {dataConsentForm?.subtitle}
              </Typography>
            </Paper>
            <Box>
              <Typography variant="subtitle2">
                Descrição do Formulário
              </Typography>
              <Typography variant="caption" color="textSecondary">
                Descreva qual o consentimento está sendo dado pelo usuário ao
                fornecer seus dados e concordar com os termos.
              </Typography>
              {!!templateId && (
                <Box p={2} mt={1} className={classes.alertBox}>
                  <Typography variant="subtitle2" className={classes.alert}>
                    IMPORTANTE: O conteúdo do Modelo de Formulário é
                    exemplificativo. REVISE todos os termos, data e informações
                    antes de prosseguir.
                  </Typography>
                </Box>
              )}
            </Box>
            <Controller
              control={control}
              name="content"
              as={
                <TextEditor
                  error={!!errors?.content}
                  helperText={errors?.content?.message}
                  configRest={{ height: 460 }}
                />
              }
              mode="onChange"
            />
          </Box>
        </form>
      )}
    </>
  )
}

export default ConsentText
