import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  groupTypography: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.palette.common.white,
  },
  formTag: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: '30px',
    },
  },
  confirmDataHeading: {
    fontFamily: 'Raleway',
    color: 'white',
    fontSize: theme.typography.pxToRem(54),
    fontWeight: 700,
    '& span': {
      backgroundColor: theme.palette.subscription.warning.success,
    },
  },
  label: {
    fontSize: theme.typography.pxToRem(19),
    color: theme.palette.subscription.primary.light,
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(9),
    },
  },
  input: {
    backgroundColor: theme.palette.common.white,
    border: 'none',
    borderRadius: 5,
    '& input': {
      color: theme.palette.subscription.primary.main,
      fontSize: theme.typography.pxToRem(19),
      padding: 10,
      fontWeight: 700,
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
      },
    },
  },
  roundedButton: {
    borderRadius: 29,
    marginTop: 20,
    fontSize: 16,
    letterSpacing: 1.78,
    width: 350,
    fontWeight: '500 !important',
    padding: `${theme.spacing(1.5)}px ${theme.spacing(4)}px`,
    boxShadow: `0px 0px 12px ${theme.colors.firstStep.button.primary}`,
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: theme.colors.firstStep.button.primary,
    },
    backgroundColor: theme.colors.firstStep.button.primary,
    color: theme.palette.common.white,
  },
  text: {
    fontSize: 30,
    fontFamily: 'Raleway',
    fontWeight: 'bold',
    lineHeight: 1.2,
    [theme.breakpoints.down('md')]: {
      fontSize: 20,
    },
  },
  textWelcome: {
    fontSize: 50,
    paddingBottom: '30px',
    [theme.breakpoints.down('md')]: {
      fontSize: 40,
      paddingBottom: '15px',
    },
  },
}))

export default styles
