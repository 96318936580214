import api from 'service/api'

const dponetAPI = api.create('dponet', false)

const checkInvite = async ({ ...supplierParams }, companyToken) =>
  await dponetAPI.post(
    `/supplier_invites/${companyToken}/check_invite`,
    supplierParams,
  )

const checkPermission = async ({ companyToken, supplierId = '', ...data }) =>
  await dponetAPI.post(
    `/supplier_invites/${companyToken}/check_permission/${supplierId}`,
    data,
  )

const checkSupplierRelations = async ({ ...supplierParams }, companyToken) =>
  await dponetAPI.post(
    `/supplier_invites/${companyToken}/check_supplier_relations`,
    supplierParams,
  )

const createSupplierRelations = async ({ ...supplierParams }, companyToken) =>
  await dponetAPI.post(
    `/supplier_invites/${companyToken}/create_supplier_relations`,
    supplierParams,
  )

const inviterInfos = async (companyToken) =>
  await dponetAPI.get(`/supplier_invites/${companyToken}/company_infos`)

const get = async ({ supplierInviteId = '', ...params }) =>
  await dponetAPI.get(`/supplier_invites/${supplierInviteId}`, { params })

const destroy = async ({ supplierInviteId = '' }) =>
  await dponetAPI.delete(`/supplier_invites/${supplierInviteId}`)

const upgradePreAvaliation = async ({ supplierInviteId = '' }) =>
  await dponetAPI.put(
    `/supplier_invites/${supplierInviteId}/pre_avaliation/upgrade`,
  )

const supplierInvite = {
  checkInvite,
  checkPermission,
  checkSupplierRelations,
  createSupplierRelations,
  destroy,
  get,
  inviterInfos,
  upgradePreAvaliation,
}

export default supplierInvite
