import React from 'react'

import { FilterButton, Filters } from 'components'

const FiltersComponent = ({ filter = {}, selectedButton }) => {
  return (
    <>
      <FilterButton setDrawerOpen={filter.setDrawerOpen} />

      {selectedButton === 'my-suppliers' ? (
        <Filters filter={filter} key={selectedButton}>
          <input textfieldinput="true" label="Identificador" name="id" />

          <input
            textfieldinput="true"
            label="Razão Social"
            name="supplier_name"
          />

          <input
            textfieldinput="true"
            label="Documento"
            name="supplier_document"
          />
        </Filters>
      ) : (
        <Filters filter={filter} key={selectedButton}>
          <input textfieldinput="true" label="Identificador" name="id" />

          <input
            textfieldinput="true"
            label="Razão Social"
            name="company_name"
          />

          <input
            textfieldinput="true"
            label="Documento"
            name="company_document"
          />
        </Filters>
      )}
    </>
  )
}

export default FiltersComponent
