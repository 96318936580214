import React from 'react'
import {
  Box,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Controller, useFormContext } from 'react-hook-form'

import helpers from 'helpers'

const RequiredForm = ({ isEdit, editionPermitted }) => {
  const { control, watch, errors, setValue } = useFormContext()

  const simplifiedRegistration = watch('simplified')
  const internationalRegistration = watch('international')

  const handleChangeInternational = ([event]) => {
    setValue('document', '')

    return event.target.checked
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box display="flex" flexDirection="column">
          {!internationalRegistration && (
            <FormControlLabel
              control={
                <Controller
                  as={<Switch color="primary" />}
                  name="simplified"
                  control={control}
                />
              }
              label="Cadastro Simplificado"
              labelPlacement="end"
              disabled={isEdit}
            />
          )}

          {!simplifiedRegistration && (
            <FormControlLabel
              control={
                <Controller
                  as={<Switch color="primary" />}
                  name="international"
                  control={control}
                />
              }
              label="Parceiro Internacional"
              labelPlacement="end"
              onChange={handleChangeInternational}
              disabled={isEdit}
            />
          )}
        </Box>
      </Grid>

      {!simplifiedRegistration && (
        <Grid item xs={12}>
          <Controller
            as={
              <TextField
                fullWidth
                label="Razão Social"
                helperText={errors?.name?.message}
                error={!!errors?.name}
                disabled={isEdit && !editionPermitted}
              />
            }
            control={control}
            name="name"
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <Controller
          as={
            <TextField
              fullWidth
              label={internationalRegistration ? 'Documento' : 'CNPJ/CPF'}
              helperText={errors?.document?.message}
              error={!!errors?.document}
              disabled={isEdit}
            />
          }
          onChange={([event]) => {
            if (internationalRegistration) return event

            return helpers.formatters.documentFormatter(event.target.value)
          }}
          control={control}
          name="document"
        />
      </Grid>

      {!simplifiedRegistration && (
        <>
          <Grid item xs={12} md={8}>
            <Controller
              as={
                <TextField
                  fullWidth
                  label="E-mail de um responsável do fornecedor"
                  helperText={errors?.email?.message}
                  error={!!errors?.email}
                  disabled={isEdit && !editionPermitted}
                />
              }
              control={control}
              name="email"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Controller
              as={
                <KeyboardDatePicker
                  disablePast
                  fullWidth
                  format="DD/MM/yyyy"
                  label="Data para o disparo do e-mail"
                  helperText={errors?.date?.message}
                  error={!!errors?.date}
                  invalidDateMessage="Data inválida"
                  disabled={isEdit && !editionPermitted}
                />
              }
              defaultValue={watch('date') || null}
              control={control}
              name="date"
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default RequiredForm
