import React from 'react'
import { Copy as CopyIcon } from 'react-feather'
import PropTypes from 'prop-types'
import { IconButton, InputAdornment, TextField } from '@material-ui/core'

import useClipboard from 'hooks/useClipboard'

import styles from './styles'

const CopyInput = ({ content, disabledCopy = false, style, ...rest }) => {
  const useStyles = styles(style)
  const classes = useStyles()

  const { copy } = useClipboard()

  const handleCopyContent = () => copy(content)

  return (
    <TextField
      variant="outlined"
      fullWidth
      disabled
      value={content}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              size="small"
              className={classes.iconButton}
              onClick={handleCopyContent}
              disabled={!content || disabledCopy}
              color="primary"
            >
              <CopyIcon size={18} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      className={classes.textField}
      {...rest}
    />
  )
}

CopyInput.propTypes = {
  content: PropTypes.string.isRequired,
  disabledCopy: PropTypes.bool,
  style: PropTypes.object,
}

export default CopyInput
