import React from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  TextField,
  MenuItem,
  Typography,
  Tooltip,
} from '@material-ui/core'

const Select = ({
  items,
  fieldName = 'name',
  anotherOption = false,
  anotherText = false,
  ...rest
}) => {
  return (
    <TextField select fullWidth variant="outlined" {...rest}>
      {items?.map((item, index) => (
        <MenuItem key={index} value={item.id}>
          <Box width="100%">
            <Tooltip title={item[fieldName] || ''} placement="top">
              <Typography>{item[fieldName] || ''}</Typography>
            </Tooltip>
          </Box>
        </MenuItem>
      ))}
      {anotherOption && (
        <MenuItem key="another" value="another">
          {anotherText ? 'Outra' : 'Outro'}
        </MenuItem>
      )}
    </TextField>
  )
}

Select.propTypes = {
  items: PropTypes.array.isRequired,
  fieldName: PropTypes.string,
  anotherOption: PropTypes.bool,
  anotherText: PropTypes.bool,
}

export default Select
