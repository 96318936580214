import React, { useEffect, useState } from 'react'
import { isEmpty, isNull } from 'lodash'
import {
  Dialog,
  Typography,
  IconButton,
  Box,
  Divider,
  Button,
} from '@material-ui/core'

import { CalcDaysToFree } from './components'
import { BaseConfirmationDialog, LoadingFeedback } from 'components'

import { X as CloseIcon, CheckCircle as CheckCircleIcon } from 'react-feather'

import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'

import { useHistory, useLocation, matchPath } from 'react-router-dom'
import { routes } from 'Routes'

import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'

import useStyles from './styles'
import logo from 'images/dponet-logo-white.svg'
import clock from 'images/clock.png'

const DialogUseLimitationNotice = () => {
  const history = useHistory()
  const snackbar = useSnackbar()

  const [isOpen, setIsOpen] = useState(false)
  const [rdMarketing, setRdMarketing] = useState(false)
  const [loading, setLoading] = useState(false)

  const { company, setFreeTrialBlock, freeTrialBlock } = useAuth()
  const { pathname } = useLocation()

  const endDate = helpers.formatters.calcDays(company?.demoPartner?.endDate)

  const unauthorizedRoutes = [
    routes.user.new,
    routes.user.edit,
    routes.mySuppliers.new,
    routes.mySuppliers.show,
    routes.mySuppliers.edit,
    routes.supplier.show,
    routes.profiles.new,
    routes.profiles.edit,
    routes.privacyStamp,
    routes.documents,
    routes.incidents.new,
    routes.incidents.edit,
    routes.userTrailSteps,
    routes.technicalFaqs.lgpdTicket,
    routes.technicalFaqs.informationSecurityTicket,
    routes.cookies.settings,
    routes.consent.new,
    routes.consent.show,
    routes.consent.consentReceiptNew,
    routes.consent.consentReceiptDetails,
    routes.complaint.all,
    routes.complaint.show,
  ]

  const unlocked = () => {
    const routesMatch = unauthorizedRoutes.map((route) =>
      matchPath(pathname, { path: route, exact: true }),
    )

    if (isEmpty(routesMatch.filter((item) => !isNull(item)))) {
      setIsOpen(false)
    } else {
      setIsOpen(true)
    }
  }

  useEffect(() => {
    unlocked()
    // eslint-disable-next-line
  }, [pathname])

  const classes = useStyles()

  const isOpenBlock =
    isOpen && company.statusId === constants.userCompanies.TRIAL_LIMITED_STATUS

  const closeDialog = async () => {
    setFreeTrialBlock(false)
    if (isOpenBlock) history.push(routes.myLgpd.dashboard)
  }

  const trailTexts = [
    'Ficar em dia com conformidade da LGPD;',
    'Gerar documentos e relatórios;',
    'Ter um canal de comunicação com titulares e ANPD;',
    'Ter suporte especializado;',
    'Evitar multas e sanções.',
  ]

  const handleNotify = async () => {
    setLoading(true)
    try {
      const response = await service.dponet.companyFreeTrials.notify_hire()
      const success = response?.data?.success

      if (success) {
        setRdMarketing(true)
      } else {
        snackbar.open({
          message: response?.data?.message,
          variant: 'error',
        })
      }
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const handleRedirect = () => {
    const token =
      company?.preRegistrationToken || company?.preRegistration?.token

    if (token) {
      setFreeTrialBlock(false)

      history.push({
        pathname: routes.subscription.default,
        search: `segment=${company?.segment?.name}&token=${token}`,
      })
    } else {
      handleNotify()
    }
  }

  return (
    <Dialog open={freeTrialBlock || isOpenBlock} maxWidth="lg" fullWidth>
      <LoadingFeedback open={loading} />
      <Box id="form-dialog-title">
        <Box component="main" className={classes.root}>
          <Box className={classes.wrapper}>
            <Typography className={classes.title}>
              Desbloqueie todo o potencial da plataforma
            </Typography>
            <Box my={2}>
              <Typography className={classes.subtitle}>
                Estamos empolgados por você estar desfrutando da experiência
                DPOnet, no entanto, essa funcionalidade não está disponível para
                teste.
              </Typography>
            </Box>
            <Typography className={classes.subtitle2}>
              Aproveite os passos da jornada até aqui e contrate a plataforma
              DPOnet para:
            </Typography>
            {trailTexts.map((text) => (
              <Box key={text} display="flex" alignItems="center">
                <Box pt={2} mr={1}>
                  <CheckCircleIcon size={24} />
                </Box>
                <Typography className={classes.trailLabel}>{text}</Typography>
              </Box>
            ))}
            <Box className={classes.BoxAlert}>
              <Box className={classes.alert}>
                <Box display="flex" alignItems="center">
                  <img src={clock} alt="Logo" width={35} />
                  <Box ml={1}>
                    <Typography>Período teste</Typography>
                  </Box>
                </Box>
                <CalcDaysToFree endDate={endDate} />
              </Box>
            </Box>
            <Divider />
            <Box className={classes.boxButton}>
              <Button
                type="button"
                variant="outlined"
                className={classes.button}
                onClick={handleRedirect}
                fullWidth
              >
                REALIZAR CONTRATAÇÃO
              </Button>
            </Box>
          </Box>
          <Box>
            <Box className={classes.rightBanner}>
              <Box
                width="100%"
                display="flex"
                justifyContent="flex-end"
                mr={-2}
              >
                <IconButton
                  size="small"
                  onClick={closeDialog}
                  aria-label="Fechar PopUp"
                >
                  <CloseIcon color="white" />
                </IconButton>
              </Box>
              <Box p={2} mt={10}>
                <Box display="flex" justifyContent="center">
                  <img src={logo} alt="Logo" width={260} />
                </Box>
                <Typography className={classes.labelRigth}>
                  +2.500 empresas confiam em nossa solução
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {rdMarketing && (
        <BaseConfirmationDialog
          displayedButtons={false}
          title="Solicitação de contratação da plataforma"
          text="Essa solicitação foi encaminhada à nossa equipe, e entraremos em contato para dar continuidade ao
              processo de contratação."
          open={rdMarketing}
          setOpen={setRdMarketing}
          variant="success"
          loading={loading}
        />
      )}
    </Dialog>
  )
}

export default DialogUseLimitationNotice
