import React from 'react'
import { Chip } from '@material-ui/core'
import clsx from 'clsx'

import useStyles from './styles'
import constants from 'constants/index'

const ChipStatus = ({ status }) => {
  const classes = useStyles()
  const supplierInviteConstants = constants.supplierInvite

  return (
    <Chip
      className={clsx(classes.root, {
        [classes.pending]: status === supplierInviteConstants.STATUSES.PENDING,
        [classes.accepted]:
          status === supplierInviteConstants.STATUSES.ACCEPTED,
        [classes.refused]: status === supplierInviteConstants.STATUSES.REFUSED,
      })}
      label={supplierInviteConstants.STATUSES_PRE_AVALIATION_LABEL[status]}
      size="small"
    />
  )
}

export default ChipStatus
