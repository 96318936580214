import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  actionIcon: {
    marginRight: theme.spacing(1),
  },
  stepper: {
    padding: 0,
    border: `1px solid ${theme.palette.custom.border}`,
    borderBottom: 0,
  },
  step: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.custom.border}`,
  },
  stepConnector: {
    display: 'none',
  },
  stepIcon: {
    borderRadius: '50%',
    height: 40,
    width: 40,
    marginTop: 10,
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepIconActive: {
    backgroundColor: '#087DF138',
    color: '#007BFF',
  },
  stepIconCompleted: {
    backgroundColor: '#E8F5E9',
    color: '#4CAF50',
  },
  stepLabelText: {
    color: '#263238',
  },
  stepLabelTextOptional: {
    color: '#546E7A',
  },
  stepLabelModify: {
    cursor: 'pointer',
  },
}))

export default styles
