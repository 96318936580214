import api from 'service/api'
import { getBaseURL } from 'service/env'
import axios from 'axios'

const dponetAPI = api.create('dponet')
const baseURL = getBaseURL('dponet')

const get = async () => {
  return await dponetAPI.get('/company_trails')
}

const concludedStep = async ({ companyTrailId, ...rest }) => {
  return await dponetAPI.put(
    `/company_trails/${companyTrailId}/concluded_step`,
    rest,
  )
}

const getImage = async (imageLink) => {
  return await axios.get(`${baseURL}/${imageLink}`, {
    responseType: 'blob',
    headers: {
      'Cache-Control': 'no-store, no-cache, must-revalidate, max-age=0',
    }
  })
}

export default { get, concludedStep, getImage }
