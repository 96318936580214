import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Typography } from '@material-ui/core'

import {
  SupplierStatsCards,
  MySuppliersCompliance,
  SupplierComplianceTable,
  SupplierQuestionnaireProgress,
} from './components'

import helpers from 'helpers'
import constants from 'constants/index'

const DashboardSupplier = ({ supplierStats }) => {
  const { suppliers: supplierIds } = constants.myLgpd.GRAPH_IDS

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h4" color="primary">
          Parceiros
        </Typography>
      </Grid>
      <Grid item xs={12} lg={6}>
        <MySuppliersCompliance supplierStats={supplierStats} />
      </Grid>
      <Grid item xs={12} lg={6}>
        <SupplierQuestionnaireProgress supplierStats={supplierStats} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={3} id={supplierIds?.cardStats}>
          <SupplierStatsCards
            label="Meus parceiros"
            value={supplierStats?.suppliers?.length}
          />
          <SupplierStatsCards
            label="Sou parceiro"
            value={supplierStats?.providingCount}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} id={supplierIds?.highCompliancers}>
        <SupplierComplianceTable
          title="Parceiros com boa conformidade"
          subtitle="Acompanhe os seus parceiros"
          suppliers={helpers.myLgpd.getSupplierByCompliance(
            supplierStats,
            51,
            101,
          )}
        />
      </Grid>
      <Grid item xs={12} md={6} id={supplierIds?.lowCompliancers}>
        <SupplierComplianceTable
          title="Parceiros com baixa conformidade"
          subtitle="Acompanhe os seus parceiros"
          suppliers={helpers.myLgpd.getSupplierByCompliance(
            supplierStats,
            0,
            50,
            'desc',
          )}
        />
      </Grid>
    </>
  )
}

DashboardSupplier.propTypes = {
  supplierStats: PropTypes.object,
}

export default DashboardSupplier
