import { colors, makeStyles } from '@material-ui/core'
import palette from 'theme/palette'

const styles = makeStyles((theme) => ({
  greenButton: {
    backgroundColor: palette.tertiary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: colors.green[500],
    },
  },
  conformityPaper: {
    backgroundColor: '#F4F6F8',
  },
}))

export default styles
