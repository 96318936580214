import api from 'service/api'

const dponetAPI = api.create('dponet')

const getCategories = async () => {
  return await dponetAPI.get('/complaint_categories')
}

const complaintCategories = { getCategories }

export default complaintCategories
