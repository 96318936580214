import React from 'react'
import { Box, Grid } from '@material-ui/core'

import {
  PartnersCompliance,
  QuestionnairesCompliance,
  SkeletonSection,
} from './components'

import useFetch from 'hooks/useFetch'

import * as service from 'service'
import constants from 'constants/index'

const Stats = () => {
  const { response, isLoading } = useFetch(service.dponet.suppliers.stats)

  const suppliersStats = response?.data || {}

  return (
    <Box id={constants.tours.thirdPartyManagements.STEPS.INTERMEDIATOR[8]}>
      {isLoading ? (
        <SkeletonSection />
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} lg={5}>
            <PartnersCompliance compliance={suppliersStats?.compliance} />
          </Grid>

          <Grid item xs={12} lg={7}>
            <QuestionnairesCompliance
              questionnaires={suppliersStats?.complianceByQuestionnaires}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  )
}

export default Stats
