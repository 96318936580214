const LOW = 'Baixo'
const MEDIUM = 'Médio'
const HIGH = 'Alto'
const SEVERE = 'Severo'
const UNDEFINED = 'Indefinido'

const LOW_ID = 1
const MEDIUM_ID = 2
const HIGH_ID = 3
const SEVERE_ID = 4
const UNDEFINED_ID = 5
const UNDEFINED_DEFAULT = 0

const ACTIVE = 'active'
const INACTIVE = 'inactive'
const ACTIVE_LABEL = 'ATIVO'
const INACTIVE_LABEL = 'INATIVO'

const STATUS_lABEL = {
  [ACTIVE]: ACTIVE_LABEL,
  [INACTIVE]: INACTIVE_LABEL,
}

const STATUS_COLOR = {
  [ACTIVE]: 'textMediumFragility',
  [INACTIVE]: 'textSevereFragility',
}

const FRAGILITY_TABS = [
  {
    value: 'all',
    label: 'Todos',
  },
  {
    value: ACTIVE,
    label: ACTIVE_LABEL,
  },
  {
    value: INACTIVE,
    label: INACTIVE_LABEL,
  },
]

const FRAGILITY_IDS = [LOW_ID, MEDIUM_ID, HIGH_ID, SEVERE_ID, UNDEFINED_ID]
const FRAGILITIES = {
  low: { id: LOW_ID, name: LOW },
  medium: { id: MEDIUM_ID, name: MEDIUM },
  high: { id: HIGH_ID, name: HIGH },
  severe: { id: SEVERE_ID, name: SEVERE },
  undefined: { id: UNDEFINED_ID, name: UNDEFINED },
}

const FRAGILITIES_LABELS = {
  1: LOW,
  2: MEDIUM,
  3: HIGH,
  4: SEVERE,
  5: UNDEFINED,
}

const FRAGILITY_MAIN_DRIVER_STEP_1 = 'company-fragility-main-step-1'
const FRAGILITY_MAIN_DRIVER_STEP_2 = 'company-fragility-main-step-2'
const FRAGILITY_MAIN_DRIVER_STEP_3 = 'company-fragility-main-step-3'

const FRAGILITIES_MAIN_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + FRAGILITY_MAIN_DRIVER_STEP_1,
    popover: {
      description:
        'Identificar Ameaças é o meio de encontrar vulnerabilidades e riscos a sua empresa, departamentos e processos para levantar possibilidades de Melhoria Contínua.',
    },
  },
  {
    element: '#' + FRAGILITY_MAIN_DRIVER_STEP_2,
    popover: {
      description:
        'É possível cadastrar uma nova ameaça, identificar o impacto e a probabilidade, incluir medidas de seguranças e locais de ocorrência (Toda empresa/Departamentos/Processos).',
    },
  },
  {
    element: '#' + FRAGILITY_MAIN_DRIVER_STEP_3,
    popover: {
      description:
        'Edite, incluindo novas medidas de segurança, e recalcule a probabilidade e o impacto da ameaça de acordo com cada medida adotada. Se julgar que não é mais necessário, exclua a ameaça.',
    },
  },
]

const NEW_FRAGILITY_DRIVER_STEP_1 = 'new-company-fragility-step-1'
const NEW_FRAGILITY_DRIVER_STEP_2 = 'new-company-fragility-step-2'
const NEW_FRAGILITY_DRIVER_STEP_3 = 'new-company-fragility-step-3'

const NEW_FRAGILITIES_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + NEW_FRAGILITY_DRIVER_STEP_1,
    popover: {
      description:
        'Ao definir o Impacto e a Probabilidade, o sistema calculará automaticamente nível da ameaça. O Impacto representa o nível de dano que a ameaça causaria à empresa caso ocorra, enquanto a Probabilidade indica a chance da ameaça realmente acontecer.',
    },
  },
  {
    element: '#' + NEW_FRAGILITY_DRIVER_STEP_2,
    popover: {
      description:
        'Adicione uma medida de segurança, definindo uma categoria. Selecione individualmente cada medida ou todas de uma vez, sendo possível excluir caso não se aplique à ameaça.',
    },
  },
  {
    element: '#' + NEW_FRAGILITY_DRIVER_STEP_3,
    popover: {
      description:
        'Inclua um local de ocorrência, com a opção de aplicar para toda a organização (os processos já existentes e novos irão conter essa ameaça), ou departamentos, e também para processos específicos.',
    },
  },
]

const PROBABILITY_CAPTION = {
  LOW: {
    level: LOW,
    description: 'Pode ocorrer, porém sem uma frequência definida',
    levelId: LOW_ID,
  },
  MEDIUM: {
    level: MEDIUM,
    description: 'Pode ocorrer com uma certa frequência',
    levelId: MEDIUM_ID,
  },
  HIGH: {
    level: HIGH,
    description: 'Pode ocorrer com frequência e a quantidade é significante',
    levelId: HIGH_ID,
  },
  SEVERE: {
    level: SEVERE,
    description: 'Pode ocorrer sempre, há muitas oportunidades de concretizar',
    levelId: SEVERE_ID,
  },
}

const IMPACT_CAPTION = {
  LOW: {
    level: LOW,
    description:
      'Não afeta o faturamento/lucro, porém a divulgação pode iniciar controvérsias a imagem pública e ser um início de um problema futuro',
    levelId: LOW_ID,
  },
  MEDIUM: {
    level: MEDIUM,
    description:
      'Afeta a imagem da empresa, causa danos ao faturamento porém pouco gasto com a recuperação',
    levelId: MEDIUM_ID,
  },
  HIGH: {
    level: HIGH,
    description:
      'Afeta muito a imagem da empresaa, danos altíssimos, recuperação pode levar um tempo',
    levelId: HIGH_ID,
  },
  SEVERE: {
    level: SEVERE,
    description:
      'Danos inimagináveis a imagem da empresa, danos ao lucro e faturamento muito maior que o faturamento anual da empresa, pode ser que não haja forma de recuperação',
    levelId: SEVERE_ID,
  },
}

const fragilities = {
  ACTIVE,
  FRAGILITIES_LABELS,
  FRAGILITIES_MAIN_DRIVER_STEPS_OBJECT,
  FRAGILITIES,
  FRAGILITY_IDS,
  FRAGILITY_MAIN_DRIVER_STEP_1,
  FRAGILITY_MAIN_DRIVER_STEP_2,
  FRAGILITY_MAIN_DRIVER_STEP_3,
  FRAGILITY_TABS,
  HIGH_ID,
  HIGH,
  IMPACT_CAPTION,
  INACTIVE,
  LOW_ID,
  LOW,
  MEDIUM_ID,
  MEDIUM,
  NEW_FRAGILITIES_DRIVER_STEPS_OBJECT,
  NEW_FRAGILITY_DRIVER_STEP_1,
  NEW_FRAGILITY_DRIVER_STEP_2,
  NEW_FRAGILITY_DRIVER_STEP_3,
  PROBABILITY_CAPTION,
  SEVERE_ID,
  SEVERE,
  STATUS_COLOR,
  STATUS_lABEL,
  UNDEFINED_DEFAULT,
  UNDEFINED_ID,
  UNDEFINED,
}

export default fragilities
