import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  statusLabelAuthorized: {
    color: theme.palette.statusLabel.success.primary,
    backgroundColor: theme.palette.statusLabel.success.secondary,
    fontWeight: 'bold',
  },
  initialContent: {
    paddingLeft: theme.spacing(3),
  },
  finalContent: {
    paddingRight: theme.spacing(3),
  },
}))

export default styles
