import api from 'service/api'
import axios from 'axios'
import * as service from 'service'
import { getBaseURL } from 'service/env'

const dponetAPI = api.create('dponet')
const baseURL = getBaseURL('dponet')

const create = async ({ ...data }) => {
  return await dponetAPI.post(`/consent_forms`, {
    ...data,
  })
}

const list = async ({ ...params }) => {
  return await dponetAPI.get('/consent_forms/', { params })
}

const listTemplates = async ({ ...params }) => {
  return await dponetAPI.get('/consent_forms/templates', { params })
}

const get = async ({ consentFormId }) => {
  return await dponetAPI.get(`/consent_forms/${consentFormId}`)
}

const getTemplate = async ({ consentFormId }) => {
  return await dponetAPI.get(`/consent_forms/${consentFormId}/template`)
}

const destroy = async ({ id = '' }) => {
  return await dponetAPI.delete(`/consent_forms/${id}`)
}

const sendLink = async ({ token, ...data }) => {
  return await dponetAPI.post(`/consent_forms/${token}/send_link`, {
    ...data,
  })
}

const update = async ({ consentFormId, ...data }) => {
  return await dponetAPI.put(`/consent_forms/${consentFormId}`, {
    ...data,
  })
}

const createReceipt = async ({ consentFormId, ...data }) => {
  const dataForm = new FormData()

  const filteredData = Object.fromEntries(
    Object.entries(data).filter(
      ([key]) =>
        key !== 'Email' &&
        key !== 'consentFormId' &&
        key !== 'document' &&
        key !== 'observation',
    ),
  )

  if (data?.document) {
    for (const value of Object.entries(data?.document)) {
      dataForm.append('consent_form_answer[attachments][]', value[1])
    }
  }

  dataForm.append('consent_form_answer[user_email]', data?.Email)
  dataForm.append('consent_form_answer[consent_form_id]', data?.consentFormId)
  dataForm.append('consent_form_answer[platform]', data?.platform)
  dataForm.append('consent_form_answer[observation]', data?.observation)

  Object.keys(filteredData).forEach((key) => {
    dataForm.append(`consent_form_answer[answers][${key}]`, filteredData[key])
  })

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/consent_forms/${consentFormId}/consent_form_answers`,
    data: dataForm,
    ...config,
  })
}

const listReceipts = async ({ ...params }) => {
  return await dponetAPI.get(`/consent_form_answers`, { params })
}

const getReceipt = async ({ consentFormId, id }) => {
  return await dponetAPI.get(
    `/consent_forms/${consentFormId}/consent_form_answers/${id}`,
  )
}

const updateResponsible = async ({ consentFormId, ...data }) => {
  return await dponetAPI.patch(
    `/consent_forms/${consentFormId}/change_responsible`,
    {
      consentForm: {
        ...data,
      },
    },
  )
}

const changeStatus = async ({ consentFormId }) => {
  return await dponetAPI.patch(`/consent_forms/${consentFormId}/change_status`)
}

const changeReceiptStatus = async ({
  consentFormAnswerId,
  consentFormId,
  data,
}) => {
  const dataForm = new FormData()

  if (data?.document) {
    for (const value of Object.entries(data?.document)) {
      dataForm.append('consent_form_answer[attachments][]', value[1])
    }
  }

  dataForm.append('consent_form_answer[observation]', data?.observation)

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'PATCH',
    url: `${baseURL}/consent_forms/${consentFormId}/consent_form_answers/${consentFormAnswerId}/change_status`,
    data: dataForm,
    ...config,
  })
}

const destroyReceipt = async ({ consentFormId, id }) => {
  return await dponetAPI.delete(
    `/consent_forms/${consentFormId}/consent_form_answers/${id}`,
  )
}

const getStats = async ({ ...params }) => {
  return await dponetAPI.get(`/consent_forms/report_stats`, { params })
}

const getAcceptedList = async ({ consentFormId, ...params }) => {
  return await dponetAPI.get(
    `/consent_forms/${consentFormId}/consent_form_answers/accepted`,
    { params },
  )
}

const exportReceipt = async ({ consentFormId = '', ...params }) => {
  return await dponetAPI.get(
    `/consent_forms/${consentFormId}/consent_form_answers/export`,
    { params },
  )
}

const acceptanceReport = async ({ consentFormId = '', ...params }) => {
  return await dponetAPI.get(
    `/consent_forms/${consentFormId}/consent_form_answers/acceptance_report`,
    { params },
  )
}

export default {
  create,
  get,
  getTemplate,
  destroy,
  list,
  listTemplates,
  sendLink,
  update,
  createReceipt,
  listReceipts,
  getReceipt,
  updateResponsible,
  changeStatus,
  changeReceiptStatus,
  destroyReceipt,
  getStats,
  getAcceptedList,
  exportReceipt,
  acceptanceReport,
}
