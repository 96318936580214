import { useEffect, useState } from 'react'

import helpers from 'helpers'

import constants from 'constants/index'

const useConvertToFile = (attachments) => {
  const [loading, setLoading] = useState(true)
  const [fileList, setFileList] = useState(null)
  const [file, setFile] = useState(null)

  useEffect(() => {
    const convertToFile = async (attachment) => {
      const response = await fetch(attachment.url)
      const blob = await response.blob()

      let mimeType = blob.type
      if (mimeType === constants.validations.UNKNOWN_FILE_MIME_TYPE)
        mimeType = helpers.validates.file.getMimeTypeFromExtension(
          attachment.name,
        )

      return new File([blob], attachment.name, { type: mimeType })
    }

    const convertAll = async () => {
      setLoading(true)
      let convertedFiles = []

      if (Array.isArray(attachments)) {
        const filePromises = attachments.map((attachment) =>
          convertToFile(attachment),
        )
        convertedFiles = await Promise.all(filePromises)

        const dataTransfer = new DataTransfer()
        convertedFiles.forEach((file) => dataTransfer.items.add(file))

        setFileList(dataTransfer.files)
      } else {
        const singleFile = await convertToFile(attachments)

        setFile(singleFile)
      }

      setLoading(false)
    }

    if (attachments) {
      convertAll()
    } else {
      setLoading(false)
    }
  }, [attachments])

  return { fileList, file, loading }
}

export default useConvertToFile
