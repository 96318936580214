import React from 'react'
import PropTypes from 'prop-types'

import { Box, Paper, Typography, Hidden } from '@material-ui/core'

import useStyles from './styles'

const AdvisorCard = ({ id, imageSrc, text, action }) => {
  const classes = useStyles()

  return (
    <Paper component={Box} elevation={2} className={classes.root} id={id}>
      <Box className={classes.container}>
        {imageSrc && (
          <Hidden xsDown={!action} smDown={!!action}>
            <img src={imageSrc} alt="Logo" width={65} />
          </Hidden>
        )}
        <Box className={classes.textBox}>
          <Typography variant="h6">{text}</Typography>
        </Box>
        {action && <Box ml={2}>{action}</Box>}
      </Box>
    </Paper>
  )
}

AdvisorCard.propTypes = {
  imageSrc: PropTypes.string,
  text: PropTypes.string,
  action: PropTypes.node,
}

export default AdvisorCard
