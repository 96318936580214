import React, { Fragment } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Link,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from '@material-ui/core'

import { Launch as LauchIcon } from '@material-ui/icons'

import theme from 'theme'
import useStyles from './styles'
import constants from 'constants/index'

const PositionCustomization = () => {
  const classes = useStyles()

  const { control, errors, watch } = useFormContext()

  const xlUp = useMediaQuery(theme.breakpoints.up('xl'), {
    defaultMatches: true,
  })

  const position = watch('position')
  const hideOnAccept = watch('hideOnAccept')

  return (
    <Fragment>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        width="100%"
      >
        <Controller
          as={
            <FormControl error={!!errors?.position}>
              <FormLabel className={classes.formLabel} color="primary">
                Defina o posicionamento do banner de cookies no seu site:
              </FormLabel>
              <RadioGroup value={position}>
                {constants.cookies.banner.CUSTOMIZATION_POSITION_RADIO_GROUP_ITEMS.map(
                  (item, index) => (
                    <Box>
                      <FormControlLabel
                        key={index}
                        control={<Radio color="primary" />}
                        value={item.value}
                        label={item.label}
                      />
                    </Box>
                  ),
                )}
              </RadioGroup>
              {!!errors?.position && (
                <FormHelperText error={!!errors?.position}>
                  {<>{errors?.position?.message}</>}
                </FormHelperText>
              )}
            </FormControl>
          }
          control={control}
          name="position"
          mode="onChange"
        />
        <Box mt={2}>
          <Controller
            name="hideOnAccept"
            control={control}
            as={
              <FormControl>
                <FormLabel className={classes.formLabel} color="primary">
                  Defina o comportamento de seu banner
                </FormLabel>
                <FormControlLabel
                  control={<Checkbox color="primary" checked={hideOnAccept} />}
                  label="Esconder banner ao aceitar"
                />
              </FormControl>
            }
            onChange={([event]) => event.target.checked}
          />
        </Box>
        <Box
          p={2}
          bgcolor={theme.palette.custom.light}
          borderRadius={1}
          mb={!xlUp && 2}
          display="flex"
          flexDirection="column"
          gridGap={theme.spacing(1.5)}
        >
          <Typography color="primary" variant="h6">
            Informação
          </Typography>
          <Typography textAlign="justify">
            Ao selecionar esta opção, o banner de cookies será reduzido para uma
            mensagem discreta assim que o usuário aceitar ou recusar os cookies.{' '}
            <strong>Atenção:</strong> será necessário adicionar um elemento HTML
            a mais em seu site.
          </Typography>
          <Typography>
            <Link
              href={constants.cookies.banner.LINK_DOCUMENTATION_HIDE_ON_ACCEPT}
              target="_blank"
              display="flex"
            >
              Consulte a documentação
              <LauchIcon className={classes.icon} />
            </Link>
          </Typography>
        </Box>
      </Box>
      {xlUp && <Divider orientation="vertical" variant="middle" />}
    </Fragment>
  )
}

export default PositionCustomization
