import React, { useState } from 'react'
import { FileText as FileTextIcon, Moon as MoonIcon } from 'react-feather'
import { Button, makeStyles, Box } from '@material-ui/core'

import QuestionsReply from '../QuestionsReply'
import RecommendedQuestions from '../RecommendedQuestions'
import InactiveQuestions from '../InactiveQuestions'

import styles from './styles'
import constants from 'constants/index'

const useStyles = makeStyles(styles)
const {
  USER_QUESTIONS_TAB_VALUE,
  RECOMMENDED_QUESTIONS_TAB_VALUE,
  INACTIVE_QUESTIONS_TAB_VALUE,
} = constants.questionnaire

const QuestionnairesTabHeader = ({
  questionnaire,
  isLoading,
  refresh = () => {},
  companyKind,
}) => {
  const classes = useStyles()

  const [currentTab, setCurrentTab] = useState(USER_QUESTIONS_TAB_VALUE)
  const isActive = (tab) => {
    return tab === currentTab
  }
  const activeStyles = {
    color: 'primary',
    classes: { label: classes.textWhite },
  }

  return (
    <>
      <Button
        disableElevation
        startIcon={<FileTextIcon size={18} />}
        variant="contained"
        onClick={() => setCurrentTab(USER_QUESTIONS_TAB_VALUE)}
        {...(isActive(USER_QUESTIONS_TAB_VALUE) && activeStyles)}
        className={classes.button}
      >
        Minhas Questões
      </Button>
      <Button
        id={constants.questionnaire.QUESTIONNAIRE_REPLY_DRIVER_STEP_2}
        disableElevation
        startIcon={<FileTextIcon size={18} />}
        variant="contained"
        onClick={() => setCurrentTab(RECOMMENDED_QUESTIONS_TAB_VALUE)}
        {...(isActive(RECOMMENDED_QUESTIONS_TAB_VALUE) && activeStyles)}
        className={classes.button}
      >
        Outras Questões
      </Button>
      <Button
        disableElevation
        startIcon={<MoonIcon size={18} />}
        variant="contained"
        onClick={() => setCurrentTab(INACTIVE_QUESTIONS_TAB_VALUE)}
        {...(isActive(INACTIVE_QUESTIONS_TAB_VALUE) && activeStyles)}
        className={classes.button}
      >
        Questões Inativas
      </Button>
      <Box>
        {currentTab === USER_QUESTIONS_TAB_VALUE && (
          <QuestionsReply
            questionnaire={questionnaire}
            isLoading={isLoading}
            refresh={refresh}
            companyKind={companyKind}
          />
        )}
        {currentTab === RECOMMENDED_QUESTIONS_TAB_VALUE && (
          <RecommendedQuestions
            displayQuestions={questionnaire?.activeQuestions?.filter(
              (question) => !question.applicableChoice,
            )}
            setCurrentTab={setCurrentTab}
            questionnaireId={questionnaire?.id}
            refresh={refresh}
          />
        )}
        {currentTab === INACTIVE_QUESTIONS_TAB_VALUE && (
          <InactiveQuestions
            displayQuestions={questionnaire?.inactiveQuestions}
          />
        )}
      </Box>
    </>
  )
}

export default QuestionnairesTabHeader
