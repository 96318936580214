import arrayIndexSize from './arrayIndexSize'
import capitalizeText from './capitalizeText'
import cardFlag from './cardFlag'
import cnpj from './cnpj'
import cnpjCpf from './cnpjCpf'
import convertFormDataToArray from './convertFormDataToArray'
import countText from './countText'
import cpf from './cpf'
import daysTillOtherDate from './daysTillOtherDate'
import dig from './dig'
import documentName from './documentName'
import getColorByVariant from './getColorByVariant'
import getIconByVariant from './getIconByVariant'
import insertArrayAt from './insertArrayAt'
import isArrayOf from './isArrayOf'
import isEmpty from './isEmpty'
import reduceRoutesToArray from './reduceRoutesToArray'
import splitFirstOccurrence from './splitFirstOccurrence'
import upperText from './upperText'
import validUrl from './validUrl'
import yearsAhead from './yearsAhead'

const functions = {
  arrayIndexSize,
  capitalizeText,
  cardFlag,
  cnpj,
  cnpjCpf,
  convertFormDataToArray,
  countText,
  cpf,
  daysTillOtherDate,
  dig,
  documentName,
  getColorByVariant,
  getIconByVariant,
  insertArrayAt,
  isArrayOf,
  isEmpty,
  reduceRoutesToArray,
  splitFirstOccurrence,
  upperText,
  validUrl,
  yearsAhead,
}

export default functions
