import React, { useEffect } from 'react'
import { RHFInput } from 'react-hook-form-input'
import { Controller, useForm } from 'react-hook-form'
import { clsx } from 'clsx'
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'

import { Label } from 'components'

import useConsent from 'hooks/useConsent'
import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import schema from './schema'
import constants from 'constants/index'

import useStyles from './styles'

const GeneralInformation = () => {
  const {
    nextStep,
    toNextStep,
    setToNextStep,
    setDataConsentForm,
    dataConsentForm,
  } = useConsent()

  const snackbar = useSnackbar()

  const classes = useStyles()

  const { register, setValue, handleSubmit, errors, control } = useForm({
    validationSchema: schema,
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      title: dataConsentForm?.title ?? '',
      subtitle: dataConsentForm?.subtitle ?? '',
      legalFrameworkId: dataConsentForm?.legalFrameworkId?.toString() ?? '',
    },
  })

  useEffect(() => {
    if (toNextStep) {
      handleSubmit(onSubmit)()

      if (errors) setToNextStep(false)
    }
    // eslint-disable-next-line
  }, [toNextStep])

  const onSubmit = async (data) => {
    try {
      setDataConsentForm((oldData) => ({ ...oldData, ...data }))

      nextStep()
      setToNextStep(false)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error, false) ||
          error?.error?.message,
        variant: 'error',
      })
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      id={constants.consent.NEW_CONSENT_FORM_DRIVER_STEP_0}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="caption" color="textSecondary">
            Preencha os campos de informações gerais do formulário de
            consentimento
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Label title="Título do formulário" item xs={12}>
              <RHFInput
                as={
                  <TextField
                    error={!!errors.title}
                    helperText={errors?.title?.message}
                    label="Insira o título do formulário"
                    fullWidth
                  />
                }
                register={register}
                setValue={setValue}
                name="title"
                mode="onChange"
              />
            </Label>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Label title="Subtítulo" item xs={12}>
              <RHFInput
                as={
                  <TextField
                    error={!!errors.subtitle}
                    helperText={errors?.subtitle?.message}
                    label="Descreva um subtítulo para o formulário"
                    fullWidth
                  />
                }
                register={register}
                setValue={setValue}
                name="subtitle"
                mode="onChange"
              />
            </Label>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Label
            title="Base legal para a coleta"
            description="Insira a finalidade para a base legal da coleta de dados"
            item
            xs={12}
          />
          <FormControl component="fieldset">
            <Controller
              control={control}
              name="legalFrameworkId"
              defaultValue=""
              as={
                <RadioGroup>
                  {constants.consent.LEGAL_BASIS_CONTROL_ITEMS.map(
                    (item, index) => {
                      const showDivider =
                        index !==
                        constants.consent.LEGAL_BASIS_CONTROL_ITEMS.length - 1

                      return (
                        <>
                          <FormControlLabel
                            value={item.value}
                            control={
                              <Radio
                                className={clsx({
                                  [classes.radioError]:
                                    !!errors?.legalFrameworkId,
                                })}
                              />
                            }
                            className={classes.optionBox}
                            label={
                              <>
                                <Typography
                                  variant="body1"
                                  className={clsx(classes.optionTitle, {
                                    [classes.radioError]:
                                      !!errors?.legalFrameworkId,
                                  })}
                                >
                                  {item.title}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  className={clsx({
                                    [classes.radioError]:
                                      !!errors?.legalFrameworkId,
                                  })}
                                >
                                  {item.description}
                                </Typography>
                              </>
                            }
                          />
                          {showDivider && <Divider />}
                        </>
                      )
                    },
                  )}
                </RadioGroup>
              }
              mode="onChange"
            />
          </FormControl>
          {errors?.legalFrameworkId && (
            <FormHelperText error={!!errors?.legalFrameworkId}>
              {<>{errors?.legalFrameworkId?.message}</>}
            </FormHelperText>
          )}
        </Grid>
      </Grid>
    </form>
  )
}

export default GeneralInformation
