import React, { useState } from 'react'
import { Grid, Paper, TablePagination } from '@material-ui/core'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { StatusesTab } from './components'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from 'components/Table'
import { ChipStatus } from 'views/ThirdPartyManagements/components/invite'

import usePagination from 'hooks/usePagination'
import useFetch from 'hooks/useFetch'

import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'
import Actions from './components/Actions'

const ReceivedTable = ({ filter }) => {
  const [selectedTab, setSelectedTab] = useState('all')

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  const supplierInviteConstants = constants.supplierInvite

  const filterStatus = () => {
    if (selectedTab === 'all')
      return [
        supplierInviteConstants.STATUSES.PENDING,
        supplierInviteConstants.STATUSES.REFUSED,
      ]

    return selectedTab
  }

  const { response, isLoading, refresh } = useFetch(
    service.dponet.supplierInvite.get,
    {
      status: filterStatus(),
      received: true,
      perPage,
      page,
      ...filter.filters,
    },
    [filter.filters, perPage, page, selectedTab],
  )

  const supplierInvites = response?.data?.supplierInvites || []

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <StatusesTab
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <PerfectScrollbar>
            <Table
              emptyMessage="Nenhum convite encontrado"
              isLoading={isLoading}
            >
              <TableHead>
                <TableRow>
                  <TableCell>Identificador</TableCell>
                  <TableCell>Razão Social</TableCell>
                  <TableCell>Documento</TableCell>
                  <TableCell>E-mail</TableCell>
                  <TableCell align="center">Status do vínculo</TableCell>
                  <TableCell align="right">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {supplierInvites.map((supplierInvite) => (
                  <TableRow key={supplierInvite.id}>
                    <TableCell>{supplierInvite.id}</TableCell>
                    <TableCell>
                      {supplierInvite?.company?.name || '-'}
                    </TableCell>
                    <TableCell>
                      {supplierInvite?.company?.document ||
                        helpers.formatters.documentFormatter(
                          supplierInvite?.document,
                        )}
                    </TableCell>
                    <TableCell>
                      {supplierInvite?.company?.email || '-'}
                    </TableCell>
                    <TableCell align="center">
                      <ChipStatus status={supplierInvite.status} />
                    </TableCell>
                    <TableCell align="right">
                      <Actions
                        supplierInvite={supplierInvite}
                        refresh={refresh}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              component="div"
              count={response?.data?.meta?.totalCount || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </PerfectScrollbar>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ReceivedTable
