import React, { Fragment } from 'react'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import PropTypes from 'prop-types'
import {
  Download as DownloadIcon,
  Power as PowerIcon,
  Trash as TrashIcon,
} from 'react-feather'
import { Skeleton } from '@material-ui/lab'
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from '@material-ui/core'

import { MenuButton, Permitted } from 'components'

import theme from 'theme'
import styles from './styles'
import { routes } from 'Routes'
import constants from 'constants/index'

const DocumentPreviewCard = ({
  currentDocument,
  handleOpenDeleteDialog = () => {},
  handleOpenDownloadModal = () => {},
  handleOpenInactiveDialog = () => {},
  skeleton = false,
}) => {
  const { PRIVACY_POLICIES } = constants.permissions
  const { CHIP_STATUS_LABEL, INACTIVE_STATUS_ID, TRANSLATION_CATEGORY } =
    constants.documents

  const useStyles = styles(currentDocument?.status)
  const classes = useStyles()
  const history = useHistory()

  const chipLabel = CHIP_STATUS_LABEL[currentDocument?.status] || 'Indefinido'

  const categoryLabel =
    TRANSLATION_CATEGORY[currentDocument?.category] || 'Indefinido'

  const isInactive = currentDocument?.status === INACTIVE_STATUS_ID

  const MENU_OPTIONS = [
    {
      icon: PowerIcon,
      title: isInactive ? 'Ativar' : 'Inativar',
      permission: PRIVACY_POLICIES.CREATE,
      handleClick: () =>
        handleOpenInactiveDialog(currentDocument?.id, currentDocument?.status),
    },
    {
      icon: TrashIcon,
      title: 'Excluir',
      permission: PRIVACY_POLICIES.DESTROY,
      handleClick: () => handleOpenDeleteDialog(currentDocument?.id),
      disabled: currentDocument?.consentFormId,
      disabledMessage: currentDocument?.consentFormId
        ? 'A exclusão não é permitida para o documento com formulário de aceites definido!'
        : '',
    },
  ]

  const handleEditRedirection = () =>
    history.push(
      reverse(routes.documents.edit, {
        documentId: currentDocument?.id,
      }),
    )

  const handleShowRedirection = () =>
    history.push(
      reverse(routes.documents.show, {
        documentId: currentDocument?.id,
      }),
    )

  return (
    <Card id={constants.tours.documents.MAIN_STEP_6}>
      <CardContent className={classes.cardContent}>
        <Box
          className={classes.titleContainer}
          {...(skeleton && { gridGap: theme.spacing(2) })}
        >
          <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            overflow="hidden"
            gridGap={theme.spacing(1)}
          >
            {skeleton ? (
              <>
                <Skeleton variant="rect" width={80} />
                <Skeleton variant="text" />
              </>
            ) : (
              <>
                <Box>
                  <Tooltip title={categoryLabel} arrow placement="right">
                    <Chip
                      label={categoryLabel}
                      className={classes.categoryChip}
                      size="small"
                    />
                  </Tooltip>
                </Box>
                <Tooltip
                  title={`#${currentDocument?.id} - ${currentDocument?.title}`}
                  placement="top-end"
                >
                  <Typography variant="h6" className={classes.title} noWrap>
                    #{currentDocument?.id} - {currentDocument?.title}
                  </Typography>
                </Tooltip>
              </>
            )}
          </Box>
          {skeleton ? (
            <Box>
              <Skeleton variant="circle" width={46} height={46} />
            </Box>
          ) : (
            <MenuButton>
              {MENU_OPTIONS?.map((option, index) => (
                <Permitted tag={option.permission} key={index}>
                  <Tooltip
                    title={option?.disabledMessage || ''}
                    placement="left-start"
                  >
                    <Box>
                      <Button
                        color="secondary"
                        startIcon={<option.icon size={16} strokeWidth={2.5} />}
                        onClick={option.handleClick}
                        disabled={option?.disabled}
                        fullWidth
                      >
                        {option.title}
                      </Button>
                    </Box>
                  </Tooltip>
                </Permitted>
              ))}
            </MenuButton>
          )}
        </Box>
        {skeleton ? (
          <Skeleton variant="rect" height={32} />
        ) : (
          <Chip label={chipLabel} className={classes.chip} color="primary" />
        )}
        <Box display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
          {skeleton ? (
            <Fragment>
              <Skeleton variant="text" />
            </Fragment>
          ) : (
            <Fragment>
              <Box display="flex" justifyContent="space-between">
                <Typography color="secondary">Aceites</Typography>
                {!currentDocument?.acceptedPrivate && (
                  <Typography variant="h6" color="secondary">
                    {currentDocument?.collectAccepted
                      ? `${currentDocument?.acceptedTotal} pessoas`
                      : 'Não coletado'}
                  </Typography>
                )}
              </Box>
              {currentDocument?.acceptedPrivate && (
                <Box
                  display="flex"
                  gridGap={theme.spacing(1)}
                  alignItems="center"
                  lineHeight={0}
                >
                  <Box width="100%">
                    <LinearProgress
                      variant="determinate"
                      value={currentDocument?.acceptedTotal}
                      className={classes.lineBar}
                    />
                  </Box>
                  <Typography color="secondary" className={classes.percentage}>
                    {currentDocument?.acceptedTotal}%
                  </Typography>
                </Box>
              )}
            </Fragment>
          )}
          <Box display="flex" gridGap={theme.spacing(2)} alignItems="center">
            {skeleton ? (
              <Fragment>
                <Skeleton variant="rect" width="45%" height={36} />
                <Skeleton variant="rect" width="45%" height={36} />
                <Skeleton variant="circle" width={36} height={36} />
              </Fragment>
            ) : (
              <Fragment>
                <Box width="100%">
                  <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={handleShowRedirection}
                  >
                    Visualizar
                  </Button>
                </Box>
                <Permitted tag={PRIVACY_POLICIES.CREATE}>
                  <Box width="100%">
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={handleEditRedirection}
                    >
                      Editar
                    </Button>
                  </Box>
                </Permitted>
                <IconButton
                  color="primary"
                  onClick={() => handleOpenDownloadModal(currentDocument)}
                >
                  <DownloadIcon />
                </IconButton>
              </Fragment>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

DocumentPreviewCard.propTypes = {
  currentDocument: PropTypes.object,
  handleOpenDeleteDialog: PropTypes.func,
  handleOpenDownloadModal: PropTypes.func,
  handleOpenInactiveDialog: PropTypes.func,
  skeleton: PropTypes.bool,
}

export default DocumentPreviewCard
