/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

const useFetch = (fn = () => {}, params, observable = [], condition = true) => {
  const [response, setResponse] = useState(null)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingStart, setIsLoadingStart] = useState(true)
  const [refreshCounter, setRefreshCounter] = useState(0)

  const refresh = () => {
    setRefreshCounter((prev) => prev + 1)
  }

  useEffect(() => {
    let isMounted = true

    if (condition) {
      setIsLoading(true)
      fn(params)
        .then((data) => {
          if (isMounted) {
            setResponse(data)
            setIsLoading(false)
            setIsLoadingStart(false)
          }
        })
        .catch((error) => {
          if (isMounted) {
            setError(error)
            setIsLoading(false)
            setIsLoadingStart(false)
          }
        })
    }

    return () => {
      isMounted = false
    }
  }, [refreshCounter, condition, ...observable])

  return { response, error, isLoading, isLoadingStart, refresh }
}

export default useFetch
