import constants from 'constants/index'

const valueToId = (value) => {
  if (constants.fragilities.UNDEFINED_DEFAULT === value)
    return constants.fragilities.UNDEFINED_ID

  return value
}

export default valueToId
