import React, { useRef, useState, useEffect } from 'react'
import { size } from 'lodash'

import PropTypes from 'prop-types'
import Chart from 'react-apexcharts'

import { Box } from '@material-ui/core'

import helpers from 'helpers'

const BarChart = ({
  names,
  series,
  heightValue,
  colors,
  id,
  showLabels = false,
  borderRadius = 0,
  enabledTotal = false,
  categoryFontSize = 8,
  gradient = false,
  gradientColor = '',
  max,
  noData = true,
  noDataText = 'SEM INFORMAÇÕES DISPONÍVEIS',
  opacity,
}) => {
  const ref = useRef(null)
  const [maxLegendCharacters, setMaxLegendCharacters] = useState(350)

  useEffect(() => {
    setMaxLegendCharacters(ref.current?.offsetWidth)
    //eslint-disable-next-line
  }, [ref.current])

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      stacked: true,
      id: id,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: showLabels,
      style: {
        fontFamily: 'Roboto, Arial, sans-serif',
        fontSize: 10,
        fontWeight: 800,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: borderRadius,
        columnWidth: 15,
        dataLabels: {
          total: { enabled: enabledTotal },
          style: { fontFamily: 'Roboto, Arial, sans-serif', fontSize: 4 },
        },
      },
    },
    xaxis: {
      categories: names,
      labels: {
        rotate: 0,
        trim: true,
        hideOverlappingLabels: false,
        style: {
          fontSize: categoryFontSize,
          fontFamily: 'Roboto, Arial, sans-serif',
          fontWeight: 500,
        },
      },
    },
    yaxis: {
      max: max,
    },
    legend: {
      showForZeroSeries: true,
      position: maxLegendCharacters < 300 ? 'bottom' : 'right',
      horizontalAlign: 'center',
      fontSize: 10,
      offsetY:
        maxLegendCharacters >= 300 ? (heightValue * 0.7) / size(series) : 10,
      markers: {
        fillColors: colors,
        radius: 15,
        ofssetY: 4,
        ofssetX: 10,
      },
      itemMargin: {
        vertical: 4,
      },
      formatter: (value) =>
        helpers.myLgpd.trimChartLegend(value, maxLegendCharacters),
    },
    fill: {
      ...(gradient && {
        type: 'gradient',
        gradient: {
          shade: 'dark',
          type: 'vertical',
          shadeIntensity: 0.5,
          gradientToColors: [gradientColor],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      }),
      colors: colors,
      opacity: opacity,
    },
    ...(noData && {
      noData: {
        text: noDataText,
        align: 'center',
        verticalAlign: 'middle',
        style: {
          fontSize: categoryFontSize,
          fontFamily: 'Roboto, Arial, sans-serif',
          fontWeight: 500,
        },
      },
    }),
  }

  return (
    <Box width="100%" ref={ref}>
      <Chart
        options={options}
        series={series}
        type="bar"
        width="100%"
        height={heightValue}
      />
    </Box>
  )
}

BarChart.propTypes = {
  names: PropTypes.arrayOf(PropTypes.string),
  series: PropTypes.arrayOf(PropTypes.object),
  heightValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  colors: PropTypes.array,
  id: PropTypes.string,
}

BarChart.defaultProps = {
  series: [],
  names: [],
  heightValue: 300,
  colors: undefined,
  id: 'rysk-by-departments',
}

export default BarChart
