const FAQ = 1
const DOCUMENTATION = 2
const LAST_UPDATES = 3

const KINDS = {
  FAQ: 'faq',
  DOCUMENTATION: 'documentation',
}

const KINDS_LABELS = {
  F: 'FAQ',
  D: 'Documentação',
}

const VISUALIZATION = {
  C: 'collaborator',
  CC: 'collaborator_and_client',
  CA: 'collaborator_and_advisor',
}

const SELECT_FAQS_DOCUMENTATION_KIND = [
  { id: KINDS.DOCUMENTATION, name: KINDS_LABELS.D },
  { id: KINDS.FAQ, name: KINDS_LABELS.F },
]

const TABS = [
  {
    value: FAQ,
    label: "FAQ'S",
  },
  // {
  //   value: DOCUMENTATION,
  //   label: 'Documentação',
  // },
  {
    value: LAST_UPDATES,
    label: 'Últimas Atualizações',
  },
]

const FAQS_DOCUMENTATION_MAIN_DRIVER_STEP_1 =
  'faqs-documentation-main-driver-step-1'
const FAQS_DOCUMENTATION_MAIN_DRIVER_STEP_2 =
  'faqs-documentation-main-driver-step-2'
const FAQS_DOCUMENTATION_MAIN_DRIVER_STEP_3 =
  'faqs-documentation-main-driver-step-3'
const FAQS_DOCUMENTATION_MAIN_DRIVER_STEP_4 =
  'faqs-documentation-main-driver-step-4'

const FAQS_DOCUMENTATION_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + FAQS_DOCUMENTATION_MAIN_DRIVER_STEP_1,
    popover: {
      description:
        'Se você já visualizou os tours e ainda não compreendeu como a plataforma funciona, esta é a tela ideal para esclarecer suas dúvidas. Aqui, os FAQs são úteis para orientar sobre o uso eficaz da plataforma, enquanto as atualizações são empregadas para apresentar as novidades mais recentes.',
    },
  },
  {
    element: '#' + FAQS_DOCUMENTATION_MAIN_DRIVER_STEP_2,
    popover: {
      description:
        'Procurando por uma FAQ específica? Utilize a barra de pesquisa.',
    },
  },
  {
    element: '#' + FAQS_DOCUMENTATION_MAIN_DRIVER_STEP_3,
    popover: {
      description:
        'As FAQs são úteis para auxiliar no uso da plataforma, fornecer informações adicionais sobre Segurança da Informação e esclarecer dúvidas jurídicas relacionadas à LGPD.',
    },
  },
  {
    element: '#' + FAQS_DOCUMENTATION_MAIN_DRIVER_STEP_4,
    popover: {
      description:
        'Em últimas atualizações, você pode conferir as mais recentes novidades da plataforma. ',
    },
  },
]

const faqsDocumentations = {
  FAQ,
  DOCUMENTATION,
  LAST_UPDATES,
  TABS,
  KINDS,
  KINDS_LABELS,
  SELECT_FAQS_DOCUMENTATION_KIND,
  VISUALIZATION,
  FAQS_DOCUMENTATION_DRIVER_STEPS_OBJECT,
  FAQS_DOCUMENTATION_MAIN_DRIVER_STEP_1,
  FAQS_DOCUMENTATION_MAIN_DRIVER_STEP_2,
  FAQS_DOCUMENTATION_MAIN_DRIVER_STEP_3,
  FAQS_DOCUMENTATION_MAIN_DRIVER_STEP_4,
}

export default faqsDocumentations
