import React from 'react'
import { Box, Grid, Paper } from '@material-ui/core'

import { Container, Page } from 'components'
import { Footer, Head } from './components'
import { CompanySection, SkeletonPage } from './components/company'

import useFetch from 'hooks/useFetch'

import * as service from 'service'

const ThirdPartyManagementCompanyShow = ({ match }) => {
  const { companySupplierId } = match.params

  const { response, isLoading } = useFetch(service.dponet.suppliers.get, {
    supplierId: companySupplierId,
  })

  const companySupplier = response?.data?.companySupplier || {}
  const company = companySupplier?.company || {}

  return (
    <Page title="Gestão de Terceiros - Visualização">
      <Container maxWidth={false} component={Box} mt={3}>
        <Paper component={Box} p={3}>
          {isLoading ? (
            <SkeletonPage />
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Head title={company.name} subtitle={company.document} />
              </Grid>
              <Grid item xs={12}>
                <CompanySection company={company} />
              </Grid>
            </Grid>
          )}
        </Paper>

        <Footer />
      </Container>
    </Page>
  )
}

export default ThirdPartyManagementCompanyShow
