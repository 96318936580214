import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  flagButton: {
    borderRadius: '50%',
    overflow: 'hidden',
    transition: 'transform 0.3s ease, filter 0.3s ease',
    '& img': {
      filter: 'grayscale(100%)',
      transition: 'filter 0.3s ease',
    },
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  selected: {
    '& img': {
      filter: 'grayscale(0%)',
    },
  },
}))

export default styles
