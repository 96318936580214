import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  initialContent: {
    paddingLeft: theme.spacing(3),
  },
  finalContent: {
    paddingRight: theme.spacing(3),
  },
}))

export default styles
