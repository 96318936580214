import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  paper: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  paperSelected: {
    backgroundColor: theme.palette.getContrastText(theme.palette.primary.main),
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: 'solid',
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr) auto',
    alignItems: 'center',
  },
  kindTypography: {
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    fontWeight: 700,
  },
  icon: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  iconSelected: {
    color: theme.palette.primary.main,
  },
}))

export default styles
