const convertFormDataToArray = (data, arrayName) => {
  const result = { ...data }
  const array = []

  Object.keys(result).forEach((key) => {
    const match = key.match(new RegExp(`^${arrayName}\\[(\\d+)\\]\\.(.+)$`))
    if (match) {
      const index = parseInt(match[1], 10)
      const property = match[2]

      if (!array[index]) array[index] = {}

      array[index][property] = result[key]
      delete result[key]
    }
  })

  result[arrayName] = array
  return result
}

export default convertFormDataToArray
