import React from 'react'
import { Box, IconButton, Typography } from '@material-ui/core'

import useStyles from './styles'
import constants from 'constants/index'

const LanguageCustomization = ({ selectedLanguage, setSelectedLanguage }) => {
  const classes = useStyles()

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      mb={2}
      gap={1}
      paddingX={1}
      alignItems="flex-start"
    >
      <Typography variant="h6" color="primary">
        Idioma
      </Typography>

      <Box display="flex" justifyContent="center" flexWrap="wrap" gap={2}>
        {constants.cookies.language.LANGUAGES.map((language) => (
          <IconButton
            key={language.code}
            className={`${classes.flagButton} ${
              selectedLanguage === language.code ? classes.selected : ''
            }`}
            onClick={() => setSelectedLanguage(language.code)}
          >
            <img
              src={language.flag}
              alt={`${language.name} flag`}
              width="32"
              height="24"
            />
          </IconButton>
        ))}
      </Box>
    </Box>
  )
}

export default LanguageCustomization
