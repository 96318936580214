import { colors } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const styles = makeStyles((theme) => ({
  containerMessages: {
    overflowY: 'auto',
    paddingRight: 14,
  },
  chip: {
    borderRadius: 4,
    fontWeight: 500,
    maxWidth: 200,
    textTransform: 'uppercase',
  },
  pendingStatus: {
    backgroundColor: colors.lightBlue[100],
    color: colors.lightBlue[800],
  },
  finalizedStatus: {
    backgroundColor: colors.green[100],
    color: colors.green[800],
  },
  convertedIncidentStatus: {
    backgroundColor: colors.red[100],
    color: colors.red[800],
  },
  noInteractionTypography: {
    color: colors.grey[500],
  },
  buttonAddCategory: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  paperButtonAddCategory: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  alertConfig: {
    padding: theme.spacing(1.25),
    marginBottom: theme.spacing(3.75),
  },
  boxButtonsConfig: {
    gap: theme.spacing(1.25),
  },
}))

export default styles
