import CompanyShow from './ThirdPartyManagementCompanyShow'
import Intermediator from './ThirdPartyManagementIntemediator'
import Invite from './ThirdPartyManagementInvite'
import Partner from './ThirdPartyManagementPartner'
import PartnerEdit from './ThirdPartyManagementPartnerEdit'
import PartnerNew from './ThirdPartyManagementPartnerNew'
import PartnerShow from './ThirdPartyManagementPartnerShow'
import PreAvaliation from './ThirdPartyManagementPreAvaliation'
import Suggestion from './ThirdPartyManagementSuggestion'
import PartnerShowQuestionnaires from './ThirdPartyManagementPartnerShowQuestionnaires'

export default {
  CompanyShow,
  Intermediator,
  Invite,
  Partner,
  PartnerEdit,
  PartnerNew,
  PartnerShow,
  PreAvaliation,
  Suggestion,
  PartnerShowQuestionnaires,
}
