import React, { useState } from 'react'
import { Box, Button } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

import {
  Container,
  ContentHeader,
  DialogLogs,
  MenuButton,
  Page,
} from 'components'
import { ConfigurationSkeleton, ManageConfiguration } from './components'

import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'

import useStyles from './styles'
import * as service from 'service'
import helpers from 'helpers'

const ComplaintConfig = () => {
  const [openLogDialog, setOpenLogDialog] = useState(false)
  const [loadingLogs, setLoadingLogs] = useState(false)
  const [responseLog, setResponseLog] = useState([])

  const classes = useStyles()
  const snackbar = useSnackbar()

  const {
    response: complaintConfigurationResponse,
    isLoading: complaintConfigurationLoading,
    refresh,
  } = useFetch(service.dponet.complaintConfigurations.mainConfiguration, [])

  const complaintConfiguration =
    complaintConfigurationResponse?.data?.complaintConfiguration

  const handleOpenLogs = async () => {
    try {
      setLoadingLogs(true)
      const responseLogs = await service.dponet.complaintConfigurations.logs({})
      const updatedlogs = helpers.logs.changeLogs(
        responseLogs?.data?.dponetAudits || [],
      )
      setResponseLog(updatedlogs)
      setOpenLogDialog(true)
    } catch (error) {
      setOpenLogDialog(false)
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error, false) ||
          error?.error?.message,
        variant: 'error',
      })
    } finally {
      setLoadingLogs(false)
    }
  }

  return (
    <Page title="Configurações Canal de Denúncia">
      <Container maxWidth={false}>
        <ContentHeader title="Configurações">
          <MenuButton>
            <Button color="secondary" fullWidth onClick={handleOpenLogs}>
              Histórico de alterações
            </Button>
          </MenuButton>
        </ContentHeader>
        <Box mt={2}>
          <Alert severity="error" className={classes.alertConfig}>
            <AlertTitle>
              ATENÇÃO! Apenas os responsáveis por cada categoria terão acesso às
              denúncias; nem mesmo o representante e o DPO poderão
              visualizá-las.
            </AlertTitle>
          </Alert>
          {!complaintConfigurationResponse && complaintConfigurationLoading ? (
            <ConfigurationSkeleton />
          ) : (
            <ManageConfiguration
              complaintConfiguration={complaintConfiguration}
              subConfigurations={complaintConfiguration?.subConfigurations}
              refresh={refresh}
            />
          )}
        </Box>
      </Container>
      {!loadingLogs && (
        <DialogLogs
          logs={
            helpers.functions.isEmpty(responseLog) ? undefined : responseLog
          }
          open={openLogDialog}
          setOpen={setOpenLogDialog}
          showRegisterSession={false}
          refresh={handleOpenLogs}
        />
      )}
    </Page>
  )
}

export default ComplaintConfig
