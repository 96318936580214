import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Box, Divider, Paper, Typography } from '@material-ui/core'

import ErrorImage from 'images/error.svg'

import useStyles from './styles'
import theme from 'theme'

const NoData2 = ({
  subTitle,
  title,
  withoutPaper = false,
  withTab = false,
  ...rest
}) => {
  const classes = useStyles()

  const commonContent = (
    <Fragment>
      {withTab && <Divider />}
      <Box
        className={classes.container}
        alignItems="center"
        display="flex"
        flexDirection="row"
        p={4}
      >
        <img src={ErrorImage} alt={title} />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          textAlign="start"
          color={theme.palette.primary.main}
          className={classes.textBox}
        >
          <Typography variant="h3" fontWeight={900} color="textSecondary">
            {title}
          </Typography>
          <Typography color="textSecondary">{subTitle}</Typography>
        </Box>
      </Box>
    </Fragment>
  )

  return withoutPaper ? (
    commonContent
  ) : (
    <Paper component={Box} {...rest} variant="outlined">
      {commonContent}
    </Paper>
  )
}

NoData2.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  withoutPaper: PropTypes.bool,
  withTab: PropTypes.bool,
}

export default NoData2
