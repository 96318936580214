import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  descriptionBox: {
    backgroundColor: theme.palette.primary.light,
    paddingBlock: theme.spacing(1),
    paddingInline: theme.spacing(2),
    borderRadius: 4,
  },
}))

export default useStyles
