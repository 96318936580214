import React, { useState } from 'react'
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer'
import { ChevronDown as ChevronDownIcon } from 'react-feather'
import PropTypes from 'prop-types'
import { Alert } from '@material-ui/lab'
import { Box, Typography } from '@material-ui/core'

import validates from 'helpers/validates'

import theme from 'theme'
import constants from 'constants/index'

const BaseDiffViewer = ({
  oldHtmlValue,
  newHtmlValue,
  hasAlert = false,
  ...rest
}) => {
  const { formatContent } = validates.comparator
  const { CUSTOM_STYLES_COMPONENT } = constants.diffViewer

  const [highlightLine, setHighlightLine] = useState([])

  const originContent = formatContent(oldHtmlValue)
  const comparativeContent = formatContent(newHtmlValue)

  const codeFoldMessageRenderer = (number) => (
    <Box display="flex" alignItems="center" gridGap={theme.spacing(2)}>
      <ChevronDownIcon />
      <Typography variant="h6">Expandir {number} linhas ...</Typography>
    </Box>
  )

  const handleLineNumberClick = (lineNumber) => setHighlightLine([lineNumber])

  const sameChangesHtml = oldHtmlValue === newHtmlValue || ''
  const sameChanges = originContent === comparativeContent || ''

  const contentAlert = validates.diffViewer.setAlertContent({
    sameChangesHtml,
    sameChanges,
  })

  return (
    <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
      {hasAlert && (
        <Alert
          variant="outlined"
          severity={!sameChangesHtml && sameChanges ? 'warning' : 'info'}
        >
          {contentAlert}
        </Alert>
      )}
      {!sameChangesHtml && !sameChanges && (
        <Box border={`1px solid ${theme.palette.paper.border}`}>
          <ReactDiffViewer
            compareMethod={DiffMethod.WORDS_WITH_SPACE}
            codeFoldMessageRenderer={codeFoldMessageRenderer}
            onLineNumberClick={handleLineNumberClick}
            highlightLines={highlightLine}
            styles={CUSTOM_STYLES_COMPONENT}
            oldValue={originContent}
            newValue={comparativeContent}
            {...rest}
          />
        </Box>
      )}
    </Box>
  )
}

BaseDiffViewer.propTypes = {
  oldHtmlValue: PropTypes.string,
  newHtmlValue: PropTypes.string,
}

export default BaseDiffViewer
