import React, { useEffect, useState } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { CircularProgress, TextField } from '@material-ui/core'
import { Controller, useFormContext } from 'react-hook-form'

const AutocompleteTypeAndSearch = ({
  textField = {},
  requestToLoadOptions,
  name = '',
  ...rest
}) => {
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const [loadingOptions, setLoadingOptions] = useState(false)

  const { control } = useFormContext()

  const getOptionLabel = (option) => {
    return option?.name || ''
  }

  const getOptionSelected = (option, value) => {
    if (!value) return false

    return option?.id === value.id || value.id === 0
  }

  const noOptionTextInstructions = () => {
    if (loadingOptions) return 'Carregando...'
    if (inputValue.length < 3) return 'Digite no mínimo 3 letras pra buscar'

    return 'Sem opções'
  }

  const handleTypeInputValue = (e) => {
    setInputValue(e.target.value)
  }

  const fetchOptions = async () => {
    setLoadingOptions(true)

    const fetchedOptions = await requestToLoadOptions(inputValue)

    setLoadingOptions(false)
    setOptions(fetchedOptions)
  }

  useEffect(() => {
    const delayRequest = setTimeout(() => {
      if (inputValue.length >= 3) {
        fetchOptions()
      }
    }, 1000)

    return () => clearTimeout(delayRequest)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  return (
    <Controller
      as={
        <Autocomplete
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          noOptionsText={noOptionTextInstructions()}
          options={options}
          {...rest}
          renderInput={(params) => (
            <TextField
              {...params}
              {...textField}
              onChange={handleTypeInputValue}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingOptions ? (
                      <CircularProgress color="primary" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      }
      control={control}
      name={name}
      mode="onBlur"
      onChange={([, data]) => data}
    />
  )
}

export default AutocompleteTypeAndSearch
