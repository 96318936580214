import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ supplierId = '', ...params }) => {
  return await dponetAPI.get(`/company_suppliers/${supplierId}`, { params })
}

const logs = async ({ supplierId = '', ...params }) => {
  return await dponetAPI.get(`/company_suppliers/${supplierId}/logs`, {
    params,
  })
}

const mailLogs = async ({ supplierId = '', ...params }) => {
  return await dponetAPI.get(`/mail_logs`, {
    params,
  })
}

const create = async (data) => {
  return await dponetAPI.postForm('/company_suppliers', data)
}

const put = async ({ supplierInviteId = '', ...params }) => {
  return await dponetAPI.put(
    `/company_suppliers/supplier_invites/${supplierInviteId}`,
    params,
  )
}

const importSuppliers = async (data) => {
  return await dponetAPI.postForm('/company_suppliers/import_suppliers', data)
}

const approveOrRefuse = async ({ supplierId = '', ...params }) => {
  return await dponetAPI.put(
    `/company_suppliers/${supplierId}/approve_or_refuse`,
    params,
  )
}

const supplierIndex = async ({ ...params }) => {
  return await dponetAPI.get('/company_suppliers/iam_supplier/', { params })
}

const clientIndex = async ({ ...params }) => {
  return await dponetAPI.get('/company_suppliers/iam_client/', { params })
}

const supplierQuestionnaires = async ({ supplierId }) => {
  return await dponetAPI.get(
    `/company_suppliers/${supplierId}/supplier_questionnaires`,
  )
}

const resendSolicitation = async ({ supplierId, ...data }) => {
  return await dponetAPI.post(
    `/company_suppliers/${supplierId}/resend_solicitation`,
    data,
  )
}

const inactivate = async ({ supplierId }) => {
  return await dponetAPI.post(`/company_suppliers/${supplierId}/inactivate`)
}

const softDelete = async ({ supplierId }) => {
  return await dponetAPI.delete(`/company_suppliers/${supplierId}`)
}

const stats = async () => {
  return await dponetAPI.get('/company_suppliers/stats')
}

export default {
  approveOrRefuse,
  clientIndex,
  create,
  get,
  importSuppliers,
  inactivate,
  logs,
  mailLogs,
  put,
  resendSolicitation,
  softDelete,
  stats,
  supplierIndex,
  supplierQuestionnaires,
}
