import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@material-ui/core'

import { Permitted, Select } from 'components'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import theme from 'theme'
import schema from './schema'
import * as service from 'service'
import permissions from 'constants/permissions'

const ConsentDetailsForm = ({
  setLoading,
  users,
  usersRefresh,
  consentForm,
  consentFormRefresh,
}) => {
  const [haveCoResponsible, setHaveCoResponsible] = useState(false)

  const snackbar = useSnackbar()
  const { control, errors, reset, setValue, handleSubmit } = useForm({
    validationSchema: schema,
    defaultValues: {
      responsibleId: consentForm?.responsibleId || undefined,
      coResponsibleId: consentForm?.coResponsibleId || undefined,
    },
  })

  const handleChangeSwitch = (event) =>
    setHaveCoResponsible(event?.target?.checked)

  const onSubmit = async (data) => {
    try {
      setLoading(true)

      await service.dponet.consent.updateResponsible({
        consentFormId: consentForm?.id,
        ...data,
      })

      snackbar.open({
        message: 'Responsável atualizado com sucesso!',
        variant: 'success',
      })
      reset()
      usersRefresh()
      consentFormRefresh()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (consentForm?.coResponsibleId) setHaveCoResponsible(true)
  }, [consentForm])

  useEffect(() => {
    if (!haveCoResponsible) setValue('coResponsibleId', undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [haveCoResponsible])

  return (
    <Box
      component="form"
      id="assign-responsible-consent-details-form"
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(3)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid container>
        <Grid item xs={12} md={10}>
          <Typography variant="h4">Atribuir Responsável</Typography>
          <Typography variant="caption" color="secondary">
            Altera o perfil responsável para gerenciar as revogações dos
            consentimentos de titulares de dados.
          </Typography>
        </Grid>

        <Permitted tag={permissions.CONSENTS.CHANGE_RESPONSIBLE}>
          <Grid item xs={12} md={2}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Salvar
            </Button>
          </Grid>
        </Permitted>
      </Grid>
      <Box p={2} bgcolor={theme.palette.background.default}>
        <Typography variant="h6">
          Encaminhar para o responsável da organização
        </Typography>
        <Typography>
          Indique qual o responsável por operacionalizar as revogações nos
          consentimentos de titulares de dados dentro da sua organização.
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gridGap={theme.spacing(1)}>
        <Controller
          as={
            <Select
              fullWidth
              label="Responsável"
              variant="outlined"
              items={users || []}
              error={!!errors?.responsibleId}
              helperText={<>{errors?.responsibleId?.message}</>}
            />
          }
          control={control}
          name="responsibleId"
          mode="onBlur"
        />
        <FormControlLabel
          control={
            <Switch
              checked={haveCoResponsible}
              onChange={handleChangeSwitch}
              color="primary"
            />
          }
          label="Ativar co-responsável"
        />
        {haveCoResponsible && (
          <Controller
            as={
              <Select
                fullWidth
                label="Co-responsável"
                variant="outlined"
                items={users || []}
                error={!!errors?.coResponsibleId}
                helperText={<>{errors?.coResponsibleId?.message}</>}
              />
            }
            control={control}
            name="coResponsibleId"
            mode="onBlur"
          />
        )}
      </Box>
    </Box>
  )
}

ConsentDetailsForm.propTypes = {
  setLoading: PropTypes.func.isRequired,
  users: PropTypes.object,
  usersRefresh: PropTypes.func.isRequired,
  consentForm: PropTypes.object,
  consentFormRefresh: PropTypes.func.isRequired,
}

export default ConsentDetailsForm
