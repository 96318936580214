import categoryName from './categoryName'
import findAnswer from './findAnswer'
import formatTimeSpent from './formatTimeSpent'
import mountComplaintInfos from './mountComplaintInfos'
import statusButtonItems from './statusButtonItems'
import statusColor from './statusColor'
import statusLabel from './statusLabel'
import subcategoryName from './subcategoryName'

export default {
  categoryName,
  findAnswer,
  formatTimeSpent,
  mountComplaintInfos,
  statusButtonItems,
  statusColor,
  statusLabel,
  subcategoryName,
}
