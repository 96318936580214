import { isNil, isEmpty } from 'lodash'

import constants from 'constants/index'

const size = (unit, sizeLimit, files) => {
  if (isNil(files) || (files instanceof FileList && isEmpty(files))) return true

  const exponentiation = constants.validations.BYTE_UNIT_EXPONENT[unit]
  const checkFileSize = (file) => file?.size <= sizeLimit * exponentiation

  if (files instanceof FileList || Array.isArray(files)) {
    return Array.from(files).every(checkFileSize)
  }

  return checkFileSize(files)
}

const type = (permittedTypes, files) => {
  if (isNil(files) || (files instanceof FileList && isEmpty(files))) return true

  const checkFileType = (file) => permittedTypes.includes(file?.type)

  if (files instanceof FileList || Array.isArray(files)) {
    return Array.from(files).every(checkFileType)
  }

  return checkFileType(files)
}

const uniqueFile = (value) => {
  if (!value || value.length === 0) return true

  const filesArray =
    value instanceof FileList || Array.isArray(value)
      ? Array.from(value)
      : [value]

  const fileNames = filesArray.map((file) => file?.name)
  const hasDuplicates = fileNames.some(
    (name, idx) => fileNames.indexOf(name) !== idx,
  )

  return !hasDuplicates
}

const isSameFile = (file1, file2) => {
  return file1?.name === file2?.name && file1?.size === file2?.size
}

const isFileInList = (file, fileList) => {
  const filesArray = fileList ? Array.from(fileList) : []

  return (
    Array.isArray(filesArray) &&
    filesArray?.some((apiFile) => isSameFile(file, apiFile))
  )
}

const filterExistingFiles = (fileList, apiFiles) => {
  const filesArray = fileList ? Array.from(fileList) : []

  return (
    Array.isArray(filesArray) &&
    filesArray?.filter((file) => !isFileInList(file, apiFiles))
  )
}

const arrayToFileList = (files) => {
  const dataTransfer = new DataTransfer()
  if (isEmpty(files)) return dataTransfer

  files.forEach((file) => dataTransfer.items.add(file))

  return dataTransfer.files
}

const findFileIdByName = (filesRemovedArray, apiFiles) => {
  if (isEmpty(filesRemovedArray) || isEmpty(apiFiles)) return []

  return filesRemovedArray?.flatMap((file) =>
    apiFiles
      .filter(
        (apiFile) =>
          apiFile.name === file.name || apiFile.url.includes(file.name),
      )
      .map((apiFile) => apiFile.id),
  )
}

const getMimeTypeFromExtension = (filename) => {
  const extension = filename
    .substring(filename.lastIndexOf('.') + 1)
    .toLowerCase()

  return (
    constants.validations.MIME_TYP_VALIDATION[extension] ||
    constants.validations.UNKNOWN_FILE_MIME_TYPE
  )
}

const handleDownloadFile = (file) => {
  const url = URL.createObjectURL(file)
  const link = document.createElement('a')
  link.href = url
  link.download = file.name
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}

export default {
  size,
  type,
  uniqueFile,
  isSameFile,
  filterExistingFiles,
  isFileInList,
  arrayToFileList,
  findFileIdByName,
  getMimeTypeFromExtension,
  handleDownloadFile,
}
