import React from 'react'

import { Backdrop, CircularProgress } from '@material-ui/core'

import useStyles from './styles'

const LoadingFeedback = ({ open = false, style }) => {
  const classes = useStyles()

  return (
    <Backdrop open={open} className={classes.backdrop}>
      <CircularProgress color="primary" style={style} />
    </Backdrop>
  )
}

export default LoadingFeedback
