import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { Box, TextField } from '@material-ui/core'

const DescriptionCustomization = ({
  selectedLanguage,
  localDescription,
  setLocalDescription,
  onDescriptionChange,
}) => {
  const debouncedDescriptionChange = useCallback(
    debounce((newValue) => {
      onDescriptionChange(selectedLanguage, newValue)
    }, 500),
    [onDescriptionChange, selectedLanguage],
  )

  const handleDescriptionChange = useCallback(
    (e) => {
      const newValue = e.target.value
      setLocalDescription(newValue)
      debouncedDescriptionChange(newValue)
    },
    [setLocalDescription, debouncedDescriptionChange],
  )

  return (
    <Box display="flex" flexDirection="column" width="100%" mb={2}>
      <TextField
        label={`Descrição em ${selectedLanguage}`}
        type="text"
        minRows={11}
        maxRows={11}
        multiline
        value={localDescription}
        onChange={handleDescriptionChange}
        fullWidth
      />
    </Box>
  )
}

DescriptionCustomization.propTypes = {
  selectedLanguage: PropTypes.string.isRequired,
  localDescription: PropTypes.string.isRequired,
  setLocalDescription: PropTypes.func.isRequired,
  onDescriptionChange: PropTypes.func.isRequired,
}

export default DescriptionCustomization
