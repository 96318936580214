import React, { Fragment } from 'react'
import {
  Eye as EyeIcon,
  File as FileIcon,
  Trash as TrashIcon,
} from 'react-feather'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Alert, Skeleton } from '@material-ui/lab'
import {
  Box,
  Button,
  Chip,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core'

import { MenuButton, Permitted } from 'components'

import helpers from 'helpers'

import theme from 'theme'
import useStyles from './styles'
import constants from 'constants/index'

const DocumentCard = ({
  currentDocument,
  skeleton = false,
  handleRedirectToDocument = () => {},
  documentUpdateRedirection = () => {},
  handleOpenPreviewDialog = () => {},
  handleOpenDeleteDialog = () => {},
}) => {
  const classes = useStyles()

  const currentDate = moment(new Date())

  const DESCRIPTION_CARD_TYPOGRAPHY = [
    `Identificador: ${currentDocument?.id}`,
    `Criado em ${helpers.formatters.ptBrFormatDateTime(
      currentDocument?.createdAt,
    )}`,
    `Última atualização: Feito ${currentDate.from(
      moment(currentDocument?.updatedAt),
    )}`,
  ]

  const MENU_OPTIONS = [
    {
      icon: EyeIcon,
      title: 'Visualizar',
      permission: constants.permissions.DOCUMENT.LIST,
      handleClick: () => handleOpenPreviewDialog(currentDocument),
    },
    {
      icon: TrashIcon,
      title: 'Excluir',
      permission: constants.permissions.DOCUMENT.DESTROY,
      handleClick: () => handleOpenDeleteDialog(currentDocument?.id),
    },
  ]

  return (
    <Paper
      component={Box}
      p={2}
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(2)}
    >
      <Box
        display="flex"
        gridGap={theme.spacing(2)}
        alignItems="center"
        justifyContent="space-between"
      >
        {skeleton ? (
          <Fragment>
            <Skeleton variant="circle" width={24} height={24} />
            <Skeleton variant="rect" width={180} height={24} />
          </Fragment>
        ) : (
          <Fragment>
            <Box display="flex" gridGap={theme.spacing(2)} alignItems="center">
              <Box display="flex" alignItems="center">
                <FileIcon />
              </Box>
              <Tooltip
                title={
                  currentDocument?.documentCategory?.name.toUpperCase() || ''
                }
                placement="top"
              >
                <Chip
                  label={currentDocument?.documentCategory?.name}
                  className={classes.chip}
                  size="small"
                />
              </Tooltip>
            </Box>
            <Box>
              <MenuButton iconButtonFontSize={16}>
                {MENU_OPTIONS.map((menuOption, index) => (
                  <Permitted tag={menuOption.permission} key={index}>
                    <Button
                      color="secondary"
                      fullWidth
                      onClick={menuOption.handleClick}
                      startIcon={
                        <menuOption.icon size={16} strokeWidth={2.5} />
                      }
                    >
                      {menuOption.title}
                    </Button>
                  </Permitted>
                ))}
              </MenuButton>
            </Box>
          </Fragment>
        )}
      </Box>
      <Box>
        <Tooltip title={currentDocument?.title || ''} placement="right" arrow>
          <Typography
            variant="h5"
            className={classes.title}
            gutterBottom
            noWrap
          >
            {currentDocument?.title}
          </Typography>
        </Tooltip>
        {skeleton
          ? [...Array(3)].map((_, index) => (
              <Skeleton key={index} variant="text" />
            ))
          : DESCRIPTION_CARD_TYPOGRAPHY.map((item, index) => (
              <Tooltip key={index} title={item || ''} placement="right" arrow>
                <Typography
                  variant="subtitle2"
                  className={classes.descriptionText}
                  noWrap
                >
                  {item}
                </Typography>
              </Tooltip>
            ))}
      </Box>
      <Box>
        {skeleton ? (
          <Skeleton variant="rect" height={36} />
        ) : (
          <Alert
            variant="filled"
            severity="info"
            className={classes.alert}
            onClick={() => documentUpdateRedirection(currentDocument?.id)}
          >
            Este documento está desatualizado. Clique aqui para atualizá-lo!
            Caso você já tenha atualizado, por favor, exclua este documento
            antigo.
          </Alert>
        )}
      </Box>
      <Box>
        {skeleton ? (
          <Skeleton variant="rect" width={180} height={36} />
        ) : (
          <Button
            onClick={() => handleRedirectToDocument(currentDocument?.link)}
          >
            Abrir documento
          </Button>
        )}
      </Box>
    </Paper>
  )
}

DocumentCard.propTypes = {
  currentDocument: PropTypes.object,
  skeleton: PropTypes.bool,
  handleRedirectToDocument: PropTypes.func,
  documentUpdateRedirection: PropTypes.func,
  handleOpenPreviewDialog: PropTypes.func,
  handleOpenDeleteDialog: PropTypes.func,
}

export default DocumentCard
