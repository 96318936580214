import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { reverse } from 'named-urls'

import { BaseConfirmationDialog, LoadingFeedback, MenuButton } from 'components'

import useSnackbar from 'hooks/useSnackbar'

import constants from 'constants/index'
import { routes } from 'Routes'
import * as service from 'service'
import helpers from 'helpers'
import permissions from 'constants/permissions'
import usePermissions from 'hooks/usePermissions'

const Actions = ({ companySupplier = {}, refresh = () => {} }) => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openInactivateDialog, setOpenInactivateDialog] = useState(false)
  const [openReactivateDialog, setOpenReactivateDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  const history = useHistory()
  const snackbar = useSnackbar()
  const { permitted } = usePermissions()

  const companySupplierConstants = constants.companySuppliers
  const supplierInvite = companySupplier?.supplierInvite

  const companySupplierStatus = companySupplier?.status
  const isCompanySupplierApproved =
    companySupplierStatus === companySupplierConstants.APPROVED_STATUS
  const isCompanySupplierInactive =
    companySupplierStatus === companySupplierConstants.INACTIVE_STATUS

  const hasCompanySuppliersUpdatePermission = permitted(
    permissions.COMPANY_SUPPLIERS.UPDATE,
  )

  const handleEdit = () => {
    history.push(
      reverse(routes.thirdPartyManagements.partners.edit, {
        supplierInviteId: supplierInvite?.id,
      }),
    )
  }

  const handleVisualize = () => {
    history.push(
      reverse(routes.thirdPartyManagements.partners.show, {
        supplierInviteId: supplierInvite?.id,
      }),
    )
  }

  const handleOpenInactivateDialog = () => setOpenInactivateDialog(true)

  const handleInactivate = async () => {
    try {
      setLoading(true)
      await service.dponet.suppliers.inactivate({
        supplierId: companySupplier?.id,
      })
      openSuccessSnackbar('Parceiro inativado com sucesso')
      setOpenInactivateDialog(false)
      refresh()
    } catch (error) {
      openErrorSnackbar(error, 'Ocorreu um erro ao inativar o parceiro')
    } finally {
      setLoading(false)
    }
  }

  const handleOpenDeleteDialog = () => setOpenDeleteDialog(true)

  const handleDelete = async () => {
    try {
      setLoading(true)
      await service.dponet.suppliers.softDelete({
        supplierId: companySupplier?.id,
      })
      openSuccessSnackbar('Parceiro excluído com sucesso')
      setOpenDeleteDialog(false)
      refresh()
    } catch (error) {
      openErrorSnackbar(error, 'Ocorreu um erro ao excluir o parceiro')
    } finally {
      setLoading(false)
    }
  }

  const handleOpenReactivateDialog = () => setOpenReactivateDialog(true)

  const handleReactivate = async () => {
    try {
      setLoading(true)
      await service.dponet.suppliers.resendSolicitation({
        supplierId: companySupplier?.id,
      })
      openSuccessSnackbar('Solicitação de reativação enviada com sucesso')
      setOpenReactivateDialog(false)
      refresh()
    } catch (error) {
      openErrorSnackbar(error, 'Ocorreu um erro ao reativar o parceiro')
    } finally {
      setLoading(false)
    }
  }

  const openErrorSnackbar = (error, message) => {
    snackbar.open({
      message:
        helpers.formatters.errorMessage(error.response.data.error) || message,
      variant: 'error',
    })
  }

  const openSuccessSnackbar = (message) => {
    snackbar.open({
      message,
      variant: 'success',
    })
  }

  return (
    <MenuButton>
      <LoadingFeedback open={loading} />

      {isCompanySupplierApproved && (
        <Button color="secondary" fullWidth onClick={handleVisualize}>
          Visualizar
        </Button>
      )}

      {isCompanySupplierApproved && hasCompanySuppliersUpdatePermission && (
        <>
          <Button
            color="secondary"
            fullWidth
            onClick={handleEdit}
            disabled={isEmpty(supplierInvite)}
          >
            Editar
          </Button>
          <Button
            color="secondary"
            fullWidth
            onClick={handleOpenInactivateDialog}
          >
            Inativar
          </Button>
        </>
      )}

      {isCompanySupplierInactive && hasCompanySuppliersUpdatePermission && (
        <Button
          color="secondary"
          fullWidth
          onClick={handleOpenReactivateDialog}
        >
          Reativar
        </Button>
      )}

      {hasCompanySuppliersUpdatePermission && (
        <Button color="secondary" fullWidth onClick={handleOpenDeleteDialog}>
          Excluir
        </Button>
      )}

      {!hasCompanySuppliersUpdatePermission && isCompanySupplierInactive && (
        <Button color="secondary" fullWidth disabled>
          NENHUMA AÇÃO DISPONÍVEL
        </Button>
      )}

      {openDeleteDialog && (
        <BaseConfirmationDialog
          open={openDeleteDialog}
          setOpen={setOpenDeleteDialog}
          title={
            isCompanySupplierApproved
              ? 'Tem certeza de que deseja excluir este vínculo?'
              : 'Excluir vínculo?'
          }
          text={
            isCompanySupplierApproved
              ? 'Caso o contrato com o parceiro tenha sido encerrado, sugerimos apenas inativá-lo na plataforma. Assim, o histórico de que o parceiro foi avaliado será mantido para auditoria futura.'
              : 'Deseja confirmar a exclusão do parceiro?'
          }
          actionButtonText="Confirmar"
          variant="warning"
          actionButton={handleDelete}
          loading={loading}
        />
      )}

      {openInactivateDialog && (
        <BaseConfirmationDialog
          open={openInactivateDialog}
          setOpen={setOpenInactivateDialog}
          title="Inativar vínculo?"
          text="Deseja confirmar a inativação de vínculo com o parceiro?"
          actionButtonText="Confirmar"
          variant="warning"
          actionButton={handleInactivate}
          loading={loading}
        />
      )}

      {openReactivateDialog && (
        <BaseConfirmationDialog
          open={openReactivateDialog}
          setOpen={setOpenReactivateDialog}
          title="Reenviar convite"
          text={
            'Ao reativar um parceiro, um novo convite é enviado automaticamente. Você pode encontrá-lo na aba "Convites".'
          }
          actionButtonText="Confirmar"
          variant="blueConfirmation"
          actionButton={handleReactivate}
          loading={loading}
        />
      )}
    </MenuButton>
  )
}

export default Actions
