import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Controller, useFormContext } from 'react-hook-form'
import { Trash2 as Trash2Icon } from 'react-feather'
import { size } from 'lodash'
import { clsx } from 'clsx'
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'

import useStyles from './styles'
import theme from 'theme'

const TextFieldList = ({
  controlName,
  arrayControlName,
  containerTitle = 'Itens adicionados',
  disabled = false,
  ...rest
}) => {
  const classes = useStyles()

  const { control, errors, setValue, triggerValidation, getValues, watch } =
    useFormContext()

  const arrayItems = watch(arrayControlName)

  const handleAddItemArray = async () => {
    const isValid = await triggerValidation(controlName)
    if (isValid) {
      const newItem = getValues(controlName)
      const currentArray = getValues(arrayControlName) || []

      const itemExists = currentArray.includes(newItem)

      if (itemExists) setValue(controlName, '')
      else if (newItem) {
        setValue(arrayControlName, [...currentArray, newItem])
        setValue(controlName, '')
      }
    }
  }

  const handleRemoveItemArray = (indexRemoveItem) => {
    const currentArray = getValues(arrayControlName) || []
    const updatedArray = currentArray.filter(
      (_, index) => index !== indexRemoveItem,
    )

    setValue(arrayControlName, updatedArray)
  }

  return (
    <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
      <Controller
        control={control}
        name={controlName}
        as={
          <TextField
            variant="outlined"
            error={!!errors?.[controlName]}
            helperText={<>{errors?.[controlName]?.message}</>}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    color="primary"
                    className={clsx({
                      [classes.buttonError]: !!errors?.[controlName],
                    })}
                    onClick={handleAddItemArray}
                    disabled={disabled}
                  >
                    Adicionar
                  </Button>
                </InputAdornment>
              ),
            }}
            disabled={disabled}
            {...rest}
          />
        }
      />
      <Controller control={control} name={arrayControlName} as={<></>} />
      {size(arrayItems) > 0 && (
        <Paper>
          <Box
            bgcolor={theme.palette.background.default}
            p={2}
            borderRadius={3}
          >
            <Typography>{containerTitle}</Typography>
          </Box>
          <Box py={2} pl={2}>
            <PerfectScrollbar
              options={{ wheelPropagation: false, useBothWheelAxes: true }}
            >
              <Box
                display="flex"
                flexDirection="column"
                gridGap={theme.spacing(1)}
                pr={2}
                maxHeight={400}
              >
                {arrayItems?.map((item, index) => (
                  <Paper key={index} className={classes.item}>
                    <Tooltip title={item}>
                      <Typography color="secondary" noWrap>
                        {item}
                      </Typography>
                    </Tooltip>
                    <IconButton onClick={() => handleRemoveItemArray(index)}>
                      <Trash2Icon size={20} />
                    </IconButton>
                  </Paper>
                ))}
              </Box>
            </PerfectScrollbar>
          </Box>
        </Paper>
      )}
    </Box>
  )
}

export default TextFieldList
