import theme from 'theme'

const INACTIVE_STATUS_ID = 0
const ACTIVE_STATUS_ID = 1

const DOCUMENT_KIND = 'document'
const REAL_KIND = 'real'
const TEMPLATE_KIND = 'template'

const LEGAL_BASIS_CONSENT_ID = '3'
const LEGAL_BASIS_LEGITIMATE_INTEREST_ID = '11'

const CONSENT_TABS = [
  { value: 'all', label: 'Todos' },
  { value: ACTIVE_STATUS_ID, label: 'ATIVOS' },
  { value: INACTIVE_STATUS_ID, label: 'INATIVOS' },
]

const RESPONSE_REVOKED_STATUS_ID = 0
const AUTHORIZED_RESPONSE_STATUS_ID = 1

const ALL_STATUSES_RESPONSE = [
  RESPONSE_REVOKED_STATUS_ID,
  AUTHORIZED_RESPONSE_STATUS_ID,
]

const AUTHORIZED_CONSENT = 'authorized'
const REVOKED_CONSENT = 'revoked'

const ACTIVE_LABEL = 'Ativo'
const INACTIVE_LABEL = 'Inativo'

const AUTHORIZED_LABEL = 'Autorizado'
const REVOKED_LABEL = 'Revogado'

const LEGITIMATE_INTEREST_LABEL = 'Legítimo Interesse'
const CONSENT_LABEL = 'Consentimento'

const DOCUMENT_PLATFORM = 'document'
const DPONET_PLATFORM = 'dponet'
const INTEGRATION_PLATFORM = 'integration'
const PRIVACY_PORTAL_PLATFORM = 'privacy_portal'

const CONSENT_RECEIPTS_TABS = [
  { value: 'all', label: 'Todos' },
  { value: AUTHORIZED_CONSENT, label: 'AUTORIZADOS' },
  { value: REVOKED_CONSENT, label: 'REVOGADOS' },
]

const ALL_STATUSES_CONSENT_RECEIPT = [AUTHORIZED_CONSENT, REVOKED_CONSENT]

const FORM_TEMPLATE = 'formTemplate'
const BLANK_FORM = 'blankForm'

const STANDARD_FIELDS_CONSENT_FORM = [
  {
    name: 'Nome completo',
    description: 'Preencha com seu nome completo.',
  },
  {
    name: 'Telefone',
    description: 'Preencha com seu número de telefone (fixo ou celular).',
  },
  { name: 'CPF', description: 'Preencha com seu CPF.' },
  { name: 'RG', description: 'Preencha com seu RG.' },
  { name: 'CNPJ', description: 'Preencha com seu CNPJ.' },
]

const STATUS_LABEL_RECEIPT = {
  [AUTHORIZED_CONSENT]: AUTHORIZED_LABEL,
  [REVOKED_CONSENT]: REVOKED_LABEL,
}

const LEGAL_BASE_LABEL_CONSENT = {
  [LEGAL_BASIS_CONSENT_ID]: CONSENT_LABEL,
  [LEGAL_BASIS_LEGITIMATE_INTEREST_ID]: LEGITIMATE_INTEREST_LABEL,
}

const RECEIPT_STATUSES = [
  { id: AUTHORIZED_CONSENT, name: AUTHORIZED_LABEL },
  { id: REVOKED_CONSENT, name: REVOKED_LABEL },
]

const PLATFORM_LABEL_CONSENT_HISTORY = {
  [DPONET_PLATFORM]: 'DPOnet',
  [PRIVACY_PORTAL_PLATFORM]: 'Portal da Privacidade',
  [INTEGRATION_PLATFORM]: 'Integração',
}

const PLATFORM_FILTER = [
  {
    id: DPONET_PLATFORM,
    name: PLATFORM_LABEL_CONSENT_HISTORY[DPONET_PLATFORM],
  },
  {
    id: PRIVACY_PORTAL_PLATFORM,
    name: PLATFORM_LABEL_CONSENT_HISTORY[PRIVACY_PORTAL_PLATFORM],
  },
  {
    id: INTEGRATION_PLATFORM,
    name: PLATFORM_LABEL_CONSENT_HISTORY[INTEGRATION_PLATFORM],
  },
]

const CONSENT_HISTORY_CONTENT = {
  [DPONET_PLATFORM]: 'Concedido via plataforma pelo controlador.',
  [PRIVACY_PORTAL_PLATFORM]: 'Concedido via formulário pelo titular de dados.',
  [INTEGRATION_PLATFORM]: 'Concedido via integração pelo controlador.',
}

const INACTIVE_CONSENT_FORM_STATUS_ID = 'inactive'
const ACTIVE_CONSENT_FORM_STATUS_ID = 'active'

const ALL_STATUSES_CONSENT = [
  INACTIVE_CONSENT_FORM_STATUS_ID,
  ACTIVE_CONSENT_FORM_STATUS_ID,
]

const FORM_STATUSES = [
  { id: ACTIVE_CONSENT_FORM_STATUS_ID, name: ACTIVE_LABEL },
  { id: INACTIVE_CONSENT_FORM_STATUS_ID, name: INACTIVE_LABEL },
]

const CONSENT_FORM_STATUS_LABEL_TRANSLATION = {
  [ACTIVE_CONSENT_FORM_STATUS_ID]: ACTIVE_LABEL,
  [INACTIVE_CONSENT_FORM_STATUS_ID]: INACTIVE_LABEL,
}

const CONSENT_FORM_STATUS_CHIP_COLOR = {
  [INACTIVE_CONSENT_FORM_STATUS_ID]: theme.palette.error.main,
  [ACTIVE_CONSENT_FORM_STATUS_ID]: theme.palette.greenPantone,
}

const LEGAL_BASIS_CONTROL_ITEMS = [
  {
    value: LEGAL_BASIS_CONSENT_ID,
    title: 'Consentimento',
    description:
      'Quando uma pessoa consente com o tratamento dos seus dados, de forma livre, inequívoca e informada.',
  },
  {
    value: LEGAL_BASIS_LEGITIMATE_INTEREST_ID,
    title: 'Legítimo interesse',
    description:
      'Quando a empresa possui um interesse legítimo para o processamento e a utilização dos dados está dentro das expectativas da pessoa, não sendo necessário obter consentimento.',
  },
]

const SUPPORTED_DOCUMENT_FILE_SCHEMA_TYPES = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'text/plain',
  'application/pdf',
  'application/msword',
  'image/png',
  'image/jpeg',
  'image/svg+xml',
  'image/webp',
]

const SUPPORTED_DOCUMENT_FILE_INPUT_TYPES =
  '.doc,.docx,.ppt,.pptx,.txt,.pdf,.jpg,.png,.jpeg,.svg,.webp'

const RECEIPT_MAIN_DRIVER_STEP_0 = 'receipt-main-driver-step-0'

const RECEIPT_MAIN_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + RECEIPT_MAIN_DRIVER_STEP_0,
    popover: {
      description:
        "Todos os formulários de consentimento preenchidos serão listados aqui. No entanto, se houver algum consentimento registrado fisicamente, você pode cadastrá-lo manualmente clicando no botão 'Registrar Consentimento'.",
    },
  },
]

const NEW_RECEIPT_DRIVER_STEP_0 = 'new-receipt-driver-step-0'

const NEW_RECEIPT_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + NEW_RECEIPT_DRIVER_STEP_0,
    popover: {
      description:
        'Os consentimentos são coletados através do envio de links ou e-mails. No entanto, se houver algum consentimento registrado fisicamente, você pode cadastrá-lo manualmente aqui.',
    },
  },
]

const NEW_FORM_DRIVER_STEP_0 = 'new-form-driver-step-0'

const NEW_FORM_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + NEW_FORM_DRIVER_STEP_0,
    popover: {
      description:
        "Os formulários de consentimento são utilizados para campanhas e eventos que necessitam da coleta de dados e assinatura para o tratamento desses dados. É possível criar novos formulários de consentimento, com alguns modelos já disponíveis. Para acessá-los, basta clicar em 'Novo Formulário' e selecionar 'Modelos de Formulários'. Outra opção é criar um documento em branco.",
    },
  },
]

const DETAILS_DRIVER_STEP_0 = 'consent-details-driver-step-0'
const DETAILS_DRIVER_STEP_1 = 'consent-details-driver-step-1'
const DETAILS_DRIVER_STEP_2 = 'consent-details-driver-step-2'

const DETAILS_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + DETAILS_DRIVER_STEP_0,
    popover: {
      description:
        "Em 'Ver Detalhes', é possível excluir ou inativar o formulário. No entanto, uma vez criado, não é mais possível editá-lo.",
    },
  },
  {
    element: '#' + DETAILS_DRIVER_STEP_1,
    popover: {
      description:
        'Gráfico de controle para os consentimentos coletados, indicando se foram revogados ou não. Após a exclusão de um formulário, todos os consentimentos associados são removidos da plataforma.',
    },
  },
  {
    element: '#' + DETAILS_DRIVER_STEP_2,
    popover: {
      description:
        'Publique seu formulário através de um link ou envie-o diretamente para o e-mail de uma pessoa.',
    },
  },
]
const NEW_CONSENT_FORM_DRIVER_STEP_0 = 'new-consent-form-driver-step-0'
const NEW_CONSENT_FORM_DRIVER_STEP_1 = 'new-consent-form-driver-step-1'
const NEW_CONSENT_FORM_DRIVER_STEP_2 = 'new-consent-form-driver-step-2'
const NEW_CONSENT_FORM_DRIVER_STEP_3 = 'new-consent-form-driver-step-3'
const NEW_CONSENT_FORM_DRIVER_STEP_4 = 'new-consent-form-driver-step-4'
const NEW_CONSENT_FORM_DRIVER_STEP_5 = 'new-consent-form-driver-step-5'

const NEW_FORM_GENERAL_INFORMATIONS_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + NEW_CONSENT_FORM_DRIVER_STEP_0,
    popover: {
      description:
        "Preencha as informações necessárias. Existem dois tipos de formulários de consentimento. 'Consentimento Livre' - Quando uma pessoa consente com o tratamento dos dados de forma livre. 'Legítimo Interesse' - Quando a empresa possui um interesse legítimo no processamento dos dados, e a utilização desses dados está dentro das expectativas do fornecedor dos dados.",
    },
  },
]

const NEW_FORM_CONSENT_TEXT_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + NEW_CONSENT_FORM_DRIVER_STEP_1,
    popover: {
      description:
        'O texto de consentimento deve ser preenchido minuciosamente, pois é ele que será apresentado a quem preencherá o formulário, explicando o motivo da coleta dos dados.',
    },
  },
]

const NEW_FORM_FIELDS_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + NEW_CONSENT_FORM_DRIVER_STEP_2,
    popover: {
      description:
        "Nos campos de coleta do formulário, o e-mail é obrigatório. Os demais campos podem ser definidos e editados para serem obrigatórios ou não. Caso não exista um campo que você precise, basta adicioná-lo clicando em 'Criar Novo Campo'.",
    },
  },
]

const NEW_FORM_PREVIEW_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + NEW_CONSENT_FORM_DRIVER_STEP_3,
    popover: {
      description:
        'Visualize uma prévia de como o formulário será apresentado.',
    },
  },
]

const NEW_FORM_SETTINGS_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + NEW_CONSENT_FORM_DRIVER_STEP_4,
    popover: {
      description:
        'Atribua um responsável para este formulário. Essa pessoa será avisada caso algum consentimento seja revogado. A atribuição de um responsável é feita com base no perfil e não no nome de usuário.',
    },
  },
]

const NEW_FORM_SHARING_DRIVER_STEPS_OBJECT = [
  {
    element: '#' + NEW_CONSENT_FORM_DRIVER_STEP_5,
    popover: {
      description:
        'Publique seu formulário através de um link ou envie-o diretamente para o e-mail de uma pessoa.',
    },
  },
]

const consent = {
  ACTIVE_CONSENT_FORM_STATUS_ID,
  ACTIVE_STATUS_ID,
  ALL_STATUSES_CONSENT_RECEIPT,
  ALL_STATUSES_CONSENT,
  ALL_STATUSES_RESPONSE,
  AUTHORIZED_CONSENT,
  AUTHORIZED_RESPONSE_STATUS_ID,
  BLANK_FORM,
  CONSENT_FORM_STATUS_CHIP_COLOR,
  CONSENT_FORM_STATUS_LABEL_TRANSLATION,
  CONSENT_HISTORY_CONTENT,
  CONSENT_RECEIPTS_TABS,
  CONSENT_TABS,
  FORM_TEMPLATE,
  INACTIVE_STATUS_ID,
  LEGAL_BASIS_CONSENT_ID,
  LEGAL_BASIS_LEGITIMATE_INTEREST_ID,
  RESPONSE_REVOKED_STATUS_ID,
  STANDARD_FIELDS_CONSENT_FORM,
  STATUS_LABEL_RECEIPT,
  LEGAL_BASE_LABEL_CONSENT,
  RECEIPT_STATUSES,
  PLATFORM_FILTER,
  FORM_STATUSES,
  PLATFORM_LABEL_CONSENT_HISTORY,
  DPONET_PLATFORM,
  PRIVACY_PORTAL_PLATFORM,
  SUPPORTED_DOCUMENT_FILE_INPUT_TYPES,
  SUPPORTED_DOCUMENT_FILE_SCHEMA_TYPES,
  RECEIPT_MAIN_DRIVER_STEP_0,
  RECEIPT_MAIN_DRIVER_STEPS_OBJECT,
  NEW_RECEIPT_DRIVER_STEP_0,
  NEW_RECEIPT_DRIVER_STEPS_OBJECT,
  NEW_FORM_DRIVER_STEP_0,
  NEW_FORM_DRIVER_STEPS_OBJECT,
  DETAILS_DRIVER_STEP_0,
  DETAILS_DRIVER_STEP_1,
  DETAILS_DRIVER_STEP_2,
  DETAILS_DRIVER_STEPS_OBJECT,
  DOCUMENT_KIND,
  DOCUMENT_PLATFORM,
  LEGAL_BASIS_CONTROL_ITEMS,
  NEW_CONSENT_FORM_DRIVER_STEP_0,
  NEW_CONSENT_FORM_DRIVER_STEP_1,
  NEW_CONSENT_FORM_DRIVER_STEP_2,
  NEW_CONSENT_FORM_DRIVER_STEP_3,
  NEW_CONSENT_FORM_DRIVER_STEP_4,
  NEW_CONSENT_FORM_DRIVER_STEP_5,
  NEW_FORM_CONSENT_TEXT_DRIVER_STEPS_OBJECT,
  NEW_FORM_FIELDS_DRIVER_STEPS_OBJECT,
  NEW_FORM_GENERAL_INFORMATIONS_DRIVER_STEPS_OBJECT,
  NEW_FORM_PREVIEW_DRIVER_STEPS_OBJECT,
  NEW_FORM_SETTINGS_DRIVER_STEPS_OBJECT,
  NEW_FORM_SHARING_DRIVER_STEPS_OBJECT,
  REAL_KIND,
  REVOKED_CONSENT,
  TEMPLATE_KIND,
}

export default consent
