const routesWithLabel = (routes, currentRoute, currentPath) => {
  const defaultLabels = {
    // Dashboard
    [routes.myLgpd.dashboard]: '',
    // Data processes
    [routes.dataProcess.all]: 'Listagem',
    [routes.dataProcess.view]: 'Processo',
    [routes.dataProcess.new]: 'Processo',
    [routes.dataProcess.edit]: 'Processo',
    [routes.dataProcess.resume]: 'Processo',
    [routes.dataProcess.suggested]: 'Sugestão',
    [routes.dataProcess.audit]: 'Auditoria',
    [routes.dataProcess.suggestedChanges]: 'Alterações sugeridas ',
    // Departments
    [routes.department.all]: 'Listagem',
    [routes.department.new]: 'Departamento',
    [routes.department.edit]: 'Departamento',
    // Users
    [routes.user.all]: 'Listagem',
    [routes.user.new]: 'Usuário',
    [routes.user.edit]: 'Usuário',
    [routes.user.account]: 'Usuário',
    // Partners
    [routes.marketplace.all]: 'Listagem',
    [routes.marketplace.show]: 'Serviços',
    // Questionnaires
    [routes.questionnaire.all]: 'Listagem',
    // Incidents
    [routes.incidents.all]: 'Listagem',
    [routes.incidents.new]: 'Incidente',
    [routes.incidents.edit]: 'Incidente',
    // Profiles
    [routes.profiles.all]: 'Listagem',
    [routes.profiles.new]: 'Perfil',
    [routes.profiles.edit]: 'Perfil',
    // Fornecedores || Parceiros
    [routes.thirdPartyManagements.intermediator]: 'Gestão de terceiros',
    [routes.thirdPartyManagements.invites]: 'Convites',
    [routes.thirdPartyManagements.partners.all]: 'Listagem',
    [routes.thirdPartyManagements.partners.new]: 'Parceiro',
    [routes.thirdPartyManagements.partners.edit]: 'Parceiro',
    [routes.thirdPartyManagements.partners.showQuestionnaires]: 'Parceiro',
    [routes.thirdPartyManagements.partners.show]: 'Parceiro',
    [routes.thirdPartyManagements.companies.show]: 'Parceiro',
    [routes.thirdPartyManagements.preAvaliations]: 'Pré-avaliação',
    [routes.thirdPartyManagements.suggestions]: 'Sugestões',
  }

  return { url: currentPath, label: defaultLabels[currentRoute] }
}

const formatters = { routesWithLabel }

export default formatters
