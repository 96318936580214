import React, { useEffect, useRef, useState } from 'react'
import { Info as InfoIcon } from 'react-feather'
import { useHistory } from 'react-router-dom'
import { Box, Tab, Tabs, Tooltip } from '@material-ui/core'

import { Container, ContentHeader, LoadingFeedback, Page } from 'components'
import { DocumentForm, AcceptedSection } from './components'

import useFetch from 'hooks/useFetch'
import usePermissions from 'hooks/usePermissions'

import theme from 'theme'
import { routes } from 'Routes'
import * as service from 'service'
import constants from 'constants/index'

const DocumentsShow = ({ match }) => {
  const { documentId } = match?.params

  const { DOCUMENTS_CATEGORY_ID, ACCEPTED_DOCUMENTS_CATEGORY_ID } =
    constants.documents
  const { CONSENTS, CONSENT_RECEIPTS } = constants.permissions

  const [currentTab, setCurrentTab] = useState(DOCUMENTS_CATEGORY_ID)
  const [hasPermission, setHasPermission] = useState(false)
  const [loading, setLoading] = useState(false)

  const formRef = useRef(null)
  const defaultValuesRef = useRef(null)

  const history = useHistory()
  const permissions = usePermissions()

  const { response, isLoading } = useFetch(
    service.dponet.privacyPolicies.get,
    {
      privacyPolicyId: documentId,
    },
    [documentId],
  )

  const privacyPolicy = response?.data?.privacyPolicy

  const hasConsentForm = privacyPolicy?.consentFormId

  const disabledTab = !hasPermission || !hasConsentForm

  const handleTabChange = (_, tab) => setCurrentTab(tab)

  const handleBackRedirection = () => history.push(routes.documents.all)

  useEffect(() => {
    if (
      permissions.permitted(CONSENTS.LIST) &&
      permissions.permitted(CONSENT_RECEIPTS.LIST)
    )
      setHasPermission(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page title="Visualizar documento">
      <Container maxWidth={false}>
        <LoadingFeedback open={loading || isLoading} />
        <ContentHeader title="Visualizar documento" />
        <Box display="flex">
          <Tabs
            scrollButtons="auto"
            value={currentTab}
            variant="scrollable"
            textColor="primary"
            indicatorColor="primary"
            onChange={handleTabChange}
          >
            <Tab value={DOCUMENTS_CATEGORY_ID} label="Documentos" />
            <Tab
              value={ACCEPTED_DOCUMENTS_CATEGORY_ID}
              label="Aceites"
              disabled={disabledTab}
            />
          </Tabs>
          {disabledTab && (
            <Tooltip
              title={
                !hasPermission
                  ? 'Você não possui permissões suficientes para visualizar os recibos e consentimentos. Caso precise de acesso, entre em contato com um superior da empresa.'
                  : 'Nenhum formulário de aceites configurado para visualização.'
              }
              placement="right"
            >
              <Box>
                <InfoIcon size={18} color={theme.palette.custom.disabled} />
              </Box>
            </Tooltip>
          )}
        </Box>
        {currentTab === DOCUMENTS_CATEGORY_ID && !isLoading && (
          <DocumentForm
            currentDocument={privacyPolicy}
            defaultValuesRef={defaultValuesRef}
            documentId={documentId}
            formRef={formRef}
            handleBackRedirection={handleBackRedirection}
            setLoading={setLoading}
            type="show"
          />
        )}
        {currentTab === ACCEPTED_DOCUMENTS_CATEGORY_ID && !isLoading && (
          <AcceptedSection
            consentFormId={privacyPolicy?.consentFormId}
            currentDocument={privacyPolicy}
          />
        )}
      </Container>
    </Page>
  )
}

export default DocumentsShow
