import { makeStyles } from '@material-ui/core'

const styles = (style) =>
  makeStyles((theme) => ({
    iconButton: {
      padding: 8,
    },
    textField: {
      borderRadius: 4,
      ...style,
    },
  }))

export default styles
