import React, { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import { isEmpty, orderBy, size } from 'lodash'

import { Box, Grid, MobileStepper, Button, Typography } from '@material-ui/core'

import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from 'react-feather'

import { AboutModal } from './components'
import { SupplierAdequacy } from '../../components'
import { LoadingFeedback } from 'components'

import { routes } from 'Routes'
import { useHistory } from 'react-router-dom'

import useSnackbar from 'hooks/useSnackbar'
import useFetch from 'hooks/useFetch'
import useStyles from './styles'

import * as service from 'service'
import helpers from 'helpers'
import constants from 'constants/index'

const FundamentalRequirements = ({ setRenderedContent }) => {
  const [activeStep, setActiveStep] = useState(1)
  const [open, setOpen] = useState(false)
  const [loadingReply, setLoadingReply] = useState(false)

  const classes = useStyles()
  const snackbar = useSnackbar()
  const history = useHistory()

  const { response, isLoading } = useFetch(service.dponet.questionnaires.get, {
    kind: constants.questionnaire.FUNDAMENTAL_REQUIREMENTS,
  })

  const { mixpanel } = helpers

  const fundamentalRequirements = response?.data?.questionnaires[0]

  const activeQuestions = fundamentalRequirements?.questions?.filter(
    (question) => question?.status !== constants.questions.INACTIVE_STATUS,
  )

  const orderedActiveQuestion = orderBy(activeQuestions, 'orderPosition', 'asc')

  const submitAnswers = async (questionnaire) => {
    setLoadingReply(true)
    const questionnaireId = fundamentalRequirements.id
    try {
      await service.dponet.questionnaires.reply({
        questionnaireId,
        questionnaire,
      })
      if (
        questionnaire.answers[0].id ===
        orderedActiveQuestion[activeQuestions?.length - 1].id
      ) {
        const response = await service.dponet.questionnaires.companyCompliance()

        const companyCompliance = response?.data

        if (companyCompliance?.negativeQuestions === 0) {
          return history.go(routes.questionnaire.all)
        } else {
          setRenderedContent(
            <SupplierAdequacy
              nonCompliance={companyCompliance?.negativeQuestions}
            />,
          )
        }
      }
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    } finally {
      setLoadingReply(false)
    }
  }

  const handleActiveQuestion = useMemo(() => {
    if (isEmpty(orderedActiveQuestion)) return 0
    return orderedActiveQuestion[activeStep - 1]
  }, [activeStep, orderedActiveQuestion])

  const handleNext = (answer) => {
    mixpanel.track('Jornada de Fornecedores', 'Diagnóstico', {
      action: `${answer}-Pergunta_${activeStep}`,
    })
    submitAnswers({
      answers: [{ id: handleActiveQuestion.id, answer: answer }],
    })
    if (activeStep < activeQuestions?.length) {
      setActiveStep(activeStep + 1)
    }
  }

  const findFirstUnansweredQuestionIndex = () =>
    orderedActiveQuestion?.findIndex((question) => !question.answer)

  useEffect(() => {
    const questions = fundamentalRequirements?.questions

    const firstUnansweredQuestionIndex = findFirstUnansweredQuestionIndex()

    const firstStep =
      firstUnansweredQuestionIndex === 0 || firstUnansweredQuestionIndex
        ? firstUnansweredQuestionIndex + 1
        : questions?.length

    setActiveStep(firstStep)
    // eslint-disable-next-line
  }, [fundamentalRequirements])

  const disableNextStep =
    activeStep === size(fundamentalRequirements?.questions)

  return (
    <>
      <LoadingFeedback open={isLoading || loadingReply} />
      {!isLoading && (
        <Box mt={15} padding="0">
          <Grid
            container
            justifyContent="center"
            className={classes.mainContainer}
          >
            <MobileStepper
              variant="progress"
              steps={activeQuestions?.length + 1}
              position="static"
              activeStep={activeStep}
              className={classes.stepper}
            />
            <Grid item xs={12}>
              <Typography
                className={clsx(
                  classes.textFormat,
                  classes.bodyText,
                  classes.questionnaireItem,
                )}
              >
                {handleActiveQuestion?.question}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.roundedButton}
                onClick={() => handleNext('Sim')}
              >
                Sim
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={clsx(
                  classes.roundedButton,
                  classes.outlined,
                  classes.noButton,
                )}
                onClick={() => handleNext('Não')}
              >
                Não
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              className={classes.arrowButtonContainer}
            >
              <Button
                disabled={activeStep === 1}
                onClick={() => {
                  setActiveStep(activeStep - 1)
                }}
              >
                <ChevronLeftIcon
                  color="white"
                  className={clsx({
                    [classes.disabledButton]: activeStep === 1,
                  })}
                  size={30}
                  strokeWidth={1.5}
                />
              </Button>
              <Typography
                className={clsx(classes.textFormat, classes.activeStepText)}
                display="inline"
              >
                {activeStep}/{activeQuestions?.length}
              </Typography>
              <Button
                disabled={disableNextStep}
                onClick={() => {
                  setActiveStep(activeStep + 1)
                }}
              >
                <ChevronRightIcon
                  color="white"
                  className={clsx({
                    [classes.disabledButton]: disableNextStep,
                  })}
                  size={30}
                  strokeWidth={1.5}
                />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography
                onClick={() => {
                  mixpanel.track('Jornada de Fornecedores', 'Diagnóstico', {
                    action: 'Saiba_mais-Diagnóstico',
                  })
                  setOpen(true)
                }}
                className={clsx(classes.textFormat, classes.aboutButton)}
              >
                Saiba mais
              </Typography>
            </Grid>
          </Grid>
          <AboutModal open={open} setOpen={setOpen} activeStep={activeStep} />
        </Box>
      )}
    </>
  )
}

export default FundamentalRequirements
