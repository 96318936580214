import React from 'react'
import { Avatar, Box, Typography } from '@material-ui/core'

import theme from 'theme'

import useStyles from './styles'

const Head = ({ title = '', subtitle, showActions = false, children }) => {
  const classes = useStyles()

  const initials =
    title
      .split(' ')
      .map((name) => name[0])
      .join('')
      .toUpperCase() || 'S'

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="center" gridGap={theme.spacing(3)}>
        <Box>
          <Avatar className={classes.avatar}>{initials}</Avatar>
        </Box>
        <Box>
          <Typography variant="h3" gutterBottom>
            {title}
          </Typography>
          {subtitle && <Typography variant="body1">{subtitle}</Typography>}
        </Box>
      </Box>

      {showActions && children}
    </Box>
  )
}

export default Head
