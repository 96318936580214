import { makeStyles } from '@material-ui/core'

const styles = (isTable, hasPagination) =>
  makeStyles((theme) => ({
    titleContainer: {
      borderBottom: 0,
      borderRadius: 0,
    },
    childrenContainer: {
      borderRadius: 0,
      ...(isTable && !hasPagination && { borderBottom: 0 }),
    },
  }))

export default styles
