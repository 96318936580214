import { colors, makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  chip: {
    textTransform: 'uppercase',
    fontSize: theme.typography.caption.fontSize,
    paddingInline: theme.spacing(1),
    backgroundColor: colors.lightBlue[100],
    color: colors.blue[600],
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))
