import React, {
  Fragment,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { Controller, FormContext, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import { sanitize } from 'dompurify'
import { Alert } from '@material-ui/lab'
import {
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  Switch,
  TablePagination,
  useMediaQuery,
} from '@material-ui/core'

import { SectionCard } from 'components'
import {
  InformationSection,
  VersionAlertDialog,
  VersionTable,
} from './components'

import helpers from 'helpers'

import usePagination from 'hooks/usePagination'
import useSnackbar from 'hooks/useSnackbar'
import useFetch from 'hooks/useFetch'

import theme from 'theme'
import schema from './schema'
import useStyles from './styles'
import * as service from 'service'
import constants from 'constants/index'

const DocumentForm = ({
  consentFormRefresh = () => {},
  currentDocument,
  defaultValuesRef,
  depreciatedDocument,
  documentId,
  formRef,
  handleBackRedirection = () => {},
  refresh = () => {},
  setLoading,
  type = 'create',
}) => {
  const {
    ACTIVE_STATUS_ID,
    DRAFT_STATUS_ID,
    PERMISSIONS_CONTROL_ITEMS,
    DOWNLOAD_SNACKBAR_MESSAGE,
  } = constants.documents

  const { getChangedValues } = helpers.validates.form

  const [openDialog, setOpenDialog] = useState(false)
  const [submitData, setSubmitData] = useState()

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(5)

  const classes = useStyles()
  const snackbar = useSnackbar()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const isCreate = type === 'create'
  const isEdit = type === 'edit'
  const isShow = type === 'show'

  const {
    response: documentVersionsResponse,
    isLoading: documentVersionsIsLoading,
    refresh: documentVersionsRefresh,
  } = useFetch(
    service.dponet.privacyPolicies.listVersions,
    {
      privacyPolicyId: documentId,
      minimal: true,
      page,
      perPage,
    },
    [documentId, page, perPage],
    !isCreate,
  )

  const documentVersions =
    documentVersionsResponse?.data?.documentVersions || []

  const defaultValues = {
    category: currentDocument?.category || '',
    otherCategory: currentDocument?.otherCategory || '',
    collectAccepted: currentDocument?.collectAccepted || false,
    content: sanitize(currentDocument?.content) || '',
    publicDocument: currentDocument?.publicDocument || false,
    title: currentDocument?.title || depreciatedDocument?.title || '',
    allowAutomaticEditing: currentDocument?.allowAutomaticEditing || false,
  }

  const formMethods = useForm({
    validationSchema: schema,
    defaultValues,
  })

  const { control, errors, handleSubmit, getValues, triggerValidation, reset } =
    formMethods

  const isActive =
    currentDocument?.status === constants.documents.ACTIVE_STATUS_ID

  const createdBySystem = currentDocument?.createdBySystem

  const handleOpenDialog = () => setOpenDialog(true)
  const handleCloseDialog = () => setOpenDialog(false)

  const handleSubmitValidation = async (status) => {
    const isValid = await triggerValidation()
    const formValues = getValues()

    const changedRelevantValues = getChangedValues(
      { content: formValues.content },
      { content: defaultValues.content },
    )

    const changedAnyValues = getChangedValues(formValues, defaultValues)
    const changeStatus = status !== currentDocument?.status

    if (!isValid || (isEmpty(changedAnyValues) && !changeStatus)) return

    if (
      isEdit &&
      !!currentDocument?.consentForm &&
      !isEmpty(changedRelevantValues)
    ) {
      setSubmitData(status)
      handleOpenDialog()
    } else {
      handleSubmit((data) => onSubmit(data, status))()
    }
  }

  const downloadDocument = async (documentVersionId) => {
    try {
      setLoading(true)

      const response = await service.dponet.documentVersions.exportDocument({
        documentVersionId,
      })

      const fileUrl = response?.data?.fileUrl

      window.open(fileUrl, '_blank')

      snackbar.open({
        message: DOWNLOAD_SNACKBAR_MESSAGE.document,
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = async (data, status) => {
    try {
      setLoading(true)

      const changedValues = helpers.validates.form.getChangedValues(
        data,
        defaultValues,
      )

      if (isEdit) {
        await service.dponet.privacyPolicies.put({
          privacyPolicyId: currentDocument?.id,
          status,
          ...changedValues,
        })
      } else {
        await service.dponet.privacyPolicies.create({
          status,
          ...data,
        })
      }

      snackbar.open({
        message: `Documento ${isEdit ? 'editado' : 'criado'} com sucesso!`,
        variant: 'success',
      })

      if (!isEdit) handleBackRedirection()

      reset()
      refresh()
      consentFormRefresh()
      documentVersionsRefresh()
      handleCloseDialog()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          `Ocorreu uma falha ao tentar ${
            isEdit ? 'editar' : 'criar'
          } o documento!`,
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  useImperativeHandle(formRef, () => ({
    getFormValues: () => getValues(),
  }))

  useImperativeHandle(defaultValuesRef, () => ({
    getDefaultValues: () => defaultValues,
  }))

  useEffect(() => {
    handleChangePage({}, 0)
  }, [])

  return (
    <FormContext {...formMethods}>
      <Box
        display="flex"
        flexDirection="column"
        gridGap={theme.spacing(4)}
        component="form"
        id="document-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        {!isActive && !isCreate && (
          <Alert variant="standard" severity="info">
            Este conteúdo&nbsp;
            {currentDocument?.status === DRAFT_STATUS_ID
              ? 'é um rascunho'
              : 'esta inativado'}
            &nbsp;e não poderá ser visível no canal de atendimento. Para
            visualizar a última versão ativa, consulte o histórico de versões no
            final da tela, se disponível.
          </Alert>
        )}
        <SectionCard
          title="Permissões"
          py={2}
          id={constants.tours.documents.MANAGEMENT_STEP_2}
        >
          <Box display="flex" flexDirection="column">
            {PERMISSIONS_CONTROL_ITEMS.map((item, index) => {
              if (!createdBySystem && item?.name === 'allowAutomaticEditing')
                return null

              return (
                <Fragment key={index}>
                  <Box width="fit-content">
                    <Controller
                      as={
                        <FormControlLabel
                          control={<Switch />}
                          label={item?.label}
                          className={classes.formControlLabel}
                          disabled={isShow}
                        />
                      }
                      control={control}
                      name={item?.name}
                    />
                  </Box>
                  {!!errors?.[item?.name] && (
                    <FormHelperText error={!!errors?.[item?.name]}>
                      {<>{errors?.[item?.name]?.message}</>}
                    </FormHelperText>
                  )}
                </Fragment>
              )
            })}
          </Box>
        </SectionCard>
        <SectionCard title="Informações do documento">
          <InformationSection
            type={type}
            currentDocument={currentDocument}
            depreciatedDocument={depreciatedDocument}
          />
        </SectionCard>
        {!isCreate && (
          <SectionCard title="Histórico de versões" isTable hasPagination>
            <VersionTable
              documentVersions={documentVersions}
              isLoading={documentVersionsIsLoading}
              downloadDocument={downloadDocument}
              documentId={documentId}
            />
            <TablePagination
              component="div"
              count={documentVersionsResponse?.data?.meta?.totalCount || 0}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              page={page - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[5, 10, 25, 100]}
              labelRowsPerPage={isDesktop ? 'Por página' : ''}
              nextIconButtonProps={{ size: 'small' }}
              backIconButtonProps={{ size: 'small' }}
            />
          </SectionCard>
        )}
        <Box
          display="flex"
          gridGap={theme.spacing(1)}
          justifyContent="flex-end"
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={handleBackRedirection}
          >
            Voltar
          </Button>
          {!isShow && (
            <Fragment>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleSubmitValidation(DRAFT_STATUS_ID)}
              >
                Salvar Rascunho
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSubmitValidation(ACTIVE_STATUS_ID)}
              >
                {isActive ? 'Salvar' : 'Publicar'}
              </Button>
            </Fragment>
          )}
        </Box>
      </Box>
      <VersionAlertDialog
        open={openDialog}
        setOpen={setOpenDialog}
        onSubmit={handleSubmit((data) => onSubmit(data, submitData))}
      />
    </FormContext>
  )
}

DocumentForm.propTypes = {
  consentFormRefresh: PropTypes.func,
  currentDocument: PropTypes.object,
  defaultValuesRef: PropTypes.shape({ current: PropTypes.object }),
  depreciatedDocument: PropTypes.object,
  documentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formRef: PropTypes.shape({ current: PropTypes.object }),
  handleBackRedirection: PropTypes.func.isRequired,
  refresh: PropTypes.func,
  setLoading: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['create', 'edit', 'show']),
}

export default DocumentForm
