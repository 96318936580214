import React, { useState } from 'react'
import { Box, Button, Grid } from '@material-ui/core'
import { FormContext, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import { Card } from 'components'
import { PartnerInfoForm } from './components'
import QuestionnaireForm from '../QuestionnaireForm'

import useSnackbar from 'hooks/useSnackbar'

import partnerInfoFormSchema from './components/PartnerInfoForm/schema'
import theme from 'theme'
import * as service from 'service'
import helpers from 'helpers'
import { routes } from 'Routes'
import constants from 'constants/index'

const ImportForm = () => {
  const [selectedQuestionnaires, setSelectedQuestionnaires] = useState([])

  const history = useHistory()
  const snackbar = useSnackbar()

  const partnerInfoForm = useForm({
    validationSchema: partnerInfoFormSchema,
    defaultValues: {
      registerType: false,
      internationalSupplier: false,
      document: undefined,
      date: null,
      supplierAudit: false,
      supplierAuditFrequency: 12,
      supplierAuditFrequencyType: 'months',
    },
  })

  const onSubmit = async () => {
    try {
      const partnerInfoFormDataValid = await partnerInfoForm.triggerValidation()

      if (!partnerInfoFormDataValid) return

      const partnerInfoFormData = partnerInfoForm.getValues()

      await service.dponet.suppliers.importSuppliers({
        companySupplier: {
          document: partnerInfoFormData.document,
          simplified: partnerInfoFormData.registerType,
          international: partnerInfoFormData.international,
          dateToSendEmails: helpers.thirdPartyManegementPartners.formatDate(
            partnerInfoFormData.date,
          ),
          details: {
            enableAudit: partnerInfoFormData.supplierAudit,
            auditFrequency: partnerInfoFormData.supplierAuditFrequency,
            auditFrequencyType: partnerInfoFormData.supplierAuditFrequencyType,
          },
          fileToImport: partnerInfoFormData.document,
          questionnaires: selectedQuestionnaires,
        },
      })

      snackbar.open({
        message:
          'Informaremos por e-mail assim que todos os parceiros forem cadastrados!',
        variant: 'success',
      })
      history.push(routes.thirdPartyManagements.invites)
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(
            error?.response?.data?.error,
            false,
          ) || 'Ocorreu um erro na importação!',
        variant: 'error',
      })
    }
  }

  const handleReturn = () => {
    history.goBack()
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card
          id={constants.tours.thirdPartyManagements.STEPS.CREATION.IMPORT[2]}
          title="Informações do Parceiro"
        >
          <FormContext {...partnerInfoForm}>
            <PartnerInfoForm />
          </FormContext>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card
          id={constants.tours.thirdPartyManagements.STEPS.CREATION.IMPORT[3]}
          title="Questionários"
        >
          <QuestionnaireForm
            selectedQuestionnaires={selectedQuestionnaires}
            setSelectedQuestionnaires={setSelectedQuestionnaires}
          />
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Box
          display="flex"
          justifyContent="flex-end"
          gridGap={theme.spacing(2)}
        >
          <Button variant="outlined" onClick={handleReturn}>
            Voltar
          </Button>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            Salvar
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

export default ImportForm
