import { Box, Button, Paper, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { FormContext, useFieldArray, useForm } from 'react-hook-form'
import { Plus as PlusIcon } from 'react-feather'
import { useHistory } from 'react-router-dom'
import { Skeleton } from '@material-ui/lab'
import { isEmpty } from 'lodash'

import useSnackbar from 'hooks/useSnackbar'
import useFetch from 'hooks/useFetch'
import usePermissions from 'hooks/usePermissions'

import { Permitted } from 'components'
import { Configuration, RecurringEmail } from './components'

import useStyles from './styles'
import { routes } from 'Routes'
import * as service from 'service'
import schema from './schema'
import helpers from 'helpers'
import constants from 'constants/index'

const ManageConfiguration = ({
  complaintConfiguration,
  subConfigurations,
  refresh,
}) => {
  const [recurring, setRecurring] = useState(false)
  const [subConfigurationsDelete, setSubConfigurationsDelete] = useState([])

  const classes = useStyles()
  const history = useHistory()
  const snackbar = useSnackbar()
  const permissions = usePermissions()

  const { response, isLoading } = useFetch(
    service.dponet.complaintCategories.getCategories,
    {},
    [],
  )
  const { response: responseUsers, isLoading: isLoadingUsers } = useFetch(
    service.dponet.users.get,
    {
      perPage: 10000,
      minimal: true,
      showSelf: true,
      userCompanyStatus: constants.userCompanies.ACTIVE,
    },
  )

  const complaintCategories = response?.data?.complaintCategories
  const users = responseUsers?.data?.users

  const showComplaintCategories = !isLoading && !isLoadingUsers

  const notHasPermissionToManage =
    !permissions.permitted(
      constants.permissions.COMPLAINTS.MANAGE_CONFIGURATIONS,
    ) &&
    !permissions.permitted(constants.permissions.COMPLAINTS.ACTIVATE_COMPLAINTS)

  const backToComplaints = () => {
    return history.push(routes.complaint.all)
  }

  const methods = useForm({
    validationSchema: schema,
    defaultValues: {
      subConfigurations: [
        {
          subConfigurationId: '',
          categoryId: '',
          responsibles: [],
          subCategories: [],
        },
      ],
    },
    mode: 'onChange',
  })

  const { control, reset, handleSubmit } = methods

  const {
    fields: complaintConfigurationsFields,
    append,
    remove,
  } = useFieldArray({
    control: control,
    name: 'subConfigurations',
  })

  const addMoreCategory = () => {
    append({
      subConfigurationId: '',
      categoryId: '',
      responsibles: [],
      subcategories: [],
    })
  }

  const onSubmit = async (data) => {
    data.subConfigurationsDelete = subConfigurationsDelete
    try {
      await service.dponet.complaintConfigurations.manage({
        complaintConfigurations: data,
      })
      snackbar.open({
        message: 'Configurações foram salvas!',
        variant: 'success',
      })
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error.response.data.error) ||
          'Aconteceu um erro ao salvar as configurações',
        variant: 'error',
      })
    } finally {
      refresh()
    }
  }

  useEffect(() => {
    setRecurring(complaintConfiguration?.recurringEmail)

    if (!isEmpty(subConfigurations)) {
      reset({
        subConfigurations: subConfigurations.map((subConfiguration) => ({
          subConfigurationId: subConfiguration.id,
          categoryId: subConfiguration.categoryId,
          responsibles: subConfiguration.responsibles || [],
          subCategories: subConfiguration.subCategories || [],
        })),
      })
    }
    // eslint-disable-next-line
  }, [subConfigurations, complaintConfiguration])

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Box>
          <Typography color="primary" variant="subtitle1">
            Categorias e Responsáveis
          </Typography>
          {showComplaintCategories ? (
            complaintConfigurationsFields.map((configurationField, index) => (
              <Configuration
                key={configurationField.id}
                fields={complaintConfigurationsFields}
                complaintCategories={complaintCategories}
                responsibles={users}
                index={index}
                remove={remove}
                configurationField={configurationField}
                setSubConfigurationsDelete={setSubConfigurationsDelete}
                disabled={notHasPermissionToManage}
              />
            ))
          ) : (
            <Box mt={1}>
              <Box>
                <Skeleton variant="rect" width="100%" height={180} />
              </Box>
              <Box mt={1}>
                <Skeleton variant="rect" width="100%" height={180} />
              </Box>
            </Box>
          )}
          <Permitted
            someTags={[
              constants.permissions.COMPLAINTS.MANAGE_CONFIGURATIONS,
              constants.permissions.COMPLAINTS.ACTIVATE_COMPLAINTS,
            ]}
          >
            <Paper className={classes.paperButtonAddCategory}>
              <Button
                onClick={addMoreCategory}
                fullWidth
                className={classes.buttonAddCategory}
                startIcon={<PlusIcon size={20} />}
                disabled={!showComplaintCategories}
              >
                Adicionar categorias e responsáveis
              </Button>
            </Paper>
          </Permitted>
        </Box>
        <RecurringEmail
          recurring={recurring}
          setRecurring={setRecurring}
          recurringEmailDays={complaintConfiguration?.recurringEmailDays}
          disabled={notHasPermissionToManage}
        />
        <Box
          mt={2}
          display="flex"
          justifyContent="flex-end"
          className={classes.boxButtonsConfig}
        >
          <Button variant="outlined" onClick={backToComplaints}>
            Voltar
          </Button>
          <Permitted
            someTags={[
              constants.permissions.COMPLAINTS.MANAGE_CONFIGURATIONS,
              constants.permissions.COMPLAINTS.ACTIVATE_COMPLAINTS,
            ]}
          >
            <Button color="primary" variant="contained" type="submit">
              Salvar
            </Button>
          </Permitted>
        </Box>
      </form>
    </FormContext>
  )
}

export default ManageConfiguration
