import React from 'react'

import { FilterButton, Filters } from 'components'

const FiltersComponent = ({ filter = {} }) => {
  return (
    <>
      <FilterButton setDrawerOpen={filter.setDrawerOpen} />
      <Filters filter={filter}>
        <input textfieldinput="true" label="Identificador" name="id" />

        <input textfieldinput="true" label="Nome" name="name" />

        <input textfieldinput="true" label="Documento" name="document" />
      </Filters>
    </>
  )
}

export default FiltersComponent
