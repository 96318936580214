import React from 'react'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'

import { Card } from 'components'

import constants from 'constants/index'

const RegisterTypeForm = ({
  registerType,
  setRegisterType,
  isEdit = false,
}) => {
  const registerTypes = constants.thirdPartyManagements.REGISTER_TYPES

  const handleChangeRegisterType = (e) => {
    setRegisterType(e.target.value)
  }

  return (
    <Card title="Opção de Cadastro">
      <RadioGroup
        defaultValue={registerTypes.REGISTER}
        onChange={handleChangeRegisterType}
        value={registerType}
      >
        <FormControlLabel
          value={registerTypes.REGISTER}
          control={<Radio />}
          label={`${isEdit ? '' : 'Cadastrar '}Parceiro`}
          disabled={isEdit}
        />
        {!isEdit && (
          <FormControlLabel
            value={registerTypes.IMPORT}
            control={<Radio />}
            label="Importar Parceiro"
          />
        )}
        <FormControlLabel
          value={registerTypes.PRE_AVALIATION}
          control={<Radio />}
          label="Pré-Avaliação"
          disabled={isEdit}
        />
      </RadioGroup>
    </Card>
  )
}

export default RegisterTypeForm
