import React, { useState } from 'react'
import { Box, IconButton, Slider } from '@material-ui/core'
import {
  MinusCircle as MinusCircleIcon,
  PlusCircle as PlusCircleIcon,
} from 'react-feather'

import useStyles from './styles'
import theme from 'theme'

const GradientSlider = ({
  options = [],
  setControllerValue,
  name,
  min = 1,
  max,
  defaultValue = 1,
}) => {
  const [value, setValue] = useState(defaultValue)
  const currentOption = options.find((option) => option.value === value)

  const classes = useStyles(options, currentOption)
  const quantity = max || options.length

  const handleChange = (e, newValue) => {
    if (setControllerValue && name) {
      setControllerValue(name, newValue)
    }

    setValue(newValue)
    return e
  }

  const addValue = () => {
    if (value < quantity) {
      handleChange(null, value + 1)
    }
  }

  const subtractValue = () => {
    if (value > min) {
      handleChange(null, value - 1)
    }
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      gridGap={theme.spacing(2)}
    >
      <IconButton size="small" onClick={subtractValue}>
        <MinusCircleIcon size={16} />
      </IconButton>
      <Box flex="1" marginTop="-3px">
        <Slider
          classes={{
            root: classes.root,
            valueLabel: classes.valueLabel,
            track: classes.track,
            rail: classes.rail,
            mark: classes.mark,
            markActive: classes.markActive,
            thumb: classes.thumb,
          }}
          value={value}
          max={quantity}
          min={min}
          valueLabelFormat={(value) =>
            options.find((option) => option.value === value)?.label
          }
          valueLabelDisplay="on"
          defaultValue={1}
          onChange={handleChange}
        />
      </Box>
      <IconButton size="small" onClick={addValue}>
        <PlusCircleIcon size={16} />
      </IconButton>
    </Box>
  )
}

export default GradientSlider
