import React, { useEffect, useImperativeHandle, useState } from 'react'
import { Check as CheckIcon } from 'react-feather'
import PropTypes from 'prop-types'
import { size } from 'lodash'
import { Box, Button, Grid } from '@material-ui/core'

import { Consents, LoadingFeedback, SectionCard } from 'components'
import SharingStep from '../SharingStep'

import useConsent from 'hooks/useConsent'

import constants from 'constants/index'

const AcceptedForm = ({
  consentForm,
  consentFormRefresh,
  defaultValuesRef,
  currentDocument,
  formRef,
  refresh,
  setLoading,
}) => {
  const { ACCEPTANCE_STEPS } = constants.documents

  const [loadingDefaultValues, setLoadingDefaultValues] = useState(true)
  const [defaultValues, setDefaultValues] = useState({})

  const {
    activeStep,
    backStep,
    dataConsentForm,
    lastActiveStep,
    setActiveStep,
    setDataConsentForm,
    setLastActiveStep,
    setToNextStep,
  } = useConsent()

  const isLastStep = activeStep === size(ACCEPTANCE_STEPS) - 1

  const handleNextStep = () => setToNextStep(true)

  useImperativeHandle(formRef, () => ({
    getFormValues: () => dataConsentForm,
  }))

  useImperativeHandle(defaultValuesRef, () => ({
    getDefaultValues: () => defaultValues,
  }))

  useEffect(() => {
    setLoadingDefaultValues(true)
    const defaultValues = {
      ...consentForm,
      title: currentDocument?.title,
      content: currentDocument?.content,
      kind: constants.consent.DOCUMENT_KIND,
      privacyPolicyId: currentDocument?.id,
      ...(consentForm?.components && {
        components: JSON.parse(consentForm?.components),
      }),
    }

    setDataConsentForm({ ...defaultValues })
    setDefaultValues({ ...defaultValues })
    setActiveStep(currentDocument?.consentFormId ? 2 : 0)
    setLastActiveStep(currentDocument?.consentFormId ? 2 : 0)
    setLoadingDefaultValues(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SectionCard title="Informações do aceites">
      <LoadingFeedback open={loadingDefaultValues} />
      <Grid container spacing={3}>
        {!loadingDefaultValues && (
          <Grid item xs={12} md={9}>
            {activeStep === 0 && <Consents.Fields />}
            {activeStep === 1 && (
              <Consents.Preview
                isAccepted={
                  !currentDocument?.consentFormId && !dataConsentForm?.id
                }
                setLoading={setLoading}
                refresh={refresh}
                consentFormRefresh={consentFormRefresh}
              />
            )}
            {activeStep === 2 && (
              <SharingStep
                setLoading={setLoading}
                refresh={refresh}
                consentFormRefresh={consentFormRefresh}
                currentDocument={currentDocument}
              />
            )}
          </Grid>
        )}
        <Grid item xs={12} md={3}>
          <Consents.Stepper
            steps={constants.documents.ACCEPTANCE_STEPS}
            lastActiveStep={lastActiveStep}
          />
          <Grid
            container
            component={Box}
            display="flex"
            direction="row"
            justifyContent="space-between"
            py={2}
          >
            <Button
              variant="outlined"
              onClick={backStep}
              disabled={activeStep === 0}
            >
              VOLTAR
            </Button>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CheckIcon />}
              onClick={handleNextStep}
            >
              {isLastStep ? 'Salvar' : 'Próximo'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </SectionCard>
  )
}

AcceptedForm.propTypes = {
  consentForm: PropTypes.object,
  consentFormRefresh: PropTypes.func.isRequired,
  defaultValuesRef: PropTypes.object,
  currentDocument: PropTypes.object,
  formRef: PropTypes.object,
  refresh: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
}

export default AcceptedForm
