import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { isNil } from 'lodash'

import { ResetTemporaryPassword } from 'components'

import { Route } from 'react-router-dom'

import * as service from 'service'
import { routes } from 'Routes'

import qs from 'query-string'
import useAuth from 'hooks/useAuth'
import useSnackbar from 'hooks/useSnackbar'

import helpers from 'helpers'
import constants from 'constants/index'

const DefaultComponent = ({ children }) => <>{children}</>

const RouteWithLayout = ({
  auth,
  layout,
  provider,
  component: Component,
  permission,
  somePermissions,
  reduceMargin,
  admin = false,
  redirect = true,
  ...rest
}) => {
  const snackbar = useSnackbar()
  const Layout = layout || DefaultComponent
  const Provider = provider || DefaultComponent
  const search = qs.parse(rest.location.search)
  const isAuthenticated = service.dponet.auth.isAuthenticated()
  const company = service.dponet.auth.getCompany()
  const {
    user,
    homeRoute,
    loaded,
    loadOut,
    isLoading,
    temporaryPassword,
    company: companyData,
  } = useAuth()

  useEffect(() => {
    const defineToken = async () => {
      service.dponet.auth.removeCompany()
      await service.dponet.auth.setToken(search.redirect_token)
      service.history.push(homeRoute)
    }

    if (search.redirect_token) {
      defineToken()
    }
  })

  if (loaded && temporaryPassword)
    return (
      <Provider component={Provider}>
        <ResetTemporaryPassword />
      </Provider>
    )

  // Steps
  const suppliersStep = user?.userSteps?.find(
    (userStep) => userStep?.tag === constants.userSteps?.STEP_SUPPLIERS_TAG,
  )

  const allStepsConcluded =
    user?.allStepsConcluded || helpers.company.isSupplier(companyData)
  const firstStepsConcluded = user && allStepsConcluded !== false
  const showBackToFirstSteps =
    firstStepsConcluded === false &&
    !isNil(companyData?.templateCompany?.id) &&
    rest.path !== routes.userSteps

  const suppliersReducedRoutes = helpers.functions.reduceRoutesToArray(
    !!suppliersStep?.concluded && routes.mySuppliers,
  )

  const unlockedRoutes = [
    ...helpers.functions.reduceRoutesToArray(routes.user),
    ...helpers.functions.reduceRoutesToArray(routes.marketplace),
    ...helpers.functions.reduceRoutesToArray(routes.privacyStamp),
    ...helpers.functions.reduceRoutesToArray(routes.profiles),
    ...helpers.functions.reduceRoutesToArray(routes.department),
    ...suppliersReducedRoutes,
    routes.userSteps,
    routes.settings,
  ]

  if (isAuthenticated) {
    if (redirect) {
      if (!auth) {
        if (!company && rest.path !== routes.selectCompany) {
          service.history.push(routes.selectCompany)
          return null
        }

        if (homeRoute) {
          service.history.push(homeRoute)
          return null
        }
      }

      if (!isLoading) {
        if (!company && rest.path !== routes.selectCompany) {
          service.history.push(routes.selectCompany)
          return null
        }

        if (company && rest.path === routes.selectCompany) {
          service.history.push(homeRoute)
          return null
        }
      }
    }

    if (companyData?.supplierLoginStep) {
      if (
        companyData?.supplierLoginStep ===
        constants.companySuppliers.UNABLE_STEP
      ) {
        service.dponet.auth.logout()
        loadOut()

        snackbar.open({
          message:
            'Você não possui vínculos pendentes ou aprovados com clientes DPOnet',
          variant: 'error',
        })
        return null
      }

      if (
        companyData?.supplierLoginStep ===
          constants.companySuppliers.FIRST_STEP &&
        rest.path !== routes.supplierStep
      ) {
        service.history.push(routes.supplierStep)
        return null
      }

      if (
        companyData?.supplierLoginStep ===
          constants.companySuppliers.QUESTIONNAIRE_STEP &&
        rest.path === routes.supplierStep
      ) {
        service.history.push(homeRoute)
        return null
      }
    }

    if (
      company &&
      firstStepsConcluded === false &&
      !unlockedRoutes.includes(rest.path) &&
      !isNil(companyData?.templateCompany?.id) &&
      rest.path !== routes.userSteps
    ) {
      service.history.push(routes.userSteps)
      return null
    }
  } else {
    if (auth) {
      service.dponet.auth.logout()
      loadOut()
      return null
    }
  }

  const renderRoute = (matchProps) => {
    return (
      <Provider component={Provider}>
        <Layout
          permission={permission}
          somePermissions={somePermissions}
          reduceMargin={reduceMargin}
          admin={admin}
          showBackToFirstSteps={showBackToFirstSteps}
          auth={auth}
        >
          <Component {...matchProps} />
        </Layout>
      </Provider>
    )
  }

  return <Route {...rest} render={(matchProps) => renderRoute(matchProps)} />
}

RouteWithLayout.propTypes = {
  component: PropTypes.any,
  layout: PropTypes.any,
  path: PropTypes.string,
  permission: PropTypes.string,
  admin: PropTypes.bool,
}

export default RouteWithLayout
