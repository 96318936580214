import React from 'react'
import clsx from 'clsx'
import { Box, Button, Paper, Typography } from '@material-ui/core'

import useSupplierInvite from 'hooks/useSupplierInvite'

import useStyles from './styles'
import { getWebsiteURL } from 'service/env'

const SupplierNotPermittedFunctionality = () => {
  const classes = useStyles()
  const { supplierData } = useSupplierInvite()

  const handleFinish = () => window.open(getWebsiteURL(), '_self')

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Box className={classes.groupTypography}>
        <Typography className={clsx(classes.text, classes.textWelcome)}>
          Permissão negada!
        </Typography>

        <Typography className={classes.text} gutterBottom>
          Identificamos que esta organização já possui um acesso ativo, mas você
          não está na lista de usuários autorizados a continuar esta jornada.
          Entre em contato com o responsável:{' '}
          <span className={classes.highlight}>
            {supplierData?.responsibleUserEmail}
          </span>
        </Typography>
      </Box>
      <Paper
        variant="outlined"
        component={Button}
        className={classes.button}
        onClick={handleFinish}
        fullWidth
      >
        Finalizar
      </Paper>
    </Box>
  )
}

export default SupplierNotPermittedFunctionality
