import { colors, makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  headerBox: {
    backgroundColor: theme.palette.primary.light,
  },
  consentBox: {
    border: `1px solid ${theme.palette.divider}`,
  },
  attributeBox: {
    backgroundColor: theme.palette.primary.light,
    border: `1px solid ${theme.palette.paper.border}`,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  menuListBox: {
    border: `1px solid ${theme.palette.divider}`,
  },
  helpCentralButton: {
    backgroundColor: colors.green[500],
    color: colors.grey[50],
    width: 150,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '&:hover': {
      backgroundColor: colors.green[500],
      opacity: 0.8,
    },
  },
  buttonField: {
    justifyContent: 'start',
    textTransform: 'initial',
    paddingInline: theme.spacing(2),
    paddingBlock: theme.spacing(1),
  },
  menuButton: {
    display: 'contents',
  },
}))

export default styles
