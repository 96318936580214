import banner from './banner'
import category from './category'
import consent from './consent'
import language from './language'

const cookies = {
  banner,
  category,
  consent,
  language,
}

export default cookies
