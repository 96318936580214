import clsx from 'clsx'
import PropTypes from 'prop-types'
import { driver } from 'driver.js'
import { isEmpty } from 'lodash'

import usePermissions from 'hooks/usePermissions'

const useDriver = ({
  stepsMatrix,
  defaultStyle,
  customStyle,
  setOpen = () => {},
}) => {
  const permissions = usePermissions()

  const handleDestroyTour = () => {
    setOpen(false)
    driverObj.destroy()
  }

  const filterTour = (stepsObject = []) =>
    stepsObject.filter((stepObject) => {
      const tag = stepObject?.tag

      if (isEmpty(tag)) return true

      if (Array.isArray(tag)) return permissions.some(tag)

      return permissions.permitted(stepObject.tag)
    })

  const driverObj = driver({
    showProgress: true,
    popoverClass: clsx(defaultStyle, customStyle),
    steps: filterTour(stepsMatrix),
    progressText: `{{current}} de {{total}}`,
    stagePadding: 4,
    stageRadius: 4,
    disableActiveInteraction: true,
    prevBtnText: 'Anterior',
    nextBtnText: 'Próximo',
    doneBtnText: 'Concluir',
    onDestroyStarted: handleDestroyTour,
  })

  return driverObj
}

useDriver.propTypes = {
  stepsMatrix: PropTypes.arrayOf(PropTypes.object),
  defaultStyle: PropTypes.string,
  customStyle: PropTypes.string,
  setOpen: PropTypes.func,
}

export default useDriver
