import { colors, makeStyles } from '@material-ui/core'

const styles = makeStyles(() => ({
  root: {
    textTransform: 'uppercase',
  },
  approved: {
    color: 'white',
    backgroundColor: colors.green[500],
  },
  inactive: {
    color: 'black',
    backgroundColor: colors.grey[300],
  },
}))

export default styles
